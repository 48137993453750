/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAKaitologo: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M0 63.330973C0 28.360335 28.360335 0 63.330973 0h258.697431c34.970638 0 63.330973 28.360335 63.330973 63.330973V254.176836c0 34.970638 28.360335 63.330973 63.330973 63.330973h172.507601c16.760318 0 32.88093-6.652951 44.779476-18.551497l83.119237-83.161884c11.898546-11.855899 18.551497-27.933864 18.551497-44.779477V63.37362C767.648161 28.360335 796.008496 0 830.979135 0h129.220773C995.170547 0 1023.530882 28.360335 1023.530882 63.330973v129.220774C1023.530882 227.522386 995.170547 255.88272 960.199908 255.88272h-102.822206c-16.802965 0-32.923577 6.652951-44.779476 18.551498l-91.179543 91.136895a63.330973 63.330973 0 0 0-18.551497 44.779476v204.279705c0 16.802965 6.695598 32.923577 18.551497 44.779477l89.644246 89.686893c11.898546 11.855899 27.976511 18.551497 44.779477 18.551497h104.357502c34.970638 0 63.330973 28.360335 63.330974 63.330974v129.220773c0 34.970638-28.360335 63.330973-63.330974 63.330974h-129.220773c-34.970638 0-63.330973-28.402982-63.330974-63.373621v-106.191329c0-16.802965-6.652951-32.88093-18.551497-44.779476l-87.767773-87.767773a63.330973 63.330973 0 0 0-44.779476-18.551497H448.647703c-34.970638 0-63.330973 28.360335-63.330973 63.330973v194.001749c0 34.970638-28.360335 63.330973-63.330973 63.330974H63.330973C28.360335 1023.530882 0 995.170547 0 960.199908v-258.69743c0-34.970638 28.360335-63.330973 63.330973-63.330973H254.176836c34.970638 0 63.330973-28.360335 63.330973-63.330974V448.647703c0-34.970638-28.360335-63.330973-63.330973-63.330973H63.330973C28.360335 385.359377 0 356.999042 0 322.028404V63.330973z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAKaitologo.defaultProps = {
  size: 14,
};

export default IconAKaitologo;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconCrownUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.573333 120.746667h-0.170666a125.568 125.568 0 0 0-92.117334 39.509333 128 128 0 0 0-34.218666 101.333333l0.682666 5.418667c2.133333 14.336 6.528 27.989333 13.098667 40.618667l1.536 2.858666L313.173333 416.341333 176.896 356.266667a65.749333 65.749333 0 0 0-90.368 75.946666l103.808 421.034667a65.749333 65.749333 0 0 0 63.829333 50.005333h515.712a65.706667 65.706667 0 0 0 63.872-50.005333l103.850667-421.376a65.749333 65.749333 0 0 0-48.128-79.573333l-4.224-0.896a65.749333 65.749333 0 0 0-37.845333 4.48l-138.496 60.586666-87.296-105.941333 1.365333-2.432c7.338667-14.037333 12.074667-29.354667 13.866667-45.482667a128 128 0 0 0-75.008-131.242666 125.696 125.696 0 0 0-50.261334-10.666667z m-4.096 65.834666l3.882667-0.128a59.946667 59.946667 0 0 1 44.117333 19.626667 62.208 62.208 0 0 1 16 49.322667c-1.237333 11.477333-5.674667 22.4-12.8 31.488l-2.773333 3.370666a32.853333 32.853333 0 0 0-0.896 42.88l118.954667 144.298667a32.853333 32.853333 0 0 0 38.528 9.216l161.28-70.485333-103.893334 421.333333H254.165333L150.357333 416.426667l159.061334 70.144a32.853333 32.853333 0 0 0 38.656-9.130667l118.954666-144.341333a32.896 32.896 0 0 0-0.853333-42.837334 62.165333 62.165333 0 0 1-15.658667-35.413333 62.165333 62.165333 0 0 1 16.469334-49.322667c10.538667-11.306667 25.045333-18.133333 40.533333-18.944z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M322.986667 589.056l42.794666 171.434667H295.466667l-38.741334-154.88a34.133333 34.133333 0 0 1 66.218667-16.554667z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconCrownUncover.defaultProps = {
  size: 14,
};

export default IconIconCrownUncover;

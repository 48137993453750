import {
  SmartFollowingInfo,
  getSavedSmartFollowing,
  SmartFollowingCircleItem,
} from "@/client/smart_follow";
import { atom } from "jotai";
import { toPairs } from "lodash";

export const smartFollowingInfoBaseAtom = atom({
  customSmartFollowingCircle: {},
  sortedCustomSmartFollowingCircle: [],
  loading: true,
} as SmartFollowingInfo & {
  loading: boolean;
  sortedCustomSmartFollowingCircle: Array<[string, SmartFollowingCircleItem]>;
});

export const smartFollowingInfoAtom = atom(
  (get) => {
    return get(smartFollowingInfoBaseAtom);
  },
  async (get, set, update?: SmartFollowingInfo) => {
    try {
      // if no explict data to update, we need to fetch the data from server
      if (!update) {
        set(smartFollowingInfoBaseAtom, {
          ...get(smartFollowingInfoBaseAtom),
          loading: true,
        });
        update = await getSavedSmartFollowing();
      }
      // we sort the circle by updatedAt from latest to oldtest for convenience
      const sortedCustomSmartFollowingCircle = toPairs(update.customSmartFollowingCircle).sort(
        (a, b) => b[1].updatedAt - a[1].updatedAt
      );

      set(smartFollowingInfoBaseAtom, {
        customSmartFollowingCircle: update.customSmartFollowingCircle,
        sortedCustomSmartFollowingCircle,
        loading: false,
      });
    } catch (e) {
      console.log("fetch saved smart following error ", e.message);
      set(smartFollowingInfoBaseAtom, {
        ...get(smartFollowingInfoBaseAtom),
        loading: false,
      });
    }
  }
);

export const init_smartFollowing_delete_props: {
  visible: boolean;
  confirmCb: () => void;
  title: string;
  content?: string;
  okText?: string;
} = {
  visible: false,
  confirmCb: () => {},
  title: "",
  content: "",
};

export const smartFollowingDeleteBaseAtom = atom(init_smartFollowing_delete_props);
export const smartFollowingDeleteInfoAtom = atom(
  (get) => get(smartFollowingDeleteBaseAtom),
  (get, set, newProps) => {
    set(smartFollowingDeleteBaseAtom, newProps);
  }
);

export const init_smartFollowing_edit_circle_props: {
  visible: boolean;
  data: Array<string>;
  selected?: Array<string>;
  name: string;
  extraCallbck?: (circleName: string, newFollowingList: string[]) => void;
  showSelectedCount?: boolean;
} = {
  visible: false,
  data: [],
  selected: [],
  name: "",
  showSelectedCount: false,
};

export const smartFollowingEditCircleBaseAtom = atom(init_smartFollowing_edit_circle_props);
export const smartFollowingEditCircleInfoAtom = atom(
  (get) => get(smartFollowingEditCircleBaseAtom),
  (get, set, newProps: typeof init_smartFollowing_edit_circle_props) => {
    newProps.showSelectedCount = newProps.showSelectedCount ?? false;
    set(smartFollowingEditCircleBaseAtom, newProps);
  }
);

export const init_smartFollowing_add_circle_props: {
  visible: boolean;
  id: number | string;
} = {
  visible: false,
  id: 0,
};

export const smartFollowingAddToCircleAtom = atom(init_smartFollowing_add_circle_props);

export const smartFollowingAddToCircleInfoAtom = atom(
  (get) => get(smartFollowingAddToCircleAtom),
  (get, set, newProps: typeof init_smartFollowing_add_circle_props) =>
    set(smartFollowingAddToCircleAtom, newProps)
);

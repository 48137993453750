import { useState } from "react";
import { Switch, Tooltip } from "@chakra-ui/react";

const OfficialSwitch = ({
  onChange,
  isChecked,
  tickerSelected,
}: {
  onChange: (checked: boolean) => void;
  isChecked: boolean;
  tickerSelected: boolean;
}) => {
  const [tooltipOpened, setTooltipOpened] = useState(false);

  return (
    <Tooltip
      label="Requires at least 1 official source related to selected tickers/NFTs"
      isOpen={tooltipOpened}
      placement="bottom"
      color="whiteAlpha.800"
      p="8px 12px"
      top={"20px"}
      left="20px"
    >
      <Switch
        colorScheme={"brand"}
        borderRadius={"34px"}
        isChecked={isChecked}
        // TODO: abstract this color in kaito-components
        sx={{
          "span.chakra-switch__track:not([data-checked])": { backgroundColor: "grayBlue.300" },
          "span.chakra-switch__thumb": { backgroundColor: "brand.600" },
          "span.chakra-switch__thumb:not([data-checked])": { backgroundColor: "grayBlue.600" },
        }}
        onChange={(e) => {
          if (!tickerSelected) {
            setTooltipOpened(true);
            // hidden tooltip after 2s
            setTimeout(() => {
              setTooltipOpened(false);
            }, 2000);
          } else {
            onChange(e.target.checked);
          }
        }}
      />
    </Tooltip>
  );
};

export default OfficialSwitch;

import { SourceFilterValue } from "@/components/Filter/SourceFilter/constants";
import { TabRadio } from "@/components/Filter/SourceFilter/TabRadio";
import { SourceFilterScope, SourceFilterType } from "@/components/Filter/SourceFilter/types";
import renderFilterField from "@/components/Filter/SourceFilter/warpcast_by_user/index";

export const WarpCast_Count_Range = [0, 10, 20, 30, 40, 50, 100, 200, 500, 1000];
export const WarpCastSourceFilter: SourceFilterValue = {
  title: "Farcaster",
  value: "Warpcast",
  rangeFilterTitle: "Engagement",
  filterBlocks: [
    {
      title: "Engagement",
      fields: [
        {
          field: "warpcast_reactions_count",
          label: "Likes",
          range: WarpCast_Count_Range,
          scope: SourceFilterScope.Warpcast,
          type: SourceFilterType.Range,
        },
        {
          field: "warpcast_recasts_count",
          label: "Recasts",
          range: WarpCast_Count_Range,
          scope: SourceFilterScope.Warpcast,
          type: SourceFilterType.Range,
        },
        {
          field: "warpcast_quotes_count",
          label: "Quotes",
          range: WarpCast_Count_Range,
          scope: SourceFilterScope.Warpcast,
          type: SourceFilterType.Range,
        },
        {
          field: "warpcast_replies_count",
          label: "Reply",
          range: WarpCast_Count_Range,
          scope: SourceFilterScope.Warpcast,
          type: SourceFilterType.Range,
        },
      ],
    },
    {
      title: "Cast Type",
      fields: [
        {
          field: "warpcast_type",
          scope: SourceFilterScope.Warpcast,
          type: SourceFilterType.SingleSelect,
          renderFilterField: (onChange, value) => (
            <TabRadio
              options={[
                { title: "All", value: "" },
                { title: "Thread", value: "thread" },
                { title: "Cast", value: "cast" },
              ]}
              value={value}
              handleChange={onChange}
              defaultValue=""
            />
          ),
          toTitle: (value) => value,
        },
      ],
    },
    {
      title: "User",
      showSuggest: true,
      field: "warpcast_user_id",
      fields: [
        {
          field: "warpcast_user_id",
          scope: SourceFilterScope.Warpcast,
          type: SourceFilterType.MultiSelect,
          renderFilterField: renderFilterField,
          toTitle: (value) => (value.length > 1 ? `${value.length} users` : `1 user`),
        },
      ],
    },
  ],
};

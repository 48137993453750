import { get, isEmpty, pick, sortBy, uniqBy } from "lodash";

import { DataSourceIcon } from "@/components/DataSourceIcon";
import { parseCustomEmoji } from "@/components/Discord/parse/markdown";
import { SearchResultTip } from "@/components/SearchResult/SearchResultTip";
import { getTwitterAvatar, removeMarkdownRegex } from "@/components/utils";
import { indexCategory } from "@/constants";
import type {
  DuneDashboardObject,
  HighLight,
  MediaSearchResult,
  NewOpenSearchDocument,
  OpenSearchA16zResearchObject,
  OpenSearchDiscordObject,
  OpenSearchMediumObject,
  OpenSearchMirrorObject,
  OpenSearchObject,
  OpenSearchPodcastObject,
  OpenSearchSnapshotObject,
  OpenSearchTallyObject,
  OpenSearchTelegramObject,
  OpenSearchTwitterObject,
  OpenSearchTwitterSpaceObject,
  OpenSearchUniswapObject,
  OpenSearchWarpCastObject,
  OpenSearchWhitePaperObject,
  OpenSearchYoutubeObject,
} from "@/types";
import { formatTime } from "@/utils";
import { dataSource2SourceName } from "@/utils/dataSource";
import { getHighlightText } from "@/utils/highlight";
import { getRealStatus } from "@/utils/snapshot";

export const getBaseRes = (v: OpenSearchObject, highlight: HighLight) => ({
  pubDate: formatTime(v.created_at),
  /**
   * if multi tickers, will return this field
   */
  ticker: v.crypto_ticker || [],
  displayTicker: v.display_ticker || [],
  displayTopic: v?.display_topic || [],
  id: v.doc_id,
  type: v.data_source,
  cardType: "normal" as any,
  custom: {
    id: v.doc_id,
    url: v.url,
    type: v.data_source,
  },
  subrequest: v.subrequest,
  /**
   * TODO: remove temp value `rawXXX`
   */
  rawOpenSearchResult: v,
  rawHighLight: highlight,
  /**
   * should remove from buildSearchResponse and render this in Exact Component
   */
  footerTip: <SearchResultTip item={v} highlight={highlight} />,
  /**
   * should remove from buildSearchResponse and render this in Exact Component
   */
  sourceIcon: <DataSourceIcon dataSource={v.data_source} />,
});

export const transformTwitterToMediaSearchResult = (
  v: OpenSearchTwitterObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  const avatarUrl = getTwitterAvatar(v.author_id);
  return {
    ...getBaseRes(v, highlight),
    name: v.twitter_tweet_author_detail.twitter_user_name,
    avatarUrl,
    content: highlightText || v.twitter_tweet_text,
    isVerified: v.twitter_tweet_author_detail.twitter_user_verified,
    id: v.twitter_tweet_id,
    custom: {
      type: v.data_source,
      id: v.twitter_tweet_id,
      conversationId: v.twitter_tweet_conversation_id,
      doc_id: v.doc_id, // add doc_id
    },
    sentiment: v.ticker_sentiment,
  };
};

export const transformDiscordToMediaSearchResult = (
  v: OpenSearchDiscordObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  return {
    ...getBaseRes(v, highlight),
    name: v.discord_author_detail.discord_user_nickname,
    avatarUrl: v.discord_author_detail.discord_user_avatar_url,
    subName: !isEmpty(v.discord_author_detail.discord_user_roles)
      ? sortBy(v.discord_author_detail.discord_user_roles, (a, b) =>
          parseInt(a.discord_role_position)
        )[0].discord_role_name
      : "",
    id: v.discord_message_id,
    content: parseCustomEmoji(highlightText || v.discord_message_content),
    custom: {
      channel_id: v.discord_channel_id,
      type: v.data_source,
      timestamp: v.created_at,
      id: v.discord_message_id,
      doc_id: v.doc_id, // add doc_id
    },
  };
};

export const transformPodcastToMediaSearchResult = (
  v: OpenSearchPodcastObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  return {
    ...getBaseRes(v, highlight),
    name: v.podcast_name,
    avatarUrl: v.podcast_episode_image_url,
    content: `${v.podcast_episode_title}`,
  };
};

export const transformYoutubeToMediaSearchResult = (
  v: OpenSearchYoutubeObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  return {
    ...getBaseRes(v, highlight),
    name: v.youtube_conference_name ?? v.youtube_hardcode_playlist_name,
    avatarUrl: v.youtube_hardcode_playlist_image_url,
    data_source: v.data_source,
    prefix: "youtube",
    content: v.youtube_hardcode_episode_full_title,
    custom: {
      type: v.data_source,
      id: v.doc_id,
      data_source: v.data_source,
      prefix: "youtube",
    },
  };
};

export const transformMirrorToMediaSearchResult = (
  v: OpenSearchMirrorObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  return {
    ...getBaseRes(v, highlight),
    name:
      v.mirror_author_name ||
      v.author_id.slice(0, 6) +
        "..." +
        v.author_id.slice(v.author_id.length - 4, v.author_id.length),
    subName: v.author_id.slice(0, 6),
    contentTitle: v.mirror_title,
    avatarUrl: v.mirror_author_icon_url,
    content: highlightText || v.mirror_body || "",
  };
};

export const transformNewsToMediaSearchResult = (
  v: OpenSearchA16zResearchObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  const base = getBaseRes(v, highlight);
  return {
    ...base,
    name: v.source_display_name,
    contentTitle: v.web_title,
    avatarUrl: v.web_logo_url,
    content: highlightText || v.web_content,
    custom: {
      ...base.custom,
      name: v.source_display_name,
    },
  };
};

export const transformResearchToMediaSearchResult = (
  v: OpenSearchA16zResearchObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  const base = getBaseRes(v, highlight);
  return {
    ...base,
    avatarUrl: (v as OpenSearchA16zResearchObject).web_logo_url,
    name: (v as OpenSearchA16zResearchObject).source_display_name,
    content: (v as OpenSearchA16zResearchObject).web_title,
    custom: {
      ...base.custom,
      name: (v as OpenSearchA16zResearchObject).source_display_name,
    },
  };
};

export const transformGovernanceToMediaSearchResult = (
  v: OpenSearchUniswapObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  const base = getBaseRes(v, highlight);
  const {
    gov_forum_post_content,
    gov_forum_thread_id,
    gov_forum_post_title,
    source_display_name,
    gov_forum_post_logo_url,
  } = v;
  return {
    ...base,
    name: source_display_name,
    avatarUrl: gov_forum_post_logo_url,
    contentTitle: gov_forum_post_title,
    content: highlightText || gov_forum_post_content,
    custom: {
      ...base.custom,
      name: source_display_name,
      threadId: gov_forum_thread_id,
    },
  };
};

export const transformDashboardToMediaSearchResult = (
  v: DuneDashboardObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  const base = getBaseRes(v, highlight);
  const duneHighlightText = get(highlight, ["dune_dashboard_text_widgets.text"], []).join(",");
  const { dune_dashboard_name, dune_dashboard_tags } = v;
  return {
    ...base,
    name: "Dune",
    avatarUrl:
      "https://global-uploads.webflow.com/62fca6954348cf2e3c918eee/63641e0612be7a5db064e76c_cta-component_decoration.svg",
    contentTitle: highlightText || dune_dashboard_name,
    content:
      duneHighlightText ||
      (dune_dashboard_tags || []).map((v) => "#" + v).join(" ") ||
      "[Dashboard]",
    custom: {
      ...base.custom,
      name: "Dune",
    },
  };
};

export const transformTwitterSpaceToMediaSearchResult = (
  v: OpenSearchTwitterSpaceObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  return {
    ...getBaseRes(v, highlight),
    avatarUrl: v.twitter_space_creator_user_profile_image_url,
    name: v.twitter_space_creator_user_name,
    isVerified: v.twitter_space_creator_user_verified,
    content: v.twitter_space_title,
  };
};
export const transformMediumToMediaSearchResult = (
  v: OpenSearchMediumObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  return {
    ...getBaseRes(v, highlight),
    name: v.medium_collection_name || v.medium_author_name,
    avatarUrl: v.medium_collection_name
      ? v.medium_collection_avatar_image_url
      : v.medium_author_avatar_image_url,
    content: v.medium_post_title,
  };
};

export const transformWhitepaperToMediaSearchResult = (
  v: OpenSearchWhitePaperObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  return {
    ...getBaseRes(v, highlight),
    name: v.ticker,
    avatarUrl: v.ticker_logo_url,
    content: highlightText,
    contentTitle: v.whitepaper_title,
  };
};

export const transformSnapshotToMediaSearchResult = (
  v: OpenSearchSnapshotObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  v.url = v.url ?? v.snapshot_proposal_url;

  return {
    ...getBaseRes(v, highlight),
    name: v.snapshot_space_name,
    avatarUrl: v.snapshot_space_avatar_url,
    content: highlightText,
    contentTitle: v.snapshot_proposal_title,
    isVerified: v.snapshot_space_verified,

    // metasearch extend result
    extendResult: {
      ...pick(v, [
        "author_id",
        "snapshot_proposal_state",
        "snapshot_proposal_author",
        "snapshot_proposal_create_datetime",
        "snapshot_proposal_end_datetime",
        "snapshot_proposal_top_voter",
        "snapshot_proposal_author_is_core",
      ]),
      snapshot_proposal_state: getRealStatus(
        v.snapshot_proposal_state,
        v.snapshot_proposal_end_datetime
      ),
    },
  };
};

export const transformTallyToMediaSearchResult = (
  v: OpenSearchTallyObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  v.url = v.url ?? v.tally_proposal_url;

  return {
    ...getBaseRes(v, highlight),
    pubDate: formatTime(v.tally_proposal_published_onchain_datetime),
    name: v.tally_dao_name,
    avatarUrl: v.tally_dao_icon_url,
    content: removeMarkdownRegex(highlightText),
    contentTitle: removeMarkdownRegex(v.tally_proposal_title),
    status: v.tally_proposal_status,

    // metasearch extend result
    extendResult: {
      ...pick(v, [
        "tally_proposal_published_onchain_datetime",
        "tally_proposal_author_username",
        "tally_proposal_voting_period_ended_datetime",
        "tally_proposal_author_address",
        "tally_proposal_author_username",
      ]),
      tally_proposal_status: v.tally_proposal_status,
    },
  };
};

export const transformTelegramToMediaSearchResult = (
  v: OpenSearchTelegramObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  const channelName = v.telegram_channel_name;

  return {
    ...getBaseRes(v, highlight),
    id: v.telegram_message_id,
    name: channelName,
    subName: v.telegram_is_admin ? "Admin" : "",
    avatarUrl: `https://kaito-public-assets.s3.us-west-2.amazonaws.com/telegram/asset/channel_${v.telegram_channel_id}.jpg`,
    content: parseCustomEmoji(highlightText || v.telegram_text),
    custom: {
      channel_id: v.telegram_channel_id,
      type: v.data_source,
      timestamp: v.created_at,
      id: v.telegram_message_id,
      doc_id: v.doc_id, // add doc_id
    },
  };
};

export const transformWarpcastToMediaSearchResult = (
  v: OpenSearchWarpCastObject,
  highlightText: string = "",
  highlight: HighLight
) => {
  return {
    ...getBaseRes(v, highlight),
    name: v.farcaster_cast_author.username,
    avatarUrl: v.farcaster_cast_author.pfp.url,
    content: highlightText || v.farcaster_cast_text,
    // isVerified: v.farcaster_cast_author.pfp.verified,
    id: v.farcaster_cast_hash,
    custom: {
      type: v.data_source,
      conversationId: v.farcaster_cast_conversation_hash,
      id: v.farcaster_cast_hash,
      doc_id: v.doc_id, // add doc_id
    },
  };
};

export const normalizeResponse = (
  v: OpenSearchObject,
  highlight?: HighLight
): MediaSearchResult => {
  const highlightText = getHighlightText(highlight);
  // fix data_source
  const dataSource = v.data_source as string;
  v.data_source = dataSource2SourceName(dataSource);

  if (v.data_source === "Twitter") {
    return transformTwitterToMediaSearchResult(v, highlightText, highlight);
  } else if (v.data_source === "Discord") {
    return transformDiscordToMediaSearchResult(v, highlightText, highlight);
  } else if (v.data_source === "Podcast") {
    return transformPodcastToMediaSearchResult(v, highlightText, highlight);
  } else if (v.data_source === "Youtube") {
    return transformYoutubeToMediaSearchResult(v, highlightText, highlight);
  } else if (v.data_source === "Mirror") {
    return transformMirrorToMediaSearchResult(v, highlightText, highlight);
  } else if (indexCategory.News.indexOf(v.data_source) > -1) {
    return transformNewsToMediaSearchResult(
      v as OpenSearchA16zResearchObject,
      highlightText,
      highlight
    );
  } else if (indexCategory.Research.indexOf(v.data_source) > -1) {
    return transformResearchToMediaSearchResult(
      v as OpenSearchA16zResearchObject,
      highlightText,
      highlight
    );
  } else if (indexCategory.Governance.indexOf(v.data_source) > -1) {
    return transformGovernanceToMediaSearchResult(
      v as OpenSearchUniswapObject,
      highlightText,
      highlight
    );
  } else if (indexCategory.Dashboard.indexOf(v.data_source) > -1) {
    return transformDashboardToMediaSearchResult(
      v as DuneDashboardObject,
      highlightText,
      highlight
    );
  } else if (v.data_source === "Twitter_Space") {
    return transformTwitterSpaceToMediaSearchResult(v, highlightText, highlight);
  } else if (v.data_source === "Medium") {
    return transformMediumToMediaSearchResult(v, highlightText, highlight);
  } else if (v.data_source === "Whitepaper") {
    return transformWhitepaperToMediaSearchResult(v, highlightText, highlight);
  } else if (v.data_source === "SnapShot") {
    return transformSnapshotToMediaSearchResult(v, highlightText, highlight);
  } else if (v.data_source === "Tally") {
    return transformTallyToMediaSearchResult(v, highlightText, highlight);
  } else if (v.data_source === "Warpcast") {
    return transformWarpcastToMediaSearchResult(v, highlightText, highlight);
  } else if (v.data_source === "Telegram") {
    return transformTelegramToMediaSearchResult(v, highlightText, highlight);
  }
};

export type NewSearchResponse = {
  display_metadata: Record<string, number> & {
    all: number;
  };
  documents: Array<NewOpenSearchDocument>;
};

export function buildSearchResponse(res: NewSearchResponse["documents"]): MediaSearchResult[] {
  const openSearchResult: [OpenSearchObject, HighLight][] = res.map((v) => [
    v,
    {
      "text.english_analyzed": v.highlight,
    },
  ]);

  return uniqBy(
    openSearchResult
      .map(([v, highlight]) => normalizeResponse(v, highlight))
      // TODO remove it
      .filter((v) => !!v),
    "id"
  );
}

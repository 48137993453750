/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconComfy: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M85.333333 179.2a34.133333 34.133333 0 0 0-34.133333 34.133333v170.666667a34.133333 34.133333 0 0 0 34.133333 34.133333h853.333334a34.133333 34.133333 0 0 0 34.133333-34.133333V213.333333a34.133333 34.133333 0 0 0-34.133333-34.133333H85.333333z m34.133334 170.666667V247.466667h785.066666v102.4H119.466667zM85.333333 605.866667a34.133333 34.133333 0 0 0-34.133333 34.133333v170.666667a34.133333 34.133333 0 0 0 34.133333 34.133333h853.333334a34.133333 34.133333 0 0 0 34.133333-34.133333v-170.666667a34.133333 34.133333 0 0 0-34.133333-34.133333H85.333333z m34.133334 170.666666v-102.4h785.066666v102.4H119.466667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconComfy.defaultProps = {
  size: 14,
};

export default IconIconComfy;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconArrowsPointingOut: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M85.312 85.312H384v85.376H231.04l256 256-60.352 60.288-256-256V384H85.376V85.312zM640 85.312h298.688V384h-85.312V231.04l-256 256-60.352-60.352 256-256H640V85.312zM487.04 597.312l-256 256H384v85.376H85.312V640h85.376v152.96l256-256 60.352 60.352zM597.376 536.96l256 256V640h85.312v298.688H640v-85.376h152.96l-256-256 60.416-60.288z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconArrowsPointingOut.defaultProps = {
  size: 14,
};

export default IconArrowsPointingOut;

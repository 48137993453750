/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconTldr: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M802.986667 107.648c14.378667 22.613333 7.978667 52.821333-14.208 67.456L448.853333 388.650667a14.805333 14.805333 0 0 0-0.853333 23.936l215.381333 166.4c18.858667 14.592 29.013333 37.418667 26.965334 61.184a71.765333 71.765333 0 0 1-33.834667 54.528l-370.090667 236.629333a47.317333 47.317333 0 0 1-65.877333-15.701333 49.365333 49.365333 0 0 1 15.36-67.2l320.213333-204.970667c8.533333-5.418667 9.088-17.92 1.066667-24.064l-214.528-165.76a69.461333 69.461333 0 0 1-26.965333-61.184c2.005333-23.04 15.018667-42.581333 33.365333-54.229333l387.84-245.077334a47.274667 47.274667 0 0 1 66.133333 14.506667z"
        fill={getIconColor(color, 0, "currentColor")}
      />
    </svg>
  );
};

IconTldr.defaultProps = {
  size: 14,
};

export default IconTldr;

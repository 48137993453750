/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconSwitch: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M321.749333 892.501333l-152.192-152.192 152.192-152.192v114.133334h380.501334v-152.192h76.117333v190.250666c0 20.992-17.066667 38.058667-38.058667 38.058667H321.706667v114.133333z m0-418.56H245.632V283.733333c0-20.992 17.066667-38.058667 38.058667-38.058666h418.56V131.498667l152.192 152.192-152.192 152.192V321.749333H321.749333v152.192z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconSwitch.defaultProps = {
  size: 14,
};

export default IconIconSwitch;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSearchlistTwitter: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M921.6 257.962667a335.573333 335.573333 0 0 1-96.512 26.453333 168.533333 168.533333 0 0 0 73.898667-92.970667 336.597333 336.597333 0 0 1-106.709334 40.789334 168.106667 168.106667 0 0 0-286.378666 153.301333 477.056 477.056 0 0 1-346.453334-175.616 167.168 167.168 0 0 0-22.698666 84.48c0 58.325333 29.610667 109.738667 74.752 139.904a167.552 167.552 0 0 1-76.16-21.034667v2.133334c0 81.450667 57.984 149.333333 134.826666 164.821333a168.917333 168.917333 0 0 1-75.946666 2.858667 168.277333 168.277333 0 0 0 157.013333 116.736 337.28 337.28 0 0 1-248.832 69.546666 475.776 475.776 0 0 0 257.621333 75.52c309.12 0 478.165333-256.085333 478.165334-478.122666 0-7.296-0.128-14.549333-0.469334-21.76a342.314667 342.314667 0 0 0 83.882667-87.04z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSearchlistTwitter.defaultProps = {
  size: 14,
};

export default IconAIconSearchlistTwitter;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconIconSyncUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M353.159529 115.169882L57.042824 239.736471l37.345882 88.786823 52.946823-22.226823a408.696471 408.696471 0 0 0-12.830117 345.328941 408.395294 408.395294 0 0 0 216.244706 217.630117l37.948235-88.545882a313.223529 313.223529 0 0 1 1.807059-576.632471v-0.12047l-37.345883-88.847059z m306.838589-4.336941a408.515765 408.515765 0 0 1 229.677176 223.11153 408.696471 408.696471 0 0 1-12.709647 345.088l52.766118-22.166589 37.345882 88.786824-289.611294 121.916235a414.840471 414.840471 0 0 1-6.445177 2.770824h-0.12047l-37.345882-88.847059 3.132235-1.325177a313.223529 313.223529 0 0 0-11.625412-579.463529l34.816-89.871059h0.120471z"
        fill={getIconColor(color, 0, "currentColor")}
      />
    </svg>
  );
};

IconIconSyncUncover.defaultProps = {
  size: 14,
};

export default IconIconSyncUncover;

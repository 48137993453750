import { InfoOutlineIcon } from "@chakra-ui/icons";
import { chakra, Flex, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

import { client } from "@/client/base";
import {
  TelegramMultiSelectName,
  TelegramMultiSelectSource,
} from "@/components/Filter/SourceFilter/multiselectConstants";
import { ClearIcon } from "@/components/Icon";
import MultiSelect from "@/components/MultiSelect";

interface TelegramChannel {
  telegram_channel_id: string;
  telegram_channel_logo_url: string;
  telegram_channel_name: string;
  telegram_channel_type: string;
}

const getRemoteOptions = async (keyword: string) => {
  const { listData = [] } = (await client.get("/ai/search/telegram_channel", {
    params: {
      keyword,
    },
  })) as any;

  return listData.map((item: TelegramChannel) => ({ value: item.telegram_channel_id, ...item }));
};

const NoResultRender = () => {
  return (
    <Flex color="whiteAlpha.200" p="6.5px" flexFlow="flex-start" height="24px" alignItems="center">
      <InfoOutlineIcon mr="6px" boxSize="11px" />
      <Text fontSize="12px" textAlign="center">
        No result found
      </Text>
    </Flex>
  );
};

const DropmenuRender = ({ telegram_channel_name, telegram_channel_logo_url }: TelegramChannel) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpoen] = useState(false);

  useEffect(() => {
    const hasToolTip = nameRef?.current?.offsetWidth < nameRef?.current?.scrollWidth;
    const handler = () => {
      if (hasToolTip) {
        if (isOpen) {
          setIsOpoen(false);
        } else {
          setIsOpoen(true);
        }
      }
    };
    const curr = containerRef?.current;
    curr?.addEventListener("mouseenter", handler);
    curr?.addEventListener("mouseleave", handler);
    return () => {
      curr.removeEventListener("mouseenter", handler);
      curr.removeEventListener("mouseleave", handler);
    };
  }, [isOpen]);

  const elementToShow = (
    <Flex gap="4px">
      {telegram_channel_logo_url ? (
        <chakra.img src={telegram_channel_logo_url} w="20px" h="20px" borderRadius="24px" />
      ) : null}
      <Text
        fontSize="14px"
        fontWeight="400"
        lineHeight="20px"
        color="#FFFFFF"
        wordBreak="break-all"
      >
        {telegram_channel_name}
      </Text>
    </Flex>
  );

  return (
    <Tooltip placement="top" label={elementToShow} isOpen={isOpen} p="8px" minW="356px">
      <Flex alignItems="center" gap="4px" p="4px" maxW="100%" ref={containerRef}>
        {telegram_channel_logo_url ? (
          <chakra.img src={telegram_channel_logo_url} w="20px" h="20px" borderRadius="24px" />
        ) : null}
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="20px"
          color="#FFFFFF"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          ref={nameRef}
        >
          {telegram_channel_name}
        </Text>
      </Flex>
    </Tooltip>
  );
};

type valueType = {
  value: string;
  telegram_channel_logo_url: string;
  telegram_channel_name: string;
  [x: string]: any;
};

type removeSelectedItemType = (v: valueType) => void;
const SelectedItemRender = ({
  value,
  removeSelectedItem,
  isActivated,
}: {
  value: valueType;
  removeSelectedItem: removeSelectedItemType;
  isActivated: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpoen] = useState(false);

  useEffect(() => {
    const hasToolTip = nameRef?.current?.offsetWidth < nameRef?.current?.scrollWidth;
    const handler = () => {
      if (hasToolTip) {
        if (isOpen) {
          setIsOpoen(false);
        } else {
          setIsOpoen(true);
        }
      }
    };
    const curr = containerRef?.current;
    curr?.addEventListener("mouseenter", handler);
    curr?.addEventListener("mouseleave", handler);
    return () => {
      curr.removeEventListener("mouseenter", handler);
      curr.removeEventListener("mouseleave", handler);
    };
  }, [isOpen]);

  const elementToShow = (
    <Flex gap="4px">
      {value.telegram_channel_logo_url ? (
        <chakra.img src={value.telegram_channel_logo_url} w="20px" h="20px" borderRadius="24px" />
      ) : null}
      <Text
        fontSize="14px"
        fontWeight="400"
        lineHeight="20px"
        color="#FFFFFF"
        wordBreak="break-all"
      >
        {value.telegram_channel_name}
      </Text>
    </Flex>
  );

  return (
    <Tooltip placement="top" label={elementToShow} isOpen={isOpen} p="8px" minW="356px">
      <Flex
        p="2px 4px"
        alignItems="center"
        gap="2px"
        bg={isActivated ? "#474f5c" : "#323740"}
        borderRadius="4px"
        ref={containerRef}
      >
        {value.telegram_channel_logo_url ? (
          <chakra.img src={value.telegram_channel_logo_url} w="20px" h="20px" borderRadius="24px" />
        ) : null}
        <Text
          fontSize="14px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          ref={nameRef}
          lineHeight="20px"
          fontWeight="500"
          color="#ffffff"
        >
          {value.telegram_channel_name}
        </Text>
        <ClearIcon
          onClick={(e: any) => {
            e.stopPropagation();
            removeSelectedItem(value);
          }}
          width="16px"
          height="16px"
          flex="none"
          ml="4px"
          cursor="pointer"
          color="whiteAlpha.200"
        />
      </Flex>
    </Tooltip>
  );
};

const renderFilterField = (onChange, value) => (
  <MultiSelect<any>
    value={value}
    getRemoteOptions={getRemoteOptions}
    onChange={onChange}
    DropMenuItem={DropmenuRender}
    RenderSelectedItem={SelectedItemRender}
    RenderNoResult={NoResultRender}
    placeholder={"Telegram Channel / Group Name"}
    name={TelegramMultiSelectName}
    source={TelegramMultiSelectSource}
  />
);
export default renderFilterField;

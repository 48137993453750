/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconHeadphones: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M152.362667 530.304a359.637333 359.637333 0 0 1 719.232 0v196.138667a130.773333 130.773333 0 0 1-130.730667 130.773333h-98.133333v-294.229333h163.498666v-32.682667a294.229333 294.229333 0 1 0-588.458666 0v32.682667h163.456v294.229333H283.136a130.773333 130.773333 0 0 1-130.773333-130.773333v-196.138667z m65.408 98.048v98.090667c0 36.096 29.269333 65.365333 65.365333 65.365333h32.682667v-163.413333H217.770667z m588.458666 0h-98.090666v163.456h32.725333c36.096 0 65.365333-29.269333 65.365333-65.365333v-98.090667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconHeadphones.defaultProps = {
  size: 14,
};

export default IconHeadphones;

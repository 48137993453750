/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconLocationUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M341.333333 298.666667a170.666667 170.666667 0 1 1 204.8 167.253333v276.48h-68.266666v-276.48A170.709333 170.709333 0 0 1 341.333333 298.666667z m170.666667-102.4a102.4 102.4 0 1 0 0 204.8 102.4 102.4 0 0 0 0-204.8z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M416.170667 611.882667l-33.322667 7.381333c-47.786667 10.538667-85.845333 26.368-111.018667 44.032-25.813333 18.133333-32.896 34.133333-32.896 44.970667 0 7.722667 3.413333 17.706667 14.72 29.610666 11.52 12.117333 29.781333 24.533333 54.997334 35.754667C358.912 795.989333 430.805333 810.666667 512 810.666667s153.088-14.677333 203.349333-37.034667c25.173333-11.221333 43.477333-23.637333 54.997334-35.754667 11.349333-11.946667 14.72-21.845333 14.72-29.610666 0-10.837333-7.082667-26.88-32.853334-44.970667-25.216-17.664-63.232-33.493333-111.018666-44.032l-33.365334-7.381333 14.72-66.645334 33.365334 7.338667c53.333333 11.818667 100.650667 30.378667 135.509333 54.869333C825.685333 631.466667 853.333333 665.472 853.333333 708.266667c0 29.952-13.781333 55.893333-33.536 76.672-19.626667 20.565333-46.293333 37.546667-76.714666 51.072C682.24 863.061333 600.448 878.933333 512 878.933333c-88.448 0-170.197333-15.872-231.082667-42.922666-30.421333-13.525333-57.130667-30.506667-76.714666-51.072C184.490667 764.202667 170.666667 738.218667 170.666667 708.266667c0-42.752 27.648-76.757333 61.909333-100.821334 34.858667-24.490667 82.176-43.093333 135.509333-54.869333l33.365334-7.338667 14.72 66.645334z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconLocationUncover.defaultProps = {
  size: 14,
};

export default IconIconLocationUncover;

import { ClearIcon } from "@/components/Icon";
import MultiSelect from "@/components/MultiSelect";
import { PremiumCommon } from "@/components/Premium";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { chakra, Flex, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

export type SelectOption = {
  value: string;
  icon_url?: string;
  title: string;
  key: string;
};
type removeSelectedItemType = (v: SelectOption) => void;
const SelectedItemRender = ({
  value,
  removeSelectedItem,
  isActivated,
}: {
  value: SelectOption;
  removeSelectedItem: removeSelectedItemType;
  isActivated: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasToolTip = nameRef?.current?.offsetWidth < nameRef?.current?.scrollWidth;
    const handler = () => {
      if (hasToolTip && !isOpen) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };
    const curr = containerRef?.current;
    curr?.addEventListener("mouseenter", handler);
    curr?.addEventListener("mouseleave", handler);
    return () => {
      curr.removeEventListener("mouseenter", handler);
      curr.removeEventListener("mouseleave", handler);
    };
  }, [isOpen]);

  const elementToShow = (
    <Flex gap="4px">
      {value.icon_url ? (
        <chakra.img src={value.icon_url} w="20px" h="20px" borderRadius="24px" />
      ) : null}
      <Text
        fontSize="14px"
        fontWeight="400"
        lineHeight="20px"
        color="#FFFFFF"
        wordBreak="break-all"
      >
        {value.title}
      </Text>
    </Flex>
  );

  return (
    <Tooltip
      placement="top"
      label={elementToShow}
      isOpen={isOpen}
      hasArrow
      backgroundColor="#2A3242"
      p="8px"
      minW="356px"
    >
      <Flex
        p="2px 4px"
        alignItems="center"
        gap="2px"
        bg={isActivated ? "#474f5c" : "#323740"}
        borderRadius="4px"
        ref={containerRef}
        minW="0"
      >
        {value.icon_url ? (
          <chakra.img src={value.icon_url} w="20px" h="20px" borderRadius="24px" />
        ) : null}
        <Text
          fontSize="14px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          ref={nameRef}
          lineHeight="20px"
          fontWeight="500"
          color="#ffffff"
        >
          {value.title}
        </Text>
        <ClearIcon
          onClick={(e: any) => {
            e.stopPropagation();
            removeSelectedItem(value);
          }}
          width="16px"
          height="16px"
          flex="none"
          ml="4px"
          cursor="pointer"
          color="whiteAlpha.200"
        />
      </Flex>
    </Tooltip>
  );
};
const ItemRender = ({ title, icon_url }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasToolTip = nameRef?.current?.offsetWidth < nameRef?.current?.scrollWidth;
    const handler = () => {
      if (hasToolTip) {
        if (isOpen) {
          setIsOpen(false);
        } else {
          setIsOpen(true);
        }
      }
    };
    const curr = containerRef?.current;
    curr?.addEventListener("mouseenter", handler);
    curr?.addEventListener("mouseleave", handler);
    return () => {
      curr.removeEventListener("mouseenter", handler);
      curr.removeEventListener("mouseleave", handler);
    };
  }, [isOpen]);

  const elementToShow = (
    <Flex gap="4px">
      {icon_url ? <chakra.img src={icon_url} w="20px" h="20px" borderRadius="24px" /> : null}
      <Text
        fontSize="14px"
        fontWeight="400"
        lineHeight="20px"
        color="#FFFFFF"
        wordBreak="break-all"
      >
        {title}
      </Text>
    </Flex>
  );

  return (
    <Tooltip placement="top" label={elementToShow} isOpen={isOpen} p="8px" minW="356px">
      <Flex alignItems="center" gap="4px" p="4px" maxW="100%" ref={containerRef}>
        {icon_url ? <chakra.img src={icon_url} w="20px" h="20px" borderRadius="24px" /> : null}
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="20px"
          color="#FFFFFF"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          ref={nameRef}
        >
          {title}
        </Text>
        <PremiumCommon name={title} isDisableTooltip />
      </Flex>
    </Tooltip>
  );
};
export const generateRenderFilterField = ({
  noResultTip,
  placeholder,
  getRemoteOptions,
  name,
  source,
}: {
  noResultTip: string;
  placeholder: string;
  name: string;
  source?: string;
  getRemoteOptions: (val: string) => Promise<SelectOption[]>;
}) => {
  // eslint-disable-next-line react/display-name
  return (onChange, value) => (
    <MultiSelect<any>
      value={value}
      getRemoteOptions={getRemoteOptions}
      onChange={onChange}
      DropMenuItem={ItemRender}
      RenderSelectedItem={SelectedItemRender}
      allowFetchWhenInputIsEmpty
      showArrowIcon
      name={name}
      source={source}
      RenderNoResult={() => {
        return (
          <Flex
            color="whiteAlpha.200"
            p="6.5px"
            flexFlow="flex-start"
            height="24px"
            alignItems="center"
          >
            <InfoOutlineIcon mr="6px" boxSize="11px" />
            <Text fontSize="12px" textAlign="center">
              {noResultTip}
            </Text>
          </Flex>
        );
      }}
      placeholder={placeholder}
    />
  );
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconInformation: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.658667 853.333333a341.333333 341.333333 0 1 1 0.682666-682.666666 341.333333 341.333333 0 0 1-0.682666 682.666666zM238.933333 517.888a273.066667 273.066667 0 1 0 0-5.888v5.888z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M477.866667 341.333333h68.266666v68.266667h-68.266666V341.333333zM477.866667 477.866667h68.266666V682.666667h-68.266666v-204.8z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconInformation.defaultProps = {
  size: 14,
};

export default IconIconInformation;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconAIconFourpointedstarCover: FunctionComponent<Props> = ({
  size,
  color,
  style: _style,
  ...rest
}) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M512 109.714286l108.653714 293.632L914.285714 512l-293.632 108.653714L512 914.285714l-108.653714-293.632L109.714286 512l293.632-108.653714L512 109.714286z"
        fill={getIconColor(color, 0, "currentColor")}
      />
    </svg>
  );
};

IconAIconFourpointedstarCover.defaultProps = {
  size: 14,
};

export default IconAIconFourpointedstarCover;

import { Button, Text } from "@chakra-ui/react";

import { listProductFeatureMap, TELEGRAM_URL } from "@/constants";
import { PlanLevel, PricingPlan } from "@/types/pricing";
import { isEarlyBirdPlan } from "@/utils";

import IconFont from "../Icon/iconfont";
import { Privacy } from "./Privacy";
import { TermAndConditionModal } from "./TermAndCondition";

const IconCheck = () => <IconFont name="IconCheck" color="white" />;

export interface PricingItem {
  theme: "normal" | "active";

  name: string;
  subtitle: string;

  level: PlanLevel;

  price: string;
  priceSub: string;
  oldPlan: string;
  monthPrice: string;

  planFeatures?: PlanFeatures;
}

export const freePlan: PricingPlan = {
  name: "MetaSearch Lite",
  planLevel: PlanLevel.Free,
  price: 0,
  isFreeTrail: false,
  list: listProductFeatureMap.get(PlanLevel.Free),
};

export type ResearchFeatureKeys =
  | "BasicSearch"
  | "AdvancedSearch"
  | "Watchlist"
  | "Dashboard"
  | "PodcastTranscript"
  | "TwitterSpaceTranscript"
  | "ConferenceTranscript"
  | "YoutubeTranscript"
  | "TLDRSearchSummary"
  | "TLDRPortfolioSummary";

export type AnalyticsFeatureKeys =
  | "SocialInterestAnalytics"
  | "TokenMindshare"
  | "SentimentAnalytics"
  | "NarrativeMonitoring"
  | "SmartFollowing"
  | "CatalystCalendar";

export type RealTimeFeatureKeys = "NewsEngine" | "ResearchAlert" | "AnalyticsAlert";

export type SupportFeatureKeys = "AdvancedSupport" | "EarlyAccessToNewFeatures";

type PlanFeatures = {
  Research: Partial<Record<ResearchFeatureKeys, React.ReactNode>>;
  Analytics: Partial<Record<AnalyticsFeatureKeys, React.ReactNode>>;
  RealTime?: Partial<Record<RealTimeFeatureKeys, React.ReactNode>>;
  Support?: Partial<Record<SupportFeatureKeys, React.ReactNode>>;
};

export const researchFeatureMapping: Record<
  ResearchFeatureKeys,
  {
    label: string;
  }
> = {
  BasicSearch: {
    label: "Basic Search",
  },
  AdvancedSearch: {
    label: "Advanced Search",
  },
  Watchlist: {
    label: "Watchlist",
  },
  Dashboard: {
    label: "Dashboard",
  },
  PodcastTranscript: {
    label: "Podcast Transcript",
  },
  TwitterSpaceTranscript: {
    label: "Twitter Space Transcript",
  },
  ConferenceTranscript: {
    label: "Conference Transcript",
  },
  YoutubeTranscript: {
    label: "Youtube Transcript",
  },
  TLDRSearchSummary: {
    label: "TL;DR Search Summary",
  },
  TLDRPortfolioSummary: {
    label: "TL;DR Portfolio Summary",
  },
};

export const analyticsFeatureMapping: Record<
  AnalyticsFeatureKeys,
  {
    label: string;
  }
> = {
  SocialInterestAnalytics: {
    label: "Social Interest Analytics",
  },
  TokenMindshare: {
    label: "Token Mindshare",
  },
  SentimentAnalytics: {
    label: "Sentiment Analytics",
  },
  NarrativeMonitoring: {
    label: "Narrative Monitoring",
  },
  SmartFollowing: {
    label: "Smart Following",
  },
  CatalystCalendar: {
    label: "Catalyst Calendar",
  },
};

export const realTimeFeatureMapping: Record<RealTimeFeatureKeys, { label: string }> = {
  NewsEngine: {
    label: "Real-time News Engine*",
  },
  ResearchAlert: {
    label: "Real-time Research Alert",
  },
  AnalyticsAlert: {
    label: "Real-time Analytics Alert*",
  },
};

export const supportFeatureMapping: Record<SupportFeatureKeys, { label: string }> = {
  AdvancedSupport: {
    label: "Advanced Support",
  },
  EarlyAccessToNewFeatures: {
    label: "Early Access to New Features",
  },
};

// default config is Free user
export const pricingItems: PricingItem[] = [
  {
    theme: "normal",
    name: "Standard",
    subtitle: "",
    level: PlanLevel.Standard,
    price: "$99",
    monthPrice: "$129",
    priceSub: "/month billed annually",
    oldPlan: "$120/month",
    planFeatures: {
      Research: {
        BasicSearch: <IconCheck />,
        AdvancedSearch: <IconCheck />,
      },
      Analytics: {},
    },
  },
  {
    theme: "normal",
    name: "Pro",
    subtitle: "",
    level: PlanLevel.Pro,
    price: isEarlyBirdPlan() ? "$300" : "$416",
    monthPrice: "$559",
    priceSub: "/month billed annually",
    oldPlan: "$400/month from 1st October",
    planFeatures: {
      Research: {
        BasicSearch: <IconCheck />,
        AdvancedSearch: <IconCheck />,
        Watchlist: <IconCheck />,
        Dashboard: <IconCheck />,
        PodcastTranscript: <IconCheck />,
        TwitterSpaceTranscript: <IconCheck />,
        ConferenceTranscript: <IconCheck />,
        YoutubeTranscript: <IconCheck />,
        TLDRSearchSummary: <IconCheck />,
        TLDRPortfolioSummary: <IconCheck />,
      },
      Analytics: {
        SocialInterestAnalytics: <IconCheck />,
        TokenMindshare: <IconCheck />,
      },
      RealTime: {
        ResearchAlert: (
          <>
            <IconCheck />
            <span className="text-white">Daily</span>
          </>
        ),
        AnalyticsAlert: (
          <>
            <IconCheck />
            <span className="text-white">Daily</span>
          </>
        ),
      },
      Support: {
        AdvancedSupport: <IconCheck />,
      },
    },
  },
  {
    theme: "normal",
    name: "Individual",
    subtitle: "",
    level: PlanLevel.Business,
    price: isEarlyBirdPlan() ? "$625" : "$833",
    monthPrice: "$1,099",
    priceSub: "/month billed annually",
    oldPlan: "$1,000/month from 1st October",
    planFeatures: {
      Research: {
        BasicSearch: <IconCheck />,
        AdvancedSearch: <IconCheck />,
        Watchlist: <IconCheck />,
        Dashboard: <IconCheck />,
        PodcastTranscript: <IconCheck />,
        TwitterSpaceTranscript: <IconCheck />,
        ConferenceTranscript: (
          <>
            <IconCheck />
            <span className="text-white">With slides</span>
          </>
        ),
        YoutubeTranscript: (
          <>
            <IconCheck />
            <span className="text-white">With slides</span>
          </>
        ),
        TLDRSearchSummary: <IconCheck />,
        TLDRPortfolioSummary: <IconCheck />,
      },
      Analytics: {
        SocialInterestAnalytics: <IconCheck />,
        TokenMindshare: <IconCheck />,
        SentimentAnalytics: <IconCheck />,
        NarrativeMonitoring: <IconCheck />,
        SmartFollowing: <IconCheck />,
        CatalystCalendar: <IconCheck />,
      },
      RealTime: {
        NewsEngine: <IconCheck />,
        ResearchAlert: (
          <>
            <IconCheck />
            <span className="text-white">Real-time</span>
          </>
        ),
        AnalyticsAlert: (
          <>
            <IconCheck />
            <span className="text-white">Real-time</span>
          </>
        ),
      },
      Support: {
        AdvancedSupport: <IconCheck />,
        EarlyAccessToNewFeatures: <IconCheck />,
      },
    },
  },
];

export enum formFieldType {
  input = "input",
  select = "select",
  checkbox = "checkbox",
}

export interface formField {
  label: string;
  type: formFieldType;
  placeholder?: string;
  isRequired?: boolean;
  field: string;
  checkboxElement?: React.ReactElement;
  selectOptions?: Array<{ value: string; label: string }>;
  displayControl?: (v: any) => any;
}

export const institutionFields: formField[] = [
  {
    label: "First name",
    type: formFieldType.input,
    placeholder: "Enter your first name",
    field: "firstName",
    isRequired: true,
  },
  {
    label: "Last name",
    type: formFieldType.input,
    placeholder: "Enter your last name",
    field: "lastName",
    isRequired: true,
  },
  {
    label: "Telegram handle",
    type: formFieldType.input,
    placeholder: "Enter your telegram handle",
    field: "telegram",
    isRequired: true,
  },
  {
    label: "Category",
    type: formFieldType.select,
    placeholder: "Select your category",
    field: "category",
    isRequired: true,
    selectOptions: [
      { value: "VC", label: "VC" },
      { value: "Hedge fund", label: "Hedge fund" },
      { value: "Market maker", label: "Market maker" },
      { value: "Exchange", label: "Exchange" },
      { value: "Research", label: "Research" },
      { value: "Project", label: "Project" },
      { value: "Individual", label: "Individual" },
      { value: "Other", label: "Other" },
    ],
  },
  {
    label: "",
    type: formFieldType.input,
    placeholder: "Please Specify",
    field: "other_category",
    isRequired: false,
    displayControl: (v: { category: string }) => (v.category === "Other" ? true : false),
  },
  {
    label: "Company",
    type: formFieldType.input,
    placeholder: "Enter your company name",
    field: "company",
    isRequired: true,
    displayControl: (v: { category: string }) => (v.category !== "Individual" ? true : false),
  },
  {
    label: "Size of company",
    type: formFieldType.select,
    placeholder: "Select your company’s size",
    field: "companySize",
    isRequired: true,
    selectOptions: [
      { value: "1-10", label: "1-10" },
      { value: "11-50", label: "11-50" },
      { value: "51-100", label: "51-100" },
      { value: "101+", label: "101+" },
    ],
    displayControl: (v: { category: string }) => (v.category !== "Individual" ? true : false),
  },

  {
    label: "Job title",
    isRequired: true,
    type: formFieldType.input,
    placeholder: "Enter your job title",
    field: "title",
    displayControl: (v: { category: string }) => (v.category !== "Individual" ? true : false),
  },
  {
    label: "",
    type: formFieldType.checkbox,
    field: "subscribe_newsletter",
    isRequired: false,
    checkboxElement: (
      <Text color="rgba(255, 255, 255, 0.4)" fontSize="14px" lineHeight="18px">
        Subscribe to receive company news and product updates from Kaito AI.
      </Text>
    ),
  },
  {
    label: "",
    type: formFieldType.checkbox,
    isRequired: false,
    field: "term",
    checkboxElement: (
      <Text
        as="div"
        color="rgba(255, 255, 255, 0.4)"
        wordBreak={"break-all"}
        flexFlow="row wrap"
        fontSize="14px"
        lineHeight="18px"
      >
        I agree to{" "}
        <TermAndConditionModal
          styles={{
            display: "inline",
            color: "#32FFDC",
            textDecoration: "underline",
            fontSize: "16px",
          }}
        />{" "}
        and{" "}
        <Privacy
          styles={{
            display: "inline",
            color: "#32FFDC",
            textDecoration: "underline",
            fontSize: "16px",
          }}
        />
      </Text>
    ),
  },
];

export const ContactSalesItems: Array<{
  title: string;
  subTitle: React.ReactNode;
  button: React.ReactNode;
  className: string;
}> = [
  {
    title: "Enterprise",
    className: "my-3",
    subTitle: (
      <div>Elevate your team experience with enterprise support and our bundled offering</div>
    ),
    button: (
      <Button
        onClick={() => {
          window.open(TELEGRAM_URL);
        }}
        className="w-[278px]"
        colorScheme="brand"
      >
        Contact Sales
      </Button>
    ),
  },
  {
    title: "API",
    subTitle: (
      <div>Seamlessly integrate our insights and leverage the power of Kaito systematically</div>
    ),
    button: (
      <Button
        onClick={() => {
          window.open(TELEGRAM_URL);
        }}
        className="w-[278px]"
        variant={"outline"}
        colorScheme="brand"
      >
        Contact Sales
      </Button>
    ),
    className: "",
  },
];

export type planTypes = "2year" | "year" | "month";

/* 

params: If there is an active price, please add the following two parameters
  activityPrice(Monthly unit price) 
  activityTotalPrice（Annual price）

*/
export const PriceContentData: Record<
  planTypes,
  {
    title: string;
    price: number;
    activityPrice?: number;
    totalPrice: number;
    activityTotalPrice?: number;
    priceUnit: string;
    planDescribe: string;
    isEnableCrypto: boolean;
  }
> = {
  "2year": {
    title: "Two-Year Individual",
    price: 750,
    totalPrice: 18000,
    // activityPrice: 698,
    // activityTotalPrice: 16758,
    priceUnit: "billed biennially",
    planDescribe: "Your current plan",
    isEnableCrypto: true,
  },
  year: {
    title: "Individual",
    price: 833,
    totalPrice: 10000,
    // activityPrice: 776,
    // activityTotalPrice: 9310,
    priceUnit: "billed annually",
    planDescribe: "Your Individual Yearly plan",
    isEnableCrypto: true,
  },
  month: {
    title: "Individual",
    price: 1099,
    totalPrice: 1099,
    priceUnit: "",
    planDescribe: "Your Individual Monthly plan",
    isEnableCrypto: false,
  },
};

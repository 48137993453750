/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconExitfullscreenOneUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M362.666667 128a42.666667 42.666667 0 0 0-85.333334 0v149.333333H128a42.666667 42.666667 0 0 0 0 85.333334h192a42.666667 42.666667 0 0 0 42.666667-42.666667V128zM746.666667 128a42.666667 42.666667 0 1 0-85.333334 0v192a42.666667 42.666667 0 0 0 42.666667 42.666667H896a42.666667 42.666667 0 1 0 0-85.333334h-149.333333V128zM85.333333 704a42.666667 42.666667 0 0 1 42.666667-42.666667h192a42.666667 42.666667 0 0 1 42.666667 42.666667V896a42.666667 42.666667 0 1 1-85.333334 0v-149.333333H128a42.666667 42.666667 0 0 1-42.666667-42.666667zM704 661.333333a42.666667 42.666667 0 0 0-42.666667 42.666667V896a42.666667 42.666667 0 1 0 85.333334 0v-149.333333h147.2a42.666667 42.666667 0 0 0 0-85.333334H704z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconExitfullscreenOneUncover.defaultProps = {
  size: 14,
};

export default IconAIconExitfullscreenOneUncover;

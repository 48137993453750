/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconFeedsUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M318.805333 625.066667h370.261334v-74.069334H318.805333v74.069334zM318.805333 476.970667h370.261334V402.901333H318.805333v74.069334zM318.805333 328.832h185.130667V254.805333H318.72v74.026667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M170.666667 917.333333l333.226666-125.610666 333.312 125.610666v-810.666666H170.666667v810.666666z m74.069333-107.093333V180.736h518.4V810.24l-259.2-92.586667-259.2 92.586667z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconFeedsUncover.defaultProps = {
  size: 14,
};

export default IconIconFeedsUncover;

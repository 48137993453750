/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconServeUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M170.666667 136.533333a34.133333 34.133333 0 0 0-34.133334 34.133334v273.066666a34.133333 34.133333 0 0 0 34.133334 34.133334h682.666666a34.133333 34.133333 0 0 0 34.133334-34.133334V170.666667a34.133333 34.133333 0 0 0-34.133334-34.133334H170.666667z m34.133333 273.066667V204.8h614.4v204.8H204.8zM170.666667 541.866667a34.133333 34.133333 0 0 0-34.133334 34.133333v273.066667a34.133333 34.133333 0 0 0 34.133334 34.133333h682.666666a34.133333 34.133333 0 0 0 34.133334-34.133333v-273.066667a34.133333 34.133333 0 0 0-34.133334-34.133333H170.666667z m34.133333 273.066666v-204.8h614.4v204.8H204.8z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M298.666667 264.533333h42.666666a21.333333 21.333333 0 0 1 21.333334 21.333334v42.666666a21.333333 21.333333 0 0 1-21.333334 21.333334H298.666667a21.333333 21.333333 0 0 1-21.333334-21.333334v-42.666666a21.333333 21.333333 0 0 1 21.333334-21.333334zM298.666667 669.866667h42.666666a21.333333 21.333333 0 0 1 21.333334 21.333333v42.666667a21.333333 21.333333 0 0 1-21.333334 21.333333H298.666667a21.333333 21.333333 0 0 1-21.333334-21.333333v-42.666667a21.333333 21.333333 0 0 1 21.333334-21.333333zM512 264.533333h-42.666667a21.333333 21.333333 0 0 0-21.333333 21.333334v42.666666a21.333333 21.333333 0 0 0 21.333333 21.333334h42.666667a21.333333 21.333333 0 0 0 21.333333-21.333334v-42.666666a21.333333 21.333333 0 0 0-21.333333-21.333334zM469.333333 669.866667h42.666667a21.333333 21.333333 0 0 1 21.333333 21.333333v42.666667a21.333333 21.333333 0 0 1-21.333333 21.333333h-42.666667a21.333333 21.333333 0 0 1-21.333333-21.333333v-42.666667a21.333333 21.333333 0 0 1 21.333333-21.333333z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconServeUncover.defaultProps = {
  size: 14,
};

export default IconIconServeUncover;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconIconAdmin: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M504.021333 192a136.021333 136.021333 0 1 0 0 272 136.021333 136.021333 0 0 0 0-272zM416 328.021333a87.978667 87.978667 0 1 1 176 0 87.978667 87.978667 0 0 1-176 0z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M208 808.021333c0-125.568 115.456-232.021333 264.021333-232.021333a23.978667 23.978667 0 1 0 0-48c-169.6 0-312.021333 122.794667-312.021333 280.021333a23.978667 23.978667 0 1 0 48 0zM712.021333 696.021333a48 48 0 1 1-96 0 48 48 0 0 1 96 0z"
        fill={getIconColor(color, 1, "currentColor")}
      />
      <path
        d="M496 696.021333a168.021333 168.021333 0 1 1 336 0 168.021333 168.021333 0 0 1-336 0z m168.021333-120.021333a120.021333 120.021333 0 1 0 0 240 120.021333 120.021333 0 0 0 0-240z"
        fill={getIconColor(color, 2, "currentColor")}
      />
    </svg>
  );
};

IconIconAdmin.defaultProps = {
  size: 14,
};

export default IconIconAdmin;

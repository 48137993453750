import { indexCategory } from "@/constants";
import { type SourceName } from "@/types";

const DATA_SOURCE_NAMES = [
  "Twitter",
  "Discord",
  "Podcast",
  "Youtube",
  "Mirror",
  "News",
  "Research",
  "Twitter_Space",
  "Medium",
  "Whitepaper",
  "SnapShot",
  "Tally",
];

const IndexCategorySourceNames = [
  indexCategory.Governance,
  indexCategory.Conference,
  indexCategory.Dashboard,
];

export const dataSource2SourceName = (data_source: string): SourceName => {
  if (DATA_SOURCE_NAMES.includes(data_source)) {
    return data_source as SourceName;
  }

  const dataSource: any = data_source;

  for (let idx = 0; idx < IndexCategorySourceNames.length; idx++) {
    if (IndexCategorySourceNames[idx].indexOf(dataSource) > -1) {
      return IndexCategorySourceNames[idx][0];
    }
  }

  const indexKeys = Object.keys(indexCategory);

  for (let idx = 0; idx < indexKeys.length; idx++) {
    const key = indexKeys[idx];
    if (key === data_source) {
      return indexCategory[key][0] as SourceName;
    }
  }

  return data_source as SourceName;
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconChartLine: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M170.666667 85.333333v768h768v85.333334H85.333333V85.333333h85.333334z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M913.664 384L640 657.664l-170.666667-170.666667-155.562666 155.605334a21.333333 21.333333 0 0 1-30.208 0l-30.165334-30.165334a21.333333 21.333333 0 0 1 0-30.208L469.333333 366.336l170.666667 170.666667 213.333333-213.333334L913.664 384z"
        fill={getIconColor(color, 1, "currentColor")}
      />
    </svg>
  );
};

IconChartLine.defaultProps = {
  size: 14,
};

export default IconChartLine;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconLockUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M319.530667 495.872H246.528A33.194667 33.194667 0 0 0 213.333333 529.066667v298.666666c0 18.346667 14.848 33.194667 33.194667 33.194667h530.944c18.346667 0 33.194667-14.848 33.194667-33.194667v-298.666666a33.194667 33.194667 0 0 0-33.194667-33.194667h-72.96v-132.693333a192.469333 192.469333 0 0 0-384.981333 0v132.693333z m53.077333-132.693333a139.392 139.392 0 0 1 278.784 0v132.693333H372.608v-132.693333z m112.853333 365.013333v-99.584h53.077334v99.584h-53.077334z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconLockUncover.defaultProps = {
  size: 14,
};

export default IconIconLockUncover;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconEdit: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M374.272 682.666667a32.810667 32.810667 0 0 1-24.192-10.666667 32.426667 32.426667 0 0 1-8.618667-25.088l5.973334-65.28a64 64 0 0 1 18.432-39.466667l391.509333-391.552a32 32 0 0 1 45.269333 0l70.741334 70.741334a32 32 0 0 1 0 45.226666l-391.424 391.552a64 64 0 0 1-39.466667 18.474667l-65.28 5.930667a31.274667 31.274667 0 0 1-2.986667 0.128z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M192 160a32 32 0 0 0-32 32v640c0 17.664 14.336 32 32 32h640a32 32 0 0 0 32-32v-304.554667h64V832a96 96 0 0 1-96 96h-640a96 96 0 0 1-96-96v-640A96 96 0 0 1 192 96h381.354667v64H192z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconEdit.defaultProps = {
  size: 14,
};

export default IconIconEdit;

import { ENV } from "../src/env";

export const SENTRY_DSN = "https://7c469dda07f49d6e08b76a27ad8bd483@sentry.kaito.ai/4";

export const getTracesSamplerRate = (samplingContext) => {
  if (ENV.stage === "dev") {
    return 1.0; // 100% sampling for development
  }
  return 0.1; // 10% sampling rate for production
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconIconTwitterUncover: FunctionComponent<Props> = ({
  size,
  color,
  style: _style,
  ...rest
}) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M699.264 170.666667h111.189333L567.466667 459.818667 853.333333 853.333333h-223.829333l-175.317333-238.634666L253.610667 853.333333H142.336l259.882667-309.333333L128 170.666667h229.504l158.464 218.154666L699.264 170.666667z m-39.04 613.333333h61.610667L324.010667 236.373333h-66.133334l402.346667 547.669334z"
        fill={getIconColor(color, 0, "currentColor")}
      />
    </svg>
  );
};

IconIconTwitterUncover.defaultProps = {
  size: 14,
};

export default IconIconTwitterUncover;

export const getPreviewContainer = () =>
  document.querySelector("#preview-container .simplebar-content-wrapper");

export const sleep = (duration: number) => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

export const removeMarkdownRegex = (text: string = "") => text.replace(/#+\s*|\*\*|[*]/g, "");

export const useAdjustedAndWeighted = (value, setValue) => {
  const weighted = value?.weighted ? "Weighted" : "Unweighted";
  const adjusted = value?.adjusted ? "Adjusted" : "Unadjusted";

  const onChange = (key: string) => (v: string) => {
    setValue({ ...value, [key]: v === "Adjusted" || v === "Weighted" });
  };

  return {
    weighted,
    adjusted,
    onChange,
  };
};

export const getTwitterAvatar = (twitterId: string) =>
  `//asset.cdn.kaito.ai/twitter-user-profile-img/${twitterId}.jpg`;

import { Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";

import { userInfoAtom } from "@/store/userInfo";
import { AlertTypeEnums } from "@/types/userInfo";
import { isFreeUser } from "@/utils/user";

import { IconAttention } from "./Icon";
import IconFont from "./Icon/iconfont";

const FreeTrialPaymentFailedTip: React.FC = () => {
  const userInfo = useAtomValue(userInfoAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [messageTip, setMessageTip] = useState("");

  useEffect(() => {
    if (isFreeUser(userInfo) && userInfo.alert) {
      const { alert, message, type } = userInfo.alert;
      if (type !== AlertTypeEnums.PROFILE_ERROR_MESSAGE && alert && message) {
        setMessageTip(message);
        onOpen();
      }
    }
  }, [userInfo]);

  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} closeOnOverlayClick={false}>
      <ModalOverlay backgroundColor={"rgba(0, 0, 0, 0.7)"} />
      <ModalContent bg="transparent" position="relative" style={{ maxWidth: "600px" }}>
        <ModalBody
          w={600}
          id="preview-container"
          pt="4"
          pb="6"
          px="6"
          overflow={"hidden"}
          bg="#191C1F"
          rounded={4}
          className="border-[1px] border-[#262A30]"
        >
          <div className="flex justify-end items-center mb-4">
            {/* <div className="font-medium text-white flex items-center">
              <IconAttention className="mr-1" />
              Credit Card Declined
            </div> */}
            <IconFont
              name="IconClose"
              className="inline-block mr-[-12px] cursor-pointer text-[#fff]/40"
              size={24}
              onClick={onClose}
            />
          </div>
          <div className="text-sm text-[#fff]/60 mb-4 flex">
            <IconAttention className="mr-1 w-[28px]" />
            {/* {
              "Your card could not be authorized to start the free trial. Please try an alternative card or contact your bank / card issuer for assistance."
            } */}
            {messageTip}
          </div>
          <div className="flex justify-end  text-black cursor-pointer">
            <div
              className="w-[48px] text-center py-2 px-4 rounded bg-[#32FFDC] text-sm font-medium box-content "
              onClick={onClose}
            >
              OK
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FreeTrialPaymentFailedTip;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconThumbupCover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M438.528 183.893333L330.922667 396.970667H228.906667c-45.781333 0-82.901333 37.12-82.901334 82.901333v264.149333c0 45.781333 37.12 82.901333 82.901334 82.901334h476.586666a131.669333 131.669333 0 0 0 129.237334-106.666667l39.168-202.837333a131.669333 131.669333 0 0 0-129.28-156.629334h-120.106667l14.933333-71.808 0.256-1.066666a129.024 129.024 0 0 0-127.146666-151.338667c-31.786667 0-60.672 18.474667-74.026667 47.36zM349.482667 465.066667a32 32 0 0 0-32 32v229.973333c0 17.664 14.336 32 32 32h4.522666a32 32 0 0 0 32-32v-229.973333a32 32 0 0 0-32-32H349.44z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconThumbupCover.defaultProps = {
  size: 14,
};

export default IconIconThumbupCover;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconKol: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M520.234667 601.386667a77.653333 77.653333 0 0 1-39.381334-9.557334 65.408 65.408 0 0 1-25.728-27.434666c-6.058667-11.776-9.045333-25.856-9.045333-42.154667v-0.213333c0-16.256 3.029333-30.293333 9.130667-42.069334 6.144-11.904 14.72-21.034667 25.770666-27.434666 11.093333-6.4 24.192-9.557333 39.253334-9.557334 15.061333 0 28.16 3.2 39.168 9.557334 11.093333 6.4 19.669333 15.530667 25.728 27.434666 6.101333 11.776 9.173333 25.813333 9.173333 42.069334v0.213333c0 16.298667-3.072 30.378667-9.173333 42.197333-6.058667 11.776-14.634667 20.906667-25.728 27.392-11.050667 6.4-24.106667 9.557333-39.168 9.557334z m0-31.658667a30.208 30.208 0 0 0 18.432-5.845333 38.4 38.4 0 0 0 12.288-16.554667c2.901333-7.125333 4.394667-15.530667 4.394666-25.088v-0.213333a65.706667 65.706667 0 0 0-4.394666-25.088 37.674667 37.674667 0 0 0-12.288-16.469334 30.250667 30.250667 0 0 0-18.432-5.845333 30.293333 30.293333 0 0 0-18.432 5.845333 38.4 38.4 0 0 0-12.373334 16.469334 65.706667 65.706667 0 0 0-4.394666 25.088v0.213333c0 9.557333 1.450667 17.92 4.352 25.088a38.4 38.4 0 0 0 12.288 16.554667 30.378667 30.378667 0 0 0 18.56 5.845333z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M626.986667 597.333333a4.266667 4.266667 0 0 1-4.266667-4.266666v-141.781334a4.266667 4.266667 0 0 1 4.266667-4.266666h29.696a4.266667 4.266667 0 0 1 4.266666 4.266666v111.061334a4.266667 4.266667 0 0 0 4.266667 4.266666h55.338667a4.266667 4.266667 0 0 1 4.266666 4.266667v22.186667a4.266667 4.266667 0 0 1-4.266666 4.266666h-93.568zM344.917333 556.416a4.266667 4.266667 0 0 1 0.896-2.645333l8.704-11.008a4.266667 4.266667 0 0 1 6.954667 0.341333l33.322667 52.266667a4.266667 4.266667 0 0 0 3.626666 1.962666h34.730667a4.266667 4.266667 0 0 0 3.584-6.613333l-49.066667-74.88a4.266667 4.266667 0 0 1 0.256-4.949333l44.586667-56.96a4.266667 4.266667 0 0 0-3.328-6.912h-30.08a4.266667 4.266667 0 0 0-3.413333 1.664L347.306667 511.104a1.322667 1.322667 0 0 1-2.389334-0.853333v-58.965334a4.266667 4.266667 0 0 0-4.266666-4.266666h-29.696a4.266667 4.266667 0 0 0-4.266667 4.266666V593.066667a4.266667 4.266667 0 0 0 4.266667 4.266666h29.696a4.266667 4.266667 0 0 0 4.266666-4.266666v-36.650667z"
        fill={getIconColor(color, 1, "currentColor")}
      />
      <path
        d="M857.856 208.853333l37.973333 0.597334 0.170667 358.698666C896 748.245333 646.272 938.666667 512 938.666667c-134.272 0-384-190.421333-384-370.517334V209.450667l38.229333-0.597334c0.725333 0 85.973333-1.664 173.909334-34.773333 90.197333-33.962667 148.394667-72.96 148.906666-73.386667l22.869334-15.36 22.912 15.36c0.512 0.426667 58.666667 39.424 148.906666 73.386667 88.021333 33.066667 173.226667 34.773333 174.08 34.773333zM183.04 568.106667c0 146.048 217.386667 317.312 328.96 317.312 111.488 0 328.96-171.264 328.96-317.269334l-0.128-306.773333c-30.976-2.048-102.4-9.6-177.024-37.632-76.117333-28.714667-129.834667-60.074667-151.765333-73.856a896.128 896.128 0 0 1-151.978667 73.813333c-74.325333 28.032-145.578667 35.626667-177.024 37.674667v306.773333z"
        fill={getIconColor(color, 2, "currentColor")}
      />
    </svg>
  );
};

IconKol.defaultProps = {
  size: 14,
};

export default IconKol;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconFilter: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M179.2 313.770667C142.592 262.997333 178.901333 192 241.493333 192h541.013334c62.634667 0 98.944 70.954667 62.250666 121.770667l-198.4 274.645333V896l-76.8-21.333333v-283.477334a85.333333 85.333333 0 0 1 16.213334-49.962666l182.058666-252.16a12.8 12.8 0 0 0-10.368-20.266667H266.538667a12.8 12.8 0 0 0-10.368 20.309333l182.058666 252.117334a85.333333 85.333333 0 0 1 16.170667 49.92V832l-76.8-12.8v-230.784L179.2 313.770667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconFilter.defaultProps = {
  size: 14,
};

export default IconIconFilter;

import { get, isEqual, sortBy } from "lodash";

export const getSpecificTickerOfficialIds = (
  officialData: any,
  dataGetPath: Array<[string, string]>
): [string][] => {
  let res = [];
  dataGetPath.forEach(([keyName, keyDataName]) => {
    const fieldOfficialData = officialData[keyName] || [];
    const newTickersOfficialIds = fieldOfficialData.map((t) => t[keyDataName]);
    res.push(newTickersOfficialIds);
  });
  return res;
};

export const shouldUpdateSourceOfficialToggle = (
  newTickersOfficialIds: string[],
  allValue: any,
  autoCompleteIndex: number
): boolean => {
  const allExistingIds = allValue[autoCompleteIndex].map((item) => item.value);
  // only new tickers have official auto_complete values and old selected values differ with new selected values
  return (
    isEqual(sortBy(allExistingIds), sortBy(newTickersOfficialIds)) &&
    newTickersOfficialIds.length > 0
  );
};

export const getRealBooleanValue = (value: any): any => {
  if (typeof value === "boolean") return value;
  // get value from URL query(refresh the page)
  if (typeof value === "string") {
    if (value === "true") {
      return true;
    } else if (value === "false") {
      return false;
    }
    return value;
  }
  return value;
};
export const isTeamOrCoreTeam = (value: string) => {
  return value === "team" || value === "coreTeam";
};

const filter_key_to_auto_completevalues_paths = {
  twitter_user_id: [
    ["value", "twitter_user_id"],
    ["username", "twitter_user_username"],
    ["name", "twitter_user_name"],
    ["avatarUrl", "twitter_user_profile_image_url"],
  ],
  discord_server_id: [
    ["value", "discord_server_id"],
    ["discord_server_icon_url", "discord_server_icon_url"],
    ["discord_server_name", "discord_server_name"],
    ["discord_server_id", "discord_server_id"],
  ],
  medium_author_id: [
    ["name", "medium_author_name"],
    ["avatarUrl", "medium_author_avatar_image_url"],
    ["value", "medium_author_id"],
  ],
  medium_collection_id: [
    ["name", "medium_collection_name"],
    ["avatarUrl", "medium_collection_avatar_image_url"],
    ["value", "medium_collection_id"],
  ],
  twitter_space_creator_user_id: [
    ["value", "twitter_user_id"],
    ["username", "twitter_user_username"],
    ["name", "twitter_user_name"],
    ["avatarUrl", "twitter_user_profile_image_url"],
  ],
  forum_data_source: [
    ["value", "gov_forum_name"],
    ["title", "gov_forum_title"],
    ["key", "gov_forum_name"],
    ["icon_url", "gov_forum_icon_url"],
  ],
};

export const extraAutoCompleteValuesFromOfficialByFilterKey = ({
  data,
  data_key,
  auto_complete_key,
}: {
  data: { [key: string]: any };
  data_key: string;
  auto_complete_key: string;
}) => {
  let values = [];

  const official_data = get(data, [data_key], []);

  official_data.forEach((d) => {
    const auto_complete_value = filter_key_to_auto_completevalues_paths[auto_complete_key].reduce(
      (total: { [key: string]: any }, curr: string) => ({
        ...total,
        [curr[0]]: d[curr[1]],
      }),
      {}
    );
    values.push(auto_complete_value);
  });

  return values;
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconLockUncover1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 210.986667a125.610667 125.610667 0 0 0-125.610667 125.610666v92.416h251.221334V336.597333A125.610667 125.610667 0 0 0 512 210.986667z m206.250667 218.026666h59.221333c40.618667 0 73.514667 32.896 73.514667 73.514667v298.666667c0 40.576-32.896 73.514667-73.514667 73.514666H246.528a73.514667 73.514667 0 0 1-73.514667-73.514666v-298.666667c0-40.618667 32.896-73.514667 73.514667-73.514667h59.221333V336.597333a206.250667 206.250667 0 0 1 412.501334 0v92.416z m-464.64 80.64v284.373334h516.693333v-284.373334H253.653333z m218.026666 192h80.64V602.026667h-80.64v99.541333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconLockUncover1.defaultProps = {
  size: 14,
};

export default IconIconLockUncover1;

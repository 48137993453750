/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAHuatitaolunTopicDiscussion11: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M96 170.666667A32 32 0 0 1 128 138.666667h768a32 32 0 0 1 32 32v576a32 32 0 0 1-32 32h-274.773333l-86.613334 86.613333a32 32 0 0 1-45.226666 0l-86.613334-86.613333H128a32 32 0 0 1-32-32V170.666667z m64 32v512h256a32 32 0 0 1 22.613333 9.386666L512 797.44l73.386667-73.386667a32 32 0 0 1 22.613333-9.386666h256v-512H160z m296.277333 70.442666a32 32 0 0 1 30.08 33.834667l-3.669333 62.122667h70.272l3.882667-65.877334a32 32 0 1 1 63.914666 3.754667l-3.669333 62.122667h67.712a32 32 0 0 1 0 64h-71.466667l-4.138666 70.4H665.6a32 32 0 0 1 0 64h-60.16l-3.882667 65.877333a32 32 0 1 1-63.914666-3.754667l3.669333-62.122666H471.04l-3.882667 65.877333a32 32 0 1 1-63.914666-3.754667l3.669333-62.122666H339.2a32 32 0 0 1 0-64h71.466667l4.138666-70.4H358.4a32 32 0 0 1 0-64h60.16l3.882667-65.877334a32 32 0 0 1 33.834666-30.08z m22.613334 159.957334l-4.096 70.4h70.272l4.138666-70.4h-70.272z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAHuatitaolunTopicDiscussion11.defaultProps = {
  size: 14,
};

export default IconAHuatitaolunTopicDiscussion11;

import { Flex, forwardRef, HTMLChakraProps } from "@chakra-ui/react";

export interface FilterButtonProps extends HTMLChakraProps<"div"> {
  selected?: boolean;
  disableShowCheckMarkIcon?: boolean;
  isActive?: boolean;
  isDisable?: boolean;
}
export const FilterButton = forwardRef<FilterButtonProps, "div">(
  ({ selected, children, disableShowCheckMarkIcon, isActive, isDisable, ...rest }, ref) => {
    const selectedStyle = selected
      ? {
          background: "brand.700",
          border: "0.5px solid",
          borderColor: "brand.600",
          color: "brand.200",
        }
      : {
          border: "0.5px solid",
          borderColor: "transparent",
        };
    const hoverStyle = {
      _before: {
        content: '""',
        display: "block",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0",
        top: "0",
        bg: "whiteAlpha.5",
        pointerEvents: "none",
        borderRadius: "4px",
      },
    };
    const activeStyle = isActive ? hoverStyle : {};
    const disableStyle = isDisable ? ({ pointerEvents: "none" } as any) : {};
    return (
      <Flex
        bg="grayBlue.700"
        borderRadius="4px"
        p="8px 16px"
        alignItems="center"
        height="36px"
        fontSize="14px"
        whiteSpace="nowrap"
        color="whiteAlpha.300"
        cursor="pointer"
        position="relative"
        className="filterButton"
        fontWeight="500"
        ref={ref}
        _hover={hoverStyle}
        {...activeStyle}
        {...selectedStyle}
        {...disableStyle}
        {...rest}
      >
        {children}
      </Flex>
    );
  }
);

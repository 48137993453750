/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconFileChartNews: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M178.517333 106.666667a93.098667 93.098667 0 0 0-93.098666 93.098666V789.333333a128 128 0 0 0 128 128h554.666666v-0.298666a93.866667 93.866667 0 0 0 7.637334 0.298666h34.901333a128 128 0 0 0 128-128v-217.216a38.826667 38.826667 0 0 0-77.568 0v172.586667a46.506667 46.506667 0 0 1-92.970667 3.584V199.765333A93.098667 93.098667 0 0 0 674.986667 106.666667H178.517333zM597.418667 384v85.333333h-341.333334V384h341.333334z m-341.333334 170.666667h298.666667v85.333333h-298.666667v-85.333333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconFileChartNews.defaultProps = {
  size: 14,
};

export default IconFileChartNews;

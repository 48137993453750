import { client } from "./base";

export interface Guide {
  userId: string;
  guideType: string;
  guideId: string;
}

export const createGuideApi = async (guideType: string) => {
  return await client.post("/portal/create_guide", { guideType: guideType });
};

export const getGuideApi = async (guideType: string) => {
  const { data } = await client.get("/portal/get_guide", {
    params: {
      guide_type: guideType,
    },
  });
  return data;
};

export type GuideItem = {
  guide_type: string;
  is_hidden: boolean;
};

export const ListGuidesApi = async (): Promise<Array<GuideItem>> => {
  const { data } = await client.get("/portal/get_guide_list");
  return data;
};

import { SmartFollowingDetailType, type SmartFollowingParams } from "@/client/smart_follow";

export type PreDefinedSmartFollowingCircleKey =
  | "alpha_hunter"
  | "mega_brains"
  | "self_circle"
  | "giga_brains"
  | "mega_degens"
  | "giga_degens"
  | "top_vcs"
  | "chinese_kols"
  | "korean_kols";

export const smartFollowingCircleKeys: PreDefinedSmartFollowingCircleKey[] = [
  "alpha_hunter",
  "mega_brains",
  "self_circle",
  "giga_brains",
  "mega_degens",
  "giga_degens",
  "top_vcs",
  "chinese_kols",
  "korean_kols",
];

export const PreDefinedSmartFollowingCircleKeyTextMap: Partial<
  Record<PreDefinedSmartFollowingCircleKey, string>
> = {
  // alpha_hunter: "Alpha Hunters",
  mega_brains: "Mega Brains",
  giga_brains: "Giga Brains",
  mega_degens: "Mega Degens",
  giga_degens: "Giga Degens",
  top_vcs: "VCs",
  chinese_kols: "Chinese KOLs",
  korean_kols: "Korean KOLs",
};

export const CircleLabelAndValueArray = Object.entries(
  PreDefinedSmartFollowingCircleKeyTextMap
).map(([k, v]) => ({ value: k, label: v }));

export interface SmartFollowingFilter
  extends Pick<
    SmartFollowingParams,
    "user_type" | "user_status" | "user_tag_individual_or_organization" | "duration"
  > {
  // TimeFilterValue
  dateRange?: string;
  sort?: SmartFollowingParams["sort"];
  filterSmartFollowers?: SmartFollowingParams["filter_smart_followers"];
  special?: string | PreDefinedSmartFollowingCircleKey;
  idList?: string;
  detailType?: SmartFollowingDetailType;
  date?: string;
}

export interface EditSmartFollowingItem {
  id: string;
  name: string;
  username: string;
  icon: string;
}

export type SmartFollowingFiltersHook = {
  filter: SmartFollowingFilter;
  setFilter: (
    patch:
      | Partial<SmartFollowingFilter>
      | ((prevState: SmartFollowingFilter) => Partial<SmartFollowingFilter>)
  ) => void;
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFrame1312317938: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M569.6 454.4a38.4 38.4 0 1 0-76.8 0v76.8h-76.8a38.4 38.4 0 1 0 0 76.8h76.8v76.8a38.4 38.4 0 1 0 76.8 0v-76.8h76.8a38.4 38.4 0 1 0 0-76.8h-76.8v-76.8z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M204.8 128A76.8 76.8 0 0 0 128 204.8v614.4A76.8 76.8 0 0 0 204.8 896h652.8a76.8 76.8 0 0 0 76.8-76.8V320a76.8 76.8 0 0 0-76.8-76.8h-308.437333l-84.48-101.376A38.4 38.4 0 0 0 435.2 128H204.8z m296.917333 178.176a38.4 38.4 0 0 0 29.482667 13.824h326.4v499.2H204.8V204.8h212.394667l84.48 101.376z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconAFrame1312317938.defaultProps = {
  size: 14,
};

export default IconAFrame1312317938;

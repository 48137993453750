import { Flex, Text, chakra, Tooltip } from "@chakra-ui/react";
import { useRef, useEffect, useState } from "react";
import { ClearIcon } from "@/components/Icon";

type valueType = {
  value: string;
  discord_server_icon_url: string;
  discord_server_name: string;
  [x: string]: any;
};

type removeSelectedItemType = (v: valueType) => void;

const SelectedItemRender = ({
  value,
  removeSelectedItem,
  isActivated,
}: {
  value: valueType;
  removeSelectedItem: removeSelectedItemType;
  isActivated: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpoen] = useState(false);

  useEffect(() => {
    const hasToolTip = nameRef?.current?.offsetWidth < nameRef?.current?.scrollWidth;
    const handler = () => {
      if (hasToolTip) {
        if (isOpen) {
          setIsOpoen(false);
        } else {
          setIsOpoen(true);
        }
      }
    };
    const curr = containerRef?.current;
    curr?.addEventListener("mouseenter", handler);
    curr?.addEventListener("mouseleave", handler);
    return () => {
      curr.removeEventListener("mouseenter", handler);
      curr.removeEventListener("mouseleave", handler);
    };
  }, [isOpen]);

  const elementToShow = (
    <Flex gap="4px">
      {value.discord_server_icon_url ? (
        <chakra.img src={value.discord_server_icon_url} w="20px" h="20px" borderRadius="24px" />
      ) : null}
      <Text
        fontSize="14px"
        fontWeight="400"
        lineHeight="20px"
        color="#FFFFFF"
        wordBreak="break-all"
      >
        {value.discord_server_name}
      </Text>
    </Flex>
  );

  return (
    <Tooltip placement="top" label={elementToShow} isOpen={isOpen} p="8px" minW="356px">
      <Flex
        p="2px 4px"
        alignItems="center"
        gap="2px"
        bg={isActivated ? "#474f5c" : "#323740"}
        borderRadius="4px"
        ref={containerRef}
      >
        {value.discord_server_icon_url ? (
          <chakra.img src={value.discord_server_icon_url} w="20px" h="20px" borderRadius="24px" />
        ) : null}
        <Text
          fontSize="14px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          ref={nameRef}
          lineHeight="20px"
          fontWeight="500"
          color="#ffffff"
        >
          {value.discord_server_name}
        </Text>
        <ClearIcon
          onClick={(e: any) => {
            e.stopPropagation();
            removeSelectedItem(value);
          }}
          width="16px"
          height="16px"
          flex="none"
          ml="4px"
          cursor="pointer"
          color="whiteAlpha.200"
        />
      </Flex>
    </Tooltip>
  );
};

export default SelectedItemRender;

import { RequestState, SearchState } from "@metasearch-io/search-ui";
import { omit } from "lodash";
import { MutableRefObject } from "react";

import { buildSearchResponse, NewSearchResponse } from "@/common/connector/buildSearchResponse";
import { buildSearchParams } from "@/common/connector/utils";
import { MediaSearchResult } from "@/types";

import { client } from "./base";

type ExtendParams = {
  /**
   * store each module accumulative total hits._source.subrequest
   */
  subRequestMap: Record<string, number>;
  /**
   * "/search_feed" inner logic build subrequest like `${searchId}_${v["_source"]["subrequest"]}` should replace this
   */
  saved_search_id?: string;
};

export type SearchSummaryResult = SearchState & ExtendParams;

export type SearchFeedRequest = "watchlist" | "discovery" | "personalized";

const removeSearchIdInSubRequestMap = (
  searchId: string,
  subRequestMap: ExtendParams["subRequestMap"]
) => {
  if (!searchId) return subRequestMap;

  const newSubRequestMap: ExtendParams["subRequestMap"] = {};
  Object.keys(subRequestMap).forEach((key) => {
    const newKey = key.replace(`${searchId}_`, "");
    newSubRequestMap[newKey] = (newSubRequestMap[newKey] ?? 0) + subRequestMap[key];
  });
  return newSubRequestMap;
};

export const fetchSearchSeed = async (
  params: Record<string, any> &
    ExtendParams & {
      request_type: SearchFeedRequest;
    },
  abortControllerRef: MutableRefObject<AbortController>
) => {
  if (abortControllerRef.current) {
    abortControllerRef.current.abort();
  }

  const newAbortController = new AbortController();
  abortControllerRef.current = newAbortController;

  const postParams = omit(params, ["subRequestMap"]);
  const subRequestMap = params.subRequestMap ?? {};
  const searchId = params.saved_search_id;

  // add sub request from
  Object.keys(subRequestMap).forEach((module) => {
    postParams[`${module}_from`] = subRequestMap[module];
  });

  const res: NewSearchResponse = await client.post(`/ai/newSearchFeed`, postParams, {
    signal: newAbortController.signal,
  });

  const total = res.display_metadata.all;
  const hitsResults = buildSearchResponse(res.documents);

  hitsResults.forEach((item) => {
    if (item.subrequest) {
      subRequestMap[item.subrequest] = (subRequestMap[item.subrequest] ?? 0) + 1;
    }
  });

  const result: any = {
    totalPages: Math.ceil(total / 20),
    totalResults: total,
    results: hitsResults,
    // results: [],
    wasSearched: true,
    showPage: "normal",
    subRequestMap: removeSearchIdInSubRequestMap(searchId, subRequestMap),
  };
  return result as unknown as SearchSummaryResult;
};

export type OfficialAccountKey =
  | "discord"
  | "gov_forum"
  | "medium_collection"
  | "twitter"
  | "twitter_space"
  | "medium_author";
export type OfficialAccountValue = {
  image: string;
  name: string;
};

const normalizeOfficialAccounts = (
  key: OfficialAccountKey,
  values: any[]
): Array<OfficialAccountValue> => {
  return values
    .map((value) => {
      switch (key) {
        case "twitter":
          return {
            image: value.twitter_user_profile_image_url,
            name: value.twitter_user_name ?? value.twitter_user_username,
          };
        case "discord":
          return {
            image: value.discord_server_icon_url,
            name: value.discord_server_name,
          };
        case "gov_forum":
          return {
            image: value.gov_forum_icon_url,
            name: value.gov_forum_title,
          };
        case "medium_collection":
          return {
            image: value.medium_collection_avatar_image_url,
            name: value.medium_collection_name,
          };
        case "medium_author":
          return {
            image: value.medium_author_avatar_image_url,
            name: value.medium_author_name,
          };
        case "twitter_space":
          return {
            image:
              value.twitter_user_profile_image_url ??
              value.twitter_space_creator_user_profile_image_url,
            name:
              value.twitter_user_name ??
              value.twitter_user_username ??
              value.twitter_space_creator_user_name,
          };
      }
    })
    .filter(Boolean);
};

export const fetchTickerOfficialAccounts = async (
  tickers: string[]
): Promise<Partial<Record<OfficialAccountKey, OfficialAccountValue[]>>> => {
  if (!tickers.length) return {};

  const res: any = await client.post(
    "/gateway/fetch_tickers_official_accounts",
    {
      tickers,
    },
    {
      // mode: 'no-cors',
      withCredentials: false,
    }
  );
  const { tickers_official_accounts } = res;

  const formattedOfficialAccounts = tickers_official_accounts?.reduce(
    (total: { [key: string]: any }, curr: any) => {
      const { official_account_blob } = curr;
      if (!official_account_blob) return total;
      const data = JSON.parse(official_account_blob);
      if (data) {
        Object.keys(data).map((key) => {
          if (data[key]) {
            const value = normalizeOfficialAccounts(key as OfficialAccountKey, data[key]);
            if (!total[key]) {
              total[key] = value;
            } else {
              total[key] = [...total[key], ...value];
            }
          }
        });
      }
      return total;
    },
    {}
  );

  return formattedOfficialAccounts;
};

/**
 * display_metadata: {docCounts: {Twitter: 106, all: 106}}
 * when keys.length === 1 return first
 */
const getRealCurrentTab = (
  display_metadata: NewSearchResponse["display_metadata"],
  currentTab: string
) => {
  const keys = Object.keys(display_metadata || {}).filter((key) => key !== "all");

  if (keys.length === 1) {
    return keys[0];
  }
  return currentTab;
};

export const fetchSearch = async (
  state: RequestState,
  searchState?: Pick<SearchState, "results">,
  controller: AbortController = new AbortController()
): Promise<SearchState> => {
  /**
   * first request current="all"
   * buildSearchParams will auto change sortField when currentTab not support
   *
   * BUG:
   *  when result.display_metadata only has one tab (UI will display the tab actually currentTab is "all")
   *  and sortField maybe "engagement" (currentTab not support), firstSearchParams will auto change sortField = date
   * FIX: so we should request again change currentTab to the only one tab
   *
   */
  const firstSearchParams = buildSearchParams({ state, searchState });

  const res: NewSearchResponse = await client.post("/ai/newSearchPost", firstSearchParams, {
    signal: controller.signal,
  });

  const total = res.display_metadata.all;
  const currentTab = (state as any).currentTab;
  const results: MediaSearchResult[] = [];
  // currentTab is all and first page
  const shouldSetDisplayMetaData = state.current === 1 && (currentTab === "all" || !currentTab);
  const nextCurrentTab = shouldSetDisplayMetaData
    ? getRealCurrentTab(res.display_metadata, currentTab)
    : currentTab;

  if (currentTab !== nextCurrentTab && state.sortField !== firstSearchParams.sort_by) {
    const secondSearchParams = buildSearchParams({
      state: {
        ...state,
        currentTab: nextCurrentTab,
      },
      searchState,
    });

    const realRes: NewSearchResponse = await client.post("/ai/newSearchPost", secondSearchParams, {
      signal: controller.signal,
    });
    results.push(...buildSearchResponse(realRes.documents));
  } else {
    results.push(...buildSearchResponse(res.documents));
  }

  const result: any = {
    totalPages: Math.ceil(total / 20),
    totalResults: total,
    results: results,
    wasSearched: true,
    currentTab: nextCurrentTab,
  };
  if (shouldSetDisplayMetaData) {
    result.displayMetaData = res.display_metadata;
  }
  return result as SearchState;
};

export type SearchTwitterUser = {
  bio: string;
  created_at: string;
  followers: string;
  icon: string;
  id: string;
  name: string;
  tag_individual_or_organization: string;
  timestamp: string;
  user_type: string;
  username: string;
  following_count: number;
};

export type SearchTwitterUserResponse = {
  listData: Array<SearchTwitterUser>;
};

export const searchTwitterUser = (params: {
  keyword?: string;
  is_kkol?: boolean;
  /** can be "xx,yy" or "xxx" */
  twitter_user_id?: string;
  /** enable batch fetch twitter */
  sort_order?: boolean;
  /** individual */
  user_type?: string;
}): Promise<SearchTwitterUserResponse> => {
  return client.get("/ai/search/twitter_user", {
    params,
  });
};

const BATCH_SIZE = 50;

export const batchFetchTwitterUser = async (
  twitterUserIds: string[]
): Promise<{
  listData: Array<SearchTwitterUser>;
}> => {
  return client.post("/ai/search/batch_twitter_user", {
    twitter_user_id: twitterUserIds.join(","),
    sort_order: true,
  });
};

export const getEth = async (query) => {
  return await client.get("/sharing/get_eth_denver", { params: query });
};

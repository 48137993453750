import MultiSelect from "@/components/MultiSelect";
import { default as TwitterByUserNoResultRender } from "./NoResultRender";
import { default as TwitterByUserDropmenuRender } from "./DropmenuRender";
import { default as TwitterByUserSelectedItemRender } from "./SelectedItemRender";
import { default as TwitterByUserGetRemoteOptions } from "./getRemoteOptions";
import {
  TwitterSpaceUserMultiSelectName,
  TwitterSpaceUserMultiSelectSource,
} from "@/components/Filter/SourceFilter/multiselectConstants";

const renderFilterField = (onChange, value) => (
  <MultiSelect<any>
    value={value}
    getRemoteOptions={TwitterByUserGetRemoteOptions}
    onChange={onChange}
    DropMenuItem={TwitterByUserDropmenuRender}
    RenderSelectedItem={TwitterByUserSelectedItemRender}
    RenderNoResult={TwitterByUserNoResultRender}
    placeholder={"@Twitter handle"}
    name={TwitterSpaceUserMultiSelectName}
    source={TwitterSpaceUserMultiSelectSource}
  />
);

export default renderFilterField;

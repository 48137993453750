import { authHeadersLocalStorageUtil } from "@/utils";

import { AxiosInterceptor } from "./types";

export const addAuthFieldsInHeader: AxiosInterceptor = (client) => {
  client.interceptors.request.use((req) => {
    if (authHeadersLocalStorageUtil.getValue()) {
      const lsValue = JSON.parse(authHeadersLocalStorageUtil.getValue());
      req.headers["Authorization"] = lsValue.Authorization;
      req.headers["user-id"] = lsValue.user_id;
      req.headers["session-id"] = lsValue.session_id;
    }

    return req;
  });
};

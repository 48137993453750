import axios from "axios";

const getRemoteOptions = async (prefix: string) => {
  const {
    data: { data },
  } = await axios.get("/api/gateway/auto_complete", {
    params: {
      prefix,
      source: "twitterUser",
    },
  });

  if (!data) return [];

  return data.map((v) => ({
    username: v.twitter_user_username,
    name: v.twitter_user_name,
    id: v.twitter_user_id,
    avatarUrl: v.twitter_user_profile_image_url,
    value: v.value,
    replaceAvatar: true,
  }));
};

export default getRemoteOptions;

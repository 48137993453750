import axios from "axios";

const getRemoteOptions = async (prefix: string) => {
  const {
    data: { data },
  } = await axios.get("/api/gateway/auto_complete", {
    params: {
      prefix,
      source: "discordServer",
    },
  });

  return data;
};

export default getRemoteOptions;

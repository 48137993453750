import { get } from "lodash";
import { FilterPipeTransform } from "@/types";

export const tickerFilterPipe: FilterPipeTransform = (filters, params) => {
  filters.forEach((filter) => {
    if (filter.field === "crypto_ticker") {
      const filterValue = get(filter, ["values", "0"], []) as string[];
      if (filterValue) {
        params["crypto_ticker"] = filterValue
          .map((v) => {
            const [_, ticker] = v.split("__");
            return ticker;
          })
          .join(",");
      }
    }
  });

  return [filters, params];
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconMarketsmartfollowingUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M298.666667 469.333333v85.333334h85.333333v-85.333334H298.666667zM554.666667 384h-85.333334v170.666667h85.333334V384zM640 554.666667V298.666667h85.333333v256h-85.333333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M42.666667 106.666667v640h426.666666v85.333333H256v85.333333h512v-85.333333h-213.333333v-85.333333h426.666666v-640H42.666667z m85.333333 554.666666v-469.333333h768v469.333333H128z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconAIconMarketsmartfollowingUncover.defaultProps = {
  size: 14,
};

export default IconAIconMarketsmartfollowingUncover;

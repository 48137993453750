/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFrame1312317979: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M427.946667 545.194667a42.666667 42.666667 0 0 0-10.282667-27.733334L180.992 241.066667A42.666667 42.666667 0 0 1 213.418667 170.666667h582.613333a42.666667 42.666667 0 0 1 32.426667 70.4l-236.672 276.394666a42.666667 42.666667 0 0 0-10.24 27.733334v260.821333a42.666667 42.666667 0 0 1-61.610667 38.272l-68.266667-33.706667a42.666667 42.666667 0 0 1-23.765333-38.272v-227.072z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAFrame1312317979.defaultProps = {
  size: 14,
};

export default IconAFrame1312317979;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconCalendar: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M750.890667 853.333333h-477.866667a68.266667 68.266667 0 0 1-68.266667-68.266666V307.2a68.266667 68.266667 0 0 1 68.266667-68.266667h68.266667V170.666667h68.266666v68.266666h204.8V170.666667h68.266667v68.266666h68.266667a68.266667 68.266667 0 0 1 68.266666 68.266667v477.866667a68.266667 68.266667 0 0 1-68.266666 68.266666z m-477.866667-409.6v341.333334h477.866667v-341.333334h-477.866667z m0-136.533333v68.266667h477.866667V307.2h-477.866667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconCalendar.defaultProps = {
  size: 14,
};

export default IconIconCalendar;

import { difference, isArray, isEmpty } from "lodash";

import { indexCategory } from "@/constants";
import { SourceName } from "@/types";
import { Ticker } from "@/types/ticker";

const IndexWithEngagement: (SourceName[] | SourceName)[] = [
  indexCategory.Governance,
  "Twitter",
  "Discord",
  indexCategory.Dashboard,
  "Mirror",
  "Medium",
  indexCategory.Vote,
  "Warpcast",
];
const IndexWithLength: (SourceName[] | SourceName)[] = [
  indexCategory.Governance,
  indexCategory.Research,
  indexCategory.Conference,
  indexCategory.News,
  ["Podcast", "Youtube", "Twitter_Space"],
  "Twitter",
  "Warpcast",
  "Discord",
  ["Mirror", "Medium"],
];
const IndexWithSmartEngagement: (SourceName[] | SourceName)[] = ["Twitter"];
const IndexWithAuthor: (SourceName[] | SourceName)[] = ["Twitter", "Mirror", "Warpcast"];
const IndexWithImpression: (SourceName[] | SourceName)[] = ["Twitter"];
const IndexWithBookmark: (SourceName[] | SourceName)[] = ["Twitter"];

export enum SortFieldEnum {
  Relevance = "relevance",
  SmartEngagement = "smart_engagement",
  Engagement = "engagement",
  Author = "author",
  Impression = "views",
  Bookmark = "bookmark",
  Length = "length",
  Date = "created_at",
  Sentiment = "sentiment",
}

export enum SortFieldOrderEnum {
  Desc = "desc",
  Asc = "asc",
}

const fieldNameMapToIndex = {
  [SortFieldEnum.Engagement]: IndexWithEngagement,
  [SortFieldEnum.SmartEngagement]: IndexWithSmartEngagement,
  [SortFieldEnum.Author]: IndexWithAuthor,
  [SortFieldEnum.Impression]: IndexWithImpression,
  [SortFieldEnum.Bookmark]: IndexWithBookmark,
  [SortFieldEnum.Length]: IndexWithLength,
};

/**
 * backend sort_by may be defined difference in difference index
 *
 * @example
 *
 * Author:
 *    Tweeter: author
 *    Mirror: mirror_author_total_collected
 */
export const sortByFieldNameAccordingToIndex = (
  fieldName: SortFieldEnum | string,
  indexArray: SourceName[]
) => {
  if (indexArray.length === 1) {
    const [index] = indexArray;
    if (index === "Mirror" && fieldName === SortFieldEnum.Author) {
      return "mirror_author_total_collected";
    }
  }
  return fieldName;
};

export const createSortValue = (
  sortField: SortFieldEnum | string,
  sortDirection: string = "desc"
) => {
  return `${sortField}--${sortDirection}`;
};

export const hasSort = (indexArray: SourceName[], fieldName: SortFieldEnum) => {
  const IndexConfig = fieldNameMapToIndex[fieldName];

  if (!IndexConfig) return false;

  return IndexConfig.some((v) => {
    if (isArray(v)) {
      return isEmpty(difference(indexArray, v));
    } else if (indexArray.length === 1) {
      return v === indexArray[0];
    }
    return false;
  });
};

/**
 * if switch sort filter, some tab not support some [sortField] should fallback to [Date]
 */
export const correctSortField = (
  indexArray: SourceName[],
  sortField: SortFieldEnum | string
): string => {
  const shouldCorrectSortFields = [
    SortFieldEnum.Engagement,
    SortFieldEnum.SmartEngagement,
    SortFieldEnum.Author,
    SortFieldEnum.Impression,
    SortFieldEnum.Bookmark,
    SortFieldEnum.Length,
  ];
  for (const _sortField of shouldCorrectSortFields) {
    if (sortField === _sortField && !hasSort(indexArray, sortField)) {
      return SortFieldEnum.Date;
    }
  }
  return sortField;
};

/**
 *
 * suggestTicker maybe matched in multiple case
 *
 * 1. "arb bnb" => suggestTicker: arb
 *    1.1 should consider uppercase or lowercase
 * 2. "Curve DAO Token" => full match suggestTicker: "Curve DAO Token"
 *    2.1 consider double space
 * 3. "bitcoin" => suggestTicker: btc , full name matched
 *
 */
export const replaceFuzzySuggestTickerInTerm = (suggestTicker: Ticker, searchTopic: string) => {
  const suggestMatchPhrase = suggestTicker.suggest_match_phrase.toLocaleUpperCase();

  const suggestMatchReg = new RegExp(`${suggestMatchPhrase.split(" ").join("\\s+")}`, "i");
  if (suggestMatchReg.test(searchTopic)) {
    return searchTopic
      .replace(suggestMatchReg, "")
      .replace(/OR AND|OR OR|AND OR|AND AND|""|<>|\(\)|[]/g, "")
      .replace(/\s+/g, " ");
  }

  return searchTopic;
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSandclockUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M213.333333 177.792C213.333333 150.186667 235.562667 128 263.125333 128h497.749334a49.706667 49.706667 0 1 1 0 99.541333v17.109334c0 65.962667-26.282667 129.28-72.96 175.957333l-105.429334 105.6 105.6 105.642667a248.917333 248.917333 0 0 1 72.96 175.914666v17.109334c27.52 0 49.792 22.272 49.792 49.792s-22.272 49.792-49.792 49.792H263.125333A49.706667 49.706667 0 0 1 213.333333 874.666667c0-27.52 22.229333-49.792 49.792-49.792v-17.066667c0-65.962667 26.282667-129.28 72.96-175.957333l105.429334-105.642667-105.429334-105.6a248.917333 248.917333 0 0 1-72.96-175.957333v-17.066667A49.706667 49.706667 0 0 1 213.333333 177.749333z m149.333334 49.749333v17.109334c0 39.68 15.701333 77.653333 43.690666 105.642666L512 455.765333l105.642667-105.642666A149.290667 149.290667 0 0 0 661.333333 244.522667v-16.981334h-298.666666z m0 597.333334h298.666666v-17.066667c0-39.68-15.701333-77.653333-43.690666-105.642667L512 596.693333 406.357333 702.293333A149.290667 149.290667 0 0 0 362.666667 807.936v17.109333-0.170666z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSandclockUncover.defaultProps = {
  size: 14,
};

export default IconAIconSandclockUncover;

import loggerClient from "@/common/loggerClient";
import { useSession } from "next-auth/react";
import { useCallbackFn } from "./utils";

/**
 * common logger event
 */
export const useLogEvent = (type: string) => {
  const session = useSession();
  const logEvent = useCallbackFn((name: string, metadata?: Record<string, any>) => {
    // convert array to string for data_source
    if (metadata?.data_source && Array.isArray(metadata.data_source)) {
      metadata.data_source = metadata.data_source.join(",");
    }
    loggerClient.logEvent({
      type: type,
      params: {
        name: name,
        metadata: {
          ...metadata,
          user_id: session.data?.user?.user_id,
          session_id: session.data?.user?.session_id,
        },
      },
    });
  });

  return { logEvent };
};

import dayjs from "dayjs";
import { clone } from "lodash";
import { i18n } from "next-i18next";
import numeral from "numeral";

import { TELEGRAM_URL } from "@/constants";
import { OpenSearchTwitterObject, OpenSearchTwitterObjectWithChildren } from "@/types";

export const formatNumber = (num: number = 0) => {
  if (num < 1000) {
    return num;
  }
  return numeral(num).format("0.0a");
};
const ONE_HOUR = 1000 * 60 * 60;
const ONE_DAY = ONE_HOUR * 24;
export const formatTime = (time: string, showGap: boolean = true) => {
  const now = Date.now();
  const current = new Date(time).getTime();
  const differ = now - current;
  if (showGap) {
    if (differ < ONE_HOUR) {
      return Math.ceil(differ / 1000 / 60) + " min";
    } else if (differ >= ONE_HOUR && differ < ONE_DAY * 2) {
      const hours = Math.floor(differ / ONE_HOUR);
      const mins = Math.floor((differ - ONE_HOUR * hours) / ONE_HOUR);
      return hours + " h" + (mins > 0 ? mins + " min" : "");
    }
  }

  return dayjs(current).format("MMM D, YYYY");
};
export const formatDateAndTime = (time: string) => {
  const current = new Date(time).getTime();

  return dayjs(current).format("ddd MMM D, hh:mm a");
};

export function formatTwitterPubType(v: OpenSearchTwitterObject) {
  if (v.twitter_tweet_is_thread) {
    return "Thread";
  } else if (v.twitter_tweet_has_no_reference) {
    return "Tweet";
  } else if (v.twitter_tweet_is_quote) {
    return "Quote";
  } else if (v.twitter_tweet_is_reply) {
    return "Reply";
  } else if (v.twitter_tweet_is_retweet) {
    return "Retweet";
  }
}

export function formatTwitterEnagement(v: OpenSearchTwitterObject) {
  return `${formatTwitterPubType(v)} · ${i18n.t("common:result_like", {
    format_count: formatNumber(v.twitter_tweet_like_count),
    count: v.twitter_tweet_like_count,
  })} · ${i18n.t("common:result_retweet", {
    format_count: formatNumber(v.twitter_tweet_retweet_count) as number,
    count: v.twitter_tweet_retweet_count,
  })} · ${i18n.t("common:result_quote", {
    format_count: formatNumber(v.twitter_tweet_quote_count) as number,
    count: v.twitter_tweet_quote_count,
  })} · ${i18n.t("common:result_reply", {
    format_count: formatNumber(v.twitter_tweet_reply_count) as number,
    count: v.twitter_tweet_reply_count,
  })}`;
}

export const formatTwitterSmartEngagement = (v: OpenSearchTwitterObject) => {
  return i18n.t("common:result_smart_engagement", {
    format_count: formatNumber(Math.floor(v.twitter_kkol_engagement_count_ranking ?? 0)) as number,
    count: Math.floor(v.twitter_kkol_engagement_count_ranking ?? 0),
  });
};

export function formatTwitterEngagementV2(v: OpenSearchTwitterObject) {
  return `${formatTwitterPubType(v)} · ${i18n.t("common:result_engagement", {
    format_count: formatNumber(v.engagement_count),
    count: v.engagement_count,
  })} · ${formatTwitterSmartEngagement(v)} · ${i18n.t("common:result_bookmark", {
    format_count: formatNumber(v.twitter_tweet_bookmark_count ?? 0) as number,
    count: v.twitter_tweet_bookmark_count ?? 0,
  })}`;
}

export function getTimeLength(time: number) {
  return dayjs
    .utc(Number(time) * 1000)
    .utcOffset(0)
    .format("HH:mm:ss");
}

type RequestSequence = number;

export class RequestSequencer {
  requestSequence: RequestSequence = 0;

  next(): RequestSequence {
    return ++this.requestSequence;
  }

  isOldRequest(request: RequestSequence): boolean {
    return request < this.requestSequence;
  }
}

export class LocalStorageUtil {
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  getValue() {
    return localStorage.getItem(this.key);
  }

  getValueArray() {
    try {
      return JSON.parse(localStorage.getItem(this.key));
    } catch {
      return null;
    }
  }

  setValue(v: string | number | string[]) {
    let newV = v;
    if (typeof newV === "number") {
      newV = String(newV);
    }
    if (Array.isArray(newV)) {
      newV = JSON.stringify(newV);
    }
    localStorage.setItem(this.key, newV);
  }
}
export const languageLocalStorage = new LocalStorageUtil("language");

export const sortByLike = (value: OpenSearchTwitterObjectWithChildren[]) => {
  const result = clone(value);
  result.sort((a, b) => b.twitter_tweet_like_count - a.twitter_tweet_like_count);
  return result;
};

export const getEnv = () => {
  if (!global.location) return "dev";
  if (location.host === "kkwdmsn2023-portal.kaito.ai") {
    return "dev";
  } else if (location.host === "portal.kaito.ai") {
    return "beta";
  }
  return "dev";
};
export const getHomepagePath = () =>
  getEnv() === "dev" ? "https://dev.kaito.ai" : "https://kaito.ai";

export function setCommonHeaders(res: any) {
  res.setHeader("Content-Type", "text/event-stream");
  res.setHeader("Access-Control-Allow-Origin", "*");
  res.setHeader("Connection", "keep-alive");
  res.setHeader("Cache-Control", "no-cache, no-transform");
}

export function sliceInputText(inputValue: string, num: number) {
  let numChars = 0;
  let slicedInput = "";
  for (let i = 0; i < inputValue.length && numChars < num; i++) {
    const char = inputValue[i];
    const charCode = char.charCodeAt(0);
    if (charCode >= 0x4e00 && charCode <= 0x9fa5) {
      // Chinese character
      numChars += 2;
    } else {
      numChars++;
    }

    // Add the character to the sliced input if it won't exceed the 20-character limit
    if (numChars <= num && (i === 0 || char !== inputValue[i - 1])) {
      slicedInput += char;
    }
  }
  return slicedInput;
}

export function filterInputValue(inputValue: string, num: number) {
  const filteredValue = inputValue.replace(/[^a-zA-Z0-9\s-_]/g, "").substring(0, num);
  return filteredValue;
}

type Entries<T extends object> = { [K in keyof T]: [K, T[K]] }[keyof T];

function reverseEnum<E extends Record<keyof E, string | number>>(
  e: E
): { [K in E[keyof E]]: Extract<Entries<E>, [any, K]>[0] };
function reverseEnum(
  e: Record<string | number, string | number>
): Record<string | number, string | number> {
  const ret: Record<string | number, string | number> = {};
  Object.keys(e).forEach((k) => (ret[e[k]] = k));
  return ret;
}

export function twoWayEnum<E extends Record<keyof E, string | number>>(e: E) {
  return Object.assign(reverseEnum(e), e);
}

export function isEarlyBirdPlan() {
  return dayjs().isBefore("2023-10-01", "day");
}

export const searchQueryIdLocalStorageUtil = new LocalStorageUtil("search_query_id");
export const authHeadersLocalStorageUtil = new LocalStorageUtil("auth_headers");
export const levelLocalStorageUtil = new LocalStorageUtil("user_level");

export const getHashRenderStr = (hash: string = "") => {
  if (!hash) return "";
  return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`;
};

export const getSafeArrayData = (data: Object | Array<any>) => {
  if (Array.isArray(data)) return data;
  return [data];
};

export const contactSales = () => {
  window.open(TELEGRAM_URL);
};

import { AxiosInterceptor } from "./types";

/**
 * ! should put in last of array, cause this will change response data and execution sequence FIFO
 * reduce visit data
 */
export const reduceVisitDataChain: AxiosInterceptor = (client) => {
  client.interceptors.response.use((resp) => {
    return resp.data;
  });
};

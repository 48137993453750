/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconBanAlert: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M193.28 790.613333L796.714667 187.221333l-45.269334-45.226666L148.053333 745.386667l45.226667 45.226666zM575.274667 199.893333c18.773333 4.778667 36.736 11.605333 53.589333 20.266667L572.16 276.906667a181.333333 181.333333 0 0 0-241.493333 171.093333v70.4L256 593.066667v-145.066667a256.128 256.128 0 0 1 192.725333-248.106667c-0.469333-2.56 0-5.205333 0-7.893333v-42.666667h126.549334v42.666667c0 2.688 0.469333 5.333333 0 7.893333zM693.333333 666.666667H363.434667l-74.666667 74.666666h559.232v-74.666666H768V448a254.805333 254.805333 0 0 0-43.349333-142.592L670.421333 359.68c14.592 26.112 22.912 56.234667 22.912 88.32v218.666667zM410.666667 794.666667v74.666666h202.666666V832v-37.333333h-202.666666z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconBanAlert.defaultProps = {
  size: 14,
};

export default IconAIconBanAlert;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconMaybebigUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M458.496 745.728h72.789333v-72.789333h-72.789333v72.789333zM615.850667 488.405333c-8.96 11.434667-18.56 21.546667-28.842667 30.378667-10.325333 8.832-19.925333 17.664-28.885333 26.496-8.96 8.874667-16.384 18.005333-22.229334 27.52-5.888 9.514667-8.874667 20.053333-8.874666 31.573333v30.634667h-68.266667v-32.981333c0-13.824 2.816-26.325333 8.362667-37.546667a153.6 153.6 0 0 1 20.693333-31.402667c8.277333-9.770667 17.237333-19.157333 26.88-28.16 9.557333-9.088 18.517333-17.92 26.794667-26.538666 8.277333-8.533333 15.146667-17.664 20.693333-27.136 5.546667-9.514667 8.32-19.925333 8.32-31.274667a55.04 55.04 0 0 0-6.101333-26.624 55.04 55.04 0 0 0-16.810667-19.029333 74.88 74.88 0 0 0-24.448-11.349334 111.146667 111.146667 0 0 0-29.354667-3.754666c-33.749333 0-65.877333 15.018667-96.426666 45.141333V325.973333c37.12-21.76 75.562667-32.597333 115.413333-32.597333 18.346667 0 35.669333 2.346667 51.968 7.125333 16.298667 4.778667 30.549333 11.776 42.794667 21.034667 12.202667 9.301333 21.845333 20.821333 28.885333 34.645333 6.997333 13.824 10.496 29.866667 10.496 48.213334 0 17.450667-2.944 32.938667-8.832 46.506666a171.818667 171.818667 0 0 1-22.229333 37.546667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M500.906667 917.333333c-229.674667-0.213333-415.744-186.581333-415.573334-416.298666C85.504 271.36 271.786667 85.248 501.461333 85.333333c229.717333 0.085333 415.872 186.282667 415.872 416-0.128 229.845333-186.538667 416.085333-416.426666 416z m-332.373334-408.832a332.8 332.8 0 1 0 0-7.168v7.168z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconAIconMaybebigUncover.defaultProps = {
  size: 14,
};

export default IconAIconMaybebigUncover;

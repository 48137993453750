/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSearchlistDiscord: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M813.653333 247.082667c-55.466667-24.32-114.944-42.24-177.152-52.522667a2.730667 2.730667 0 0 0-2.858666 1.28c-7.68 13.013333-16.128 29.994667-22.058667 43.306667a692.608 692.608 0 0 0-198.997333 0 430.933333 430.933333 0 0 0-22.4-43.306667 2.858667 2.858667 0 0 0-2.816-1.28A739.84 739.84 0 0 0 210.176 247.04a2.517333 2.517333 0 0 0-1.152 0.981333c-112.853333 161.194667-143.786667 318.421333-128.597333 473.728a2.816 2.816 0 0 0 1.152 1.92 735.189333 735.189333 0 0 0 217.344 105.088 2.901333 2.901333 0 0 0 3.029333-0.981333c16.768-21.845333 31.658667-44.928 44.458667-69.12 0.768-1.450667 0.042667-3.114667-1.493334-3.712a485.205333 485.205333 0 0 1-67.882666-30.933333 2.602667 2.602667 0 0 1-0.298667-4.437334c4.565333-3.242667 9.130667-6.656 13.482667-10.112a2.773333 2.773333 0 0 1 2.816-0.341333c142.464 62.165333 296.661333 62.165333 437.418666 0a2.773333 2.773333 0 0 1 2.858667 0.341333c4.352 3.413333 8.96 6.826667 13.525333 10.112a2.602667 2.602667 0 0 1-0.256 4.437334c-21.674667 12.117333-44.202667 22.357333-67.925333 30.933333a2.602667 2.602667 0 0 0-1.450667 3.669333c13.056 24.234667 27.989333 47.274667 44.373334 69.12a2.816 2.816 0 0 0 3.072 1.024 732.8 732.8 0 0 0 217.685333-105.088 2.645333 2.645333 0 0 0 1.152-1.92c18.133333-179.498667-30.378667-335.445333-128.682667-473.728a2.176 2.176 0 0 0-1.152-0.981333z m-445.952 380.16c-42.88 0-78.250667-37.674667-78.250666-83.925334 0-46.208 34.688-83.882667 78.250666-83.882666 43.904 0 78.933333 37.973333 78.208 83.882666 0 46.250667-34.645333 83.882667-78.208 83.882667z m289.194667 0c-42.88 0-78.208-37.674667-78.208-83.925334 0-46.208 34.645333-83.882667 78.208-83.882666 43.946667 0 78.933333 37.973333 78.250667 83.882666 0 46.250667-34.304 83.882667-78.250667 83.882667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSearchlistDiscord.defaultProps = {
  size: 14,
};

export default IconAIconSearchlistDiscord;

const radio = {
  baseStyle: {
    control: {
      _checked: {
        _before: {
          content: `""`,
          display: "inline-block",
          pos: "relative",
          w: "10px",
          h: "10px",
          border: "2px solid",
          borderColor: "currentColor",
          borderRadius: "50%",
          bg: "brand.200",
        },
        borderColor: "brand.200!important",
      },
      border: "1px solid",
      borderColor: "grayBlue.200",
      bg: "grayBlue.500",
    },
  },
};
export default radio;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconTrackUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 85.333333C276.352 85.333333 85.333333 276.352 85.333333 512s191.018667 426.666667 426.666667 426.666667 426.666667-191.018667 426.666667-426.666667h-85.333334a341.333333 341.333333 0 1 1-341.333333-341.333333V85.333333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M512 277.333333a234.666667 234.666667 0 1 0 234.666667 234.666667h-85.333334A149.333333 149.333333 0 1 1 512 362.666667v-85.333334zM646.4 243.754667V377.6h134.912L896 262.4h-134.314667V128L646.4 243.754667z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
      <path
        d="M804.352 24.704V219.733333h194.304l-199.594667 200.533334h-135.082666l-121.813334 121.898666-60.330666-60.330666 121.898666-122.026667V226.133333L804.352 24.746667zM689.066667 334.933333h74.538666l29.738667-29.866666h-74.325333V231.338667l-29.952 30.037333V334.933333z"
        fill={getIconColor(color, 2, 'currentColor')}
      />
    </svg>
  );
};

IconIconTrackUncover.defaultProps = {
  size: 14,
};

export default IconIconTrackUncover;

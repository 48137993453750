/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconHearUncovert: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.744 186.154667c-90.794667-79.36-224.981333-95.573333-329.258667-6.485334-112.725333 96.298667-129.109333 258.56-39.936 373.290667 40.448 52.053333 127.573333 136.106667 202.154667 205.141333a7458.005333 7458.005333 0 0 0 138.496 124.970667l2.986667 2.602667 25.557333-29.141334-25.557333 29.184 25.557333 22.314667 25.514667-22.314667-25.514667-29.184 25.514667 29.184 3.029333-2.645333a5239.594667 5239.594667 0 0 0 39.466667-34.986667c25.898667-23.082667 61.269333-54.997333 98.986666-89.984 74.581333-69.034667 161.706667-153.088 202.24-205.141333 88.746667-114.304 74.922667-277.76-40.405333-373.632-105.386667-87.594667-238.208-72.533333-328.832 6.826667z m0 618.666666a7371.178667 7371.178667 0 0 1-114.346667-103.594666c-75.648-70.101333-157.781333-149.76-193.621333-195.84-63.445333-81.664-52.48-197.034667 29.141333-266.752 78.08-66.730667 183.210667-49.578667 249.344 27.733333l29.482667 34.474667 29.44-34.474667c65.877333-76.970667 169.642667-94.037333 249.813333-27.392 82.346667 68.437333 92.501333 184.32 28.714667 266.368-35.84 46.122667-117.973333 125.781333-193.664 195.84a7378.346667 7378.346667 0 0 1-114.304 103.594667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconHearUncovert.defaultProps = {
  size: 14,
};

export default IconIconHearUncovert;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSearchlistPodcast: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 102.314667a364.8 364.8 0 0 0-364.8 364.8A364.672 364.672 0 0 0 367.616 802.133333c-5.632-33.792-10.026667-67.754667-11.392-94.72a286.464 286.464 0 0 1-130.944-240.298666A286.933333 286.933333 0 0 1 512 180.522667a286.933333 286.933333 0 0 1 286.634667 286.592 286.762667 286.762667 0 0 1-130.901334 240.384c-1.365333 26.88-5.76 60.885333-11.477333 94.72A364.8 364.8 0 0 0 512 102.357333z m0 508.16c-53.589333 0-104.277333 14.037333-104.277333 71.253333 0 53.930667 20.992 169.813333 33.578666 216.234667 8.448 31.018667 39.893333 38.186667 70.656 38.186666 30.805333 0 62.293333-7.125333 70.656-38.058666 12.586667-46.762667 33.578667-162.56 33.578667-216.32 0-57.301333-50.645333-71.338667-104.234667-71.338667z m0-52.138667a91.136 91.136 0 0 0 91.178667-91.221333c0-50.346667-42.197333-91.178667-91.221334-91.178667s-91.178667 40.704-91.178666 91.178667c0 50.517333 40.704 91.221333 91.178666 91.221333z m234.496-91.221333a234.496 234.496 0 1 0-469.034667 0c0 73.045333 34.048 137.429333 86.357334 180.48 7.936-20.608 23.04-42.154667 52.224-57.088 0-0.085333 0.469333-0.085333 0.853333-0.085334a155.733333 155.733333 0 0 1-61.269333-123.306666 156.330667 156.330667 0 1 1 312.704 0c0 50.346667-24.234667 94.72-61.184 123.306666 0.213333 0 0.810667 0.042667 0.768 0.085334 29.184 14.933333 44.245333 36.437333 52.181333 57.045333 52.352-42.965333 86.4-107.477333 86.4-180.437333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSearchlistPodcast.defaultProps = {
  size: 14,
};

export default IconAIconSearchlistPodcast;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSearchlistMedium: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M568.832 514.816a258.773333 258.773333 0 1 1-517.546667 0 258.773333 258.773333 0 0 1 517.546667 0zM853.589333 514.816c0 134.869333-58.154667 244.224-129.877333 244.224-71.765333 0-129.92-109.354667-129.92-244.224 0-134.912 58.154667-244.266667 129.92-244.266667 71.722667 0 129.877333 109.354667 129.877333 244.224zM965.845333 514.773333c0 121.130667-19.541333 219.306667-43.648 219.306667s-43.648-98.176-43.648-219.306667c0-121.088 19.541333-219.306667 43.648-219.306666s43.648 98.218667 43.648 219.306666z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSearchlistMedium.defaultProps = {
  size: 14,
};

export default IconAIconSearchlistMedium;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconCopyUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M342.186667 119.466667h452.778666c50.005333 0 90.624 40.618667 90.794667 90.794666v509.269334c0 18.773333-15.36 34.133333-34.133333 34.133333-18.773333 0-34.133333-15.36-34.133334-34.133333V210.261333a22.485333 22.485333 0 0 0-22.528-22.528H342.186667c-18.773333 0-34.133333-15.36-34.133334-34.133333 0-18.773333 15.36-34.133333 34.133334-34.133333zM206.677333 283.818667h463.36a51.370667 51.370667 0 0 1 51.370667 51.2V836.266667c0 28.330667-22.869333 51.2-51.2 51.2H206.677333c-28.330667 0-51.2-22.869333-51.2-51.2V335.018667c0-28.330667 22.869333-51.2 51.2-51.2z m17.066667 535.381333h429.397333V352.085333H223.744V819.2z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconCopyUncover.defaultProps = {
  size: 14,
};

export default IconIconCopyUncover;

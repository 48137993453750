/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconTrendingUp: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M531.498667 618.112a40.533333 40.533333 0 0 0 28.586666 11.861333h23.765334a40.533333 40.533333 0 0 0 28.629333-11.861333l190.634667-190.634667L896 520.32V277.333333h-242.986667l92.842667 92.885334-173.866667 173.866666-121.514666-121.514666a40.533333 40.533333 0 0 0-28.629334-11.861334h-23.722666a40.533333 40.533333 0 0 0-28.629334 11.861334l-271.658666 271.658666 57.258666 57.258667 254.890667-254.890667 121.514667 121.514667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconTrendingUp.defaultProps = {
  size: 14,
};

export default IconIconTrendingUp;

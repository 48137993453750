/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconStackedchart: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M904.362667 156.586667a28.586667 28.586667 0 0 0-48.768-20.224l-124.586667 124.586666-84.736-69.333333a28.586667 28.586667 0 0 0-38.826667 2.474667l-147.370666 155.989333L364.8 302.506667a28.586667 28.586667 0 0 0-36.266667 9.386666l-86.784 126.165334-109.226666-93.610667A28.586667 28.586667 0 0 0 85.333333 366.08v476.202667c0 15.786667 12.8 28.586667 28.586667 28.586666h761.898667c15.786667 0 28.586667-12.8 28.586666-28.586666V156.586667z m-57.130667 160.682666l-107.904 123.306667-92.842667-77.397333a28.586667 28.586667 0 0 0-39.808 3.157333l-120.832 138.069333-96.256-32.085333a28.586667 28.586667 0 0 0-32.810666 11.264l-99.584 149.376-114.730667-64.512v-140.245333l86.186667 73.856a28.586667 28.586667 0 0 0 42.112-5.504l90.709333-131.84 92.032 45.994666a28.586667 28.586667 0 0 0 33.536-5.973333l143.658667-152.064 84.181333 68.864a28.586667 28.586667 0 0 0 38.272-1.92l94.08-94.08v91.733333zM763.989333 499.2l83.242667-95.146667v409.642667H142.506667V634.026667l109.824 61.781333a28.586667 28.586667 0 0 0 37.76-9.045333l102.186666-153.301334 93.610667 31.189334a28.586667 28.586667 0 0 0 30.506667-8.277334l114.986666-131.413333 92.842667 77.397333c11.946667 9.941333 29.568 8.533333 39.808-3.114666z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconStackedchart.defaultProps = {
  size: 14,
};

export default IconIconStackedchart;

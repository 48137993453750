import { PlanLevel } from "@/types/pricing";
import { FreeTrialInfo, PortalUserInfo } from "@/types/userInfo";

import { client } from "./base";

export const getPortalUserInfo = (): Promise<PortalUserInfo> => {
  return client.get(`/portal/get_user_info`).then((data) => {
    const user = data.data as PortalUserInfo;
    user.membershipLevel = user.membershipLevel ?? PlanLevel.Free;

    // mock data: out date subscription
    // user.stripeSubscriptionId = "sub_1NbKJEAUNE8IewMVXDFZvqGZ";
    // user.stripeCustomerId = "cus_OO6WuELsvtcRNx";

    // mock data: monthly
    // user.stripeSubscriptionId = "sub_1NaFFZAUNE8IewMVIL4xxgwc";
    // user.stripeCustomerId = "cus_OMzEdi8DPN9KrZ";

    return user;
  });
};

export const updateUserInfo = (
  userInfo:
    | Partial<PortalUserInfo>
    | {
        podcast_visit?: number;
      }
) => {
  return client.patch("/portal/update_user", userInfo);
};

// expireDate timestamp
export const setUserFreeTrial = (expireDate: number) => {
  return client.put("/portal/set_user_free_trial", { expireDate });
};

export const putDataRequest = (params: { url: string; source: string; field: string }) => {
  return client.post("/portal/data_request", params);
};

export const setFreeTrialInfo = (data: FreeTrialInfo) => {
  return client.post("/portal/set_free_trial_info", data);
};

export const logUserAccess = async () => {
  return client.post(`/portal/log_user_access`);
};

export const SLACK_URL_PARAM = "slack_connect";
// This API is used to encrypt the current URL so that it can be returned to the original URL after authorization in Slack.
export const getEncryptedValue = (hasPopup: boolean) => {
  const url = new URL(window.location.href);
  hasPopup && url.searchParams.set(SLACK_URL_PARAM, "true");

  const urlSuffix = url.href.replace(`${url.protocol}//`, "");
  return client.get(`/portal/encrypted_value?redirectUrl=${urlSuffix}`);
};

export const getTwitterGrantUrlApi = async (type: string): Promise<{ url: string }> => {
  return client.get("/portal/get_twitter_grant_url", {
    params: {
      callback_url_type: type,
    },
  });
};

export const connectTwitterUserApi = async (data: {
  oauthToken: string;
  oauthVerifier: string;
}) => {
  return client.post("/portal/connect_twitter_user", data);
};

export const disconnectTwitterApi = async () => {
  return client.delete("/portal/disconnect_twitter_user");
};

export const disconnectSlackApi = async () => {
  return client.patch("/portal/disconnect_slack_user");
};
export const getOwnerSub = (data) => {
  return client.get("/portal/get_owner_sub", {
    params: data,
  });
};
export const getOwnerInfo = () => {
  return client.get("/portal/get_owner_info");
};

export const createOwnerSub = (data: { subEmail: string }) => {
  return client.post("/portal/create_owner_sub", data);
};

export const deleteOwnerSub = async (data) => {
  return client.delete("/portal/delete_owner_sub", {
    params: data,
  });
};

import MultiSelect from "@/components/MultiSelect";
import axios from "axios";
import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { default as TwitterByUserDropmenuRender } from "../twitter_space_by_user/DropmenuRender";
import { default as TwitterByUserSelectedItemRender } from "../twitter_space_by_user/SelectedItemRender";
import {
  MediumAuthorMultiSelectName,
  MediumMultiSelectSource,
} from "@/components/Filter/SourceFilter/multiselectConstants";

const getRemoteOptions = async (prefix: string) => {
  const {
    data: { data },
  } = await axios.get("/api/gateway/auto_complete", {
    params: {
      prefix,
      source: "mediumAuthor",
    },
  });

  if (!data) {
    return [];
  }

  return data.map((v) => ({
    name: v.medium_author_name,
    avatarUrl: v.medium_author_avatar_image_url,
    value: v.value,
  }));
};

const NoResultRender = () => {
  return (
    <Flex color="whiteAlpha.200" flexFlow="flex-start" height="24px" p="6.5px" alignItems="center">
      <InfoOutlineIcon mr="6px" boxSize="11px" />
      <Text fontSize="12px" textAlign="center">
        No author found
      </Text>
    </Flex>
  );
};

const renderFilterField = (onChange, value) => (
  <MultiSelect<any>
    value={value}
    getRemoteOptions={getRemoteOptions}
    onChange={onChange}
    DropMenuItem={TwitterByUserDropmenuRender}
    RenderSelectedItem={TwitterByUserSelectedItemRender}
    RenderNoResult={NoResultRender}
    placeholder={"Medium Author"}
    name={MediumAuthorMultiSelectName}
    source={MediumMultiSelectSource}
  />
);
export default renderFilterField;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconDiscovery: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M690.773333 306.56l-256 64a85.333333 85.333333 0 0 0-62.08 62.08l-64 257.066667a21.333333 21.333333 0 0 0 21.333334 26.666666h5.333333l257.066667-64a85.333333 85.333333 0 0 0 62.08-62.08l64-257.066666a21.333333 21.333333 0 0 0-21.333334-26.666667h-6.4zM637.44 386.56l-47.146667 188.16a21.333333 21.333333 0 0 1-15.573333 15.573333L386.56 637.44l47.146667-188.16a21.333333 21.333333 0 0 1 15.573333-15.573333l188.16-47.146667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M512 85.333333a426.666667 426.666667 0 1 0 0 853.333334 426.666667 426.666667 0 0 0 0-853.333334z m0 64a362.666667 362.666667 0 1 1 0 725.333334 362.666667 362.666667 0 0 1 0-725.333334z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconDiscovery.defaultProps = {
  size: 14,
};

export default IconIconDiscovery;

import { Button, Text } from "@chakra-ui/react";
import { useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";

import { getListInvoice } from "@/client/payment";
import { IconPaymentErr } from "@/components/Icon";
import { TELEGRAM_URL } from "@/constants";
import { useToast } from "@/hooks/useToast";
import { useCallbackFn } from "@/hooks/utils";
import { pricingModalAtom } from "@/store/ui-control";
import { paymentRetryUseAtom } from "@/store/ui-control";
import { subscriptionAtom, userInfoAtom } from "@/store/userInfo";
import { ScenarioEnums } from "@/types/userInfo";
import { isFreeWithoutAlert, isPaymentErr, paymentErrType } from "@/utils/user";

export const PaymentFailModal: React.FC = () => {
  const userInfo = useAtomValue(userInfoAtom);
  const subscription = useAtomValue(subscriptionAtom);
  const updatePricingModal = useSetAtom(pricingModalAtom);
  const updatePaymentRetry = useSetAtom(paymentRetryUseAtom);
  const toast = useToast();

  const handleViewPlan = useCallbackFn(() => {
    updatePricingModal({
      enableHide: true,
      visible: true,
    });
  });

  const handleRetryPay = async (type) => {
    const invoice = await getListInvoice(userInfo.stripeSubscriptionId);
    if (!!invoice.data.length) {
      updatePaymentRetry({
        payPrice: invoice.data[0]?.total || 0,
      });
      if (type == "retry") {
        updatePaymentRetry({
          isShowRetryPay: true,
        });
      } else {
        updatePaymentRetry({
          isShowUpdatePay: true,
        });
      }
    } else {
      toast.error("No bill found");
    }
  };

  const errMsg = useMemo(() => {
    let errTitle = {
      title: "",
      msg: "",
    };
    if (isFreeWithoutAlert(userInfo)) {
      errTitle.title = "Please subscribe to unlock full access";
      errTitle.msg =
        "With Kaito Individual, gain access to thousands of premium sources, advanced mindshare and sentiment analytics, narrative tracking, smart following and so much more.";
      return errTitle;
    }
    errTitle.title = "Your subscription has expired";
    errTitle.msg = userInfo.alert.message || "";
    return errTitle;
  }, [userInfo]);

  return (
    <>
      {(isPaymentErr(userInfo, subscription) || isFreeWithoutAlert(userInfo)) &&
        location.pathname !== "/account" && (
          <div
            className="w-full h-full absolute top-0 left-0 bg-no-repeat z-[39] flex flex-col items-center justify-center"
            style={{
              background: `linear-gradient(rgba(25, 28, 31, 0.00) 0%, #1F2123 100%)`,
              backdropFilter: "blur(8px)",
            }}
          >
            <div className="w-[600px]  bg-[#262A30] border border-[#323740] p-6 rounded-[8px]">
              <div className="text-[20px] text-[#fff] mb-4 flex items-center">
                <IconPaymentErr className="mr-3 min-w-[24px]" />
                <Text className="font-medium ">{errMsg.title}</Text>
              </div>

              <Text className="text-white/80 text-base mb-6"> {errMsg.msg}</Text>
              <div className="flex justify-between w-full text-sm">
                {((isPaymentErr(userInfo, subscription) &&
                  paymentErrType(userInfo) === ScenarioEnums.ACTIVELY_CANCEL) ||
                  isFreeWithoutAlert(userInfo)) && (
                  <div className="flex justify-between w-full text-sm">
                    <div
                      className="flex-1 mr-6  box-content py-2  text-center rounded border border-[#474F5C] text-white font-medium cursor-pointer"
                      onClick={() => {
                        window.open(TELEGRAM_URL);
                      }}
                    >
                      Contact Sales
                    </div>
                    <div
                      className="flex-1 box-content py-2 text-center rounded bg-[#32FFDC] text-black font-medium cursor-pointer"
                      onClick={handleViewPlan}
                    >
                      View Plans
                    </div>
                  </div>
                )}

                {isPaymentErr(userInfo, subscription) &&
                  paymentErrType(userInfo) === ScenarioEnums.SUBSCRIPTION_RENEW_FAILED && (
                    <div className="flex justify-between w-full text-sm">
                      <Button
                        backgroundColor="#474F5C"
                        background="#262A30"
                        variant="outline"
                        _hover={{}}
                        className="flex-1 mr-6 text-center rounded text-white font-medium cursor-pointer"
                        onClick={() => handleRetryPay("update")}
                      >
                        Update Payment Method
                      </Button>
                      <Button
                        colorScheme="brand"
                        className="flex-1 text-center rounded bg-[#32FFDC] text-black font-medium cursor-pointer"
                        onClick={() => handleRetryPay("retry")}
                      >
                        Retry Payment
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

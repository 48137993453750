/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSearchlistConference: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M105.002667 204.8c-12.757333 0-23.082667 10.368-23.082667 23.125333V754.773333c0 12.8 10.325333 23.082667 23.082667 23.082667h600.746666c12.8 0 23.082667-10.325333 23.082667-23.082667v-150.357333l152.96 110.250667c30.634667 22.058667 68.864-5.546667 68.864-49.621334v-347.306666c0-44.117333-38.229333-71.68-68.821333-49.664l-152.96 110.293333V227.925333c0-12.757333-10.368-23.082667-23.125334-23.082666H105.002667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSearchlistConference.defaultProps = {
  size: 14,
};

export default IconAIconSearchlistConference;

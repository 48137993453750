import { indexCategory } from "@/constants";
import { OmitStringType, SourceName } from "@/types";

// index === data_source
const isSameDataSourceList = [
  "Twitter",
  "Discord",
  "Podcast",
  "Youtube",
  "Mirror",
  "Twitter_Space",
  "Medium",
  "Whitepaper",
];

const dataSource2SourceNameMap = {
  snapshot_proposal: "SnapShot",
  telegram_message: "Telegram",
  tally_proposal: "Tally",
  farcaster: "Warpcast",
};

export const dataSource2Index = (data_source: any) => {
  if (isSameDataSourceList.includes(data_source)) {
    return data_source;
  }

  if (indexCategory.News.indexOf(data_source) > -1) {
    return "News";
  } else if (indexCategory.Research.indexOf(data_source) > -1) {
    return "Research";
  } else if (indexCategory.Governance.indexOf(data_source) > -1) {
    return "Governance";
  } else if (indexCategory.Dashboard.indexOf(data_source) > -1) {
    return "Dashboard";
  } else if (indexCategory.Vote.indexOf(data_source) > -1) {
    // newly
    return "Vote";
  } else if (data_source === "telegram_message") {
    return "Telegram";
  } else if (data_source === "arcaster") {
    return "Warpcast";
  }

  return data_source;
};

export const dataSource2SourceName = (
  data_source: any
): OmitStringType<SourceName, "Newsletter"> => {
  if (isSameDataSourceList.includes(data_source)) {
    return data_source;
  }
  if (indexCategory.News.indexOf(data_source) > -1) {
    return "News";
  } else if (indexCategory.Research.indexOf(data_source) > -1) {
    return "Research";
  } else if (indexCategory.Governance.indexOf(data_source) > -1) {
    return "Forum";
  } else if (indexCategory.Dashboard.indexOf(data_source) > -1) {
    return "DuneDashboard";
  }

  if (dataSource2SourceNameMap[data_source]) return dataSource2SourceNameMap[data_source];

  return data_source;
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconLinkUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M180.138667 843.861333a256 256 0 0 1 0-362.026666l90.496-90.496 60.330666 60.330666-90.453333 90.496a170.666667 170.666667 0 0 0 241.322667 241.365334l90.496-90.496 60.373333 60.330666-90.538667 90.496a256 256 0 0 1-362.026666 0zM391.338667 270.634667l90.496-90.496a256.085333 256.085333 0 0 1 329.386666-27.690667l2.474667-2.474667 30.165333 30.165334a256 256 0 0 1 0 362.026666l-90.496 90.496-60.330666-60.330666 90.496-90.496a170.666667 170.666667 0 0 0-241.365334-241.365334l-90.496 90.496-60.330666-60.330666z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M330.965333 632.661333l301.738667-301.653333 60.330667 60.330667-301.696 301.653333-60.373334-60.330667z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconLinkUncover.defaultProps = {
  size: 14,
};

export default IconIconLinkUncover;

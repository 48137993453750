import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import Button from "./components/button";
import Checkbox from "./components/checkbox";
import Divider from "./components/divider";
import Radio from "./components/radio";
import Tooltip from "./components/tooltip";
const themeConfig: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({
  components: {
    Radio,
    Checkbox,
    Divider,
    Button,
    Tooltip,
  },
  colors: {
    whiteAlpha: {
      /** G1-7 special text/hover fill */
      5: "rgba(255, 255, 255, 0.05)",
      /** G1-6 special text */
      10: "rgba(255, 255, 255, 0.10)",
      /** G1-5 Disabled */
      100: "rgba(255, 255, 255, 0.2)",
      /** G1-4 Tag Text */
      200: "rgba(255, 255, 255, 0.4)",
      /** G1-3 Normal Text */
      300: "rgba(255, 255, 255, 0.6)",
      /** G1-2 Primary Text */
      400: "rgba(255, 255, 255, 0.8)",
    },
    highlight: "#FF5065",
    brand: {
      /** K1-1 Hover */
      100: "#8AFFEB",
      /** K1-2 Normal */
      200: "#32FFDC",
      /** K1-3 Active */
      300: "#28D1B4",
      /** K1-4 Hight light Text */
      400: "#10C2A3",
      /** K1-5 Normal dark */
      500: "#178070",
      /** K1-6 Disable/Button Stroke */
      600: "#144D43",
      /** K1-7 Tag/Button Fill */
      700: "#102622",
    },
    /** K2 */
    K2: {
      /** K2-1 Hover */
      100: "#7C6EFF",
      /** K2-2 Normal */
      200: "#6C50FF",
      /** K2-3 Active */
      300: "#4E38D9",
      /** K2-4 Hight light Text */
      400: "#3F2FC4",
      /** K2-5 Normal dark */
      500: "#2D24AB",
      /** K2-6 Disable/Button Stroke */
      600: "#1C1973",
      /** K2-7 Tag/Button Fill */
      700: "#100E4A",
    },
    /** G1 */
    G1: {
      /** G1-1 Hight Light */
      100: "#fff",
      /** G1-2 Primary Text */
      200: "rgba(255, 255, 255, 0.8)",
      /** G1-3 Normal Text */
      300: "rgba(255, 255, 255, 0.6)",
      /** G1-4 Tag Text */
      400: "rgba(255, 255, 255, 0.4)",
      /** G1-5 Disabled */
      500: "rgba(255, 255, 255, 0.2)",
      /** G1-6 special text */
      600: "rgba(255, 255, 255, 0.1)",
      /** G1-7 special text/hover fill */
      700: "rgba(255, 255, 255, 0.05)",
    },
    /** G2 */
    grayBlue: {
      /** G2-1 Hight Light Gray 1 */
      100: "#5A6576",
      /** G2-2 Hight Light Gray 2 */
      200: "#474F5C",
      /** G2-3 Tip Bg */
      300: "#393F4A",
      /** G2-4 Secondary Button */
      400: "#323740",
      /** G2-5 Card Bg above Card */
      500: "#262A30",
      /** G2-6 Card Bg */
      600: "#1F2226",
      /** G2-7 Menu Bg */
      700: "#191C1F",
      /** G2-8 web bg */
      800: "#0D0F12",
    },
    /**Blue */
    B: {
      /** R-1 Hover */
      100: "#4DAFFF",
      /** B-2 Normal */
      200: "#2496FF",
      /** B-3 Active */
      300: "#1473D9",
      /** B-4 hight light Text */
      400: "#0754B3",
      /** B-5 Normal dark */
      500: "#093C84",
      /** B-6 / */
      600: "#04275F",
      /** B-7 / */
      700: "#051A3D",
    },
    /**red */
    R: {
      /** R-1 Hover */
      100: "#FF7773",
      /** R-2 Normal */
      200: "#FF4B4B",
      /** R-3 Active */
      300: "#D9343A",
      /** R-4 hight light Text */
      400: "#B3222C",
      /** R-5 Normal dark */
      500: "#7D0F1A",
      /** R-6 / */
      600: "#610713",
      /** R-7 / */
      700: "#4A030D",
    },
    htmlBg: "#0D0F12",
    invalid: "#FF4B4B",
  },
  radii: {
    layoutRadius: "4px",
  },
  styles: {
    global: {
      "::-webkit-input-placeholder": {
        color: "whiteAlpha.200",
        backgroundColor: "#191C1F",
        opacity: "1",
      },
      "::-moz-placeholder": {
        color: "rgba(255, 255, 255, 0.4)",
        backgroundColor: "grayBlue.700",
        opacity: "1",
      },
      "::-ms-placeholder": {
        color: "rgba(255, 255, 255, 0.4)",
        backgroundColor: "grayBlue.700",
        opacity: "1",
      },
      "::placeholder": {
        color: "rgba(255, 255, 255, 0.4)",
        backgroundColor: "grayBlue.700",
        opacity: "1",
      },
    },
  },
  config: themeConfig,
});
export default theme;

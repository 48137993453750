/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconShare: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M800 896A96 96 0 0 0 896 800V524.8a12.8 12.8 0 0 0-12.8-12.8h-51.2a12.8 12.8 0 0 0-12.8 12.8v275.2a19.2 19.2 0 0 1-19.2 19.2H224a19.2 19.2 0 0 1-19.2-19.2V224a19.2 19.2 0 0 1 19.2-19.2h253.44c19.2 0 28.842667 0 33.621333-4.778667 4.821333-4.821333 4.821333-14.421333 4.821334-33.621333s0-28.8-4.821334-33.621333C506.282667 128 496.682667 128 477.482667 128H224A96 96 0 0 0 128 224v576A96 96 0 0 0 224 896h576z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M640.64 168.917333c0 37.12 18.304 40.917333 40.874667 40.917334h74.837333l-222.677333 222.634666c-28.885333 28.928-28.885333 28.928 0 57.856 14.506667 14.421333 21.717333 21.674667 28.928 21.674667 7.253333 0 14.506667-7.253333 28.928-21.674667l222.634666-222.677333v74.794667c0 22.613333 2.005333 40.96 40.96 40.96 38.869333 0 40.874667-18.346667 40.874667-40.96V141.653333a13.653333 13.653333 0 0 0-13.653333-13.653333h-200.832c-22.570667 0-40.874667 3.754667-40.874667 40.917333z"
        fill={getIconColor(color, 1, "currentColor")}
      />
    </svg>
  );
};

IconShare.defaultProps = {
  size: 14,
};

export default IconShare;

import dayjs from "dayjs";
import Stripe from "stripe";

import { PlanInterval, PlanLevel } from "@/types/pricing";
import { AlertTypeEnums, PaymentType, PortalUserInfo, ScenarioEnums } from "@/types/userInfo";

export const planLevelArr: Array<PlanLevel> = [
  PlanLevel.Free,
  PlanLevel.Standard,
  PlanLevel.FreeTrial,
  PlanLevel.Pro,
  PlanLevel.BusinessTrial,
  PlanLevel.Business,
];
export const PlanIntervalArr: Array<PlanInterval> = ["month", "year", "2year"];

export const paymentErrTypeArr: Array<ScenarioEnums> = [
  ScenarioEnums.ACTIVELY_CANCEL,
  ScenarioEnums.SUBSCRIPTION_RENEW_FAILED,
];

export const isPayUser = (user: PortalUserInfo) => {
  return user.membershipLevel && user.membershipLevel !== PlanLevel.Free;
};

export const isFreeUser = (user: PortalUserInfo) => {
  return user.membershipLevel && user.membershipLevel === PlanLevel.Free;
};

export const isStandard = (user: PortalUserInfo) => {
  return user.membershipLevel === PlanLevel.Standard;
};
export const isProOrFreeTrial = (user: PortalUserInfo) => {
  return user.membershipLevel === PlanLevel.Pro || user.membershipLevel === PlanLevel.FreeTrial;
};

export const isBusinessOrBusinessTrial = (user: PortalUserInfo) => {
  return (
    user.membershipLevel === PlanLevel.Business || user.membershipLevel === PlanLevel.BusinessTrial
  );
};

/**
 * 比较特殊一点：
 * * 通过 stripe 体验 free trial 用户: (membershipLevel === PlanLevel.Pro || membershipLevel === PlanLevel.Business) && subscription?.status === "trialing"
 *
 */
export const getRealMemberShipLevel = (
  user: PortalUserInfo,
  subscription?: Stripe.Subscription
) => {
  const isFreeUser = !isPayUser(user);

  return isFreeUser
    ? PlanLevel.Free
    : (user.membershipLevel === PlanLevel.Pro || user.membershipLevel === PlanLevel.Business) &&
        subscription?.status === "trialing"
      ? PlanLevel.FreeTrial
      : user.membershipLevel;
};

export const isTrialUser = (user: PortalUserInfo, subscription?: Stripe.Subscription) => {
  const userPlanLevel = getRealMemberShipLevel(user, subscription);

  return [PlanLevel.FreeTrial, PlanLevel.BusinessTrial].includes(userPlanLevel);
};

export const isCanceledPeriodPay = (subscription: Stripe.Subscription) => {
  return subscription?.cancel_at_period_end;
};

/**
 * 目前认为：PlanLevel.FreeTrial === PlanLevel.Pro
 */
export const getLevelIndex = (planLevel: PlanLevel) => {
  let nextPlanLevel = planLevel;
  if (planLevel === PlanLevel.FreeTrial) {
    nextPlanLevel = PlanLevel.Pro;
  }
  if (planLevel === PlanLevel.BusinessTrial) {
    nextPlanLevel = PlanLevel.Business;
  }
  return planLevelArr.findIndex((l) => l === nextPlanLevel);
};

/**
 *  levelIndex compare: p1 - p2
 *
 */
export const comparePlanLevel = (p1: PlanLevel, p2: PlanLevel) => {
  const p1levelIndex = getLevelIndex(p1);
  const p2LevelIndex = getLevelIndex(p2);
  return p1levelIndex - p2LevelIndex;
};

/**
 * user can access some function
 * @returns {boolean} if access return true, else return false
 */
export const canAccess = (user: PortalUserInfo, allowVersion: PlanLevel) => {
  const enable = comparePlanLevel(user.membershipLevel, allowVersion) >= 0;
  return enable;
};

export const isPaymentErr = (user: PortalUserInfo, subscription: Stripe.Subscription) => {
  if (user.alert?.alert && user.alert?.type === AlertTypeEnums.PROFILE_ERROR_MESSAGE) {
    if (isFreeUser(user)) {
      return true;
    }
    if (isPayUser(user) && subscription && subscription.status == "past_due") {
      return true;
    }

    return false;
  }

  return false;
};

export const isFreeWithoutAlert = (user: PortalUserInfo) => {
  if (isFreeUser(user)) {
    if (user.alert?.alert) {
      return user.alert?.type !== AlertTypeEnums.PROFILE_ERROR_MESSAGE;
    }
    return true;
  }
  return false;
};

export const paymentErrType = (user: PortalUserInfo) => {
  if (user.alert?.scenario && paymentErrTypeArr.includes(user.alert?.scenario)) {
    return user.alert.scenario;
  }
  return "";
};

export const getPriceStrFormat = (price) => {
  const numList = String(price).split(".");

  return `${parseInt(numList[0]).toLocaleString()}${numList.length > 1 && numList[1] !== "00" ? "." + numList[1] : ""}`;
};

export const dateFormat = (timestamp: number) => {
  return dayjs(timestamp * 1000).format("MMM D, YYYY");
};

export const isPayTypeCrypto = (user: PortalUserInfo) => {
  return user.paymentType && user.paymentType === PaymentType.ONLINE_LOOP_CRYPTO;
};

export const comparePlanInterval = (current: PlanInterval, next: PlanInterval) => {
  const p1levelIndex = PlanIntervalArr.findIndex((l) => l === current);
  const p2levelIndex = PlanIntervalArr.findIndex((l) => l === next);

  return p2levelIndex - p1levelIndex;
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+$/;
  return emailRegex.test(email);
};

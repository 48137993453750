import { useEffect } from "react";
import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

interface RelevantUserModalProps {
  children: (modalState: { isOpen: boolean; onOpen: () => void }) => React.ReactNode;
  closeHook?: () => void;
  openHook?: () => void;
}

export const RelevantUserModal: React.FC<RelevantUserModalProps> = ({
  children,
  closeHook,
  openHook,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!isOpen && closeHook) {
      closeHook();
    }

    if (isOpen && openHook) {
      openHook();
    }
  }, [isOpen, closeHook, openHook]);

  return (
    <>
      {children({ isOpen, onOpen })}
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent
          p="32px"
          bg="grayBlue.700"
          border="1px solid"
          borderColor="whiteAlpha.10"
          borderRadius="4px"
        >
          <CloseIcon
            position="absolute"
            right="15px"
            top="15px"
            width="10px"
            height="10px"
            color="whiteAlpha.200"
            onClick={onClose}
            cursor="pointer"
          />
          <Text fontSize="14px" color="whiteAlpha.300" mt="8px">
            Relevant users are a set of Twitter users which Kaito deems as relevant to the crypto
            community, discovered and maintained through sophisticated data science and Kaito’s own
            proprietary state-of-the-art machine learning algorithm. They typically include official
            projects, builders, researchers, investors and KOLs.
          </Text>
          <Flex mt="24px">
            <Button
              size="sm"
              bg="brand.200"
              color="black"
              borderRadius="4px"
              fontSize="12px"
              _hover={{
                bg: "brand.100",
              }}
              ml="auto"
              _active={{
                bg: "brand.300",
              }}
              _disabled={{
                bg: "brand.500!important",
                color: "blackAlpha.500",
                cursor: "not-allowed",
              }}
              onClick={onClose}
            >
              OK
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

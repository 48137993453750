/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconBulb: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.914667 65.28c159.658667 0 291.413333 132.096 291.413333 294.485333 0 98.986667-38.912 178.773333-116.608 231.978667v95.146667c0 10.88-6.4 21.205333-14.293333 28.586666a40.832 40.832 0 0 1-30.72 10.154667l-267.136-21.504c-20.053333-1.621333-37.461333-18.474667-37.461334-38.741333v-72.96c-67.968-49.322667-116.48-134.528-116.48-232.661334 0-162.389333 131.584-294.485333 291.285334-294.485333zM395.818667 536.021333c12.586667 6.784 18.986667 19.925333 18.986666 34.304v59.264l194.218667 15.232v-74.496c0-13.781333 5.674667-26.581333 17.493333-33.536 66.901333-39.253333 98.56-97.152 98.56-177.024 0-119.552-95.658667-216.746667-212.778666-216.746666-117.077333 0-213.290667 97.194667-213.290667 216.746666 0 77.568 35.626667 143.488 96.810667 176.256zM340.608 786.773333a38.741333 38.741333 0 0 0 35.712 41.557334l265.216 19.370666a38.698667 38.698667 0 0 0 41.301333-35.925333 38.784 38.784 0 0 0-35.712-41.557333l-265.216-19.370667a38.698667 38.698667 0 0 0-41.301333 35.925333z m84.48 122.666667a38.784 38.784 0 0 0 35.541333 41.685333l96.256 7.466667a38.698667 38.698667 0 0 0 41.472-35.754667 38.784 38.784 0 0 0-35.541333-41.685333l-96.256-7.466667a38.741333 38.741333 0 0 0-41.472 35.754667zM357.12 359.04a33.877333 33.877333 0 0 0 36.906667 30.549333 33.92 33.92 0 0 0 30.336-37.12c-5.376-55.594667 43.136-75.349333 48.682666-77.397333a34.176 34.176 0 0 0 20.224-43.434667 33.621333 33.621333 0 0 0-42.752-20.650666c-36.053333 12.672-101.973333 59.904-93.397333 148.053333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconBulb.defaultProps = {
  size: 14,
};

export default IconBulb;

import { isEmpty, remove } from "lodash";
import { FC } from "react";
import { TwcComponentProps } from "react-twc";
import { twMerge } from "tailwind-merge";

import { twx } from "@/utils/twx";

interface Props {
  options: { label: string; value: string }[];
  value: string;
  onChange: (v: string) => void;
  isDisabled?: boolean;
  defaultValue?: string;
}

export const TagSelect: FC<Props> = ({
  options,
  value,
  onChange,
  isDisabled = false,
  defaultValue = "",
}) => {
  const current = value ?? defaultValue;

  const onSelect = (v: string) => {
    if (isDisabled) return;
    const array = current.split(",");
    if (!array[0]) {
      array.shift();
    }
    if (current.includes(v)) {
      remove(array, (item) => item === v);
    } else {
      array.push(v);
    }
    onChange(isEmpty(array) ? undefined : array.join(","));
  };

  return (
    <div
      className={twMerge([
        "flex items-center gap-3 h-8",
        isDisabled && "[&_div]:text-white/40 [&_div]:cursor-not-allowed",
      ])}
    >
      {options.map((item) => (
        <Tag
          key={item.label}
          onClick={() => onSelect(item.value)}
          active={current.includes(item.value)}
        >
          {item.label}
        </Tag>
      ))}
    </div>
  );
};

const Tag = twx.div<TwcComponentProps<"div"> & { active?: boolean; disabled?: boolean }>(
  (props) => [
    `py-1 w-[108px] text-sm text-white/80 bg-G2-500 rounded h-7 cursor-pointer border border-solid border-G2-500 flex justify-center`,
    props.active &&
      "border bg-brand-200/[0.05] border-solid border-[#144D43] text-brand-200 font-medium",
    props.disabled && "text-white/40 cursor-not-allowed",
  ]
);

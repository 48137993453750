/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconBookmarksUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M385.237333 498.261333a112.896 112.896 0 0 1-112.426666-113.024 112.554667 112.554667 0 0 1 112.725333-111.957333 112.213333 112.213333 0 0 1 112.256 113.450667 112.64 112.64 0 0 1-112.554667 111.530666z m0-56.448a56.32 56.32 0 0 0 56.448-56.106666c0-30.72-25.173333-56.064-55.850666-56.192a56.362667 56.362667 0 0 0-56.746667 55.850666 56.32 56.32 0 0 0 56.106667 56.448z m513.408 79.914667c-120.96-120.96-241.877333-241.962667-362.922666-362.794667-15.36-15.36-30.208-31.018667-48.128-43.904-18.645333-13.44-37.973333-21.205333-60.842667-21.12-40.618667 0.213333-81.237333 0.042667-121.898667 0.042667v0.042667c-42.368 0-84.736-0.341333-127.146666 0.128-47.402667 0.512-83.712 37.632-83.797334 85.077333-0.085333 83.968-0.042667 167.936 0.085334 251.904 0 6.528 0.768 13.226667 2.133333 19.626667 4.992 23.125333 19.114667 41.514667 34.986667 57.472 130.304 131.114667 261.12 261.674667 391.936 392.277333a89.856 89.856 0 0 0 126.122666-0.256c83.626667-83.498667 167.253333-167.04 250.581334-250.837333 6.613333-6.613333 12.373333-14.634667 16.554666-22.997334 17.664-34.944 10.325333-76.672-17.664-104.661333z m-50.218666 81.109333C787.2 663.466667 725.333333 725.333333 665.557333 785.194667l-63.573333 63.616a23.125333 23.125333 0 0 1-16.085333 7.68 22.741333 22.741333 0 0 1-15.829334-7.552l-69.546666-69.546667a278432.298667 278432.298667 0 0 1-317.866667-318.08c-6.741333-6.784-12.501333-15.786667-17.578667-23.722667a14.165333 14.165333 0 0 1-1.408-6.570666c-0.170667-73.728-0.213333-153.770667-0.085333-251.818667 0-9.728 5.973333-15.36 16.384-15.36a13005.781333 13005.781333 0 0 1 76.970667-0.042667h114.389333c21.76 0 39.253333 0 55.296-0.085333h0.128c6.144 0 11.093333 1.962667 18.389333 7.253333 21.76 15.616 39.936 34.645333 59.221334 54.826667 7.168 7.509333 14.549333 15.274667 22.101333 22.741333l160.384 160c52.906667 52.906667 107.605333 107.605333 161.621333 161.194667a23.296 23.296 0 0 1 6.528 16.554667c0 6.4-2.389333 12.416-6.570666 16.554666z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconBookmarksUncover.defaultProps = {
  size: 14,
};

export default IconIconBookmarksUncover;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconExitFullscreenUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M128 209.066667a4.266667 4.266667 0 0 0-4.266667 4.266666v597.333334a4.266667 4.266667 0 0 0 4.266667 4.266666h768a4.266667 4.266667 0 0 0 4.266667-4.266666V213.333333a4.266667 4.266667 0 0 0-4.266667-4.266666H128zM46.933333 213.333333c0-44.8 36.266667-81.066667 81.066667-81.066666h768c44.8 0 81.066667 36.266667 81.066667 81.066666v597.333334a81.066667 81.066667 0 0 1-81.066667 81.066666H128A81.066667 81.066667 0 0 1 46.933333 810.666667V213.333333z m705.536 186.197334a38.4 38.4 0 0 1 0 54.272L694.314667 512l58.154666 58.197333a38.4 38.4 0 1 1-54.272 54.272l-85.333333-85.333333a38.4 38.4 0 0 1 0-54.272l85.333333-85.333333a38.4 38.4 0 0 1 54.272 0z m-426.666666 0a38.4 38.4 0 1 0-54.272 54.272L329.685333 512l-58.154666 58.197333a38.4 38.4 0 0 0 54.272 54.272l85.333333-85.333333a38.4 38.4 0 0 0 0-54.272l-85.333333-85.333333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconExitFullscreenUncover.defaultProps = {
  size: 14,
};

export default IconAIconExitFullscreenUncover;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconExit: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M760.874667 832H263.125333c-39.253333 0-71.125333-31.829333-71.125333-71.125333V618.666667h71.125333v142.208h497.749334V263.125333H263.125333V405.333333H192V263.125333c0-39.253333 31.829333-71.125333 71.125333-71.125333h497.749334c39.253333 0 71.125333 31.829333 71.125333 71.125333v497.749334c0 39.253333-31.829333 71.125333-71.125333 71.125333z m-284.416-177.792v-106.666667H192v-71.082666h284.458667v-106.666667L654.208 512l-177.749333 142.208z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconExit.defaultProps = {
  size: 14,
};

export default IconIconExit;

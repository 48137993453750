import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { signOut } from "next-auth/react";
import React from "react";

import { userInfoAtom } from "@/store/userInfo";

import { IconAttention } from "./Icon";
import IconFont from "./Icon/iconfont";

const SimultaneousLoginTip: React.FC<{}> = () => {
  const userInfo = useAtomValue(userInfoAtom);
  const handleCallback = async () => {
    await signOut({ callbackUrl: "/" });
  };

  return (
    <Modal
      isCentered
      onClose={() => {}}
      isOpen={userInfo.isSimultaneousLogin}
      closeOnOverlayClick={false}
    >
      <ModalOverlay backgroundColor={"rgba(0, 0, 0, 0.7)"} />
      <ModalContent bg="transparent" position="relative" style={{ maxWidth: "600px" }}>
        <ModalBody
          w={600}
          id="preview-container"
          pt="4"
          pb="6"
          px="6"
          overflow={"hidden"}
          bg="#191C1F"
          rounded={4}
          className="border-[1px] border-[#262A30]"
        >
          <div className="flex justify-between items-center mb-4">
            <div className="font-medium text-white flex items-center">
              <IconAttention className="mr-1" />
              Security Alert
            </div>
            <IconFont
              name="IconClose"
              className="inline-block mr-[-12px] cursor-pointer text-[#fff]/40"
              size={24}
              onClick={handleCallback}
            />
          </div>
          <div className="text-sm text-[#fff]/60 mb-4">
            {
              "You've been logged out due to login attempts from other devices. To protect your account and ensure you can still access our services, please change your password and do not share your account information with others."
            }
          </div>
          <div className="flex justify-end  text-black cursor-pointer">
            <div
              className="w-[48px] text-center py-2 px-4 rounded bg-[#32FFDC] text-sm font-medium box-content "
              onClick={handleCallback}
            >
              OK
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SimultaneousLoginTip;

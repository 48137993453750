/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconDoubleArrow: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M349.866667 224.341333L170.325333 403.882667l50.858667 50.858666L349.866667 325.973333v473.642667h71.936V224.341333H349.866667zM673.792 224.341333h-71.936v575.317334h71.936L853.333333 620.117333l-50.858666-50.858666-128.682667 128.725333V224.341333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconDoubleArrow.defaultProps = {
  size: 14,
};

export default IconIconDoubleArrow;

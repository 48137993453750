/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconGovernmentUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1084 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M542.208 90.666667L0 416H190.208v448h-106.666667v64h917.333334v-64h-106.666667V416h190.208L542.208 90.666667z m288 325.333333v448h-149.333333V416h149.333333z m-213.333333 0v448h-149.333334V416h149.333334z m236.458666-64H231.082667L542.208 165.333333l311.125333 186.666667zM403.541333 416v448h-149.333333V416h149.333333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconGovernmentUncover.defaultProps = {
  size: 14,
};

export default IconIconGovernmentUncover;

import { Spinner as Spin } from "@chakra-ui/react";
export const Spinner = (props) => {
  return (
    <Spin
      size={"sm"}
      speed="0.8s"
      style={{
        borderLeftColor: "unset",
      }}
      {...props}
    />
  );
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconRocketCover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M376.021333 715.989333l-48.896-48.896a49.493333 49.493333 0 0 1-12.202666-50.986666c4.736-14.08 11.093333-32.426667 18.688-53.504H166.101333a38.186667 38.186667 0 0 1-33.109333-19.2 37.802667 37.802667 0 0 1 0.341333-38.144L216.448 365.226667a113.92 113.92 0 0 1 98.005333-55.893334h130.304c3.797333-6.357333 7.594667-12.202667 11.392-17.92 129.664-191.232 322.816-197.546667 438.058667-176.341333 18.346667 3.328 32.64 17.706667 36.096 36.096 21.248 115.413333 14.72 308.394667-176.341333 438.058667-5.546667 3.84-11.562667 7.594667-17.92 11.392v130.304c0 40.234667-21.205333 77.568-55.893334 98.005333l-140.117333 83.114667a37.973333 37.973333 0 0 1-57.301333-32.768V709.546667c-22.314667 7.765333-41.813333 14.08-56.490667 18.858666a49.92 49.92 0 0 1-50.346667-12.373333h0.128z m360.021334-343.381333a63.317333 63.317333 0 1 0 0.042666-126.634667 63.317333 63.317333 0 0 0 0 126.634667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconRocketCover.defaultProps = {
  size: 14,
};

export default IconIconRocketCover;

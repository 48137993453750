/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconMousedownUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M360.874667 618.88c44.544 44.544 100.992 68.309333 163.328 68.309333 62.378667 0 118.784-23.765333 163.328-65.322666 44.544-44.544 68.266667-100.949333 68.266666-163.328V280.362667c0-62.336-23.722667-118.784-68.266666-163.328s-100.992-68.266667-163.328-68.266667c-62.336 0-118.784 23.722667-163.328 65.28-44.544 44.586667-68.266667 100.992-68.266667 163.370667v178.133333c0 62.378667 23.722667 118.784 68.266667 163.328zM393.557333 146.773333c35.626667-35.626667 80.170667-53.418667 130.645334-53.418666 50.474667 0 95.018667 17.792 130.645333 53.418666 32.682667 35.626667 53.461333 80.213333 53.461333 130.688v178.133334a184.277333 184.277333 0 0 1-53.461333 130.688c-35.626667 32.64-80.170667 53.418667-130.645333 53.418666a184.234667 184.234667 0 0 1-130.645334-53.418666c-32.682667-35.626667-53.461333-80.213333-53.461333-130.688V277.418667c0-47.530667 17.834667-95.061333 53.461333-130.688z m141.610667 726.912l-126.762667-126.762666 27.648-27.648 99.072 99.114666 99.157334-99.114666 27.605333 27.648-126.72 126.72z m27.605333-656.256a38.613333 38.613333 0 1 1-77.226666 0 38.613333 38.613333 0 0 1 77.226666 0z m-27.605333 772.053334l-126.762667-126.72 27.648-27.733334 99.072 99.157334 99.157334-99.114667 27.605333 27.648-126.72 126.762667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconMousedownUncover.defaultProps = {
  size: 14,
};

export default IconAIconMousedownUncover;

const checkbox = {
  baseStyle: {
    control: {
      border: "1px solid",
      backgroundColor: "#2E3239",
      borderColor: "#4B5363",
      _checked: {
        backgroundColor: "brand.200",
        borderColor: "brand.200",
        _hover: {
          backgroundColor: "brand.200",
          borderColor: "brand.200",
        },
      },
      _indeterminate: {
        backgroundColor: "brand.200",
        borderColor: "brand.200",
        _hover: {
          backgroundColor: "brand.200",
          borderColor: "brand.200",
        },
      },
      _invalid: {
        borderColor: "grayBlue.200",
      },
    },
  },
};
export default checkbox;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconWatchlist: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M192 149.333333A64 64 0 0 1 256 85.333333h512a64 64 0 0 1 64 64V938.666667L512 676.864 192 938.666667V149.333333z m71.125333 13.568v620.629334l208.341334-170.496a64 64 0 0 1 81.066666 0l208.341334 170.496V162.901333H263.125333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconWatchlist.defaultProps = {
  size: 14,
};

export default IconIconWatchlist;

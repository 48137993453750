/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconAddAlertCover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M448.725333 192v-42.666667h126.549334v42.666667c0 2.688 0.469333 5.333333 0 7.893333A256.085333 256.085333 0 0 1 768 448v25.173333a234.666667 234.666667 0 0 0-274.346667 268.117334H175.957333v-37.290667-37.333333H256V448a256.128 256.128 0 0 1 192.725333-248.106667c-0.469333-2.56 0-5.205333 0-7.893333zM558.805333 869.333333a235.050667 235.050667 0 0 1-49.962666-74.666666H410.624v74.666666h148.138667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M917.333333 704a192 192 0 1 1-384 0 192 192 0 0 1 384 0z m-155.733333-128h-72.533333v91.733333H597.333333v72.533334h91.733334v91.733333h72.533333v-91.733333H853.333333v-72.533334h-91.733333V576z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconAIconAddAlertCover.defaultProps = {
  size: 14,
};

export default IconAIconAddAlertCover;

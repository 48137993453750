/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconRobot1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M688.469333 537.301333a33.706667 33.706667 0 0 0-33.493333-33.834666 33.706667 33.706667 0 0 0-33.536 33.834666v72.192a33.706667 33.706667 0 0 0 33.536 33.834667 33.706667 33.706667 0 0 0 33.493333-33.834667v-72.192zM369.066667 503.466667a33.706667 33.706667 0 0 1 33.493333 33.834666v72.192a33.706667 33.706667 0 0 1-33.536 33.834667 33.706667 33.706667 0 0 1-33.493333-33.834667v-72.192a33.706667 33.706667 0 0 1 33.493333-33.834666zM407.04 717.781333a33.706667 33.706667 0 0 1 33.493333-33.877333h142.933334a33.706667 33.706667 0 0 1 33.536 33.877333 33.706667 33.706667 0 0 1-33.536 33.834667h-142.933334a33.706667 33.706667 0 0 1-33.536-33.834667z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M545.536 263.978667c22.570667-12.16 37.973333-36.138667 37.973333-63.786667C583.466667 160.298667 551.466667 128 512 128c-39.466667 0-71.466667 32.298667-71.466667 72.192 0 27.648 15.36 51.626667 37.973334 63.786667v95.104H340.394667c-77.738667 0-140.714667 63.616-140.714667 142.122666v2.261334h-11.605333v-38.357334a33.706667 33.706667 0 0 0-33.536-33.834666 33.706667 33.706667 0 0 0-33.493334 33.834666v64.682667A106.24 106.24 0 0 0 85.333333 609.493333v52.181334c0 43.349333 26.154667 82.346667 66.005334 98.474666l50.858666 20.522667C214.613333 846.378667 271.786667 896 340.48 896h343.125333c68.650667 0 125.824-49.621333 138.24-115.328l50.858667-20.522667A106.026667 106.026667 0 0 0 938.666667 661.674667v-52.181334a106.24 106.24 0 0 0-35.754667-79.701333V465.066667a33.706667 33.706667 0 0 0-33.493333-33.834667 33.706667 33.706667 0 0 0-33.536 33.834667v38.4l-2.218667-0.042667h-9.386667v-2.261333c0-78.506667-62.976-142.122667-140.714666-142.122667h-138.069334v-95.146667zM152.32 609.493333c0-21.162667 17.024-38.357333 37.973333-38.357333h9.386667v135.637333l-23.466667-9.514666a38.357333 38.357333 0 0 1-23.893333-35.584v-52.181334z m719.274667 0v52.181334a38.357333 38.357333 0 0 1-23.893334 35.584l-23.466666 9.514666v-135.637333h9.386666c20.949333 0 37.973333 17.194667 37.973334 38.357333z m-531.2-182.741333h343.125333c40.704 0 73.685333 33.322667 73.685333 74.453333v252.672c0 41.088-32.981333 74.410667-73.685333 74.410667H340.437333a74.069333 74.069333 0 0 1-73.685333-74.410667v-252.672c0-41.088 32.981333-74.453333 73.685333-74.453333z"
        fill={getIconColor(color, 1, "currentColor")}
      />
    </svg>
  );
};

IconRobot1.defaultProps = {
  size: 14,
};

export default IconRobot1;

import { OpenSearchSnapshotObject } from "@/types";
import dayjs from "dayjs";

type SnapshotStateValue = OpenSearchSnapshotObject["snapshot_proposal_state"];

export const getRealStatus = (status: SnapshotStateValue, endDateTime: string) => {
  const isEnd = dayjs(endDateTime).diff(Date.now(), "hour") <= 0;
  if (isEnd && status === "active") return "closed";
  return status;
};

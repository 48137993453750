/* eslint-disable jsx-a11y/alt-text */
import { isString } from "lodash";
import Image from "next/image";
import React, { useMemo } from "react";

import { convertToCdnUrl } from "../utils";

type ImageProps = Parameters<typeof Image>[0];

export type KImageProps = ImageProps & {};

/**
 * Extends the Next.js Image component with additional props and additional ability.
 *
 * !IMPORTANT: This component will rewrite the kaito-public-assets domain to cdn domain.
 * * add domain https://nextjs.org/docs/app/api-reference/components/image#remotepatterns
 *
 * Features:
 * * auto rewrite kaito-public-assets domain to cdn domain
 */
export const KImage = (props: KImageProps) => {
  const { src, ...rest } = props;

  const newSrc = useMemo(() => {
    if (isString(src)) {
      const cdnUrl = convertToCdnUrl(src);
      return cdnUrl;
    }
    return src;
  }, [src]);

  return <Image src={newSrc} {...rest} />;
};

import Image from "next/image";
import { FC } from "react";

import { withPrefix } from "@/utils/awsPath";
import { twx } from "@/utils/twx";

import { IconAKaitologo } from "../Icon/iconfont";
import { Button } from "../ui/button";

const S = {
  PopoverContent: twx.div`w-[374px] rounded border-none`,
  Wrapper: twx.div`rounded flex flex-col items-start gap-3 bg-G2-500 twinkle-border`,
  TagWrapper: twx.div`h-6 flex py-1 px-2.5 justify-center items-center gap-1.5 rounded-2xl bg-gradient-to-r from-[#32ffdc] to-[#d3ec94]`,
  TagText: twx.div`text-center font-semibold text-xs font-sans text-brand-700 not-italic`,
  Title: twx.div`text-xl font-semibold leading-normal relative`,
  Desc: twx.div`text-sm not-italic leading-normal text-white/60 font-normal [&_p]:mb-2 [&_p:last-child]:mb-0`,
  BtnGroup: twx.div`flex flex-row items-center justify-end w-full gap-4`,
  Tag: twx.span`absolute bottom-[5px] ml-2 text-xs rounded-[2px] bg-brand-500 py-[2px] px-1  inline-flex items-center justify-center `,
};

export const JoyrideTitle: FC = () => {
  return (
    <>
      <S.Wrapper>
        <S.TagWrapper>
          <IconAKaitologo className="w-3 h-3 text-brand-700" />
          <S.TagText>New Feature</S.TagText>
        </S.TagWrapper>
        <Image
          src={withPrefix("/catalyst/catalyst_feature.jpg")}
          width={334}
          height={174}
          className="rounded"
          alt="catalyst feature"
        />
        <S.Title>
          AI-Powered Catalyst Calendar - Your Second Pair of Eyes
          <S.Tag>Beta</S.Tag>
        </S.Title>
      </S.Wrapper>
    </>
  );
};

export const JoyrideContent: FC<{
  handleClose: (type: boolean) => void;
}> = ({ handleClose }) => {
  return (
    <>
      <S.Desc>
        <p>
          We are experimenting with a large-scale content understanding model that analyzes
          announcements and discussions on CT, governance forums, and more, transforming them into a
          structured database of upcoming events and catalysts.
        </p>
        <p>
          We invite you to join us on our journey of refining our AI by submitting missing catalysts
          and any feedback!
        </p>
      </S.Desc>

      <S.BtnGroup>
        <Button
          variant="secondary"
          className="bg-G2-200"
          onClick={() => {
            handleClose(false);
          }}
        >
          Got It
        </Button>
        <Button
          variant="default"
          onClick={() => {
            handleClose(true);
          }}
        >
          Try Now
        </Button>
      </S.BtnGroup>
    </>
  );
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconRobot: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M670.848 534.485333a30.122667 30.122667 0 0 0-60.330667 0v64.170667a30.122667 30.122667 0 0 0 60.330667 0v-64.170667zM383.317333 504.405333c16.64 0 30.165333 13.482667 30.165334 30.08v64.170667a30.122667 30.122667 0 0 1-60.330667 0v-64.170667c0-16.64 13.525333-30.08 30.165333-30.08zM417.493333 694.912c0-16.64 13.525333-30.08 30.165334-30.08h128.682666a30.122667 30.122667 0 1 1 0 60.16h-128.682666a30.122667 30.122667 0 0 1-30.165334-30.08z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M542.165333 291.541333A64.128 64.128 0 0 0 512 170.666667a64.128 64.128 0 1 0-30.165333 120.874666v84.522667H357.546667a126.506667 126.506667 0 0 0-126.677334 126.336v2.005333h-10.410666v-34.090666a30.122667 30.122667 0 0 0-60.330667 0v57.514666A93.952 93.952 0 0 0 128 598.613333v46.378667a94.293333 94.293333 0 0 0 59.392 87.509333l45.781333 18.261334A126.634667 126.634667 0 0 0 357.589333 853.333333h308.821334a126.634667 126.634667 0 0 0 124.416-102.485333l45.781333-18.261333A94.293333 94.293333 0 0 0 896 644.992v-46.378667c0-28.245333-12.458667-53.589333-32.170667-70.826666v-57.514667a30.122667 30.122667 0 0 0-60.330666 0v34.133333l-2.005334-0.042666h-8.448v-2.005334a126.506667 126.506667 0 0 0-126.634666-126.336h-124.245334V291.541333zM188.330667 598.613333a34.133333 34.133333 0 0 1 34.133333-34.090666h8.448v120.533333l-21.12-8.405333a34.090667 34.090667 0 0 1-21.461333-31.658667v-46.378667z m647.338666 0v46.378667c0 13.952-8.533333 26.453333-21.461333 31.658667l-21.162667 8.448v-120.576h8.448a34.133333 34.133333 0 0 1 34.133334 34.090666zM357.546667 436.224h308.821333c36.608 0 66.304 29.653333 66.304 66.176v224.597333c0 36.522667-29.696 66.133333-66.304 66.133334H357.546667a66.261333 66.261333 0 0 1-66.304-66.133334v-224.597333c0-36.522667 29.696-66.133333 66.304-66.133333z"
        fill={getIconColor(color, 1, "currentColor")}
      />
    </svg>
  );
};

IconRobot.defaultProps = {
  size: 14,
};

export default IconRobot;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconTokenmindshareUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M483.541333 156.458667c0-15.744 12.757333-28.458667 28.458667-28.458667a384 384 0 0 1 384 384 28.458667 28.458667 0 0 1-28.458667 28.458667H512a28.458667 28.458667 0 0 1-28.458667-28.458667V156.458667z m56.917334 29.653333v297.429333h297.429333a327.210667 327.210667 0 0 0-297.429333-297.429333zM240.469333 240.469333a384.512 384.512 0 0 1 124.544-83.328l21.76 52.565334A327.637333 327.637333 0 0 0 280.746667 280.704m0 0a327.125333 327.125333 0 1 0 533.589333 356.522667l52.565333 21.76a384.469333 384.469333 0 0 1-83.328 124.544m0 0A384 384 0 0 1 240.469333 240.469333"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconTokenmindshareUncover.defaultProps = {
  size: 14,
};

export default IconAIconTokenmindshareUncover;

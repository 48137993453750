/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconSuspendCover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M170.666667 284.458667A113.92 113.92 0 0 1 284.458667 170.666667h455.082666A113.92 113.92 0 0 1 853.333333 284.458667v455.082666A113.92 113.92 0 0 1 739.541333 853.333333H284.458667A113.92 113.92 0 0 1 170.666667 739.541333V284.458667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconSuspendCover.defaultProps = {
  size: 14,
};

export default IconIconSuspendCover;

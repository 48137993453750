/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSearchlistWhitePaper: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M663.125333 515.413333h23.552c14.08 0 24.448 2.986667 31.061334 8.874667 6.570667 5.674667 9.898667 14.549333 9.898666 26.624a35.797333 35.797333 0 0 1-5.802666 21.162667 32.896 32.896 0 0 1-16.384 11.946666 75.818667 75.818667 0 0 1-25.258667 3.754667h-17.066667V515.413333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M586.666667 122.88a102.4 102.4 0 0 1 69.461333 27.178667l155.733333 143.786666a102.4 102.4 0 0 1 32.896 75.221334v429.653333a102.4 102.4 0 0 1-102.4 102.4H281.6a102.4 102.4 0 0 1-102.4-102.4V225.28a102.4 102.4 0 0 1 102.4-102.4h305.066667zM518.826667 716.8l62.08-243.712h-50.858667l-31.061333 133.12c-1.109333 5.248-2.602667 11.818667-4.437334 19.797333a882.346667 882.346667 0 0 0-4.437333 23.893334 375.04 375.04 0 0 0-3.072 20.138666 553.002667 553.002667 0 0 0-8.533333-48.128 981.973333 981.973333 0 0 0-2.730667-12.288l-35.498667-136.533333H391.466667l-35.498667 136.533333c-0.896 2.986667-1.92 7.04-3.072 12.288l-3.072 16.384a445.568 445.568 0 0 0-5.12 31.744c-0.426667-4.096-1.28-8.96-2.389333-14.677333a797.952 797.952 0 0 0-9.557334-49.152l-31.061333-133.12H250.88L312.96 716.8h58.709333l32.768-127.658667a596.736 596.736 0 0 0 9.216-44.714666 107.093333 107.093333 0 0 0 2.048-12.629334c0.426667 2.986667 1.152 7.168 2.048 12.629334l3.072 16.725333c1.365333 5.930667 2.517333 11.392 3.413334 16.384 1.152 4.992 2.048 8.746667 2.730666 11.264l33.109334 128h58.709333z m239.189333-223.232c-14.336-13.653333-37.205333-20.48-68.608-20.48h-77.824V716.8h51.541333v-86.698667h22.186667c17.749333 0 32.64-2.133333 44.714667-6.485333 12.032-4.309333 21.717333-10.24 29.013333-17.749333 7.509333-7.722667 12.8-16.384 16.042667-25.941334 3.157333-9.813333 4.778667-20.053333 4.778666-30.72 0-23.68-7.296-42.24-21.845333-55.637333z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSearchlistWhitePaper.defaultProps = {
  size: 14,
};

export default IconAIconSearchlistWhitePaper;

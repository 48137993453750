import { Icon, IconProps } from "./Icon";

export const LeftOutline = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 6 9" {...props}>
      <path
        d="M4.54023 0.040039L5.45947 0.959278L1.91909 4.49966L5.45947 8.04004L4.54023 8.95928L0.0806144 4.49966L4.54023 0.040039Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </Icon>
  );
};

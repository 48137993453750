import { useCallbackRef, useToast as useChakraToast, UseToastOptions } from "@chakra-ui/react";

import { ErrorIcon, IconAttention, SuccessIcon } from "@/components/Icon";
import { ToastContent } from "@/components/ToastContent";
export function useToast() {
  const toast = useChakraToast();
  const success = useCallbackRef(
    (content: React.ReactNode, duration: number = 2000, options?: UseToastOptions) => {
      return toast({
        duration: duration,
        position: "top",
        containerStyle: {
          minW: 0,
        },
        render: (props) => (
          <ToastContent>
            <SuccessIcon display="inline" mr="3" mt="0" />
            <div onClick={props.onClose}>{content}</div>
          </ToastContent>
        ),
        ...options,
      });
    }
  );
  const error = useCallbackRef(
    (content: React.ReactNode, duration: number = 2000, options?: UseToastOptions) => {
      return toast({
        duration: duration,
        position: "top",
        containerStyle: {
          minW: 0,
        },
        render: () => (
          <ToastContent>
            <ErrorIcon display="inline" mr="3" mt="0" />
            {content}
          </ToastContent>
        ),
        ...options,
      });
    }
  );

  const info = useCallbackRef(
    (content: React.ReactNode, duration: number = 2000, options?: UseToastOptions) => {
      return toast({
        duration: duration,
        position: "top",
        containerStyle: {
          minW: 0,
        },
        render: () => (
          <ToastContent>
            <IconAttention className="mr-3 mt-0" />
            {content}
          </ToastContent>
        ),
        ...options,
      });
    }
  );

  return {
    success,
    error,
    info,
    toast,
  };
}

import "@metasearch-io/rc-picker/assets/index.css";
import "simplebar/dist/simplebar.min.css";
import "../../scripts/wdyr";
import "../styles/globals.css";
import "jotai-devtools/styles.css";

import axios from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { DevTools } from "jotai-devtools";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";

import Layout from "@/components/Layout";
import { Providers } from "@/providers";

import nextI18nConfig from "../../next-i18next.config";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

global.localStorage && global.localStorage.setItem("chakra-ui-color-mode", "dark");

// from https://nextjs.org/docs/basic-features/layouts
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps<{ session: Session }> & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
  const router = useRouter();

  axios.interceptors.response.use(
    function (response) {
      // If the response is successful, just return it
      return response;
    },
    function (error) {
      // If the response has a status of 401, redirect to the login page
      if (error.response && error.response.status === 401) {
        router.push("/auth/entry");
      }
      // Otherwise, throw the error so it can be caught by a .catch block
      return Promise.reject(error);
    }
  );

  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);
  return (
    <SessionProvider session={session} refetchOnWindowFocus={true} refetchInterval={60 * 50}>
      <DevTools />
      <Providers>{getLayout(<Component {...pageProps} />)}</Providers>
    </SessionProvider>
  );
}

export default appWithTranslation(App, nextI18nConfig);

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconNarrativeMindshareUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M938.666667 128h32a32 32 0 0 0-32-32V128zM85.333333 128v-32a32 32 0 0 0-32 32H85.333333z m0 640h-32c0 17.664 14.336 32 32 32V768z m320 0l22.613334-22.613333a32 32 0 0 0-22.613334-9.386667V768z m106.666667 106.666667l-22.613333 22.613333a32 32 0 0 0 45.226666 0L512 874.666667z m106.666667-106.666667v-32a32 32 0 0 0-22.613334 9.386667l22.613334 22.613333z m320 0v32a32 32 0 0 0 32-32H938.666667zM479.957333 279.210667a32 32 0 0 0-63.914666-3.754667l63.914666 3.754667zM394.666667 638.122667a32 32 0 1 0 63.914666 3.754666l-63.914666-3.754666z m234.581333-358.912a32 32 0 0 0-63.914667-3.754667l63.914667 3.754667z m-85.248 358.912a32 32 0 1 0 63.914667 3.754666l-63.914667-3.754666zM704 416a32 32 0 0 0 0-64v64zM341.333333 352a32 32 0 0 0 0 64v-64z m341.333334 213.333333a32 32 0 0 0 0-64v64z m-362.666667-64a32 32 0 0 0 0 64v-64zM938.666667 96H85.333333v64h853.333334v-64zM53.333333 128v640h64V128h-64zM85.333333 800h320v-64H85.333333v64z m297.386667-9.386667l106.666667 106.666667 45.226666-45.226667-106.666666-106.666666-45.226667 45.226666z m151.893333 106.666667l106.666667-106.666667-45.226667-45.226666-106.666666 106.666666 45.226666 45.226667z m84.053334-97.28H938.666667v-64h-320v64z m352-32V128h-64v640h64zM416.042667 275.456l-21.333334 362.666667 63.914667 3.754666 21.333333-362.666666-63.914666-3.754667z m149.333333 0l-21.333333 362.666667 63.914666 3.754666 21.333334-362.666666-63.914667-3.754667zM704 352H341.333333v64h362.666667v-64z m-21.333333 149.333333h-181.333334v64H682.666667v-64z m-181.333334 0H320v64h181.333333v-64z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconNarrativeMindshareUncover.defaultProps = {
  size: 14,
};

export default IconAIconNarrativeMindshareUncover;

import axios from "axios";
import { atom } from "jotai";
import { get as lodashGet } from "lodash";

import { SavedSearch } from "@/components/Alert/types";
import { userInfoAtom } from "@/store/userInfo";
import { isBusinessOrBusinessTrial, isProOrFreeTrial, isStandard } from "@/utils/user";

export interface AddAlertSettings {
  isOpen: boolean;
  savedSearch?: SavedSearch;
  mode?: "alert" | "search";
  submitMethod?: "create" | "update";
  modalTitle?: "Edit Alert" | "Add Alert" | "Save Search";
  confirmButtonText?: string;
}
export const tickersOfficialDataAtom = atom<{ [key: string]: any }>({});
export const isUpgradeOpenAlertAtom = atom(false);
export const isUpgradeOpenWatchlistAtom = atom(false);
export const isSavedSearchListOpenAtom = atom(false);
export const isEditSearchQueryOpenAtom = atom(false);
export const isShowTelegramGuideAtom = atom(false);
export const isShowExceedLimitDialogAtom = atom(false);
export const isLimitDialogOpenAtom = atom(false);
export const limitDialogTypeAtom = atom<"searches" | "alert">("searches");
export const alertNextTokenAtom = atom("");
export const isLoadingLoadSearchListAtom = atom(false);
export const savedSearchAlertListAtom = atom<SavedSearch[]>([]);
export const isShowWatchListManageModalAtom = atom(false);
export const addAlertSettingsAtom = atom<AddAlertSettings>({
  isOpen: false,
  savedSearch: {
    searchName: "",
    searchUrl: "",
  },
});

export const alertNumLimitAtom = atom((get) => {
  const userInfo = get(userInfoAtom);

  if (isStandard(userInfo)) {
    return 0;
  } else if (isProOrFreeTrial(userInfo)) {
    return 5;
  } else if (isBusinessOrBusinessTrial(userInfo)) {
    return 10;
  }

  return 0;
});

export const watchNumLimitAtom = atom((get) => {
  const userInfo = get(userInfoAtom);
  if (isStandard(userInfo)) {
    return 1;
  } else if (isProOrFreeTrial(userInfo)) {
    return 10;
  } else if (isBusinessOrBusinessTrial(userInfo)) {
    return 20;
  }
  return 0;
});

export const searchNumLimitAtom = atom((get) => {
  const userInfo = get(userInfoAtom);
  if (isBusinessOrBusinessTrial(userInfo)) {
    return 20;
  }
  return 20;
});

export const savedAlertNumAtom = atom(
  (get) => get(savedSearchAlertListAtom).filter((v) => v.alertEnabled).length
);
export const saveWatchNumAtom = atom(
  (get) => get(savedSearchAlertListAtom).filter((v) => v.watchlistEnabled).length
);
export const savedSearchNumAtom = atom((get) => get(savedSearchAlertListAtom).length);

export const isExceedWatchLimitAtom = atom((get) => {
  const savedSearchList = get(savedSearchAlertListAtom);
  const watchNumLimit = get(watchNumLimitAtom);
  const saveWatchNum = savedSearchList.filter((v) => v.watchlistEnabled).length;
  return saveWatchNum >= watchNumLimit;
});

export const isExceedSearchLimitAtom = atom((get) => {
  const savedSearchList = get(savedSearchAlertListAtom);
  const searchNumLimit = get(searchNumLimitAtom);
  const savedSearchNum = savedSearchList.length;
  return savedSearchNum >= searchNumLimit;
});

export const isExceedAlertLimitAtom = atom((get) => {
  const savedAlertNum = get(savedAlertNumAtom);
  const alertNumLimit = get(alertNumLimitAtom);
  return savedAlertNum >= alertNumLimit;
});

export const editSearchQueryParamsAtom = atom<Partial<any>>({
  searchName: "",
  searchId: "",
  schedule: undefined as SavedSearch["schedule"] | undefined,
  watchlistEnable: false,
});

export const setLimitDialogAtom = atom(
  null,
  (get, set, { isOpen, dialogType }: { isOpen: boolean; dialogType?: "searches" | "alert" }) => {
    set(isLimitDialogOpenAtom, isOpen);
    set(limitDialogTypeAtom, dialogType ?? null); // 如果没有提供 dialogType，设置为 null
  }
);

export const updateSavedSearchAlertAtom = atom(
  null,
  async (get, set, value: Partial<SavedSearch>) => {
    const savedSearchList = get(savedSearchAlertListAtom);

    if (!value.searchId) return;

    const prevSavedSearchList = [...savedSearchList];
    const updatedSavedSearchList = savedSearchList.map((savedSearch) => {
      if (savedSearch.searchId === value.searchId) {
        return { ...savedSearch, ...value };
      }
      return savedSearch;
    });

    set(savedSearchAlertListAtom, updatedSavedSearchList);

    try {
      const response = await axios.post("/api/gateway/update_search", value);
      const updatedSearch = lodashGet(response, "data.data");

      const finalSavedSearchList = savedSearchList.map((savedSearch) => {
        if (savedSearch.searchId === value.searchId) {
          return { ...savedSearch, ...updatedSearch };
        }
        return savedSearch;
      });

      set(savedSearchAlertListAtom, finalSavedSearchList);
    } catch (error) {
      set(savedSearchAlertListAtom, prevSavedSearchList);
    }
  }
);

import { MembershipStatus, MembershipType } from "./account";
import { PlanLevel } from "./pricing";

/**
 * from /portal/get_user_info
 */
export interface PortalUserInfo {
  email: string;
  familyName: string;
  givenName: string;
  membershipEnd: number;
  membershipStart: number;
  // "TRIAL_ACTIVE"
  membershipStatus?: MembershipStatus;
  // "TRIAL"
  membershipType?: MembershipType;
  // level: FREE, PRO, ENTERPRISE
  membershipLevel: PlanLevel;
  telegramChatId?: string;
  telegramChatUsername?: string;
  //stripe subscribed product id
  stripeProductId?: string;
  // stripe customer id
  stripeCustomerId?: string;
  // stripe subscription id
  stripeSubscriptionId?: string;
  // indicate if the user set "no more stripe reminders'
  stripeStopNotify?: boolean;
  // has request a demo
  stripeDemoRequested?: boolean;
  // mark user free trialed
  freeTrialRequested?: boolean;
  // when FreeTrial auto change to default level
  recoverFromFreeTrial?: boolean;
  // slack chat access token
  slackChatId?: string;
  slackAccessToken?: string;

  /** limit **/
  podcastRecord?: {
    time: number;
    counter: number;
  };

  /**
   * save dashboard edit data
   * struct: Array<DashboardViewData>
   */
  dashboardEditResults?: string;

  referralCode?: string;
  alert?: Alert;
  referralInfo?: {
    referralPaidCount: number;
    referralRegisteredCount: number;
    score: number;
    refereeList: RefereeInfo[];
  };

  twitterInfo?: {
    twitter_user_id: string;
    twitter_user_name: string;
    twitter_screen_name: string;
    twitter_disconnected_at: string;
  };
  paymentType: PaymentType;
  payerWallet?: string;
}

export enum PaymentType {
  OFFLINE_STRIPE = "OFFLINE_STRIPE",
  ONLINE_STRIPE = "ONLINE_STRIPE",
  OFFLINE_PAYMENT = "OFFLINE_PAYMENT",
  ONLINE_LOOP_CRYPTO = "ONLINE_LOOP_CRYPTO",
}

export interface RefereeInfo {
  created: number | string;
  contributionScore: number;
  email: string;
  self: boolean;
}
export interface Alert {
  type: AlertTypeEnums;
  alert: boolean;
  message: string;
  scenario?: ScenarioEnums;
}

export enum AlertTypeEnums {
  PAYMENT = "payment",
  PROFILE_ERROR_MESSAGE = "profile_error_message",
}
export enum ScenarioEnums {
  ACTIVELY_CANCEL = "actively_cancel",
  SUBSCRIPTION_RENEW_FAILED = "subscription_renew_failed",
}

export interface FreeTrialInfo {
  lastName: string;
  firstName: string;
  telegram: string;
  category: string;
  company?: string;
  companySize?: string;
  title?: string;
  email: string;
}

export interface OwnerSubInfo {
  groupName: string;
  seatQuota: number;
  ownerEmail?: string;
  userType: UserType;
}

export enum UserType {
  OWNER = "OWNER",
  SUB = "SUB",
  NORMAL = "NORMAL",
}

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconArrowsPointingIn: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M128 67.648l256 256v-152.96h85.312v298.624H170.688V384h152.96l-256-256L128 67.648zM956.352 128l-256 256h152.96v85.312H554.688V170.688H640v152.96l256-256 60.352 60.352zM170.688 554.688h298.624v298.624H384v-152.96l-256 256L67.648 896l256-256h-152.96V554.688zM554.688 554.688h298.624V640h-152.96l256 256-60.352 60.352-256-256v152.96H554.688V554.688z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconArrowsPointingIn.defaultProps = {
  size: 14,
};

export default IconArrowsPointingIn;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconAAddnewchat: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M469.333333 704a21.333333 21.333333 0 0 0 21.333334 21.333333h42.666666a21.333333 21.333333 0 0 0 21.333334-21.333333v-128a21.333333 21.333333 0 0 1 21.333333-21.333333h128a21.333333 21.333333 0 0 0 21.333333-21.333334v-42.666666a21.333333 21.333333 0 0 0-21.333333-21.333334h-128a21.333333 21.333333 0 0 1-21.333333-21.333333v-128a21.333333 21.333333 0 0 0-21.333334-21.333333h-42.666666a21.333333 21.333333 0 0 0-21.333334 21.333333v128a21.333333 21.333333 0 0 1-21.333333 21.333333h-128a21.333333 21.333333 0 0 0-21.333333 21.333334v42.666666a21.333333 21.333333 0 0 0 21.333333 21.333334h128a21.333333 21.333333 0 0 1 21.333333 21.333333v128z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M210.304 813.696c166.613333 166.613333 436.778667 166.613333 603.392 0 166.4-166.741333 166.4-436.650667 0-603.392-166.613333-166.613333-436.778667-166.613333-603.392 0-166.613333 166.613333-166.613333 436.778667 0 603.392z m60.330667-60.330667a341.333333 341.333333 0 0 1 0-482.730666 341.717333 341.717333 0 0 1 482.730666 0A341.333333 341.333333 0 0 1 270.634667 753.365333z"
        fill={getIconColor(color, 1, "currentColor")}
      />
    </svg>
  );
};

IconAAddnewchat.defaultProps = {
  size: 14,
};

export default IconAAddnewchat;

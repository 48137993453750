import { AxiosError } from "axios";

import events, { GlobalEventType } from "@/common/events";

import { AxiosInterceptor } from "./types";

export enum LimitCheckType {
  RATE,
  QUOTA,
}

// https://github.com/MetaSearch-IO/KaitoAIWebServer/blob/main/apps/portal/src/common/apiWithRateLimit.ts#L58
type ErrorResponse = {
  errMsg: string;
  type: LimitCheckType;
};

const tryParseErrorResponse = (error: AxiosError): ErrorResponse => {
  let errResponse: ErrorResponse;
  if (typeof error.response.data === "string") {
    try {
      errResponse = JSON.parse(error.response.data) as ErrorResponse;
    } catch (e) {}
  } else {
    errResponse = error.response.data as ErrorResponse;
  }
  return errResponse;
};

export type ErrorNotifyMessage = {
  /**
   * This is the error message
   */
  message: string;
  errResponse: ErrorResponse;
  /**
   * This is the path without query parameters
   */
  path: string;
};

export const errorNotify: AxiosInterceptor = (client) => {
  client.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 429) {
        const path = error.config.url.split("?")[0];
        const errResponse = tryParseErrorResponse(error);
        events.emit(GlobalEventType.RATE_LIMIT_REACHED, {
          message: ` ${error.config.url}`,
          path,
          errResponse,
        });
      }
      return Promise.reject(error);
    }
  );
};

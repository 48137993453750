import { ENV } from "@/env";
import { AxiosInterceptor } from "./types";

/**
 * when use axios in node.js server
 */
export const nodeEnvInterceptor: AxiosInterceptor = (client) => {
  client.interceptors.request.use((req) => {
    if (typeof window === "undefined") {
      // if node env should add localhost
      if (req.baseURL[0] === "/") {
        req.baseURL = `http://localhost:${ENV.localhostPort}${req.baseURL}`;
      }

      req.headers["caller"] = "MetaSearchInternalAPI";
    }

    return req;
  });
};

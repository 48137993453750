import { AxiosInterceptor } from "./types";

const publicApiPathConfig = {
  "/gateway/details": "/gateway/details/audio",
  "/ai/audio_list": "/sharing/audio_list",
  "/ai/audio_series_list": "/sharing/audio_series_list",
  "/ai/article_tldr": "/sharing/article_tldr",
  "/ai/analytics/kkol": "/sharing/kkol",
};

const publicPaths = ["/events", "/share"];

/**
 * some api will auto switch to public api path (/api/public/xxx)
 *
 * @example when load pages /events/ethXXX then request will change /api/ai/audio_list => /api/sharing/audio_list => {aiserver_endpoint}/api/sharing/audio_list
 */
export const switchPublicApi: AxiosInterceptor = (client) => {
  client.interceptors.request.use((config) => {
    const oldUrl = config.url;
    if (publicPaths.some((path) => location.pathname.startsWith(path))) {
      const targetUrl = publicApiPathConfig[oldUrl];
      // console.log("switch to public api:", { oldUrl, targetUrl });
      if (targetUrl) {
        config.url = targetUrl;
      }
    }
    return config;
  });
};

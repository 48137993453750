import { Box, Img } from "@chakra-ui/react";
import { useAtom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { isEmpty, take } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useCss } from "react-use";
import { twMerge } from "tailwind-merge";

import { searchTwitterUser } from "@/client/search";
import IconFont from "@/components/Icon/iconfont";
import { TwitterWithPrefix } from "@/components/SmartFollowing/ConnectTwitterSuccess";
import {
  isShowSyncedSuccessAtom,
  isTwitterSyncedAtom,
  useTwitterFollowingData,
} from "@/components/SmartFollowing/hooks";
import { userInfoAtom } from "@/store/userInfo";
import { twx } from "@/utils/twx";

export const twitterSyncingBannerAtom = atomWithStorage("TWITTER_IS_SYNCING_BANNER", true);

export const TwitterCircle = () => {
  const userInfo = useAtomValue(userInfoAtom);
  const IS_SYNCED = useAtomValue(isTwitterSyncedAtom);
  const [showBanner, setShowBanner] = useAtom(twitterSyncingBannerAtom);
  const [showIsSyncedBanner, setShowIsSyncedBanner] = useAtom(isShowSyncedSuccessAtom);
  const [memberIcons, setMemberIcons] = useState<string[]>([]);
  const [followerCount, setFollowerCount] = useState("0");

  const router = useRouter();

  useEffect(() => {
    userInfo?.twitterInfo?.twitter_user_id &&
      searchTwitterUser({
        twitter_user_id: userInfo?.twitterInfo?.twitter_user_id,
      }).then((res) => {
        const user = res?.listData?.[0];
        setFollowerCount(user?.followers);
      });
  }, [userInfo?.twitterInfo?.twitter_user_id]);

  const { data = [] } = useTwitterFollowingData({
    id: userInfo?.twitterInfo?.twitter_user_id,
    // id: "950486928784228352",
    type: "following",
    sort: { field: "followers", direction: "desc" },
    user_type: "kkol",
  });

  useEffect(() => {
    const items = take(data, 3);
    if (!isEmpty(items)) {
      setMemberIcons(items.map((item) => item.icon));
    }
  }, [data]);

  const className = useCss({
    "@media only screen and (max-width: 1599px)": {
      width: "calc(67% - 12px)",
    },
    "@media only screen and (min-width: 1600px) and (max-width: 1919px)": {
      width: "calc(50% - 12px)",
    },
  });

  const onPush = () => {
    if (!IS_SYNCED) return;
    router.push("/smart_following/setting/twitter_circle");
  };

  return (
    <Container
      className={twMerge([IS_SYNCED && "hover:bg-white/[0.05] cursor-pointer group", className])}
      onClick={onPush}
    >
      {showBanner && (
        <BannerContainer>
          <IconFont name="icon-Alert_cover" className="mr-1 text-brand-200" size={16} />
          <span>
            Your Twitter circle is currently in the process of syncing. You will be notified once
            it’s completed!
          </span>
          <GotIt onClick={() => setShowBanner(false)}>Got it</GotIt>
        </BannerContainer>
      )}
      {showIsSyncedBanner && (
        <BannerContainer>
          <IconFont name="icon-Alert_cover" className="mr-1 text-brand-200" size={16} />
          <span>Your Twitter Circle has been synced successfully!</span>
          <GotIt onClick={() => setShowIsSyncedBanner(false)}>Got it</GotIt>
        </BannerContainer>
      )}
      <div className="flex gap-3 items-center">
        <TwitterWithPrefix />
        <div className="text-white font-medium text-sm">
          @{userInfo?.twitterInfo?.twitter_user_name}
        </div>
        <div className="ml-auto text-xs text-white/60 flex items-center hover:text-brand-200">
          {!IS_SYNCED && (
            <>
              <IconFont
                name="icon-sync-uncover"
                size={16}
                className="text-brand-200 mr-1 animate-spin"
              />
              <span>Syncing...</span>
            </>
          )}
        </div>
      </div>
      <div className="text-xs text-white/80">
        Your Twitter circle represents your web3 community and your primary information source.
        Kaito automatically maps your connections and creates a default circle.
      </div>
      {IS_SYNCED && (
        <div className="flex items-center justify-between">
          <div className="text-white/60 text-xs flex items-center gap-0.5">
            <MemberIcons icons={memberIcons} />
            <span>{followerCount ?? 0} Smart Followers</span>
          </div>

          <span className="text-white/60 flex items-center text-xs gap-1 ml-9 group-hover:text-brand-200">
            View full list
            <IconFont name="IconLongRight" size={14} />
          </span>
        </div>
      )}
    </Container>
  );
};

export const MemberIcons = ({ icons }) => {
  return (
    <Box display={"flex"} alignItems="center">
      {icons.map((icon, index) => (
        <Img
          key={icon}
          src={icon}
          pos="relative"
          width={"16px"}
          height="16px"
          borderRadius={"16px"}
          left={index !== 0 ? `-${4 * index}px` : ""}
        />
      ))}
    </Box>
  );
};

const Container = twx.div`flex flex-col bg-[#1F2226] p-4 gap-3 rounded w-[calc(40%-12px)] min-w-[700px]`;
const BannerContainer = twx.div`flex text-xs text-white/90 items-center bg-brand-200/5 px-4 py-1.5 w-[calc(100%+32px)] -ml-4 -mt-4 rounded-tl rounded-tr`;
const GotIt = twx.div`ml-auto w-[50px] h-[20px] bg-G2-200 rounded px-2 text-center text-xs leading-5 cursor-pointer`;

import axios from "axios";
import { uniqBy } from "lodash";
import {
  SelectOption,
  generateRenderFilterField,
} from "../podcast_by_name/generateMultiSelectFilterField";
import {
  GovernanceMultiSelectName,
  GovernanceMultiSelectSource,
} from "@/components/Filter/SourceFilter/multiselectConstants";

const getRemoteOptions = async (prefix: string) => {
  const {
    data: { data },
  } = await axios.get("/api/gateway/auto_complete", {
    params: {
      prefix,
      source: "governance",
    },
  });

  return uniqBy(
    data?.map((v) => ({
      title: v.gov_forum_title,
      icon_url: v.gov_forum_icon_url,
      value: v.value,
      key: v.value,
    })) as SelectOption[],
    "value"
  );
};

const renderFilterField = generateRenderFilterField({
  placeholder: "Governance Forum",
  noResultTip: "No governance forum found",
  getRemoteOptions,
  name: GovernanceMultiSelectName,
  source: GovernanceMultiSelectSource,
});
export default renderFilterField;

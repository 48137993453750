import { uniqBy } from "lodash";
import {
  generateRenderFilterField,
  SelectOption,
} from "../podcast_by_name/generateMultiSelectFilterField";
import {
  ConferenceMultiSelectName,
  ConferenceMultiSelectSource,
} from "@/components/Filter/SourceFilter/multiselectConstants";
import { client } from "@/client/base";

const getRemoteOptions = async (prefix: string) => {
  const { data } = await client.get("/ai/conference_autocomplete", {
    params: {
      prefix,
    },
  });

  return uniqBy(
    data?.map((v) => ({
      title: v.conference_name,
      icon_url: v.conference_logo_url,
      value: v.value,
      key: v.value,
    })) as SelectOption[],
    "value"
  );
};

const renderFilterField = generateRenderFilterField({
  placeholder: "Conference Source",
  noResultTip: "No conference source found",
  getRemoteOptions,
  name: ConferenceMultiSelectName,
  source: ConferenceMultiSelectSource,
});
export default renderFilterField;

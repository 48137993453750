import { useSetAtom } from "jotai";
import { useEffect } from "react";

import { ErrorNotifyMessage, LimitCheckType } from "@/client/interceptors";
import events, { GlobalEventType } from "@/common/events";
import { useToast } from "@/hooks/useToast";
import { useCallbackFn } from "@/hooks/utils";

import { errorNotifyAtom } from "./atom";

const toastId = "rate-limit-reached-toast";
const duration = 5000;

const ToastContent = ({
  moreDetail,
  handleClose,
}: {
  moreDetail?: string;
  handleClose: () => void;
}) => {
  return (
    <div className="flex items-center text-sm">
      Too many requests, please wait while we refresh your data.
      {moreDetail}
      <span className="ml-4 text-B-200 cursor-pointer" onClick={handleClose}>
        Got It
      </span>
    </div>
  );
};

export const RateLimitReached = () => {
  const toast = useToast();
  const setErrorNotify = useSetAtom(errorNotifyAtom);

  const handleClose = useCallbackFn(() => {
    toast.toast.closeAll({
      positions: ["top"],
    });
  });

  useEffect(() => {
    const onRateLimitReached = (data: ErrorNotifyMessage) => {
      if (toast.toast.isActive(toastId)) return;
      handleClose();

      if (process.env.NEXT_PUBLIC_STAGE === "beta") {
        toast.info(<ToastContent handleClose={handleClose} />, duration, {
          id: toastId,
          containerStyle: {
            maxWidth: "100%",
          },
        });
      } else {
        // Dev: should show detail info
        console.info(`[RateLimitReached]: ${data.message}`);
        toast.info(<ToastContent moreDetail={data.message} handleClose={handleClose} />, duration, {
          id: toastId,
          containerStyle: {
            maxWidth: "100%",
          },
        });
      }
    };

    const onQuotaLimitReached = (data: ErrorNotifyMessage) => {
      const searchApiList = ["/ai/newSearch", "/ai/newSearchPost"];
      if (searchApiList.includes(data.path)) {
        setErrorNotify((draft) => {
          draft.config.searchApi = true;
        });
      }
    };

    const sb = events.addListener(
      GlobalEventType.RATE_LIMIT_REACHED,
      (data: ErrorNotifyMessage) => {
        if (data.errResponse.type === LimitCheckType.QUOTA) {
          onQuotaLimitReached(data);
        }

        if (data.errResponse.type === LimitCheckType.RATE) {
          onRateLimitReached(data);
        }
      }
    );
    return () => {
      handleClose();
      sb.remove();
    };
  }, [handleClose, setErrorNotify]);

  return null;
};

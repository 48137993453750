/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconNewspaper: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M270.592 355.84a12.8 12.8 0 0 0 12.8 12.8h264.106667a12.8 12.8 0 0 0 12.8-12.8v-36.778667a12.8 12.8 0 0 0-12.8-12.8h-264.106667a12.8 12.8 0 0 0-12.8 12.8v36.778667zM560.298667 489.557333a12.8 12.8 0 0 1-12.8 12.8h-264.106667a12.8 12.8 0 0 1-12.8-12.8v-36.821333a12.8 12.8 0 0 1 12.8-12.8h264.106667a12.8 12.8 0 0 1 12.8 12.8v36.821333zM270.592 623.232a12.8 12.8 0 0 0 12.8 12.8h152.661333a12.8 12.8 0 0 0 12.8-12.8v-36.778667a12.8 12.8 0 0 0-12.8-12.8H283.392a12.8 12.8 0 0 0-12.8 12.8v36.778667z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M128 233.472A105.472 105.472 0 0 1 233.472 128h371.370667a105.472 105.472 0 0 1 105.472 105.472v191.616h80.213333A105.472 105.472 0 0 1 896 530.56v222.848a105.472 105.472 0 0 1-105.472 105.429333v-62.378666a43.093333 43.093333 0 0 0 43.093333-43.093334v-222.805333a43.093333 43.093333 0 0 0-43.093333-43.093333h-80.213333v228.778666a80.213333 80.213333 0 0 0 80.213333 80.213334v62.378666H270.592A142.592 142.592 0 0 1 128 716.288V233.472z m544.597333 562.986667a141.952 141.952 0 0 1-24.661333-80.213334V233.472a43.093333 43.093333 0 0 0-43.093333-43.093333H233.472a43.093333 43.093333 0 0 0-43.093333 43.093333v482.773333a80.213333 80.213333 0 0 0 80.213333 80.213334h402.005333z"
        fill={getIconColor(color, 1, "currentColor")}
      />
    </svg>
  );
};

IconNewspaper.defaultProps = {
  size: 14,
};

export default IconNewspaper;

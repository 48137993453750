import type { FC, ReactNode } from "react";
import { Suspense } from "react";

import { Repeater } from "./Repeater";

/**
 * like vue <keep-alive>
 */
export const Offscreen: FC<{
  /**
   *  hidden | false
   *  visible | true
   */
  mode: "visible" | "hidden" | boolean;
  children: ReactNode;
  fallback?: ReactNode;
}> = (props) => {
  const { mode, children, fallback } = props;
  return (
    <Suspense fallback={fallback}>
      <Repeater mode={mode}>{children}</Repeater>
    </Suspense>
  );
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconWatchlistAdd: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M213.333333 234.666667A64 64 0 0 1 277.333333 170.666667H512v59.733333H273.066667v477.866667l170.666666-128a64 64 0 0 1 76.8 0l170.666667 128V469.333333h59.733333v358.4l-268.8-201.6L213.333333 827.733333V234.666667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M721.066667 170.666667v89.6H810.666667v59.733333h-89.6v89.6h-59.733334V320h-89.6V260.266667h89.6V170.666667h59.733334z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconAIconWatchlistAdd.defaultProps = {
  size: 14,
};

export default IconAIconWatchlistAdd;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconResearch: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M361.386667 402.858667a32 32 0 0 0 0 64h317.568a32 32 0 0 0 0-64H361.386667zM361.386667 561.621333a32 32 0 0 0 0 64h317.568a32 32 0 0 0 0-64H361.386667z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M242.346667 85.333333A71.68 71.68 0 0 0 170.666667 157.013333v714.410667c0 39.594667 32.085333 71.68 71.68 71.68h555.648a71.68 71.68 0 0 0 71.68-71.68V315.776a32 32 0 0 0-9.344-22.613333L661.888 94.72a32 32 0 0 0-22.613333-9.386667H242.346667zM234.666667 157.013333c0-4.266667 3.413333-7.68 7.68-7.68h383.658666l179.712 179.712v542.378667c0 4.266667-3.456 7.68-7.68 7.68H242.346667a7.68 7.68 0 0 1-7.68-7.68V157.013333z"
        fill={getIconColor(color, 1, "currentColor")}
      />
    </svg>
  );
};

IconResearch.defaultProps = {
  size: 14,
};

export default IconResearch;

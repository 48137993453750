/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIcontUpCopy: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M788.053333 609.493333L511.36 332.8 234.666667 609.493333 294.997333 669.866667l216.362667-216.405334 216.362667 216.405334 60.330666-60.373334z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIcontUpCopy.defaultProps = {
  size: 14,
};

export default IconIcontUpCopy;

import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

const fallbackIcon = {
  path: (
    <g stroke="currentColor" strokeWidth="1.5">
      <path
        strokeLinecap="round"
        fill="none"
        d="M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
      />
      <path
        fill="currentColor"
        strokeLinecap="round"
        d="M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
      />
      <circle fill="none" strokeMiterlimit="10" cx="12" cy="12" r="11.25" />
    </g>
  ),
  viewBox: "0 0 24 24",
};

export interface IconProps extends React.SVGAttributes<SVGElement> {}

/**
 * The Icon component renders as an svg element to help define your own custom components.
 */
export const Icon = forwardRef<"svg", IconProps>((props, ref) => {
  const {
    viewBox,
    color = "currentColor",
    focusable = false,
    children,
    className,
    ...rest
  } = props;

  const shared: any = {
    ref,
    focusable,
    style: {
      color,
    },
    className: twMerge(
      "w-[1em] h-[1em] inline-block leading-[1em] shrink-0 align-middle",
      className
    ),
  };

  const _viewBox = viewBox ?? fallbackIcon.viewBox;

  const _path = (children ?? fallbackIcon.path) as React.ReactNode;

  return (
    <svg viewBox={_viewBox} {...shared} {...rest}>
      {_path}
    </svg>
  );
});

Icon.displayName = "Icon";

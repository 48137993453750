/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconAlertUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M448.725333 192v-42.666667h126.549334v42.666667c0 2.688 0.469333 5.333333 0 7.893333A256.085333 256.085333 0 0 1 768 448v218.666667h80v74.666666H176V704v-37.333333H256V448a256.128 256.128 0 0 1 192.725333-248.106667c-0.469333-2.56 0-5.205333 0-7.893333z m244.608 474.666667V448a181.333333 181.333333 0 0 0-362.666666 0v218.666667h362.666666z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M410.666667 794.666667v74.666666h202.666666V832v-37.333333h-202.666666z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconAlertUncover.defaultProps = {
  size: 14,
};

export default IconIconAlertUncover;

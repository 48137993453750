/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconBulb1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M640 925.866667a12.8 12.8 0 0 1-12.8 12.8H396.8a12.8 12.8 0 0 1-12.8-12.8v-59.733334a12.8 12.8 0 0 1 12.8-12.8h230.4a12.8 12.8 0 0 1 12.8 12.8v59.733334z m1.28-126.549334a12.8 12.8 0 0 1-12.714667 11.349334H395.434667a12.8 12.8 0 0 1-12.714667-11.392L374.485333 725.333333a361.002667 361.002667 0 0 0-25.216-81.962666c-15.104-21.333333-30.848-39.552-46.08-57.344A284.416 284.416 0 0 1 213.333333 384a298.666667 298.666667 0 0 1 597.333334 0 285.568 285.568 0 0 1-89.301334 201.002667l-0.725333 0.853333a754.773333 754.773333 0 0 0-45.696 57.344A366.933333 366.933333 0 0 0 649.642667 725.333333l-8.362667 73.984z"
        fill={getIconColor(color, 0, "currentColor")}
      />
    </svg>
  );
};

IconBulb1.defaultProps = {
  size: 14,
};

export default IconBulb1;

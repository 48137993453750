/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconBarChartCover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M704 682.666667a64 64 0 0 1 64-64h128a64 64 0 0 1 64 64v192A64 64 0 0 1 896 938.666667h-128a64 64 0 0 1-64-64V682.666667zM402.773333 103.168c-12.032 11.434667-18.773333 26.965333-18.773333 43.093333v731.434667c0 16.213333 6.741333 31.701333 18.773333 43.093333 11.946667 11.434667 28.245333 17.877333 45.226667 17.877334h128c16.981333 0 33.28-6.4 45.226667-17.834667 12.032-11.434667 18.773333-26.965333 18.773333-43.093333V146.261333c0-16.213333-6.741333-31.701333-18.773333-43.093333A65.664 65.664 0 0 0 576 85.333333h-128c-16.981333 0-33.28 6.4-45.226667 17.834667zM82.773333 381.44A64 64 0 0 0 64 426.666667v448A64 64 0 0 0 128 938.666667h128a64 64 0 0 0 64-64V426.666667A64 64 0 0 0 256 362.666667H128a64 64 0 0 0-45.226667 18.773333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconBarChartCover.defaultProps = {
  size: 14,
};

export default IconAIconBarChartCover;

import { Tooltip } from "@chakra-ui/react";
import Image from "next/image";
import { twMerge } from "tailwind-merge";

import PremiumIcon from "@/public/premium-icon.png";

const Styles = {
  PremiumText: "text-[#F6D16F] text-xs font-normal inline-flex gap-1 items-center",
  PremiumIcon: "w-3 h-3",
  PremiumTextLg: "text-base ",
  PremiumIconLg: "w-5 h-5",
};

const isPremiumName = (name: string) => {
  return false; // name === "4Pillars";
};

export const Premium: React.FC<{
  PremiumTextCls?: string;
  PremiumIconCls?: string;
}> = ({ PremiumTextCls, PremiumIconCls }) => {
  return (
    <div className={twMerge(Styles.PremiumText, PremiumTextCls)}>
      <span className={twMerge(Styles.PremiumIcon, PremiumIconCls)}>
        <Image src={PremiumIcon} alt="" />
      </span>
      Premium
    </div>
  );
};

export const PremiumCommon: React.FC<{
  name: string;
  isDisableTooltip?: boolean;
}> = ({ name, isDisableTooltip: isDisabled = false }) => {
  if (isPremiumName(name)) {
    return (
      <Tooltip
        isDisabled={isDisabled}
        placement="bottom"
        hasArrow
        bg="#2A3242"
        border="1px solid #474F5C"
        sx={{
          "--popper-arrow-shadow-color": "#474F5C",
        }}
        label={<span className="text-xs font-normal text-white">Exclusive on Kaito</span>}
      >
        <div className="inline-flex ml-1 h-3 cursor-pointer">
          <Premium />
        </div>
      </Tooltip>
    );
  }
  return null;
};

export const PremiumInHeader: React.FC<{
  name: string;
}> = ({ name }) => {
  if (!isPremiumName(name)) return;

  return (
    <div
      className="flex h-7 p-1 pr-[6px] w-[fit-content] mb-2"
      style={{
        borderRadius: "14px 4px 4px 14px",
        background: "rgba(246, 209, 111, 0.15)",
      }}
    >
      <Premium PremiumTextCls={Styles.PremiumTextLg} PremiumIconCls={Styles.PremiumIconLg} />
    </div>
  );
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconMaybeUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M476.842667 712.533333h59.733333v-59.733333h-59.733333v59.733333zM605.952 501.418667c-7.338667 9.386667-15.232 17.664-23.68 24.917333-8.448 7.253333-16.341333 14.506667-23.68 21.76-7.338667 7.253333-13.44 14.72-18.261333 22.528-4.821333 7.808-7.253333 16.469333-7.253334 25.941333v25.130667h-55.978666v-27.093333a68.266667 68.266667 0 0 1 6.826666-30.762667c4.522667-9.216 10.24-17.834667 16.981334-25.813333 6.826667-7.978667 14.122667-15.701333 22.016-23.125334 7.893333-7.424 15.232-14.677333 22.016-21.76 6.784-6.997333 12.416-14.464 16.981333-22.229333 4.565333-7.850667 6.826667-16.384 6.826667-25.685333a45.226667 45.226667 0 0 0-4.992-21.845334 45.226667 45.226667 0 0 0-13.781334-15.616 61.44 61.44 0 0 0-20.053333-9.301333 91.178667 91.178667 0 0 0-24.149333-3.072c-27.648 0-54.016 12.373333-79.104 37.034667V368.085333C457.088 350.250667 488.704 341.333333 521.386667 341.333333c15.018667 0 29.269333 1.92 42.666666 5.845334 13.354667 3.925333 25.045333 9.642667 35.072 17.28 10.026667 7.594667 17.92 17.066667 23.68 28.416 5.802667 11.349333 8.661333 24.533333 8.661334 39.552 0 14.293333-2.432 27.008-7.253334 38.186666a141.098667 141.098667 0 0 1-18.261333 30.805334z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M511.658667 853.333333a341.333333 341.333333 0 1 1 0.682666-682.666666 341.333333 341.333333 0 0 1-0.682666 682.666666zM238.933333 517.888a273.066667 273.066667 0 1 0 0-5.888v5.888z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconMaybeUncover.defaultProps = {
  size: 14,
};

export default IconIconMaybeUncover;

import { Filter } from "@metasearch-io/search-ui";
import { flatten, get, isEmpty } from "lodash";

import { SelectRadioType } from "@/components/MultiSelect/SelectCheckBox";
import { indexCategory, reversedIndexCategory } from "@/constants";
import { FilterPipeTransform } from "@/types";

import { getFieldProperty } from "../utils";
import { SNAPSHOT_ID_NAME, TALLY_ID_NAME } from "./searchBySource/vote";
import { SourceFilterType } from "./types";

export const rangeFilterPipe: FilterPipeTransform = (filters: Filter[], result) => {
  filters
    .filter((v) => v.values)
    .forEach(({ field, values }) => {
      const fieldProperty = getFieldProperty(field);
      if (fieldProperty) {
        const value = values[0];
        switch (fieldProperty.type) {
          case SourceFilterType.Range: {
            const range = fieldProperty.range || [];
            if (value[0] === 0 && value[1] === 10) {
              // all, so no filter
              return {};
            } else if (value[0] === 0 && value[1] !== 10) {
              return (result[`max_${field}`] = range[value[1] as number]);
            } else if (value[1] === 10) {
              result[`min_${field}`] = range[value[0] as number];
            } else {
              result[`min_${field}`] = range[value[0] as number];
              result[`max_${field}`] = range[value[1] as number];
            }
            break;
          }
          case SourceFilterType.Text: {
            result[field] = value;
            break;
          }
          case SourceFilterType.MultiSelect: {
            const prefix = value?.[0]?.type === SelectRadioType.Exclude ? "exclude_" : "";
            /**
             * when field is vote_project_name, the filter arguments should be:
             * vote_snapshot_space_id / exclude_vote_snapshot_space_id && vote_tally_dao_id / exclude_vote_tally_dao_id
             * instead of vote_project_name / exclude_vote_project_name
             */

            if (field === "vote_project_name") {
              let snapshotIds = [];
              let tallyIds = [];
              if (Array.isArray(value)) {
                value.map((item: any) => {
                  if (item.data?.[SNAPSHOT_ID_NAME]) {
                    snapshotIds.push(item.data[SNAPSHOT_ID_NAME]);
                  }
                  if (item.data?.[TALLY_ID_NAME]) {
                    tallyIds.push(item.data[TALLY_ID_NAME]);
                  }
                });
                if (snapshotIds.length) {
                  result[`${prefix}${SNAPSHOT_ID_NAME}`] = snapshotIds.join(",");
                }
                if (tallyIds.length) {
                  result[`${prefix}${TALLY_ID_NAME}`] = tallyIds.join(",");
                }
              }
            } else {
              result[`${prefix}${field}`] = (value as any[]).map((item) => item.value).join(",");
            }

            break;
          }
          case SourceFilterType.RadioSelect: {
            result[field] = value as boolean;
            break;
          }
          case SourceFilterType.SingleSelect: {
            result[field] = value;
          }
          default:
            break;
        }
      }
    });
  return [filters, result];
};

export const indexFilterPipe: FilterPipeTransform = (filters: Filter[], params) => {
  let result = { ...params };
  filters.forEach((filter) => {
    if (filter.field === "index") {
      const value = get(filter, ["values", "0"], []) as string[];
      // result["tabs"] = [];
      let currIndex = new Set(
        flatten(
          value.map((v) => {
            // result["tabs"].push(v);
            if (indexCategory[v]) {
              const sources = params[`${v}_source`.toLocaleLowerCase()];
              delete result[`${v}_source`.toLocaleLowerCase()];
              return isEmpty(sources)
                ? indexCategory[reversedIndexCategory[v] ?? v]
                : sources.split(",");
            }
            return v;
          })
        )
      );
      result["index"] = [...currIndex].join(",");
      if (isEmpty(value)) {
        result["index"] = "";
      }
    }
  });
  if (!result["index"]) {
    result["index"] = "";
  }
  return [filters, result];
};

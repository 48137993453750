import { userInfoAtom } from "@/store/userInfo";
import { useAtomValue, useAtom } from "jotai";
import { getPriceStrFormat } from "@/utils/user";
import { useRouter } from "next/router";
import { useCallbackFn } from "@/hooks/utils";
import { getUpdatePaymentTypeUrl } from "@/client/payment";
import { paymentRetryUseAtom } from "@/store/ui-control";
import { Dialog } from "@/components/Dialog";

const CONFIRM_TITLE = "Confirmation of payment";
const PAYMENT_METHOD_SUCCESS = "payment_successful";

export const PaymentConfire: React.FC<{ payInvoice: () => void }> = ({ payInvoice }) => {
  const userInfo = useAtomValue(userInfoAtom);
  const [paymentRetry, updatePaymentRetry] = useAtom(paymentRetryUseAtom);
  const router = useRouter();

  const handleUpgradePaymentMethod = useCallbackFn(async () => {
    const success_url = `${location.origin}${location.pathname}?${PAYMENT_METHOD_SUCCESS}=1`;
    const cancel_url = `${location.origin}${location.pathname}`;

    const result = await getUpdatePaymentTypeUrl({
      stripe_customer_id: userInfo.stripeCustomerId,
      stripe_subscription_id: userInfo.stripeSubscriptionId,
      success_url,
      cancel_url,
    });

    router.push(result.checkout_url);
    closeShowUpdatePay();
  });

  const closeShowTryPay = () => {
    updatePaymentRetry({
      isShowRetryPay: false,
    });
  };
  const closeShowUpdatePay = () => {
    updatePaymentRetry({
      isShowUpdatePay: false,
    });
  };

  return (
    <>
      <Dialog
        isOpen={paymentRetry.isShowRetryPay}
        onClose={closeShowTryPay}
        title={CONFIRM_TITLE}
        content={`You are about to be charged $${paymentRetry.payPrice ? getPriceStrFormat(paymentRetry.payPrice / 100) : 0} immediately. Do you want to proceed?`}
        cancelText="Cancel"
        okText="Continue"
        showCloseIcon
        onOk={() => {
          closeShowTryPay();
          payInvoice();
        }}
        onCancel={closeShowTryPay}
      />
      <Dialog
        isOpen={paymentRetry.isShowUpdatePay}
        onClose={closeShowUpdatePay}
        title={CONFIRM_TITLE}
        content={`After completing your credit card update, you will be charged $${paymentRetry.payPrice ? getPriceStrFormat(paymentRetry.payPrice / 100) : 0} immediately. Do you want to proceed?`}
        cancelText="Cancel"
        okText="Continue"
        showCloseIcon
        onOk={handleUpgradePaymentMethod}
        onCancel={closeShowUpdatePay}
      />
    </>
  );
};

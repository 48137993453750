/* tslint:disable */
/* eslint-disable */

import React, { SVGAttributes, FunctionComponent } from "react";
import IconNewspaper from "./IconNewspaper";
import IconResearch from "./IconResearch";
import IconHistory from "./IconHistory";
import IconShare from "./IconShare";
import IconKol from "./IconKol";
import IconAIconNewchat from "./IconAIconNewchat";
import IconBulb1 from "./IconBulb1";
import IconRobot1 from "./IconRobot1";
import IconAInaddnewChat from "./IconAInaddnewChat";
import IconCalendar from "./IconCalendar";
import IconAAddnewchat from "./IconAAddnewchat";
import IconRobot from "./IconRobot";
import IconASearchsummary from "./IconASearchsummary";
import IconTldr from "./IconTldr";
import IconChartLine from "./IconChartLine";
import IconIconTelescopeUncover from "./IconIconTelescopeUncover";
import IconIconTwitterUncover from "./IconIconTwitterUncover";
import IconIconPersonUncover1 from "./IconIconPersonUncover1";
import IconIconRocketCover from "./IconIconRocketCover";
import IconIconLightningCover from "./IconIconLightningCover";
import IconAIconSandclockUncover from "./IconAIconSandclockUncover";
import IconIconSuspendCover from "./IconIconSuspendCover";
import IconCopilot from "./IconCopilot";
import IconAIconFourpointedstarCover from "./IconAIconFourpointedstarCover";
import IconIconSyncUncover from "./IconIconSyncUncover";
import IconIconAlertCover from "./IconIconAlertCover";
import IconIconMinus from "./IconIconMinus";
import IconIconAdmin from "./IconIconAdmin";
import IconAFrame1312317937 from "./IconAFrame1312317937";
import IconAFrame1312317938 from "./IconAFrame1312317938";
import IconAIconMousedownUncover from "./IconAIconMousedownUncover";
import IconAFrame1312317979 from "./IconAFrame1312317979";
import IconIconLinkUncover from "./IconIconLinkUncover";
import IconIconPersonUncover from "./IconIconPersonUncover";
import IconIconEntityUncover from "./IconIconEntityUncover";
import IconNft from "./IconNft";
import IconHeadphones from "./IconHeadphones";
import IconIconStackedchart from "./IconIconStackedchart";
import IconHeatmap from "./IconHeatmap";
import IconIconCopyUncover from "./IconIconCopyUncover";
import IconIconEditUncover from "./IconIconEditUncover";
import IconIconAuthenticationUncover from "./IconIconAuthenticationUncover";
import IconIconCrownUncover from "./IconIconCrownUncover";
import IconIconLocationUncover from "./IconIconLocationUncover";
import IconAIconTokenmindshareUncover from "./IconAIconTokenmindshareUncover";
import IconIconFeedsUncover from "./IconIconFeedsUncover";
import IconAKaitologo from "./IconAKaitologo";
import IconAIconMarketsmartfollowingUncover from "./IconAIconMarketsmartfollowingUncover";
import IconIconTrackUncover from "./IconIconTrackUncover";
import IconIconRefreshUncover from "./IconIconRefreshUncover";
import IconAIconNarrativeMindshareUncover from "./IconAIconNarrativeMindshareUncover";
import IconIconMaybeCover from "./IconIconMaybeCover";
import IconAIconSearchlistWhitePaper from "./IconAIconSearchlistWhitePaper";
import IconAIconSearchlistResearch from "./IconAIconSearchlistResearch";
import IconFileChartNews from "./IconFileChartNews";
import IconASmartOptimization1Copy from "./IconASmartOptimization1Copy";
import IconAIconSearchlistConference from "./IconAIconSearchlistConference";
import IconAIconSearchlistDiscord from "./IconAIconSearchlistDiscord";
import IconAIconSearchlistResearch2 from "./IconAIconSearchlistResearch2";
import IconAIconSearchlistPodcast from "./IconAIconSearchlistPodcast";
import IconAIconSearchlistTwitter from "./IconAIconSearchlistTwitter";
import IconAIconSearchlistResearch1 from "./IconAIconSearchlistResearch1";
import IconAIconSearchlistMedium from "./IconAIconSearchlistMedium";
import IconAIconSearchlistMirror from "./IconAIconSearchlistMirror";
import IconAIconSearchlistDashboard from "./IconAIconSearchlistDashboard";
import IconAIconSearchlistTheblock from "./IconAIconSearchlistTheblock";
import IconAIconSearchlistMedium1 from "./IconAIconSearchlistMedium1";
import IconAIconBarChartCover1 from "./IconAIconBarChartCover1";
import IconAHuatitaolunTopicDiscussion11 from "./IconAHuatitaolunTopicDiscussion11";
import IconAIconFullscreenOneUncover from "./IconAIconFullscreenOneUncover";
import IconAIconExitfullscreenOneUncover from "./IconAIconExitfullscreenOneUncover";
import IconIconShareUncover from "./IconIconShareUncover";
import IconIconSnapshotOfficialCover from "./IconIconSnapshotOfficialCover";
import IconTldrNeutral from "./IconTldrNeutral";
import IconIconLockUncover1 from "./IconIconLockUncover1";
import IconAIconChartUncover from "./IconAIconChartUncover";
import IconAIconBarChartCover from "./IconAIconBarChartCover";
import IconIconSentiment from "./IconIconSentiment";
import IconIconHearUncovert from "./IconIconHearUncovert";
import IconIconKeywordsUncover from "./IconIconKeywordsUncover";
import IconIocnAuthorUncover from "./IconIocnAuthorUncover";
import IconIconBookmarksUncover from "./IconIconBookmarksUncover";
import IconBulb from "./IconBulb";
import IconIconServeUncover from "./IconIconServeUncover";
import IconIconClockCover from "./IconIconClockCover";
import IconAIconExitFullscreenUncover from "./IconAIconExitFullscreenUncover";
import IconAIconFullscreenUncover from "./IconAIconFullscreenUncover";
import IconIconComfy from "./IconIconComfy";
import IconIconCompact from "./IconIconCompact";
import IconIconGovernmentUncover from "./IconIconGovernmentUncover";
import IconAIconSoundwave from "./IconAIconSoundwave";
import IconAMictalk from "./IconAMictalk";
import IconIconLongDown from "./IconIconLongDown";
import IconIconListCheck from "./IconIconListCheck";
import IconArrowsPointingOut from "./IconArrowsPointingOut";
import IconArrowsPointingIn from "./IconArrowsPointingIn";
import IconShow from "./IconShow";
import IconIconDeleteUncover from "./IconIconDeleteUncover";
import IconIconWatchlist from "./IconIconWatchlist";
import IconIconDashboard from "./IconIconDashboard";
import IconIconDiscovery from "./IconIconDiscovery";
import IconAIconWatchlistAdd from "./IconAIconWatchlistAdd";
import IconAIconSuccessUncover1 from "./IconAIconSuccessUncover1";
import IconIconLongRight from "./IconIconLongRight";
import IconHome from "./IconHome";
import IconAIconAddAlertUncover from "./IconAIconAddAlertUncover";
import IconAIconBanAlert from "./IconAIconBanAlert";
import IconCard from "./IconCard";
import IconIconTriangleRight from "./IconIconTriangleRight";
import IconIconThumbdown from "./IconIconThumbdown";
import IconIconTriangleLeft from "./IconIconTriangleLeft";
import IconIconSort from "./IconIconSort";
import IconIconDown from "./IconIconDown";
import IconIconClockUncover from "./IconIconClockUncover";
import IconCopy from "./IconCopy";
import IconIconSearch from "./IconIconSearch";
import IconIconLockUncover from "./IconIconLockUncover";
import IconIconAlertUncover from "./IconIconAlertUncover";
import IconIconCalendar from "./IconIconCalendar";
import IconIconThumbupCover from "./IconIconThumbupCover";
import IconIconFlashUncover from "./IconIconFlashUncover";
import IconIconTrendingUp from "./IconIconTrendingUp";
import IconIconClose from "./IconIconClose";
import IconIconCheck from "./IconIconCheck";
import IconIconSwitch from "./IconIconSwitch";
import IconIconChatUncover from "./IconIconChatUncover";
import IconIconDownCopy from "./IconIconDownCopy";
import IconIconInfoCover from "./IconIconInfoCover";
import IconIconDoubleLeft from "./IconIconDoubleLeft";
import IconIconRefresh from "./IconIconRefresh";
import IconIconRight from "./IconIconRight";
import IconIconExit from "./IconIconExit";
import IconIconSlideBack from "./IconIconSlideBack";
import IconIconPaperAirplaneCover from "./IconIconPaperAirplaneCover";
import IconIconAttention from "./IconIconAttention";
import IconIconThumbdownCover from "./IconIconThumbdownCover";
import IconIconMore from "./IconIconMore";
import IconIconTriangleDown from "./IconIconTriangleDown";
import IconIconClock from "./IconIconClock";
import IconIconDoubleArrow from "./IconIconDoubleArrow";
import IconIcontUp from "./IconIcontUp";
import IconIconAddUncover from "./IconIconAddUncover";
import IconIconAttentionCover from "./IconIconAttentionCover";
import IconIcontUpCopy from "./IconIcontUpCopy";
import IconAIconSuccessUncover from "./IconAIconSuccessUncover";
import IconIconEdit from "./IconIconEdit";
import IconAIconAddAlertCover from "./IconAIconAddAlertCover";
import IconIconInformation from "./IconIconInformation";
import IconIconThumbup from "./IconIconThumbup";
import IconIconFilter from "./IconIconFilter";
import IconIconDoubleRight from "./IconIconDoubleRight";
import IconIconBulb from "./IconIconBulb";
import IconIconMaybeUncover from "./IconIconMaybeUncover";
import IconAIconMaybebigUncover from "./IconAIconMaybebigUncover";
import IconIconLink from "./IconIconLink";
import IconSetting from "./IconSetting";
import IconIconSad from "./IconIconSad";
import IconIconTriangleUp from "./IconIconTriangleUp";
export { default as IconNewspaper } from "./IconNewspaper";
export { default as IconResearch } from "./IconResearch";
export { default as IconHistory } from "./IconHistory";
export { default as IconShare } from "./IconShare";
export { default as IconKol } from "./IconKol";
export { default as IconAIconNewchat } from "./IconAIconNewchat";
export { default as IconBulb1 } from "./IconBulb1";
export { default as IconRobot1 } from "./IconRobot1";
export { default as IconAInaddnewChat } from "./IconAInaddnewChat";
export { default as IconCalendar } from "./IconCalendar";
export { default as IconAAddnewchat } from "./IconAAddnewchat";
export { default as IconRobot } from "./IconRobot";
export { default as IconASearchsummary } from "./IconASearchsummary";
export { default as IconTldr } from "./IconTldr";
export { default as IconChartLine } from "./IconChartLine";
export { default as IconIconTelescopeUncover } from "./IconIconTelescopeUncover";
export { default as IconIconTwitterUncover } from "./IconIconTwitterUncover";
export { default as IconIconPersonUncover1 } from "./IconIconPersonUncover1";
export { default as IconIconRocketCover } from "./IconIconRocketCover";
export { default as IconIconLightningCover } from "./IconIconLightningCover";
export { default as IconAIconSandclockUncover } from "./IconAIconSandclockUncover";
export { default as IconIconSuspendCover } from "./IconIconSuspendCover";
export { default as IconCopilot } from "./IconCopilot";
export { default as IconAIconFourpointedstarCover } from "./IconAIconFourpointedstarCover";
export { default as IconIconSyncUncover } from "./IconIconSyncUncover";
export { default as IconIconAlertCover } from "./IconIconAlertCover";
export { default as IconIconMinus } from "./IconIconMinus";
export { default as IconIconAdmin } from "./IconIconAdmin";
export { default as IconAFrame1312317937 } from "./IconAFrame1312317937";
export { default as IconAFrame1312317938 } from "./IconAFrame1312317938";
export { default as IconAIconMousedownUncover } from "./IconAIconMousedownUncover";
export { default as IconAFrame1312317979 } from "./IconAFrame1312317979";
export { default as IconIconLinkUncover } from "./IconIconLinkUncover";
export { default as IconIconPersonUncover } from "./IconIconPersonUncover";
export { default as IconIconEntityUncover } from "./IconIconEntityUncover";
export { default as IconNft } from "./IconNft";
export { default as IconHeadphones } from "./IconHeadphones";
export { default as IconIconStackedchart } from "./IconIconStackedchart";
export { default as IconHeatmap } from "./IconHeatmap";
export { default as IconIconCopyUncover } from "./IconIconCopyUncover";
export { default as IconIconEditUncover } from "./IconIconEditUncover";
export { default as IconIconAuthenticationUncover } from "./IconIconAuthenticationUncover";
export { default as IconIconCrownUncover } from "./IconIconCrownUncover";
export { default as IconIconLocationUncover } from "./IconIconLocationUncover";
export { default as IconAIconTokenmindshareUncover } from "./IconAIconTokenmindshareUncover";
export { default as IconIconFeedsUncover } from "./IconIconFeedsUncover";
export { default as IconAKaitologo } from "./IconAKaitologo";
export { default as IconAIconMarketsmartfollowingUncover } from "./IconAIconMarketsmartfollowingUncover";
export { default as IconIconTrackUncover } from "./IconIconTrackUncover";
export { default as IconIconRefreshUncover } from "./IconIconRefreshUncover";
export { default as IconAIconNarrativeMindshareUncover } from "./IconAIconNarrativeMindshareUncover";
export { default as IconIconMaybeCover } from "./IconIconMaybeCover";
export { default as IconAIconSearchlistWhitePaper } from "./IconAIconSearchlistWhitePaper";
export { default as IconAIconSearchlistResearch } from "./IconAIconSearchlistResearch";
export { default as IconFileChartNews } from "./IconFileChartNews";
export { default as IconASmartOptimization1Copy } from "./IconASmartOptimization1Copy";
export { default as IconAIconSearchlistConference } from "./IconAIconSearchlistConference";
export { default as IconAIconSearchlistDiscord } from "./IconAIconSearchlistDiscord";
export { default as IconAIconSearchlistResearch2 } from "./IconAIconSearchlistResearch2";
export { default as IconAIconSearchlistPodcast } from "./IconAIconSearchlistPodcast";
export { default as IconAIconSearchlistTwitter } from "./IconAIconSearchlistTwitter";
export { default as IconAIconSearchlistResearch1 } from "./IconAIconSearchlistResearch1";
export { default as IconAIconSearchlistMedium } from "./IconAIconSearchlistMedium";
export { default as IconAIconSearchlistMirror } from "./IconAIconSearchlistMirror";
export { default as IconAIconSearchlistDashboard } from "./IconAIconSearchlistDashboard";
export { default as IconAIconSearchlistTheblock } from "./IconAIconSearchlistTheblock";
export { default as IconAIconSearchlistMedium1 } from "./IconAIconSearchlistMedium1";
export { default as IconAIconBarChartCover1 } from "./IconAIconBarChartCover1";
export { default as IconAHuatitaolunTopicDiscussion11 } from "./IconAHuatitaolunTopicDiscussion11";
export { default as IconAIconFullscreenOneUncover } from "./IconAIconFullscreenOneUncover";
export { default as IconAIconExitfullscreenOneUncover } from "./IconAIconExitfullscreenOneUncover";
export { default as IconIconShareUncover } from "./IconIconShareUncover";
export { default as IconIconSnapshotOfficialCover } from "./IconIconSnapshotOfficialCover";
export { default as IconTldrNeutral } from "./IconTldrNeutral";
export { default as IconIconLockUncover1 } from "./IconIconLockUncover1";
export { default as IconAIconChartUncover } from "./IconAIconChartUncover";
export { default as IconAIconBarChartCover } from "./IconAIconBarChartCover";
export { default as IconIconSentiment } from "./IconIconSentiment";
export { default as IconIconHearUncovert } from "./IconIconHearUncovert";
export { default as IconIconKeywordsUncover } from "./IconIconKeywordsUncover";
export { default as IconIocnAuthorUncover } from "./IconIocnAuthorUncover";
export { default as IconIconBookmarksUncover } from "./IconIconBookmarksUncover";
export { default as IconBulb } from "./IconBulb";
export { default as IconIconServeUncover } from "./IconIconServeUncover";
export { default as IconIconClockCover } from "./IconIconClockCover";
export { default as IconAIconExitFullscreenUncover } from "./IconAIconExitFullscreenUncover";
export { default as IconAIconFullscreenUncover } from "./IconAIconFullscreenUncover";
export { default as IconIconComfy } from "./IconIconComfy";
export { default as IconIconCompact } from "./IconIconCompact";
export { default as IconIconGovernmentUncover } from "./IconIconGovernmentUncover";
export { default as IconAIconSoundwave } from "./IconAIconSoundwave";
export { default as IconAMictalk } from "./IconAMictalk";
export { default as IconIconLongDown } from "./IconIconLongDown";
export { default as IconIconListCheck } from "./IconIconListCheck";
export { default as IconArrowsPointingOut } from "./IconArrowsPointingOut";
export { default as IconArrowsPointingIn } from "./IconArrowsPointingIn";
export { default as IconShow } from "./IconShow";
export { default as IconIconDeleteUncover } from "./IconIconDeleteUncover";
export { default as IconIconWatchlist } from "./IconIconWatchlist";
export { default as IconIconDashboard } from "./IconIconDashboard";
export { default as IconIconDiscovery } from "./IconIconDiscovery";
export { default as IconAIconWatchlistAdd } from "./IconAIconWatchlistAdd";
export { default as IconAIconSuccessUncover1 } from "./IconAIconSuccessUncover1";
export { default as IconIconLongRight } from "./IconIconLongRight";
export { default as IconHome } from "./IconHome";
export { default as IconAIconAddAlertUncover } from "./IconAIconAddAlertUncover";
export { default as IconAIconBanAlert } from "./IconAIconBanAlert";
export { default as IconCard } from "./IconCard";
export { default as IconIconTriangleRight } from "./IconIconTriangleRight";
export { default as IconIconThumbdown } from "./IconIconThumbdown";
export { default as IconIconTriangleLeft } from "./IconIconTriangleLeft";
export { default as IconIconSort } from "./IconIconSort";
export { default as IconIconDown } from "./IconIconDown";
export { default as IconIconClockUncover } from "./IconIconClockUncover";
export { default as IconCopy } from "./IconCopy";
export { default as IconIconSearch } from "./IconIconSearch";
export { default as IconIconLockUncover } from "./IconIconLockUncover";
export { default as IconIconAlertUncover } from "./IconIconAlertUncover";
export { default as IconIconCalendar } from "./IconIconCalendar";
export { default as IconIconThumbupCover } from "./IconIconThumbupCover";
export { default as IconIconFlashUncover } from "./IconIconFlashUncover";
export { default as IconIconTrendingUp } from "./IconIconTrendingUp";
export { default as IconIconClose } from "./IconIconClose";
export { default as IconIconCheck } from "./IconIconCheck";
export { default as IconIconSwitch } from "./IconIconSwitch";
export { default as IconIconChatUncover } from "./IconIconChatUncover";
export { default as IconIconDownCopy } from "./IconIconDownCopy";
export { default as IconIconInfoCover } from "./IconIconInfoCover";
export { default as IconIconDoubleLeft } from "./IconIconDoubleLeft";
export { default as IconIconRefresh } from "./IconIconRefresh";
export { default as IconIconRight } from "./IconIconRight";
export { default as IconIconExit } from "./IconIconExit";
export { default as IconIconSlideBack } from "./IconIconSlideBack";
export { default as IconIconPaperAirplaneCover } from "./IconIconPaperAirplaneCover";
export { default as IconIconAttention } from "./IconIconAttention";
export { default as IconIconThumbdownCover } from "./IconIconThumbdownCover";
export { default as IconIconMore } from "./IconIconMore";
export { default as IconIconTriangleDown } from "./IconIconTriangleDown";
export { default as IconIconClock } from "./IconIconClock";
export { default as IconIconDoubleArrow } from "./IconIconDoubleArrow";
export { default as IconIcontUp } from "./IconIcontUp";
export { default as IconIconAddUncover } from "./IconIconAddUncover";
export { default as IconIconAttentionCover } from "./IconIconAttentionCover";
export { default as IconIcontUpCopy } from "./IconIcontUpCopy";
export { default as IconAIconSuccessUncover } from "./IconAIconSuccessUncover";
export { default as IconIconEdit } from "./IconIconEdit";
export { default as IconAIconAddAlertCover } from "./IconAIconAddAlertCover";
export { default as IconIconInformation } from "./IconIconInformation";
export { default as IconIconThumbup } from "./IconIconThumbup";
export { default as IconIconFilter } from "./IconIconFilter";
export { default as IconIconDoubleRight } from "./IconIconDoubleRight";
export { default as IconIconBulb } from "./IconIconBulb";
export { default as IconIconMaybeUncover } from "./IconIconMaybeUncover";
export { default as IconAIconMaybebigUncover } from "./IconAIconMaybebigUncover";
export { default as IconIconLink } from "./IconIconLink";
export { default as IconSetting } from "./IconSetting";
export { default as IconIconSad } from "./IconIconSad";
export { default as IconIconTriangleUp } from "./IconIconTriangleUp";

export type IconNames =
  | "newspaper"
  | "Research"
  | "history"
  | "share"
  | "kol"
  | "a-icon-newchat"
  | "bulb1"
  | "robot1"
  | "a-inaddnew-chat"
  | "Calendar"
  | "a-Addnewchat"
  | "robot"
  | "a-Searchsummary"
  | "tldr"
  | "chart-line"
  | "icon-telescope-uncover"
  | "icon-twitter-uncover"
  | "icon-person-uncover1"
  | "icon-rocket-cover"
  | "icon-lightning-cover"
  | "a-icon-sandclock-uncover"
  | "icon-suspend-cover"
  | "Copilot"
  | "a-icon-Fourpointedstar-cover"
  | "icon-sync-uncover"
  | "icon-Alert_cover"
  | "icon-minus"
  | "icon-Admin"
  | "a-Frame1312317937"
  | "a-Frame1312317938"
  | "a-icon-mousedown-uncover"
  | "a-Frame1312317979"
  | "icon-Link-uncover"
  | "icon-person-uncover"
  | "icon-entity-uncover"
  | "NFT"
  | "headphones"
  | "icon-Stackedchart"
  | "heatmap"
  | "icon-copy-uncover"
  | "icon-edit-uncover"
  | "icon-authentication-uncover"
  | "icon-crown-uncover"
  | "icon-location-uncover"
  | "a-icon-Tokenmindshare-uncover"
  | "icon-Feeds-uncover"
  | "a-Kaitologo"
  | "a-icon-Marketsmartfollowing-uncover"
  | "icon-track-uncover"
  | "icon-refresh-uncover"
  | "a-icon-NarrativeMindshare-uncover"
  | "Icon-maybe-cover"
  | "a-icon_searchlist_WhitePaper"
  | "a-icon_searchlist_research"
  | "file-chart-News"
  | "a-smart-optimization1-copy"
  | "a-icon_searchlist_conference"
  | "a-icon_searchlist_discord"
  | "a-icon_searchlist_research-2"
  | "a-icon_searchlist_podcast"
  | "a-icon_searchlist_Twitter"
  | "a-icon_searchlist_research-1"
  | "a-icon_searchlist_medium"
  | "a-icon_searchlist_mirror"
  | "a-icon_searchlist_dashboard"
  | "a-icon_searchlist_theblock"
  | "a-icon_searchlist_medium-1"
  | "a-icon-BarChart-cover1"
  | "a-huatitaolun_topic-discussion11"
  | "a-icon-fullscreen_one-uncover"
  | "a-icon-exitfullscreen_one-uncover"
  | "icon-Share-uncover"
  | "icon-Snapshot_Official-cover"
  | "TLDR-Neutral"
  | "icon-lock-uncover1"
  | "a-icon-chart-uncover"
  | "a-icon-BarChart-cover"
  | "icon_sentiment"
  | "icon-hear_uncovert"
  | "icon-Keywords_uncover"
  | "iocn-Author_uncover"
  | "icon-Bookmarks_uncover"
  | "bulb"
  | "icon-Serve_uncover"
  | "Icon-Clock_cover"
  | "a-icon-ExitFullscreen-uncover"
  | "a-icon-Fullscreen-uncover"
  | "icon-Comfy"
  | "icon-compact"
  | "icon-government-uncover"
  | "a-icon-soundwave"
  | "a-Mictalk"
  | "Icon-Long_down"
  | "icon-list_check"
  | "arrows-pointing-out"
  | "arrows-pointing-in"
  | "show"
  | "icon-delete-uncover"
  | "icon-Watchlist"
  | "icon-Dashboard"
  | "icon-Discovery"
  | "a-icon-WatchlistAdd"
  | "a-icon-Success-uncover1"
  | "IconLongRight"
  | "home"
  | "a-icon_addAlert-uncover"
  | "a-icon_banAlert"
  | "Card"
  | "IconTriangleRight"
  | "IconThumbdown"
  | "IconTriangleLeft"
  | "Icon-Sort"
  | "IconDown"
  | "Icon-Clock_uncover"
  | "copy"
  | "IconSearch"
  | "icon-lock-uncover"
  | "icon-Alert_uncover"
  | "IconCalendar"
  | "IconThumbup-cover"
  | "IconFlash-uncover"
  | "IconTrendingUp"
  | "IconClose"
  | "IconCheck"
  | "IconSwitch"
  | "Icon-chat-uncover"
  | "IconDown-copy"
  | "Icon-info-cover"
  | "IconDoubleLeft"
  | "IconRefresh"
  | "IconRight"
  | "IconExit"
  | "IconSlideBack"
  | "IconPaper-airplane-cover"
  | "IconAttention"
  | "IconThumbdown-cover"
  | "IconMore"
  | "IconTriangleDown"
  | "IconClock"
  | "IconDoubleArrow"
  | "IcontUp"
  | "icon-Add-uncover"
  | "Icon-Attention-cover"
  | "IcontUp-copy"
  | "a-icon-Success-uncover"
  | "IconEdit"
  | "a-icon-addAlert_cover"
  | "Icon-information"
  | "IconThumbup"
  | "IconFilter"
  | "IconDoubleRight"
  | "IconBulb"
  | "Icon-maybe-uncover"
  | "a-Icon-maybebig-uncover"
  | "IconLink"
  | "Setting"
  | "IconSad"
  | "IconTriangleUp";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  name: IconNames;
  size?: number;
  color?: string | string[];
}

const IconFont: FunctionComponent<Props> = ({ name, ...rest }) => {
  switch (name) {
    case "newspaper":
      return <IconNewspaper {...rest} />;
    case "Research":
      return <IconResearch {...rest} />;
    case "history":
      return <IconHistory {...rest} />;
    case "share":
      return <IconShare {...rest} />;
    case "kol":
      return <IconKol {...rest} />;
    case "a-icon-newchat":
      return <IconAIconNewchat {...rest} />;
    case "bulb1":
      return <IconBulb1 {...rest} />;
    case "robot1":
      return <IconRobot1 {...rest} />;
    case "a-inaddnew-chat":
      return <IconAInaddnewChat {...rest} />;
    case "Calendar":
      return <IconCalendar {...rest} />;
    case "a-Addnewchat":
      return <IconAAddnewchat {...rest} />;
    case "robot":
      return <IconRobot {...rest} />;
    case "a-Searchsummary":
      return <IconASearchsummary {...rest} />;
    case "tldr":
      return <IconTldr {...rest} />;
    case "chart-line":
      return <IconChartLine {...rest} />;
    case "icon-telescope-uncover":
      return <IconIconTelescopeUncover {...rest} />;
    case "icon-twitter-uncover":
      return <IconIconTwitterUncover {...rest} />;
    case "icon-person-uncover1":
      return <IconIconPersonUncover1 {...rest} />;
    case "icon-rocket-cover":
      return <IconIconRocketCover {...rest} />;
    case "icon-lightning-cover":
      return <IconIconLightningCover {...rest} />;
    case "a-icon-sandclock-uncover":
      return <IconAIconSandclockUncover {...rest} />;
    case "icon-suspend-cover":
      return <IconIconSuspendCover {...rest} />;
    case "Copilot":
      return <IconCopilot {...rest} />;
    case "a-icon-Fourpointedstar-cover":
      return <IconAIconFourpointedstarCover {...rest} />;
    case "icon-sync-uncover":
      return <IconIconSyncUncover {...rest} />;
    case "icon-Alert_cover":
      return <IconIconAlertCover {...rest} />;
    case "icon-minus":
      return <IconIconMinus {...rest} />;
    case "icon-Admin":
      return <IconIconAdmin {...rest} />;
    case "a-Frame1312317937":
      return <IconAFrame1312317937 {...rest} />;
    case "a-Frame1312317938":
      return <IconAFrame1312317938 {...rest} />;
    case "a-icon-mousedown-uncover":
      return <IconAIconMousedownUncover {...rest} />;
    case "a-Frame1312317979":
      return <IconAFrame1312317979 {...rest} />;
    case "icon-Link-uncover":
      return <IconIconLinkUncover {...rest} />;
    case "icon-person-uncover":
      return <IconIconPersonUncover {...rest} />;
    case "icon-entity-uncover":
      return <IconIconEntityUncover {...rest} />;
    case "NFT":
      return <IconNft {...rest} />;
    case "headphones":
      return <IconHeadphones {...rest} />;
    case "icon-Stackedchart":
      return <IconIconStackedchart {...rest} />;
    case "heatmap":
      return <IconHeatmap {...rest} />;
    case "icon-copy-uncover":
      return <IconIconCopyUncover {...rest} />;
    case "icon-edit-uncover":
      return <IconIconEditUncover {...rest} />;
    case "icon-authentication-uncover":
      return <IconIconAuthenticationUncover {...rest} />;
    case "icon-crown-uncover":
      return <IconIconCrownUncover {...rest} />;
    case "icon-location-uncover":
      return <IconIconLocationUncover {...rest} />;
    case "a-icon-Tokenmindshare-uncover":
      return <IconAIconTokenmindshareUncover {...rest} />;
    case "icon-Feeds-uncover":
      return <IconIconFeedsUncover {...rest} />;
    case "a-Kaitologo":
      return <IconAKaitologo {...rest} />;
    case "a-icon-Marketsmartfollowing-uncover":
      return <IconAIconMarketsmartfollowingUncover {...rest} />;
    case "icon-track-uncover":
      return <IconIconTrackUncover {...rest} />;
    case "icon-refresh-uncover":
      return <IconIconRefreshUncover {...rest} />;
    case "a-icon-NarrativeMindshare-uncover":
      return <IconAIconNarrativeMindshareUncover {...rest} />;
    case "Icon-maybe-cover":
      return <IconIconMaybeCover {...rest} />;
    case "a-icon_searchlist_WhitePaper":
      return <IconAIconSearchlistWhitePaper {...rest} />;
    case "a-icon_searchlist_research":
      return <IconAIconSearchlistResearch {...rest} />;
    case "file-chart-News":
      return <IconFileChartNews {...rest} />;
    case "a-smart-optimization1-copy":
      return <IconASmartOptimization1Copy {...rest} />;
    case "a-icon_searchlist_conference":
      return <IconAIconSearchlistConference {...rest} />;
    case "a-icon_searchlist_discord":
      return <IconAIconSearchlistDiscord {...rest} />;
    case "a-icon_searchlist_research-2":
      return <IconAIconSearchlistResearch2 {...rest} />;
    case "a-icon_searchlist_podcast":
      return <IconAIconSearchlistPodcast {...rest} />;
    case "a-icon_searchlist_Twitter":
      return <IconAIconSearchlistTwitter {...rest} />;
    case "a-icon_searchlist_research-1":
      return <IconAIconSearchlistResearch1 {...rest} />;
    case "a-icon_searchlist_medium":
      return <IconAIconSearchlistMedium {...rest} />;
    case "a-icon_searchlist_mirror":
      return <IconAIconSearchlistMirror {...rest} />;
    case "a-icon_searchlist_dashboard":
      return <IconAIconSearchlistDashboard {...rest} />;
    case "a-icon_searchlist_theblock":
      return <IconAIconSearchlistTheblock {...rest} />;
    case "a-icon_searchlist_medium-1":
      return <IconAIconSearchlistMedium1 {...rest} />;
    case "a-icon-BarChart-cover1":
      return <IconAIconBarChartCover1 {...rest} />;
    case "a-huatitaolun_topic-discussion11":
      return <IconAHuatitaolunTopicDiscussion11 {...rest} />;
    case "a-icon-fullscreen_one-uncover":
      return <IconAIconFullscreenOneUncover {...rest} />;
    case "a-icon-exitfullscreen_one-uncover":
      return <IconAIconExitfullscreenOneUncover {...rest} />;
    case "icon-Share-uncover":
      return <IconIconShareUncover {...rest} />;
    case "icon-Snapshot_Official-cover":
      return <IconIconSnapshotOfficialCover {...rest} />;
    case "TLDR-Neutral":
      return <IconTldrNeutral {...rest} />;
    case "icon-lock-uncover1":
      return <IconIconLockUncover1 {...rest} />;
    case "a-icon-chart-uncover":
      return <IconAIconChartUncover {...rest} />;
    case "a-icon-BarChart-cover":
      return <IconAIconBarChartCover {...rest} />;
    case "icon_sentiment":
      return <IconIconSentiment {...rest} />;
    case "icon-hear_uncovert":
      return <IconIconHearUncovert {...rest} />;
    case "icon-Keywords_uncover":
      return <IconIconKeywordsUncover {...rest} />;
    case "iocn-Author_uncover":
      return <IconIocnAuthorUncover {...rest} />;
    case "icon-Bookmarks_uncover":
      return <IconIconBookmarksUncover {...rest} />;
    case "bulb":
      return <IconBulb {...rest} />;
    case "icon-Serve_uncover":
      return <IconIconServeUncover {...rest} />;
    case "Icon-Clock_cover":
      return <IconIconClockCover {...rest} />;
    case "a-icon-ExitFullscreen-uncover":
      return <IconAIconExitFullscreenUncover {...rest} />;
    case "a-icon-Fullscreen-uncover":
      return <IconAIconFullscreenUncover {...rest} />;
    case "icon-Comfy":
      return <IconIconComfy {...rest} />;
    case "icon-compact":
      return <IconIconCompact {...rest} />;
    case "icon-government-uncover":
      return <IconIconGovernmentUncover {...rest} />;
    case "a-icon-soundwave":
      return <IconAIconSoundwave {...rest} />;
    case "a-Mictalk":
      return <IconAMictalk {...rest} />;
    case "Icon-Long_down":
      return <IconIconLongDown {...rest} />;
    case "icon-list_check":
      return <IconIconListCheck {...rest} />;
    case "arrows-pointing-out":
      return <IconArrowsPointingOut {...rest} />;
    case "arrows-pointing-in":
      return <IconArrowsPointingIn {...rest} />;
    case "show":
      return <IconShow {...rest} />;
    case "icon-delete-uncover":
      return <IconIconDeleteUncover {...rest} />;
    case "icon-Watchlist":
      return <IconIconWatchlist {...rest} />;
    case "icon-Dashboard":
      return <IconIconDashboard {...rest} />;
    case "icon-Discovery":
      return <IconIconDiscovery {...rest} />;
    case "a-icon-WatchlistAdd":
      return <IconAIconWatchlistAdd {...rest} />;
    case "a-icon-Success-uncover1":
      return <IconAIconSuccessUncover1 {...rest} />;
    case "IconLongRight":
      return <IconIconLongRight {...rest} />;
    case "home":
      return <IconHome {...rest} />;
    case "a-icon_addAlert-uncover":
      return <IconAIconAddAlertUncover {...rest} />;
    case "a-icon_banAlert":
      return <IconAIconBanAlert {...rest} />;
    case "Card":
      return <IconCard {...rest} />;
    case "IconTriangleRight":
      return <IconIconTriangleRight {...rest} />;
    case "IconThumbdown":
      return <IconIconThumbdown {...rest} />;
    case "IconTriangleLeft":
      return <IconIconTriangleLeft {...rest} />;
    case "Icon-Sort":
      return <IconIconSort {...rest} />;
    case "IconDown":
      return <IconIconDown {...rest} />;
    case "Icon-Clock_uncover":
      return <IconIconClockUncover {...rest} />;
    case "copy":
      return <IconCopy {...rest} />;
    case "IconSearch":
      return <IconIconSearch {...rest} />;
    case "icon-lock-uncover":
      return <IconIconLockUncover {...rest} />;
    case "icon-Alert_uncover":
      return <IconIconAlertUncover {...rest} />;
    case "IconCalendar":
      return <IconIconCalendar {...rest} />;
    case "IconThumbup-cover":
      return <IconIconThumbupCover {...rest} />;
    case "IconFlash-uncover":
      return <IconIconFlashUncover {...rest} />;
    case "IconTrendingUp":
      return <IconIconTrendingUp {...rest} />;
    case "IconClose":
      return <IconIconClose {...rest} />;
    case "IconCheck":
      return <IconIconCheck {...rest} />;
    case "IconSwitch":
      return <IconIconSwitch {...rest} />;
    case "Icon-chat-uncover":
      return <IconIconChatUncover {...rest} />;
    case "IconDown-copy":
      return <IconIconDownCopy {...rest} />;
    case "Icon-info-cover":
      return <IconIconInfoCover {...rest} />;
    case "IconDoubleLeft":
      return <IconIconDoubleLeft {...rest} />;
    case "IconRefresh":
      return <IconIconRefresh {...rest} />;
    case "IconRight":
      return <IconIconRight {...rest} />;
    case "IconExit":
      return <IconIconExit {...rest} />;
    case "IconSlideBack":
      return <IconIconSlideBack {...rest} />;
    case "IconPaper-airplane-cover":
      return <IconIconPaperAirplaneCover {...rest} />;
    case "IconAttention":
      return <IconIconAttention {...rest} />;
    case "IconThumbdown-cover":
      return <IconIconThumbdownCover {...rest} />;
    case "IconMore":
      return <IconIconMore {...rest} />;
    case "IconTriangleDown":
      return <IconIconTriangleDown {...rest} />;
    case "IconClock":
      return <IconIconClock {...rest} />;
    case "IconDoubleArrow":
      return <IconIconDoubleArrow {...rest} />;
    case "IcontUp":
      return <IconIcontUp {...rest} />;
    case "icon-Add-uncover":
      return <IconIconAddUncover {...rest} />;
    case "Icon-Attention-cover":
      return <IconIconAttentionCover {...rest} />;
    case "IcontUp-copy":
      return <IconIcontUpCopy {...rest} />;
    case "a-icon-Success-uncover":
      return <IconAIconSuccessUncover {...rest} />;
    case "IconEdit":
      return <IconIconEdit {...rest} />;
    case "a-icon-addAlert_cover":
      return <IconAIconAddAlertCover {...rest} />;
    case "Icon-information":
      return <IconIconInformation {...rest} />;
    case "IconThumbup":
      return <IconIconThumbup {...rest} />;
    case "IconFilter":
      return <IconIconFilter {...rest} />;
    case "IconDoubleRight":
      return <IconIconDoubleRight {...rest} />;
    case "IconBulb":
      return <IconIconBulb {...rest} />;
    case "Icon-maybe-uncover":
      return <IconIconMaybeUncover {...rest} />;
    case "a-Icon-maybebig-uncover":
      return <IconAIconMaybebigUncover {...rest} />;
    case "IconLink":
      return <IconIconLink {...rest} />;
    case "Setting":
      return <IconSetting {...rest} />;
    case "IconSad":
      return <IconIconSad {...rest} />;
    case "IconTriangleUp":
      return <IconIconTriangleUp {...rest} />;
  }
};

export default IconFont;

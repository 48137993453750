export enum GuideTypes {
  REFERRAL_PROGRAM_INVITE = "REFERRAL_PROGRAM_INVITE",
  CATALYST_NEW_FEATURE = "CATALYST_NEW_FEATURE",
  BOOKMARKS = "BOOKMARKS",
  // TEST_FEATURE= "TEST_FEATURE6",
  TLDR_METHODOLOGY = "TLDR_METHODOLOGY",
  ADJUST_AND_WEIGHT = "ADJUST_AND_WEIGHT",
  COPILOT_HISTORY = "COPILOT_HISTORY",
  COPILOT_SHARE = "COPILOT_SHARE",
}

import { ReactElement } from "react";

import { OmitStringType, SourceName } from "@/types";

import {
  ConferenceIcon,
  DashboardIcon,
  DiscordIcon,
  GovernanceIcon,
  MediumIcon,
  MirrorIcon,
  NewsIcon,
  PodcastIcon,
  ResearchIcon,
  SnapshotIcon,
  TallyIcon,
  TelegramSourceIcon,
  TwitterIcon,
  TwitterSpaceIcon,
  WarpcastIcon,
  WhitepaperIcon,
} from "./Icon";

const dataSource2IconMap: Record<SourceName, ReactElement> = {
  Twitter: <TwitterIcon />,
  Discord: <DiscordIcon />,
  Podcast: <PodcastIcon />,
  Youtube: <ConferenceIcon />,
  Mirror: <MirrorIcon />,
  News: <NewsIcon />,
  Research: <ResearchIcon />,
  Forum: <GovernanceIcon />,
  DuneDashboard: <DashboardIcon />,
  Twitter_Space: <TwitterSpaceIcon />,
  Medium: <MediumIcon />,
  Whitepaper: <WhitepaperIcon />,
  SnapShot: <SnapshotIcon />,
  Telegram: <TelegramSourceIcon />,
  Tally: <TallyIcon />,
  Warpcast: <WarpcastIcon />,
  Newsletter: <></>,
};

type NewDataSource = OmitStringType<SourceName, "Newsletter">;

export const DataSourceIcon = ({ dataSource }: { dataSource: NewDataSource }) => {
  return dataSource2IconMap[dataSource] || null;
};

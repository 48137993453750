import type { Filter, RequestState, SortDirection } from "@metasearch-io/search-ui";
import { clone, countBy, forEach, intersection, isArray, transform } from "lodash";

import { filterToParams } from "@/components/Filter/utils";
import { getFilterValue, setFilterValue } from "@/components/Filter/utils";
import {
  ConferenceIcon,
  DashboardIcon,
  DiscordIcon,
  GovernanceIcon,
  MediumIcon,
  MirrorIcon,
  NewsIcon,
  PodcastIcon,
  ResearchIcon,
  SnapshotIcon,
  TallyIcon,
  TelegramSourceIcon,
  TwitterIcon,
  TwitterSpaceIcon,
  WarpcastIcon,
} from "@/components/Icon";
import { correctSortField, sortByFieldNameAccordingToIndex } from "@/components/Sort/utils";
import { indexCategory } from "@/constants";
import { searchQueryIdLocalStorageUtil } from "@/utils";

export const dataSource2Index = (data_source: any) => {
  if (data_source === "Twitter") {
    return "Twitter";
  } else if (data_source === "Discord") {
    return "Discord";
  } else if (data_source === "Podcast") {
    return "Podcast";
  } else if (data_source === "Youtube") {
    return "Conference";
  } else if (data_source === "Mirror") {
    return "Mirror";
  } else if (indexCategory.News.indexOf(data_source) > -1) {
    return "News";
  } else if (indexCategory.Research.indexOf(data_source) > -1) {
    return "Research";
  } else if (indexCategory.Governance.indexOf(data_source) > -1) {
    return "Governance";
  } else if (indexCategory.Dashboard.indexOf(data_source) > -1) {
    return "Dashboard";
  } else if (data_source === "Twitter_Space") {
    return "Twitter_Space";
  } else if (data_source === "Medium") {
    return "Medium";
  } else if (indexCategory.Vote.indexOf(data_source) > -1) {
    // newly
    return "Vote";
  }
  return null;
};

export const dataSource2Icon = (data_source: any) => {
  if (data_source === "Twitter") {
    return <TwitterIcon />;
  } else if (data_source === "Discord") {
    return <DiscordIcon />;
  } else if (data_source === "Podcast") {
    return <PodcastIcon />;
  } else if (data_source === "Youtube") {
    return <ConferenceIcon />;
  } else if (data_source === "Mirror") {
    return <MirrorIcon />;
  } else if (indexCategory.News.indexOf(data_source) > -1) {
    return <NewsIcon />;
  } else if (indexCategory.Research.indexOf(data_source) > -1) {
    return <ResearchIcon />;
  } else if (indexCategory.Governance.indexOf(data_source) > -1) {
    return <GovernanceIcon />;
  } else if (indexCategory.Dashboard.indexOf(data_source) > -1) {
    return <DashboardIcon />;
  } else if (data_source === "Twitter_Space") {
    return <TwitterSpaceIcon />;
  } else if (data_source === "Medium") {
    return <MediumIcon />;
  } else if (data_source === "snapshot_proposal") {
    return <SnapshotIcon />;
  } else if (data_source === "tally") {
    return <TallyIcon />;
  } else if (data_source === "telegram") {
    return <TelegramSourceIcon />;
  } else if (data_source === "farcaster") {
    return <WarpcastIcon />;
  }

  return <></>;
};

function buildSearchSort(sortField?: string, sortDirection?: SortDirection) {
  if (!sortField || !sortDirection) {
    return {};
  }

  return {
    sort_by: sortField,
    sort_order: sortDirection,
  };
}

const findIndexCategoryRealValue = (tab: string) => {
  return indexCategory[tab] ? indexCategory[tab][0] : tab;
};

export const covertTabToIndex = <T extends string | string[]>(tabs: T): T => {
  if (isArray(tabs)) {
    return tabs.map((tab) => findIndexCategoryRealValue(tab)) as T;
  }
  return findIndexCategoryRealValue(tabs) as unknown as T;
};

export const getRealIndexFromIndexAndTab = (
  indexValue: string[],
  currentTab: string | string[] = "all"
) => {
  if (currentTab !== "all") {
    return isArray(currentTab) ? intersection(indexValue, currentTab) : [currentTab];
  }
  return indexValue;
};

export function buildSearchFilter(filters?: Filter[], currentTab: string | string[] = "all") {
  if (!filters) return {};
  const [_, params] = filterToParams(filters, {});
  params["index"] = getRealIndexFromIndexAndTab(params["index"].split(","), currentTab);
  params["tab"] = currentTab;
  // not need official account when it is false
  if (params["twitter_is_from_official_account"] === "false") {
    params["twitter_is_from_official_account"] = undefined;
  }
  return params;
}

export function buildSearchParams({
  state,
  searchState,
}: {
  state: RequestState & {
    currentTab?: string;
  };
  searchState?: any;
}) {
  const pageSize = state.resultsPerPage ?? 20;
  const current = state.current ?? 1;

  /*   In order to detect in real time whether the current query field uses the input keyword or autocomplete,
   if autocomplete (topic) is stored, but the keyword has changed, clear it. */
  const nextFilters = clone(state.filters);
  const topicId = getFilterValue(nextFilters, "topic_id", "");
  if (topicId && isArray(topicId) && !!topicId.length && searchState) {
    if (topicId[1] !== searchState.searchTerm) {
      setFilterValue(nextFilters, "topic_id", "");
    }
  }
  const filters = buildSearchFilter(nextFilters, state.currentTab);
  searchQueryIdLocalStorageUtil.setValue(crypto.randomUUID());

  const indexFrom = {};
  //
  if (searchState && current !== 1) {
    forEach(
      countBy(searchState.results, (v) => v.subrequest),
      (value, key) => {
        indexFrom[key + "_from"] = value;
      }
    );
  }

  // TODO: should correctSortField outside @zilin
  const sortField = correctSortField(filters.index, state.sortField);
  const searchSort = buildSearchSort(
    sortByFieldNameAccordingToIndex(sortField, filters.index),
    state.sortDirection
  );
  return transform(
    {
      // if string is empty, don't send it
      enable_subrequest: true,
      query: state.searchTerm || undefined,
      ...filters,
      ...searchSort,
      from: pageSize * (current - 1),
      size: pageSize,
      query_id: searchQueryIdLocalStorageUtil.getValue(),
      ...indexFrom,
    },
    (result, value, key) => {
      if (isArray(value)) {
        result[key] = value.join();
      } else {
        result[key] = value;
      }
    }
  ) as any;
}

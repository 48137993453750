/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconIconPersonUncover1: FunctionComponent<Props> = ({
  size,
  color,
  style: _style,
  ...rest
}) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M405.333333 93.866667a183.466667 183.466667 0 1 0 0 366.933333 183.466667 183.466667 0 0 0 0-366.933333zM290.133333 277.333333a115.2 115.2 0 1 1 230.4 0 115.2 115.2 0 0 1-230.4 0z m388.010667-98.730666a115.114667 115.114667 0 0 1 55.850667 98.773333c0 41.856-22.314667 78.549333-55.893334 98.773333l35.242667 58.453334a183.381333 183.381333 0 0 0 88.917333-157.226667 183.381333 183.381333 0 0 0-88.917333-157.226667l-35.2 58.453334z m272.341333 496.469333a204.8 204.8 0 0 0-89.472-89.472l-31.018666 60.8a136.533333 136.533333 0 0 1 59.690666 59.690667c6.4 12.629333 10.496 28.714667 12.672 55.04 2.133333 26.752 2.218667 61.013333 2.218667 109.354666v25.6h68.266667v-27.136c0-46.506667 0-83.498667-2.474667-113.365333-2.474667-30.549333-7.68-56.618667-19.882667-80.512zM453.973333 563.242667h-96.853333c-46.506667 0-83.541333 0-113.365333 2.432-30.592 2.517333-56.661333 7.722667-80.512 19.882666a204.8 204.8 0 0 0-89.514667 89.514667c-12.16 23.893333-17.365333 49.92-19.882667 80.512-2.432 29.866667-2.432 66.858667-2.432 113.365333v27.093334a34.133333 34.133333 0 0 0 34.133334 34.133333h640a34.133333 34.133333 0 0 0 34.133333-34.133333v-27.093334c0-46.506667 0-83.541333-2.432-113.365333-2.517333-30.592-7.722667-56.661333-19.882667-80.512a204.8 204.8 0 0 0-89.514666-89.514667c-23.893333-12.16-49.92-17.365333-80.512-19.882666-29.866667-2.432-66.858667-2.432-113.365334-2.432z m-338.56 206.378666c2.133333-26.325333 6.229333-42.410667 12.672-55.04a136.533333 136.533333 0 0 1 57.472-58.581333l5.461334-2.688c12.16-5.546667 27.690667-9.130667 51.797333-11.093333C269.525333 640 303.744 640 352.085333 640h93.866667c48.341333 0 82.56 0 109.312 2.218667 26.325333 2.133333 42.410667 6.229333 55.04 12.672a136.533333 136.533333 0 0 1 59.733333 59.648c6.4 12.672 10.453333 28.757333 12.629334 55.082666V870.4H113.152c0-43.904 0.170667-75.648 2.218667-100.778667z"
        fill={getIconColor(color, 0, "currentColor")}
      />
    </svg>
  );
};

IconIconPersonUncover1.defaultProps = {
  size: 14,
};

export default IconIconPersonUncover1;

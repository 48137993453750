/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconClockUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 853.333333a341.333333 341.333333 0 1 1 341.333333-341.333333 341.717333 341.717333 0 0 1-341.333333 341.333333z m0-614.4a273.066667 273.066667 0 1 0 273.066667 273.066667A273.365333 273.365333 0 0 0 512 238.933333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M415.445333 656.810667l-48.256-48.256L463.744 512 367.189333 415.445333l48.256-48.256L512 463.744l96.554667-96.554667 48.256 48.256L560.256 512l96.554667 96.554667-48.256 48.256L512 560.256l-96.554667 96.554667z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconClockUncover.defaultProps = {
  size: 14,
};

export default IconIconClockUncover;

import { Flex, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";

import loggerClient from "@/common/loggerClient";

import OfficialSwitch from "./OfficialSwitch";
import {
  getRealBooleanValue,
  getSpecificTickerOfficialIds,
  isTeamOrCoreTeam,
  shouldUpdateSourceOfficialToggle,
} from "./utils";

const official_toggle_to_auto_complete_key = {
  twitter_is_from_official_account: {
    filter_keys: ["twitter_user_id"],
    official_data_key: ["twitter"],
    official_data_value_key: {
      twitter_user_id: [
        ["value", "twitter_user_id"],
        ["username", "twitter_user_username"],
        ["name", "twitter_user_name"],
        ["avatarUrl", "twitter_user_profile_image_url"],
      ],
    },
    auto_complete_filter_position: [11],
    officialDataGetPath: [["twitter", "twitter_user_id"]],
  },
  discord_user_official_group: {
    filter_keys: ["discord_server_id", "discord_user_official"],
    official_data_key: ["discord"],
    official_data_value_key: {
      discord_server_id: [
        ["value", "discord_server_id"],
        ["discord_server_icon_url", "discord_server_icon_url"],
        ["discord_server_name", "discord_server_name"],
        ["discord_server_id", "discord_server_id"],
      ],
    },
    auto_complete_filter_position: [2, 3],
    officialDataGetPath: [["discord", "discord_server_id"]],
  },
  medium_is_from_official_account: {
    filter_keys: ["medium_collection_id", "medium_author_id"],
    official_data_key: ["medium_collection", "medium_author"],
    official_data_value_key: {
      medium_author_id: [
        ["name", "medium_author_name"],
        ["avatarUrl", "medium_author_avatar_image_url"],
        ["value", "medium_author_id"],
      ],
      medium_collection_id: [
        ["name", "medium_collection_name"],
        ["avatarUrl", "medium_collection_avatar_image_url"],
        ["value", "medium_collection_id"],
      ],
    },
    auto_complete_filter_position: [1, 2],
    officialDataGetPath: [
      ["medium_collection", "medium_collection_id"],
      ["medium_author", "medium_author_id"],
    ],
  },
  twitter_space_is_from_official_account: {
    filter_keys: ["twitter_space_creator_user_id"],
    official_data_key: ["twitter_space"],
    official_data_value_key: {
      twitter_space_creator_user_id: [
        ["value", "twitter_user_id"],
        ["username", "twitter_user_username"],
        ["name", "twitter_user_name"],
        ["avatarUrl", "twitter_user_profile_image_url"],
      ],
    },
    auto_complete_filter_position: [1],
    officialDataGetPath: [["twitter_space", "twitter_user_id"]],
  },
  governance_official_source_group: {
    filter_keys: ["forum_data_source", "governance_official_source"],
    official_data_key: ["gov_forum"],
    official_data_value_key: {
      forum_data_source: [
        ["value", "gov_forum_name"],
        ["title", "gov_forum_title"],
        ["key", "gov_forum_name"],
        ["icon_url", "gov_forum_icon_url"],
      ],
    },
    auto_complete_filter_position: [1, 2],
    officialDataGetPath: [["gov_forum", "gov_forum_name"]],
  },
};

const RangeSourceOfficialFilter = (
  onChange,
  value,
  { filters, officialAccounts, field, allValue, handleValue, index, handleValues }
) => {
  const [canTriggerToggle, changeCanTriggerToggle] = useState(false);

  const isChecked = useMemo(() => {
    // get value from default value or trigger by toggle
    return getRealBooleanValue(value);
  }, [value]);

  useEffect(() => {
    let res = isChecked;
    let newCanTriggleToggle = canTriggerToggle;
    const allTickersOfficialIdsInThisSource = getSpecificTickerOfficialIds(
      officialAccounts,
      official_toggle_to_auto_complete_key[field].officialDataGetPath || []
    );

    switch (field) {
      case "twitter_is_from_official_account": {
        allTickersOfficialIdsInThisSource.forEach((value, index) => {
          const selectedAutoCompleteIdsMatchTickerNeed = shouldUpdateSourceOfficialToggle(
            value,
            allValue,
            official_toggle_to_auto_complete_key[field].auto_complete_filter_position[index]
          );

          if (selectedAutoCompleteIdsMatchTickerNeed !== res) {
            res = selectedAutoCompleteIdsMatchTickerNeed;
          }
          newCanTriggleToggle = value.length > 0; // as long as one auto_complete field have values of officialData, officialToggle can trigger
        });
        break;
      }
      case "discord_user_official_group": {
        allTickersOfficialIdsInThisSource.forEach((value, index) => {
          const selectedAutoCompleteIdsMatchTickerNeed = shouldUpdateSourceOfficialToggle(
            value,
            allValue,
            official_toggle_to_auto_complete_key[field].auto_complete_filter_position[index]
          );
          if (selectedAutoCompleteIdsMatchTickerNeed !== res) {
            res = selectedAutoCompleteIdsMatchTickerNeed;
          }

          newCanTriggleToggle = value.length > 0; // as long as one auto_complete field have values of officialData, officialToggle can trigger
        });

        // discord source has a specific toggle to control the user should be official
        res =
          res &&
          isTeamOrCoreTeam(
            allValue[official_toggle_to_auto_complete_key[field].auto_complete_filter_position[1]]
          );
        break;
      }
      case "governance_official_source_group": {
        allTickersOfficialIdsInThisSource.forEach((value, index) => {
          const selectedAutoCompleteIdsMatchTickerNeed = shouldUpdateSourceOfficialToggle(
            value,
            allValue,
            official_toggle_to_auto_complete_key[field].auto_complete_filter_position[index]
          );
          if (selectedAutoCompleteIdsMatchTickerNeed !== res) {
            res = selectedAutoCompleteIdsMatchTickerNeed;
          }

          newCanTriggleToggle = value.length > 0; // as long as one auto_complete field have values of officialData, officialToggle can trigger
        });

        // governance source has a specific toggle to control the user should be official
        res =
          res &&
          isTeamOrCoreTeam(
            allValue[official_toggle_to_auto_complete_key[field].auto_complete_filter_position[1]]
          );

        break;
      }
      case "medium_is_from_official_account": {
        allTickersOfficialIdsInThisSource.forEach((value, index) => {
          const selectedAutoCompleteIdsMatchTickerNeed = shouldUpdateSourceOfficialToggle(
            value,
            allValue,
            official_toggle_to_auto_complete_key[field].auto_complete_filter_position[index]
          );
          if (selectedAutoCompleteIdsMatchTickerNeed !== res) {
            res = selectedAutoCompleteIdsMatchTickerNeed;
          }
          newCanTriggleToggle = newCanTriggleToggle || value.length > 0; // as long as one auto_complete field have values of officialData, officialToggle can trigger
        });

        break;
      }
      case "twitter_space_is_from_official_account": {
        allTickersOfficialIdsInThisSource.forEach((value, index) => {
          const selectedAutoCompleteIdsMatchTickerNeed = shouldUpdateSourceOfficialToggle(
            value,
            allValue,
            official_toggle_to_auto_complete_key[field].auto_complete_filter_position[index]
          );
          if (selectedAutoCompleteIdsMatchTickerNeed !== res) {
            res = selectedAutoCompleteIdsMatchTickerNeed;
          }
          newCanTriggleToggle = value.length > 0; // as long as one auto_complete field have values of officialData, officialToggle can trigger
        });

        break;
      }
      default:
        break;
    }

    if (newCanTriggleToggle !== canTriggerToggle) {
      changeCanTriggerToggle(newCanTriggleToggle);
    }

    if (getRealBooleanValue(res) !== getRealBooleanValue(isChecked)) {
      handleValue(index, res);
    }
  }, [officialAccounts, allValue, field, handleValue, index, isChecked, canTriggerToggle]);

  const changeFieldOfficialToggle = useCallback(
    (checked: boolean) => {
      const newValues = [];
      loggerClient.logEvent({
        type: "click",
        params: {
          name: "toggleOfficialButton",
          value: checked,
          field,
        },
      });
      // if open the official toogle for current source, auto fill the corresponding official data for the auto complete dropmenu
      official_toggle_to_auto_complete_key[field].filter_keys.forEach((key, index) => {
        if (!checked) {
          // for specific discord official_user toggle, set it to false insteat of a empty array
          if (
            ["discord_user_official_group", "governance_official_source_group"].includes(field) &&
            index === 1
          ) {
            newValues.push({
              index:
                official_toggle_to_auto_complete_key[field].auto_complete_filter_position[index],
              value: "all",
            });
          } else {
            newValues.push({
              index:
                official_toggle_to_auto_complete_key[field].auto_complete_filter_position[index],
              value: [],
            });
          }
        } else {
          if (
            ["discord_user_official_group", "governance_official_source_group"].includes(field) &&
            index === 1
          ) {
            newValues.push({
              index:
                official_toggle_to_auto_complete_key[field].auto_complete_filter_position[index],
              value: "team",
            });
          } else {
            const values = officialAccounts[
              official_toggle_to_auto_complete_key[field].official_data_key[index]
            ]?.map((item) => {
              return official_toggle_to_auto_complete_key[field].official_data_value_key[
                key
              ].reduce((total, curr) => {
                return {
                  ...total,
                  [curr[0]]: item[curr[1]],
                };
              }, {});
            });

            if (values) {
              newValues.push({
                index:
                  official_toggle_to_auto_complete_key[field].auto_complete_filter_position[index],
                value: values,
              });
            }
          }
        }
      });
      handleValues(newValues);
    },
    [field, handleValues, officialAccounts]
  );

  return (
    <Flex flexDirection={"column"} w="100%">
      <Flex justifyContent={"space-between"}>
        <Text fontSize="14px" color={"whiteAlpha.300"}>
          One-Step Official
        </Text>
        <OfficialSwitch
          onChange={changeFieldOfficialToggle}
          isChecked={isChecked}
          tickerSelected={
            canTriggerToggle ||
            ["discord_user_official", "governance_official_source"].includes(field)
          }
        />
      </Flex>
    </Flex>
  );
};

export default RangeSourceOfficialFilter;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconThumbdown: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M330.965333 601.472l107.605334 213.034667c13.312 28.842667 42.24 47.36 74.069333 47.36a129.066667 129.066667 0 0 0 126.848-152.405334l-14.933333-71.808h120.106666a131.669333 131.669333 0 0 0 129.28-156.629333l-39.168-202.837333a131.669333 131.669333 0 0 0-129.28-106.666667H228.906667c-45.781333 0-82.858667 37.12-82.858667 82.858667v264.192c0 45.781333 37.12 82.901333 82.901333 82.901333h102.016z m181.674667 192.128a13.312 13.312 0 0 1-12.16-7.850667 32 32 0 0 0-0.682667-1.408L386.133333 559.189333V239.786667h319.402667c30.378667 0 56.490667 21.546667 62.250667 51.370666l39.168 202.794667a63.402667 63.402667 0 0 1-62.250667 75.434667h-162.133333a34.133333 34.133333 0 0 0-33.408 41.088l23.466666 112.341333a60.757333 60.757333 0 0 1-59.946666 70.826667zM317.824 239.786667v293.418666H228.949333a14.634667 14.634667 0 0 1-14.634666-14.634666V254.378667c0-8.064 6.528-14.592 14.634666-14.592h88.874667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconThumbdown.defaultProps = {
  size: 14,
};

export default IconIconThumbdown;

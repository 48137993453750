/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconBulb: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M624 874.666667h-224v-74.666667h224v74.666667z m0-112h-224l-8.32-74.666667a315.818667 315.818667 0 0 0-22.058667-71.68c-13.226667-18.688-27.008-34.645333-40.32-50.218667a248.874667 248.874667 0 0 1-78.634666-176.768 261.333333 261.333333 0 1 1 522.666666 0 249.856 249.856 0 0 1-78.122666 175.872l-0.64 0.768c-13.312 15.530667-27.050667 31.658667-40.021334 50.176a321.237333 321.237333 0 0 0-22.101333 71.850667l-8.448 74.666667zM512 202.666667a186.88 186.88 0 0 0-186.666667 186.666666c0 57.642667 24.021333 85.589333 60.416 128 13.781333 16.085333 29.44 34.346667 44.8 55.637334 18.56 36.096 31.232 74.922667 37.546667 115.029333h87.808c6.485333-39.978667 19.157333-78.72 37.546667-114.773333 14.933333-21.333333 30.592-39.68 44.373333-55.808l0.554667-0.682667c36.266667-42.538667 60.288-70.656 60.288-127.402667A186.88 186.88 0 0 0 512 202.666667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconBulb.defaultProps = {
  size: 14,
};

export default IconIconBulb;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconKeywordsUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M674.133333 170.666667v179.2h65.408a34.133333 34.133333 0 0 1 34.133334 34.133333v104.96H853.333333a34.133333 34.133333 0 0 1 34.133334 34.133333V853.333333a34.133333 34.133333 0 0 1-34.133334 34.133334H170.666667a34.133333 34.133333 0 0 1-34.133334-34.133334v-330.24a34.133333 34.133333 0 0 1 34.133334-34.133333h79.658666V384a34.133333 34.133333 0 0 1 34.133334-34.133333H349.866667V236.288a34.133333 34.133333 0 0 1 25.642666-33.066667l256-65.621333A34.133333 34.133333 0 0 1 674.133333 170.666667z m-68.266666 179.2V214.656L418.133333 262.784V349.866667h187.733334zM318.592 418.133333v104.96a34.133333 34.133333 0 0 1-34.133333 34.133334H204.8v261.973333h614.4v-261.973333h-79.658667a34.133333 34.133333 0 0 1-34.133333-34.133334V418.133333H318.592z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconKeywordsUncover.defaultProps = {
  size: 14,
};

export default IconIconKeywordsUncover;

const tooltip = {
  baseStyle: {
    bg: "#2A3242",
    border: "1px solid",
    borderColor: "grayBlue.200",
    color: "white",
    borderRadius: "4px",
    minW: "72px",
    maxW: "368px",
    p: "8px 16px",
  },
};
export default tooltip;

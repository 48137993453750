import { match } from "path-to-regexp";

import { AxiosInterceptor } from "./types";

// if 401 will not redirect to /auth
const white_list = ["/auth/entry", "/events/:path*"];

const white_list_matcher = white_list.map((path) => match(path));

export const notAuth: AxiosInterceptor = (client) => {
  const when401 = () => {
    if (white_list_matcher.some((urlMatch) => urlMatch(location.pathname))) return;

    const url = new URL(window.location.href);
    location.href = `/auth/entry?callbackUrl=${encodeURIComponent(url.pathname + url.search)}`;
  };

  client.interceptors.response.use(
    function (response) {
      // If the response is successful, just return it
      return response;
    },
    function (error) {
      // If the response has a status of 401, redirect to the login page
      if (error.response && error.response.status === 401) {
        when401();
      }

      // portal internal api error
      if (error.response && error.response.status === 500 && error.response.data?.errMsg) {
        // e.g: Request failed with status code 401
        if (error.response.data.errMsg.includes?.("401")) {
          when401();
        }
      }

      // Otherwise, throw the error so it can be caught by a .catch block
      return Promise.reject(error);
    }
  );
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSoundwave: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M270.634667 210.346667l-30.165334 30.165333a384 384 0 0 0 0 543.061333l30.165334 30.165334-60.373334 60.330666-30.165333-30.165333c-183.253333-183.296-183.253333-480.426667 0-663.722667l30.165333-30.208L270.634667 210.346667zM813.696 149.973333l30.165333 30.208c183.296 183.253333 183.296 480.426667 0 663.722667l-30.165333 30.165333-60.330667-60.330666 30.165334-30.165334a384 384 0 0 0 0-543.061333l-30.165334-30.165333 60.330667-60.330667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M391.296 331.008l-30.165333 30.165333a213.333333 213.333333 0 0 0 0 301.696l30.165333 30.165334-60.330667 60.330666-30.165333-30.165333a298.666667 298.666667 0 0 1 0-422.4l30.165333-30.122667 60.330667 60.330667zM692.992 270.677333l30.165333 30.165334a298.666667 298.666667 0 0 1 0 422.4l-30.165333 30.122666-60.330667-60.330666 30.165334-30.165334a213.333333 213.333333 0 0 0 0-301.696l-30.165334-30.165333 60.330667-60.330667z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
      <path
        d="M384 512a128 128 0 1 1 256 0 128 128 0 0 1-256 0z m128-42.666667a42.666667 42.666667 0 1 0 0 85.333334 42.666667 42.666667 0 0 0 0-85.333334z"
        fill={getIconColor(color, 2, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSoundwave.defaultProps = {
  size: 14,
};

export default IconAIconSoundwave;

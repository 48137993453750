/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconSearch: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M729.856 648.234667A298.709333 298.709333 0 0 0 279.466667 258.133333a298.666667 298.666667 0 0 0 390.058666 450.432l171.093334 171.093334 60.330666-60.373334-171.093333-171.093333z m-88.32-329.770667a213.333333 213.333333 0 1 1-301.696 301.696 213.333333 213.333333 0 0 1 301.653333-301.696z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconSearch.defaultProps = {
  size: 14,
};

export default IconIconSearch;

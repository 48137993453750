/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconFullscreenOneUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M704 85.333333a42.666667 42.666667 0 1 0 0 85.333334H853.333333v149.333333a42.666667 42.666667 0 1 0 85.333334 0V128a42.666667 42.666667 0 0 0-42.666667-42.666667h-192zM938.666667 704a42.666667 42.666667 0 1 0-85.333334 0V853.333333h-149.333333a42.666667 42.666667 0 1 0 0 85.333334H896a42.666667 42.666667 0 0 0 42.666667-42.666667v-192zM128 661.333333a42.666667 42.666667 0 0 1 42.666667 42.666667V853.333333h149.333333a42.666667 42.666667 0 1 1 0 85.333334H128a42.666667 42.666667 0 0 1-42.666667-42.666667v-192a42.666667 42.666667 0 0 1 42.666667-42.666667zM128 85.333333a42.666667 42.666667 0 0 0-42.666667 42.666667v192a42.666667 42.666667 0 0 0 85.333334 0V170.666667h149.333333a42.666667 42.666667 0 0 0 0-85.333334H128z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconFullscreenOneUncover.defaultProps = {
  size: 14,
};

export default IconAIconFullscreenOneUncover;

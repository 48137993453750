import { InfoOutlineIcon } from "@chakra-ui/icons";
import { chakra, Divider, Flex, Stack, Text, useOutsideClick } from "@chakra-ui/react";
import { useCombobox, useMultipleSelection } from "downshift";
import { useAtomValue } from "jotai";
import { clamp, debounce, get, isArray } from "lodash";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import SimpleBar from "simplebar-react";

import loggerClient from "@/common/loggerClient";
import { TwitterUserMultiSelectName } from "@/components/Filter/SourceFilter/multiselectConstants";
import { valueType } from "@/components/Filter/SourceFilter/twitter_space_by_user/SelectedItemRender";
import IconFont from "@/components/Icon/iconfont";
import { SelectCheckBox, SelectRadioType } from "@/components/MultiSelect/SelectCheckBox";
import { smartFollowingInfoAtom } from "@/store/smartFollowing";
import { RequestSequencer } from "@/utils";

import { ArrowDown, ClearIcon } from "../Icon";
import { MultiSelectProps, Option } from "./MultiSelectTypes";

function MultiSelect<T extends Option>({
  options = [],
  value,
  getRemoteOptions,
  onChange,
  DropMenuItem,
  RenderSelectedItem,
  RenderNoResult,
  placeholder = "",
  upToSelected = 10,
  allowFetchWhenInputIsEmpty,
  showArrowIcon,
  name,
  source,
  allowCustomNoResult = false,
  inputPrefix,
}: MultiSelectProps<T>) {
  const [items, setItems] = useState<Option[]>(options);
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>();
  const containerRef = useRef<HTMLDivElement>();
  const dropMenuRef = useRef<HTMLDivElement>();
  const valueRef = useRef({ options });
  const [searchType, setSearchType] = useState<string>(SelectRadioType.From);
  const smartFollowingState = useAtomValue(smartFollowingInfoAtom);

  useEffect(() => {
    if (value.length >= 1) {
      if (!value[0]?.type) {
        onChange(value.map((v) => ({ ...v, type: searchType })));
      } else {
        setSearchType(value[0]?.type);
      }
    }
  }, [value, onChange, searchType]);

  const onMultiSelectChange = (t: string) => {
    if (value?.length > 0) {
      onChange(value.map((v) => ({ ...v, type: t })));
    }
    setSearchType(t);
  };

  const requestSequencer = useMemo(() => {
    return new RequestSequencer();
  }, []);

  const fetchSuggestion = useMemo(() => {
    if (!getRemoteOptions) return null;
    return debounce(async (inputValue) => {
      if (!inputValue && name === TwitterUserMultiSelectName) {
        setItems(buildTwitterItems([], smartFollowingState, name, inputValue));
        return;
      }
      if (!inputValue && !allowFetchWhenInputIsEmpty) {
        setItems([]);
        return;
      }

      const requestId = requestSequencer.next();
      const data = await getRemoteOptions(inputValue);
      const timer = setTimeout(() => {
        setLoading(false);
        clearTimeout(timer);
      });
      if (!requestSequencer.isOldRequest(requestId)) {
        setItems(buildTwitterItems(data, smartFollowingState, name, inputValue) || []);
        setTimeout(() => {
          // scroll the dropment list to top
          dropMenuRef?.current?.scrollIntoView();
        }, 100);
      }
    }, 300);
  }, [getRemoteOptions, requestSequencer, allowFetchWhenInputIsEmpty, smartFollowingState]);

  const dropMenuItems = useMemo(() => {
    const selectedValues = value.map((v) => v.value);
    return items.filter((item) => selectedValues.indexOf(item.value) === -1);
  }, [items, value]);

  const {
    inputValue,
    setInputValue,
    isOpen,
    selectItem,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
  } = useCombobox({
    initialInputValue: "",
    items: dropMenuItems,
    itemToString: (item) => (item ? item.value : ""),
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: "",
            isOpen: true,
            highlightedIndex: -1,
          };
        case useCombobox.stateChangeTypes.FunctionOpenMenu:
          inputRef.current.focus();
          return {
            ...changes,
            isOpen: true,
          };
        default:
          return changes;
      }
    },
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          loggerClient.logEvent({
            type: "click",
            params: {
              name: "autoComplete" + name + "selectItem",
              selectItem: newSelectedItem,
            },
          });
          if (newSelectedItem) {
            setTimeout(() => {
              inputRef.current.focus();
              inputRef.current.scrollIntoView({
                behavior: "smooth",
              });
            }, 100);

            if (selectedItems.length >= upToSelected) {
              return;
            }
            onChange([...value, newSelectedItem as T]);
            selectItem(null);
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue);
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    if (fetchSuggestion) {
      fetchSuggestion(inputValue);
    } else {
      const data = valueRef.current.options.filter((item: Option) =>
        item.value.includes(inputValue)
      );
      setItems(data);
      const timer = setTimeout(() => setLoading(false));
      return () => clearTimeout(timer);
    }
  }, [inputValue, fetchSuggestion, valueRef]);

  const { getDropdownProps, selectedItems, removeSelectedItem, activeIndex, setActiveIndex } =
    useMultipleSelection({
      selectedItems: value,
      onStateChange({ selectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            loggerClient.logEvent({
              type: "click",
              params: {
                name: "autoComplete" + name + "removeItem",
              },
            });
            onChange(selectedItems);
            break;
          default:
            break;
        }
      },
    });

  const handleKeyDown = (e) => {
    if (!inputValue && value.length !== 0) {
      let newIndex = -1;
      if (e.key === "ArrowLeft") {
        newIndex =
          activeIndex === -1 ? value.length - 1 : clamp(activeIndex - 1, 0, value.length - 1);
      } else if (e.key === "ArrowRight") {
        newIndex = activeIndex === -1 ? 0 : clamp(activeIndex + 1, 0, value.length - 1);
      } else if (e.key === "Backspace") {
        if (activeIndex > -1) {
          removeSelectedItem(value[activeIndex]);
          return;
        } else {
          newIndex = value.length - 1;
        }
      }

      setActiveIndex(newIndex);
    }
  };

  const renderSelectedItems = (): React.ReactNode => {
    return selectedItems.map((v, index) => {
      return (
        <Flex
          key={v.value + index}
          height="24px"
          alignItems="center"
          fontSize="14px"
          borderRadius="4px"
          fontWeight="medium"
          maxW="100%"
        >
          {v?._type ? (
            <CircleSelectItem
              value={v as any}
              removeSelectedItem={removeSelectedItem}
              isActivated={activeIndex === index}
            />
          ) : (
            <RenderSelectedItem
              value={v}
              removeSelectedItem={removeSelectedItem}
              isActivated={activeIndex === index}
            />
          )}
        </Flex>
      );
    });
  };

  useOutsideClick({
    enabled: true,
    ref: containerRef,
    handler: () => {
      closeMenu();
      setInputValue("");
    },
  });

  const renderSmartFollowingCircle = () => {
    if (name !== TwitterUserMultiSelectName) return null;
    const filteredItems = dropMenuItems.filter((item) => item?._type);
    if (filteredItems.length === 0) return null;
    return (
      <div className="flex flex-col gap-1">
        <div className="text-xs text-white/60 px-2 py-[1px]">Circles</div>
        {filteredItems.map((item, index) => (
          <Flex
            key={item.value}
            alignItems="center"
            {...getItemProps({ item, index })}
            h="28px"
            lineHeight="20px"
            cursor="pointer"
            _hover={{
              bg: "rgba(255, 255, 255, 0.04)",
              borderRadius: "2px",
            }}
          >
            <CircleDropItem {...(item as any)} />
          </Flex>
        ))}
        {inputValue && <div className="text-xs text-white/60 px-2 py-[1px]">Users</div>}
      </div>
    );
  };

  const popoverContent = (): React.ReactNode => {
    if (value.length >= upToSelected) {
      return (
        <Flex
          color="rgba(255, 255, 255, 0.4)"
          flexFlow="flex-start"
          height="24px"
          p="6.5px"
          alignItems="center"
        >
          <InfoOutlineIcon mr="6px" boxSize="11px" />
          <Text fontSize="12px" color="whiteAlpha.200" textAlign="center">
            You can select a maximum of {upToSelected}
          </Text>
        </Flex>
      );
    }
    if (dropMenuItems.length === 0) {
      if (loading) {
        return null;
      }

      if (RenderNoResult) {
        return <RenderNoResult />;
      }
      return <Text>No Result</Text>;
    }
    return (
      <>
        <Stack className="my-2" ref={dropMenuRef}>
          {renderSmartFollowingCircle()}
          {dropMenuItems.map((item, index) => {
            if (item?._type) return null;
            return (
              <Flex
                key={item.value}
                alignItems="center"
                {...getItemProps({ item, index })}
                h="28px"
                bg={highlightedIndex === index ? "rgba(255, 255, 255, 0.04)" : ""}
                lineHeight="20px"
                cursor="pointer"
                _hover={{
                  bg: "rgba(255, 255, 255, 0.04)",
                }}
              >
                <DropMenuItem {...(item as any)} />
              </Flex>
            );
          })}
        </Stack>
      </>
    );
  };

  getDropdownProps({}, { suppressRefError: true });
  const isShowPopover = () => {
    if (allowFetchWhenInputIsEmpty) {
      return isOpen;
    }
    return isOpen && inputValue && !loading;
  };
  return (
    <Flex
      flexFlow="cloumn"
      flexDirection="column"
      maxWidth="100%"
      position="relative"
      width="100%"
      bg="#262A30"
      borderRadius="4px"
      ref={containerRef}
      id="excludeContainer"
    >
      <Flex
        onClick={isOpen ? undefined : openMenu}
        minWidth="80px"
        borderRadius={isShowPopover() ? "4px 4px 0 0" : "4px"}
        position="relative"
        width="100%"
        borderColor="grayBlue.500"
        flexDirection="column"
        p="4px 8px"
        overflow="hidden"
        border={
          isOpen ? "0.5px solid rgba(255, 255, 255, 0.1)" : "0.5px solid rgba(255, 255, 255, 0)"
        }
        borderBottom={isShowPopover() && "0px"}
      >
        {/**
         * UI don't want it scroll in popover
         * TODO optimize the maxHeight, let it be one of props
         */}
        <SimpleBar style={{ maxHeight: "90px" }}>
          <Flex
            gap="4px"
            alignItems="center"
            justifyContent="flex-start"
            flexFlow="wrap"
            flex="1"
            minW="0"
            pr={showArrowIcon ? "16px" : "0px"}
          >
            <SelectCheckBox
              value={searchType}
              onChange={onMultiSelectChange}
              length={value.length}
            />
            {value.length > 0 || isOpen ? (
              renderSelectedItems()
            ) : (
              <Text color="whiteAlpha.200" lineHeight="20px" fontSize="14px" marginLeft="8px">
                {placeholder}
              </Text>
            )}
            {showArrowIcon ? (
              <ArrowDown
                position="absolute"
                right="0"
                top="4px"
                width="16px"
                height="16px"
                color="whiteAlpha.300"
                transform={isOpen ? "rotate(180deg)" : ""}
                onClick={isOpen ? closeMenu : () => {}}
                cursor="pointer"
              />
            ) : null}

            <Flex
              maxW="100%"
              display="inline-flex"
              flex="1"
              position="relative"
              alignItems="center"
            >
              <Text visibility="hidden" minW={isOpen ? "70px" : "0px"} maxW="300px" height="24px">
                {isOpen ? inputValue : ""}
              </Text>
              <chakra.input
                bg="transparent"
                border="none"
                outline="none"
                fontWeight="medium"
                width={isOpen ? "100%" : "0px"}
                fontSize="14px"
                position="absolute"
                onFocus={() => {
                  setTimeout(() => {
                    inputRef.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }, 100);
                }}
                {...getInputProps({
                  ref: inputRef,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    setLoading(true);
                    setInputValue(e.target.value);
                  },
                  onKeyDown: handleKeyDown,
                  value: inputValue,
                })}
              />
            </Flex>
          </Flex>
        </SimpleBar>
        {isShowPopover() ? <Divider mt="4px" /> : null}
      </Flex>

      <div {...getMenuProps()} style={{ maxHeight: "300px", position: "relative" }}>
        <Flex
          borderRadius="0px 0px 4px 4px"
          left="0px"
          top="0px"
          zIndex={100}
          position={"absolute"}
          flexFlow="column"
          backgroundColor="#262A30"
          display={isShowPopover() ? "flex" : "none"}
          width="100%"
          maxHeight="233px"
          overflow="auto"
          gap="6px"
          p="8px"
          pt="0"
          pb="0"
          border={isOpen ? "0.5px solid rgba(255, 255, 255, 0.1)" : ""}
          borderTop={isShowPopover() && "0px"}
        >
          {popoverContent()}
        </Flex>
      </div>
    </Flex>
  );
}

const CircleDropItem = ({ username, value }) => {
  const length = value.split(",").length;
  return (
    <div className="flex p-2 gap-1.5 text-white">
      <div
        className="w-5 h-5 p-1 flex items-center justify-center rounded-full"
        style={{
          background:
            "linear-gradient(135deg, rgba(50, 255, 220, 0.24) 4.17%, rgba(52, 133, 255, 0.40) 95.83%)",
        }}
      >
        <IconFont name="icon-person-uncover1" size={12} />
      </div>
      <span className="text-sm">
        {username} ({length})
      </span>
    </div>
  );
};

const CircleSelectItem = ({
  value,
  removeSelectedItem,
  isActivated,
}: {
  value: valueType;
  removeSelectedItem: any;
  isActivated: boolean;
}) => {
  return (
    <div className="flex items-center gap-1 px-2 py-0.5 bg-white/10  rounded">
      <div
        className="w-5 h-5 p-1 flex items-center justify-center rounded-full"
        style={{
          background:
            "linear-gradient(135deg, rgba(50, 255, 220, 0.24) 4.17%, rgba(52, 133, 255, 0.40) 95.83%)",
        }}
      >
        <IconFont name="icon-person-uncover1" size={12} />
      </div>
      <div className="text-xs">{value.username}</div>
      <ClearIcon
        onClick={(e: any) => {
          e.stopPropagation();
          removeSelectedItem(value);
        }}
        width="12px"
        height="12px"
        flex="none"
        cursor="pointer"
        color="whiteAlpha.200"
      />
    </div>
  );
};

function buildTwitterItems(data, smartFollowingState, name, inputValue = "") {
  if (name !== TwitterUserMultiSelectName) return data;
  const circles = get(smartFollowingState, "sortedCustomSmartFollowingCircle", []);
  const dropItems = circles.map((item) => ({
    id: item[1].followingIdList.join(","),
    username: item[0],
    value: item[1].followingIdList.join(","),
    _type: "circle",
  }));
  const filteredItems = dropItems.filter((item) => item.username.includes(inputValue));
  if (isArray(data)) {
    return [...filteredItems, ...data];
  }
}

export default MultiSelect;

import axios from "axios";
import { uniqBy } from "lodash";
import {
  generateRenderFilterField,
  SelectOption,
} from "../podcast_by_name/generateMultiSelectFilterField";
import {
  ResearchSourceMultiSelectName,
  ResearchSourceMultiSelectSource,
} from "@/components/Filter/SourceFilter/multiselectConstants";

const getRemoteOptions = async (prefix: string) => {
  const {
    data: { data },
  } = await axios.get("/api/gateway/auto_complete", {
    params: {
      prefix,
      source: "research",
    },
  });

  return uniqBy(
    data?.map((v) => ({
      title: v.research_title,
      icon_url: v.research_icon_url,
      value: v.value,
      key: v.value,
    })) as SelectOption[],
    "value"
  );
};

const renderFilterField = generateRenderFilterField({
  placeholder: "Research Source",
  noResultTip: "No research source found",
  getRemoteOptions,
  name: ResearchSourceMultiSelectName,
  source: ResearchSourceMultiSelectSource,
});
export default renderFilterField;

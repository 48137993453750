/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconListCheck: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M328.832 179.498667a42.666667 42.666667 0 0 0-60.330667-60.330667L170.666667 217.002667l-33.834667-33.834667a42.666667 42.666667 0 0 0-60.330667 60.330667l64 64a42.666667 42.666667 0 0 0 60.330667 0l128-128zM328.832 478.165333a42.666667 42.666667 0 0 0-60.330667-60.330666L170.666667 515.669333l-33.834667-33.834666a42.666667 42.666667 0 0 0-60.330667 60.330666l64 64a42.666667 42.666667 0 0 0 60.330667 0l128-128zM328.832 716.501333a42.666667 42.666667 0 0 1 0 60.330667l-128 128a42.666667 42.666667 0 0 1-60.330667 0l-64-64a42.666667 42.666667 0 1 1 60.330667-60.330667l33.834667 33.834667 97.834666-97.834667a42.666667 42.666667 0 0 1 60.330667 0zM448 469.333333a42.666667 42.666667 0 1 0 0 85.333334h469.333333a42.666667 42.666667 0 1 0 0-85.333334h-469.333333zM405.333333 810.666667a42.666667 42.666667 0 0 1 42.666667-42.666667h469.333333a42.666667 42.666667 0 1 1 0 85.333333h-469.333333a42.666667 42.666667 0 0 1-42.666667-42.666666zM448 170.666667a42.666667 42.666667 0 1 0 0 85.333333h469.333333a42.666667 42.666667 0 1 0 0-85.333333h-469.333333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconListCheck.defaultProps = {
  size: 14,
};

export default IconIconListCheck;

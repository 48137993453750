import { Avatar, chakra, Flex, Tooltip } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

import { VerifiedIcon } from "@/components/Icon";
import IconFont from "@/components/Icon/iconfont";
import { getTwitterAvatar } from "@/components/utils";
import detaultTwitterUserPng from "@/public/default_twitter_avatar.png";
import { twx } from "@/utils/twx";

import { valueType } from "./SelectedItemRender";

interface DropmenuRenderProps extends valueType {
  replaceAvatar?: boolean;
}

const DropmenuRender = ({
  username,
  name,
  verified,
  avatarUrl: avatarUrlProp,
  value,
  replaceAvatar = false,
}: DropmenuRenderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLParagraphElement>(null);
  const userNameRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpoen] = useState(false);

  useEffect(() => {
    const hasTooltip =
      nameRef?.current?.offsetWidth < nameRef?.current?.scrollWidth ||
      userNameRef?.current?.offsetWidth < userNameRef?.current?.scrollWidth;
    const handler = () => {
      if (hasTooltip) {
        if (isOpen) {
          setIsOpoen(false);
        } else {
          setIsOpoen(true);
        }
      }
    };
    const curr = containerRef?.current;
    curr?.addEventListener("mouseenter", handler);
    curr?.addEventListener("mouseleave", handler);
    return () => {
      curr.removeEventListener("mouseenter", handler);
      curr.removeEventListener("mouseleave", handler);
    };
  }, [isOpen]);
  const avatarUrl = replaceAvatar ? getTwitterAvatar(value) : avatarUrlProp;

  const elementToShow = (
    <Flex gap="4px">
      {avatarUrl ? (
        <Avatar
          src={avatarUrl}
          w="20px"
          h="20px"
          overflow="hidden"
          icon={<chakra.img src={detaultTwitterUserPng.src} />}
        />
      ) : null}
      <Flex flexDirection="column">
        <UserName className="break-all overflow-visible">
          {verified && <VerifiedIcon ml="4px" fontSize="12px" />}@{username}
        </UserName>
        <Name className="break-all overflow-visible">{name}</Name>
      </Flex>
    </Flex>
  );

  return (
    <Tooltip placement="top" label={elementToShow} isOpen={isOpen} p="8px" minW="356px">
      <Flex alignItems="center" gap="6px" p="8px" maxW="100%" ref={containerRef}>
        {avatarUrl === "Search" ? (
          <>
            <IconFont name="IconSearch" size={20} />
            <UserName ref={nameRef}>{`Search for "@${username}"`}</UserName>
          </>
        ) : (
          <>
            {avatarUrl || replaceAvatar ? (
              <>
                <Avatar
                  src={avatarUrl}
                  w="20px"
                  h="20px"
                  overflow="hidden"
                  icon={<chakra.img src={detaultTwitterUserPng.src} />}
                />
              </>
            ) : null}
            <UserName ref={nameRef}>@{username}</UserName>
            {verified && <VerifiedIcon fontSize="12px" />}
            {username ? <Name ref={userNameRef}>{name}</Name> : null}
          </>
        )}
      </Flex>
    </Tooltip>
  );
};

const UserName = twx.span`text-sm truncate text-white`;
const Name = twx.span`text-xs truncate text-white/40`;

export default DropmenuRender;

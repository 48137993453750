import type { FC, ReactNode } from "react";
import { useRef } from "react";

export const Repeater: FC<{
  mode: "visible" | "hidden" | boolean;
  children: ReactNode;
}> = (props) => {
  // props
  const { mode, children } = props;
  // refs
  const resolveRef = useRef<() => void>();
  // destroy promise
  if (resolveRef.current) {
    resolveRef.current();
    resolveRef.current = void 0;
  }

  const isHidden = mode === "hidden" || mode === false;

  if (isHidden) {
    throw new Promise<void>((resolve) => (resolveRef.current = resolve));
  }
  return <>{children}</>;
};

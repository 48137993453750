import { atom } from "jotai";

import { createGuideApi, GuideItem, ListGuidesApi } from "@/client/guide";
import { GuideTypes } from "@/types/guideType";

const baseGuideAtom = atom<GuideItem[]>([]);
baseGuideAtom.debugLabel = "baseGuideAtom";

export const guideAtom = atom(
  (get) => get(baseGuideAtom),
  /**
   * if guide_type is not provided, it will update all guides
   */
  async (get, set, guideType?: keyof typeof GuideTypes) => {
    if (!guideType) {
      return ListGuidesApi().then((data) => {
        set(baseGuideAtom, data);
        return data;
      });
    }

    // Optimism create guide success
    set(baseGuideAtom, (prev) => {
      return [...prev, { guide_type: guideType as string, is_hidden: true }];
    });
    createGuideApi(guideType);
    return get(baseGuideAtom);
  }
);

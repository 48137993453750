/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSearchlistMedium1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M876.757333 253.781333a100.138667 100.138667 0 0 1-168.533333 73.173334c-16.085333-15.018667-35.242667-28.672-57.258667-28.672h-10.197333c-21.973333 0-41.173333 13.653333-57.216 28.672-17.92 16.725333-41.941333 26.965333-68.394667 26.965333s-50.474667-10.24-68.394666-26.965333c-16.042667-15.018667-35.242667-28.672-57.216-28.672h-15.701334c-18.645333 0-34.986667 11.392-47.872 24.832-12.16 12.629333-22.186667 28.202667-22.186666 45.738666v20.693334c0 20.650667 12.074667 38.954667 25.6 54.613333 15.232 17.536 24.448 40.448 24.448 65.536 0 25.045333-9.216 47.957333-24.405334 65.536-13.568 15.616-25.642667 33.92-25.642666 54.613333v26.24c0 17.493333 10.026667 33.066667 22.186666 45.738667 12.885333 13.44 29.269333 24.789333 47.914667 24.789333h15.616c22.016 0 41.216-13.610667 57.258667-28.629333a100.138667 100.138667 0 1 1 0 146.304c-16.042667-15.018667-35.242667-28.672-57.216-28.672h-10.24c-21.973333 0-41.130667 13.653333-57.216 28.672a100.138667 100.138667 0 0 1-144.085334-138.666667c13.525333-15.658667 25.642667-33.962667 25.642667-54.613333v-21.162667c0-20.693333-12.117333-38.997333-25.642667-54.613333a99.754667 99.754667 0 0 1-24.405333-65.536c0-25.088 9.173333-48 24.405333-65.536 13.525333-15.658667 25.642667-33.962667 25.642667-54.613333v-15.616c0-20.650667-12.117333-38.954667-25.642667-54.613334a100.138667 100.138667 0 0 1 144.085334-138.666666c16.085333 15.018667 35.242667 28.629333 57.258666 28.629333h10.154667c22.016 0 41.216-13.610667 57.258667-28.629333 17.92-16.725333 41.941333-26.965333 68.394666-26.965334s50.474667 10.24 68.394667 26.965334c16.042667 15.018667 35.242667 28.629333 57.216 28.629333h10.24c21.973333 0 41.173333-13.610667 57.216-28.629333a100.138667 100.138667 0 0 1 168.533333 73.130666z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M548.522667 509.653333a33.365333 33.365333 0 1 1-66.730667 0 33.365333 33.365333 0 0 1 66.730667 0zM809.984 509.653333a33.365333 33.365333 0 1 1-66.773333 0 33.365333 33.365333 0 0 1 66.773333 0zM809.984 771.114667a33.365333 33.365333 0 1 1-66.773333 0 33.365333 33.365333 0 0 1 66.773333 0z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSearchlistMedium1.defaultProps = {
  size: 14,
};

export default IconAIconSearchlistMedium1;

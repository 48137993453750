/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSearchlistResearch1: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M384 716.8v81.066667a21.333333 21.333333 0 0 0 21.333333 21.333333h469.333334a21.333333 21.333333 0 0 0 21.333333-21.333333v-366.933334a21.333333 21.333333 0 0 0-21.333333-21.333333h-81.066667v204.8a102.4 102.4 0 0 1-102.4 102.4H384z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M149.333333 204.8a21.333333 21.333333 0 0 0-21.333333 21.333333v418.133334a21.333333 21.333333 0 0 0 21.333333 21.333333h68.266667l-32.256 129.109333a8.533333 8.533333 0 0 0 13.610667 8.746667L371.2 665.6h349.866667a21.333333 21.333333 0 0 0 21.333333-21.333333V226.133333a21.333333 21.333333 0 0 0-21.333333-21.333333H149.333333z m93.312 143.36h280.448v57.386667H242.645333V348.202667z m382.976 133.12H242.602667v57.386667h382.976v-57.344z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSearchlistResearch1.defaultProps = {
  size: 14,
};

export default IconAIconSearchlistResearch1;

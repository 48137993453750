import { isNil } from "lodash";

export interface TaskFlowConfig {
  /**
   * @default false
   * will add prev executed result as first param
   */
  passFirstParam?: boolean;
}

export type TaskFlowFn<
  A extends (...args: any) => any,
  B extends (...args: any) => any,
  T extends any[],
  Config extends TaskFlowConfig,
  FirstCreate extends boolean,
> = (
  ...args: Config["passFirstParam"] extends true & BoolReverse<FirstCreate>
    ? Shift<Parameters<A>>
    : Parameters<A>
) => T["length"] extends 0
  ? { next: Config["passFirstParam"] extends true ? ShiftArgument<B> : B }
  : { next: TaskFlowFn<B, First<T>, Shift<T>, Config, false> };

/**
 *
 * like combine _.curry and _.flow
 *
 *
 * @example
 *
 * **config.passFirstParam = true**
 * ```ts
 * const get = createTaskFlow([_.get, _.filter], { passFirstParam: true })
 *
 *  // result : [2]
 * const result = pipe.next({ a: [1, 2] }, "a").next((x) => x % 2 === 0);
 * ```
 * ```ts
 * const get = createTaskFlow([_.get, _.filter])
 *
 * // result: [4]
 * const result = pipe.next({ a: [1, 2] }, "a").next([4], (x) => x % 2 === 0);
 * ```
 */
export const createTaskFlow = <
  A extends (...args: any) => any,
  B extends (...args: any) => any,
  T extends any[],
  Config extends TaskFlowConfig,
  FirstCreate extends boolean = true,
>(
  [first, next, ...restFn]: readonly [f: A, next: B, ...restFn: T],
  config: Config = {
    passFirstParam: false,
  } as Config,
  isFirstCreate?: FirstCreate
) => {
  const fn = ((...args: any[]) => {
    const result = first(...args);
    if (isNil(next)) {
      return result;
    }

    const createCurry = (next: (...args: any[]) => any, result: any) => {
      if (next.length === 0) return next;
      return (...args) => {
        return next.apply(null, [result].concat(args));
      };
    };

    const nextFn = config.passFirstParam ? createCurry(next, result) : next;

    const rest = [nextFn].concat(restFn);
    return createTaskFlow(rest as any, config, false);
  }) as TaskFlowFn<A, B, T, Config, FirstCreate>;

  return { next: fn };
};

import {
  chakra,
  Flex,
  HTMLChakraProps,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from "@chakra-ui/react";

interface RadioButtonProps extends UseRadioProps {
  title: string;
}
const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { title, ...radioProps } = props;
  const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } = useRadio(radioProps);
  return (
    <chakra.label
      {...htmlProps}
      cursor="pointer"
      flex="1"
      color="G1.400"
      fontSize="14px"
      display="flex"
      borderRadius="4px"
      sx={state.isDisabled && { cursor: "not-allowed" }}
    >
      <input {...getInputProps({})} hidden />
      <Flex
        {...getCheckboxProps()}
        sx={state.isChecked ? { color: "brand.200", bg: "grayBlue.400", fontWeight: "500" } : {}}
        flex="1"
        height="28px"
        justifyContent="center"
        alignItems="center"
      >
        {title}
      </Flex>
    </chakra.label>
  );
};
interface TabRadioProps extends HTMLChakraProps<"div"> {
  options: { title: string; value: string; disable?: boolean }[];
  defaultValue?: string;
  value: string;
  handleChange: (value: string) => void;
}
export const TabRadio: React.FC<TabRadioProps> = ({
  options,
  defaultValue,
  value,
  handleChange,
}) => {
  const { getRadioProps } = useRadioGroup({
    value: value ?? defaultValue,
    onChange: handleChange,
  });
  return (
    <Flex
      height="32px"
      alignItems="center"
      gap="4px"
      flex="1"
      bg="grayBlue.500"
      px="2px"
      borderRadius="4px"
    >
      {options.map((v) => {
        return (
          <RadioButton
            key={v.title}
            title={v.title}
            {...getRadioProps({ value: v.value })}
            isDisabled={v.disable}
          />
        );
      })}
    </Flex>
  );
};

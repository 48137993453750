/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconPaperAirplaneCover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M470.186667 641.152l214.698666-251.733333-50.261333-50.304-251.733333 214.698666-237.653334-79.189333-2.005333-66.730667 711.125333-284.458666 46.208 46.208-284.416 711.125333-66.773333-1.962667-79.189333-237.653333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconPaperAirplaneCover.defaultProps = {
  size: 14,
};

export default IconIconPaperAirplaneCover;

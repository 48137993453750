/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconCopilot: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M341.333333 416a42.666667 42.666667 0 0 0-85.333333 0v64a42.666667 42.666667 0 1 0 85.333333 0v-64zM554.666667 416a42.666667 42.666667 0 1 0-85.333334 0v64a42.666667 42.666667 0 1 0 85.333334 0v-64zM768 416a42.666667 42.666667 0 1 0-85.333333 0v64a42.666667 42.666667 0 1 0 85.333333 0v-64z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M85.333333 85.333333a42.666667 42.666667 0 0 0-42.666666 42.666667v640a42.666667 42.666667 0 0 0 42.666666 42.666667h149.333334v64a42.666667 42.666667 0 0 0 61.738666 38.144L500.778667 810.666667H938.666667a42.666667 42.666667 0 0 0 42.666666-42.666667V128a42.666667 42.666667 0 0 0-42.666666-42.666667H85.333333z m234.666667 682.666667a42.666667 42.666667 0 0 0-42.666667-42.666667H128V170.666667h768v554.666666h-405.333333a42.624 42.624 0 0 0-19.072 4.522667L320 805.632V768z"
        fill={getIconColor(color, 1, "currentColor")}
      />
    </svg>
  );
};

IconCopilot.defaultProps = {
  size: 14,
};

export default IconCopilot;

import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

import { Spinner } from "./spinner";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-[#32FFDC]  text-black hover:bg-[#9fffef]",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: `border border-brand-600 text-brand-200 bg-background hover:bg-accent hover:text-accent-foreground`,
        secondary: "bg-G2-400 text-secondary-foreground hover:bg-G2-400/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-[#32FFDC] underline-offset-4 hover:underline",
        dangerous: "text-white bg-[#FF4B4B] hover:bg-[#FF4B4B]/90",
        "brand-bg-outline":
          "text-brand-200 bg-brand-700 border border-brand-600 hover:bg-[#17312D]",
        "brand-outline":
          "text-brand-200 border border-brand-200 hover:border-brand-100 hover:bg-white/10 hover:text-brand-100",
        span: "text-[#32FFDC]",
      },
      size: {
        xs: "h-6 px-2 py-1",
        sm: "h-8 px-2 py-1",
        xl: "h-10 px-4 py-2",
        default: "h-9 rounded px-4",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
        small: "h-[30px] px-2 py-1 rounded text-xs gap-1",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  spinnerSize?: "xs" | "sm" | "md" | "lg" | "xl";
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      isLoading,
      spinnerSize = "sm",
      asChild = false,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    if (isLoading) {
      return (
        <Comp
          className={cn(buttonVariants({ variant, size, className }), "pointer-events-none")}
          ref={ref}
          {...props}
        >
          <div className="inline-flex items-center opacity-50">
            {children}&nbsp;&nbsp;
            <Spinner size={spinnerSize} />
          </div>
        </Comp>
      );
    } else {
      return (
        <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
          {children}
        </Comp>
      );
    }
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };

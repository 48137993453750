/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconRefreshUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 162.133333a349.866667 349.866667 0 1 0 247.381333 597.248 34.133333 34.133333 0 1 1 48.298667 48.298667A416.938667 416.938667 0 0 1 512 930.133333c-230.912 0-418.133333-187.221333-418.133333-418.133333 0-230.912 187.221333-418.133333 418.133333-418.133333a416.938667 416.938667 0 0 1 295.68 122.453333c14.250667 14.250667 34.688 36.266667 54.186667 57.514667V170.666667a34.133333 34.133333 0 1 1 68.266666 0v192a34.133333 34.133333 0 0 1-34.133333 34.133333h-192a34.133333 34.133333 0 1 1 0-68.266667h115.370667a2210.133333 2210.133333 0 0 0-59.989334-63.914666A348.629333 348.629333 0 0 0 512 162.133333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconRefreshUncover.defaultProps = {
  size: 14,
};

export default IconIconRefreshUncover;

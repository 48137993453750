/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconEntityUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M679.893333 313.6V170.666667h-175.232A291.285333 291.285333 0 0 0 213.333333 461.994667v389.290666h627.541334v-414.165333L679.893333 313.6zM611.84 783.232H281.386667v-321.28a223.402667 223.402667 0 0 1 223.274666-223.232h107.178667v544.512z m160.981333 0h-90.026666V401.066667l90.709333 69.546666-0.682667 312.618667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M395.093333 552.661333h137.472v68.053334H395.050667v-68.053334z m0-139.178666h137.472v68.053333H395.050667V413.482667z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconEntityUncover.defaultProps = {
  size: 14,
};

export default IconIconEntityUncover;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSearchlistResearch: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M626.773333 122.88H221.952a42.666667 42.666667 0 0 0-42.666667 42.666667v692.906666a42.666667 42.666667 0 0 0 42.666667 42.666667h580.266667a42.666667 42.666667 0 0 0 42.666666-42.666667V324.266667l-218.026666-201.386667zM319.530667 648.704h373.930666v57.386667H319.488v-57.386667z m280.448-143.36v57.344H319.488v-57.386667h280.448z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSearchlistResearch.defaultProps = {
  size: 14,
};

export default IconAIconSearchlistResearch;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIocnAuthorUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M365.141333 269.482667a175.616 175.616 0 1 1 351.189334 0 175.616 175.616 0 0 1-351.189334 0zM540.757333 162.133333a107.349333 107.349333 0 1 0 0 214.698667 107.349333 107.349333 0 0 0 0-214.698667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M500.309333 586.581333C314.368 586.581333 170.666667 719.658667 170.666667 875.776v34.133333H102.4v-34.133333c0-201.088 182.058667-357.461333 397.909333-357.461333h34.133334v68.266666h-34.133334z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
      <path
        d="M827.605333 588.928a34.133333 34.133333 0 0 0-48.256 0l-202.112 202.112a34.133333 34.133333 0 0 0-9.984 24.106667V896a34.133333 34.133333 0 0 0 34.133334 34.133333h80.810666a34.133333 34.133333 0 0 0 24.149334-9.984l202.112-202.112a34.133333 34.133333 0 0 0 0-48.298666l-80.853334-80.810667zM635.52 861.866667v-32.554667l167.978667-167.978667 32.554666 32.554667-167.978666 167.978667h-32.554667z"
        fill={getIconColor(color, 2, 'currentColor')}
      />
    </svg>
  );
};

IconIocnAuthorUncover.defaultProps = {
  size: 14,
};

export default IconIocnAuthorUncover;

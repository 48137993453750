import fetchAdapter from "@haverstack/axios-fetch-adapter";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import axiosRetry, { IAxiosRetryConfig } from "axios-retry";

import { authHeadersLocalStorageUtil } from "@/utils";

import {
  AxiosInterceptor,
  errorNotify,
  nodeEnvInterceptor,
  reduceVisitDataChain,
} from "./interceptors";
import { addAuthFieldsInHeader } from "./interceptors/addAuthFieldsInHeader";
import { notAuth } from "./interceptors/notAuth";
import { switchPublicApi } from "./interceptors/public";
import { decryptRespInterceptor } from "./interceptors/securityInterceptor";

export const BASE_URL = "/api";

type KaitoAxiosInstance = AxiosInstance & {};

export const createClient = (config?: AxiosRequestConfig<any>) => {
  const client = axios.create({
    ...config,
  });

  composeInterceptors(client, [
    nodeEnvInterceptor,
    switchPublicApi,
    notAuth,
    decryptRespInterceptor,
    reduceVisitDataChain,
    addAuthFieldsInHeader,
    errorNotify,
  ]);

  return client as KaitoAxiosInstance;
};

export const composeInterceptors = (client: AxiosInstance, interceptors: AxiosInterceptor[]) => {
  interceptors.forEach((interceptor) => {
    interceptor(client);
  });
};

export const client = createClient({
  baseURL: "/api",
});

/**
 * use fetchAdapter
 */
export const fetchClient = createClient({
  baseURL: "/api",
  adapter: fetchAdapter,
});

let tokenFetchPromise: Promise<void> | null = null;

const fetchLatestToken = async () => {
  try {
    // if the error is 403, we need to refresh the token, store it in the local storage and retry the request
    const data = await (await fetch(`${window.location.origin}/api/auth/session`)).json();
    authHeadersLocalStorageUtil.setValue(
      JSON.stringify({
        Authorization: `Bearer ${data?.idToken}`,
        user_id: data?.user?.user_id,
        session_id: data?.user?.session_id,
      })
    );
  } catch (e) {
    console.error(`retry to get latest token error: ${e.message}`);
    throw e;
  } finally {
    // restore the tokenFetchPromise to null
    tokenFetchPromise = null;
  }
};

const retryOptions: IAxiosRetryConfig = {
  retries: 2,
  retryDelay: (retryCount) => retryCount * 1000,
  retryCondition: async (error) => {
    switch (error.response.status) {
      case 403: {
        // If token fetch is not in progress, start fetching the token
        if (!tokenFetchPromise) {
          tokenFetchPromise = fetchLatestToken();
        }
        await tokenFetchPromise;
        return true;
      }
      default:
        return false;
    }
  },
};

axiosRetry(client, retryOptions);
axiosRetry(fetchClient, retryOptions);

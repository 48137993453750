import { numFormat } from "./math";
import { get } from "lodash";
import Stripe from "stripe";
import { PricingPlan } from "@/types/pricing";

/**
 * https://stripe.com/docs/api/subscriptions/object#subscription_object-status
 *
 *
 */
export const isSubscriptionLastPaymentError = (subscription?: Stripe.Subscription) => {
  if (!subscription) return false;

  return subscription.status === "past_due";
  // if (typeof subscription.latest_invoice === "string") return false;
  // const latest_invoice = subscription.latest_invoice;
  // if (!latest_invoice || typeof latest_invoice.payment_intent === "string") return false;
  // return !!latest_invoice.payment_intent.last_payment_error;
};

export const getCurrentPlan = (subscription: Stripe.Subscription) => {
  const plan = get(subscription, "plan");
  return plan;
};

export const getNextPeriodPlanPrice = (subscription?: Stripe.Subscription) => {
  if (!subscription) return "";

  const plan = getCurrentPlan(subscription);

  if (!plan) return "";

  const amount = plan.amount;
  return `$${numFormat(amount / 100)}`;
};

const getInterval = (interval: string) => {
  if (!interval) return "";
  return interval === "year" ? " (Yearly)" : " (Monthly)";
};
export const getNextPeriodPlanLevel = (subscription: Stripe.Subscription, plans: PricingPlan[]) => {
  if (!subscription) return null;

  const plan = getCurrentPlan(subscription);

  if (!plan) return null;

  const planLevel = get(plan, "product.metadata.level");
  if (planLevel)
    return (
      `${getInterval(get(plan, "product.interval")) || getInterval(get(plan, "interval"))} ` +
      planLevel
    );

  const planInPrices = plans.find((_plan) => plan.id === _plan.priceId);

  return `${getInterval(get(planInPrices, "interval"))}` + get(planInPrices, "planLevel");
};

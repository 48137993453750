"use client";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import themeConfig from "@/theme";

const theme = extendTheme(themeConfig, {
  styles: {
    global: {
      "html, body": {
        background: "htmlBg",
      },
    },
  },
  components: {
    FormLabel: {
      baseStyle: {
        fontSize: "14px",
      },
    },
    FormError: {
      baseStyle: {
        fontSize: "14px",
      },
    },
  },
});
global.localStorage && global.localStorage.setItem("chakra-ui-color-mode", "dark");

export function Providers({ children }: { children: React.ReactNode }) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}

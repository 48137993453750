import { EventEmitter } from "fbemitter";

const eventEmitter = new EventEmitter();

export enum GlobalEventType {
  AudioCurrentTimeChange = "audioCurrentTimeChange",
  ToggleLocateButton = "toggleLocateButton",
  MatchListSelected = "matchListSelected",
  UpdateCurrentTime = "updateCurrentTime",
  AbortSearch = "abortSearch",
  RATE_LIMIT_REACHED = "rateLimitReached",
}

export default eventEmitter;

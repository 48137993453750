/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconSentiment: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M942.208 494.250667h-198.784a234.709333 234.709333 0 0 0-462.848 0h-198.826667A39.125333 39.125333 0 0 0 42.666667 533.333333h938.666666a39.125333 39.125333 0 0 0-39.125333-39.082666z"
        fill={getIconColor(color, 0, '#27F0A7')}
        fillOpacity=".8"
      />
      <path
        d="M81.792 572.416h198.784a234.709333 234.709333 0 0 0 462.848 0h198.826667c21.546667 0 39.082667-17.493333 39.082666-39.082667H42.666667c0 21.589333 17.493333 39.082667 39.125333 39.082667z"
        fill={getIconColor(color, 1, '#F4595A')}
        fillOpacity=".8"
      />
    </svg>
  );
};

IconIconSentiment.defaultProps = {
  size: 14,
};

export default IconIconSentiment;

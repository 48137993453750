import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const NoResultRender = () => {
  return (
    <Flex color="whiteAlpha.200" flexFlow="flex-start" height="24px" p="6.5px" alignItems="center">
      <InfoOutlineIcon mr="6px" boxSize="11px" />
      <Text fontSize="12px" textAlign="center">
        No user found
      </Text>
    </Flex>
  );
};

export default NoResultRender;

/* eslint-disable react-hooks/rules-of-hooks */
import { useCallbackFn } from "@/hooks/utils";
import { noop } from "lodash";
import { createContext, useEffect } from "react";
import { useLocalStorage } from "react-use";
import { useSmartFollowingFilters } from "./hooks";
import { SmartFollowingFilter, SmartFollowingFiltersHook } from "./types";

export const SmartFollowingFilterContext = createContext<SmartFollowingFiltersHook>({
  filter: {},
  setFilter: noop,
});

export const SmartFollowingFilterProvider = SmartFollowingFilterContext.Provider;

/**
 * @param {string} cacheKey if you want to persist the filter between renders
 */
export const withSmartFollowingFilterProvider = <T extends Object>(
  Component: React.ComponentType<T>,
  defaultState?: Partial<SmartFollowingFilter>,
  cacheKey?: string
) => {
  const withSmartFollowingFilter: React.FC<T> = (props: T) => {
    const enableCaching = cacheKey !== undefined;
    const [value, setValue] = useLocalStorage(`sf/${cacheKey}`);
    const { filter, setFilter } = useSmartFollowingFilters(value ?? defaultState);

    const handleSetFilter = useCallbackFn(
      (
        patch:
          | Partial<SmartFollowingFilter>
          | ((prevState: SmartFollowingFilter) => Partial<SmartFollowingFilter>)
      ) => {
        setFilter(patch);
        setTimeout(() => {});
      }
    );

    useEffect(() => {
      if (enableCaching) {
        setValue(filter);
      }
    }, [enableCaching, filter, setValue]);

    return (
      <SmartFollowingFilterProvider value={{ filter, setFilter: handleSetFilter }}>
        <Component {...props} />
      </SmartFollowingFilterProvider>
    );
  };
  return withSmartFollowingFilter;
};

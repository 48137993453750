/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconShow: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 780.8c-66.517333 0.853333-132.266667-13.525333-192.384-41.941333a408.405333 408.405333 0 0 1-123.264-92.501334 429.226667 429.226667 0 0 1-85.632-136.448l-4.053333-12.8 4.266666-12.8a432.085333 432.085333 0 0 1 85.461334-136.533333 408.32 408.32 0 0 1 123.221333-92.458667 437.12 437.12 0 0 1 192.341333-41.941333c66.474667-0.853333 132.266667 13.525333 192.384 41.941333a408.277333 408.277333 0 0 1 123.264 92.501334 425.472 425.472 0 0 1 85.632 136.448l4.053334 12.8-4.266667 12.8a422.442667 422.442667 0 0 1-401.066667 270.933333z m0-486.4a336.725333 336.725333 0 0 0-319.530667 202.666667 336.682667 336.682667 0 0 0 319.488 202.666666 336.768 336.768 0 0 0 319.530667-202.666666 336.384 336.384 0 0 0-319.530667-202.666667z m0 324.266667a122.410667 122.410667 0 1 1 85.674667-35.84c-22.613333 22.912-53.504 35.84-85.717334 35.84z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconShow.defaultProps = {
  size: 14,
};

export default IconShow;

import {
  WarpCastMultiSelectName,
  WarpCastMultiSelectSource,
} from "@/components/Filter/SourceFilter/multiselectConstants";
import getRemoteOptions from "@/components/Filter/SourceFilter/warpcast_by_user/getRemoteOptions";
import MultiSelect from "@/components/MultiSelect";

import { default as TwitterByUserDropmenuRender } from "../twitter_space_by_user/DropmenuRender";
import { default as TwitterByUserSelectedItemRender } from "../twitter_space_by_user/SelectedItemRender";

const renderFilterField = (onChange, value) => (
  <MultiSelect<any>
    value={value}
    getRemoteOptions={getRemoteOptions}
    onChange={onChange}
    DropMenuItem={TwitterByUserDropmenuRender}
    RenderSelectedItem={TwitterByUserSelectedItemRender}
    placeholder={"@Username / ENS"}
    name={WarpCastMultiSelectName}
    source={WarpCastMultiSelectSource}
  />
);
export default renderFilterField;

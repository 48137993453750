import { default as DiscordByServerNoResultRender } from "./NoResultRender";
import { default as DiscordByServerDropmenuRender } from "./DropmenuRender";
import { default as DiscordByServerSelectedItemRender } from "./SelectedItemRender";
import { default as DiscordByServerGetRemoteOptions } from "./getRemoteOptions";
import MultiSelect from "@/components/MultiSelect";
import {
  DiscordMultiSelectSource,
  DiscordServerMultiSelectName,
} from "@/components/Filter/SourceFilter/multiselectConstants";

const renderFilterField = (onChange, value) => (
  <MultiSelect<any>
    value={value}
    getRemoteOptions={DiscordByServerGetRemoteOptions}
    onChange={onChange}
    DropMenuItem={DiscordByServerDropmenuRender}
    RenderSelectedItem={DiscordByServerSelectedItemRender}
    RenderNoResult={DiscordByServerNoResultRender}
    placeholder={"Discord Server"}
    name={DiscordServerMultiSelectName}
    source={DiscordMultiSelectSource}
  />
);
export default renderFilterField;

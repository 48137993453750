import React, { useRef } from "react";
import { TwcComponentProps } from "react-twc";
import { useMount } from "react-use";
import { twMerge } from "tailwind-merge";

import IconFont from "@/components/Icon/iconfont";
import { useDisclosure } from "@/components/ui/hooks";
import { Label } from "@/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger, Portal } from "@/components/ui/popover";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio";
import { twx } from "@/utils/twx";

export enum SelectRadioType {
  From = "From",
  Exclude = "Exclude",
}

interface Props {
  value?: string;
  onChange: (v: string) => void;
  length?: number;
}

export const SelectCheckBox: React.FC<Props> = ({ value, onChange, length }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const ref = useRef(null);
  const onValueChange = (v: string) => {
    onChange(v);
    onClose();
  };

  const onTrigger = (e) => {
    e.stopPropagation();
    onToggle();
  };

  useMount(() => {
    ref.current = document.querySelector("#excludeContainer");
  });

  return (
    <Popover open={isOpen} onOpenChange={onToggle}>
      <PopoverTrigger>
        <div
          className="flex flex-row text-sm items-center text-brand-200 gap-0.5 w-[82px]"
          id="select_check_box"
          onClick={onTrigger}
        >
          {value}
          <IconFont
            name="IconDown"
            size={16}
            className={twMerge([isOpen && "rotate-180", "ml-auto mr-2"])}
          />
          <Divider />
        </div>
      </PopoverTrigger>
      <Portal container={ref.current}>
        <PopoverContent
          alignOffset={-8}
          sideOffset={8}
          align="start"
          side="bottom"
          className="w-[90px] py-2 z-[500]"
          onClick={(e) => e.stopPropagation()}
        >
          <RadioGroup
            className="gap-2"
            defaultValue={SelectRadioType.From}
            value={value}
            onValueChange={onValueChange}
          >
            <RadioItemContainer active={value === SelectRadioType.From}>
              <RadioGroupItem value={SelectRadioType.From} id="r1" />
              <Label htmlFor="r1" className="cursor-pointer">
                {SelectRadioType.From}
              </Label>
            </RadioItemContainer>
            <RadioItemContainer active={value === SelectRadioType.Exclude}>
              <RadioGroupItem value={SelectRadioType.Exclude} id="r2" />
              <Label htmlFor="r2" className="cursor-pointer">
                {SelectRadioType.Exclude}
              </Label>
            </RadioItemContainer>
          </RadioGroup>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const RadioItemContainer = twx.div<TwcComponentProps<"div"> & { active?: boolean }>((props) => [
  "flex items-center gap-1 px-2 py-0.5 hover:bg-G2-500",
  props.active && "text-brand-200",
]);

const Divider = twx.div`w-[1px] h-[20px] shrink-0 bg-white/10 `;

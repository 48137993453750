import { isNil } from "lodash";
import Stripe from "stripe";

import { client } from "@/client/base";
import { PlanInterval, PlanLevel, PricingPlan } from "@/types/pricing";
import { createTaskFlow } from "@/utils/flow";
import { getCurrentPlan } from "@/utils/subscription";

import { freePlan } from "./constant";

export const planLevelArr: Array<PlanLevel> = [
  PlanLevel.Free,
  PlanLevel.Standard,
  PlanLevel.FreeTrial,
  PlanLevel.Pro,
  PlanLevel.BusinessTrial,
  PlanLevel.Business,
  // PlanLevel.Enterprise,
];

export const planLevel2Display = (planLevel: PlanLevel) => {
  return (
    {
      [PlanLevel.Free]: "MetaSearch Free",
      [PlanLevel.Standard]: "MetaSearch Standard",
      [PlanLevel.FreeTrial]: "MetaSearch Pro",
      [PlanLevel.Pro]: "MetaSearch Pro",
      [PlanLevel.Business]: "MetaSearch Individual",
      [PlanLevel.BusinessTrial]: "MetaSearch Individual",
      // [PlanLevel.Enterprise]: "MetaSearch Enterprise",
    }[planLevel] ?? "MetaSearch Free"
  );
};

/**
 * 目前认为：PlanLevel.FreeTrial === PlanLevel.Pro
 */
export const getLevelIndex = (planLevel: PlanLevel): [number, PlanLevel] => {
  let nextPlanLevel = planLevel;

  if (planLevel === PlanLevel.FreeTrial) {
    nextPlanLevel = PlanLevel.Pro;
  }

  if (planLevel === PlanLevel.BusinessTrial) {
    nextPlanLevel = PlanLevel.Business;
  }

  return [planLevelArr.findIndex((l) => l === nextPlanLevel), nextPlanLevel];
};

/**
 *  levelIndex compare: p1 - p2
 *
 */
export const comparePlanLevel = (p1: PlanLevel, p2: PlanLevel) => {
  const [p1levelIndex] = getLevelIndex(p1);
  const [p2LevelIndex] = getLevelIndex(p2);

  return p1levelIndex - p2LevelIndex;
};

export const findUserCurrentPlan = (
  currentLevel: PlanLevel,
  subscription: Stripe.Subscription,
  stripePlanData: PricingPlan[]
) => {
  const plan = getCurrentPlan(subscription);
  return (
    stripePlanData.find(
      (data) => data.planLevel === currentLevel && plan?.interval === data.interval
    ) ?? freePlan
  );
};

export const createCheckoutUrl = async ({
  priceId,
}): Promise<{
  checkout_url: string;
}> => {
  return await client.post("/payment/create_checkout", {
    priceId,
  });
};

export const changePlan = async ({
  newPriceId,
}): Promise<{
  checkout_url: string;
}> => {
  return await client.post("/payment/schedule_change_subscription", {
    newPriceId,
  });
};

export const findCheckoutPlan = ({
  tab,
  planLevel,
  plans,
}: {
  tab: PlanInterval;
  planLevel: PlanLevel;
  plans: PricingPlan[];
}) => {
  const plan = plans?.find((plan) => plan.planLevel === planLevel && plan.interval === tab);
  return plan;
};

/**
 * Downlevel or UpLevel is very complicated, so split to multiple steps
 */
export const createGetDownLevelOrUpLevelPipe = () => {
  let isUpOrDown: number;
  /**
   * baseCompareFn
   */
  const baseCompareFn = (planLevel: PlanLevel, userCurrentPlanLevel: PlanLevel) => {
    isUpOrDown = comparePlanLevel(planLevel, userCurrentPlanLevel);
  };

  /**
   * whenFreeTrial
   *  same level but current plan is trialing, should set upgrade
   *  or BusinessTrial should set upgrade
   */
  const whenFreeTrial = (subscription: Stripe.Subscription, userCurrentPlanLevel: PlanLevel) => {
    if (
      (subscription?.status === "trialing" && isUpOrDown === 0) ||
      userCurrentPlanLevel === PlanLevel.BusinessTrial
    ) {
      isUpOrDown = 1;
    }
  };

  /**
   * whenIntervalChange
   *  monthly pro  => yearly pro is upgrade, force change it value as 1
   */
  const whenIntervalChange = (currentInterval: string, nextInterval: string) => {
    if (isNil(currentInterval)) return;
    if (nextInterval !== "year") return;
    if (currentInterval !== nextInterval) {
      isUpOrDown = isUpOrDown === 0 ? 1 : isUpOrDown;
    }
  };

  const getValue = () => isUpOrDown;

  const taskFlow = [baseCompareFn, whenFreeTrial, whenIntervalChange, getValue] as const;

  return createTaskFlow(taskFlow);
};

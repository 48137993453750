/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconShareUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M179.2 192a12.8 12.8 0 0 1 12.8-12.8H384a51.2 51.2 0 1 0 0-102.4H192a115.2 115.2 0 0 0-115.2 115.2v640a115.2 115.2 0 0 0 115.2 115.2h640a115.2 115.2 0 0 0 115.2-115.2v-203.221333a51.2 51.2 0 1 0-102.4 0V832a12.8 12.8 0 0 1-12.8 12.8h-640a12.8 12.8 0 0 1-12.8-12.8v-640z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M546.133333 128A51.2 51.2 0 0 1 597.333333 76.8h298.666667A51.2 51.2 0 0 1 947.2 128v298.666667a51.2 51.2 0 0 1-102.4 0V251.605333l-258.218667 258.176a51.2 51.2 0 0 1-72.405333-72.405333L772.394667 179.2H597.333333A51.2 51.2 0 0 1 546.133333 128z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconShareUncover.defaultProps = {
  size: 14,
};

export default IconIconShareUncover;

import { HighLight } from "@/types";
import { get } from "lodash";
import { i18n } from "next-i18next";

export const getHighlightText = (highlight: HighLight) => {
  const highlightText = highlight && get(highlight, ["text.english_analyzed"], []).join(" ");
  return highlightText;
};

export const getHightLightNumber = (highlight: HighLight) => {
  const highlightNum = highlight ? (highlight["text.english_analyzed"] || []).length : 0;
  return highlightNum;
};

export const getHighlightTextNumber = (highlight: HighLight) => {
  const highlightNum = getHightLightNumber(highlight);
  const hitNumText = i18n.t("common:result_hit_keyword", { count: highlightNum });
  return hitNumText;
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconIconDownCopy: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M234.666667 414.506667l276.693333 276.693333 276.693333-276.693333L727.722667 354.133333l-216.362667 216.405334L294.997333 354.133333 234.666667 414.506667z"
        fill={getIconColor(color, 0, "currentColor")}
      />
    </svg>
  );
};

IconIconDownCopy.defaultProps = {
  size: 14,
};

export default IconIconDownCopy;

const mapping = {
  "kaito-public-assets.s3.us-west-2.amazonaws.com": "asset.cdn.kaito.ai",
};

export const convertToCdnUrl = (url: string) => {
  let newUrl = url;

  Object.keys(mapping).forEach((key) => {
    newUrl = newUrl.replace(key, mapping[key]);
  });

  return newUrl;
};

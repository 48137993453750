/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconIconAlertCover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M447.658667 173.525333V140.8a12.8 12.8 0 0 1 12.8-12.8h102.997333a12.8 12.8 0 0 1 12.8 12.8v32.725333c0 2.858667 0.426667 5.674667 0 8.405334 112.512 30.037333 195.754667 137.130667 195.754667 264.661333v220.416a12.8 12.8 0 0 0 12.8 12.8H840.533333a12.8 12.8 0 0 1 12.8 12.8V746.666667a12.8 12.8 0 0 1-12.8 12.8h-657.066666a12.8 12.8 0 0 1-12.8-12.8v-27.008-27.050667a12.8 12.8 0 0 1 12.8-12.8h55.68a12.8 12.8 0 0 0 12.8-12.8v-220.416c0-127.530667 83.2-234.666667 195.754666-264.661333-0.426667-2.730667 0-5.546667 0-8.405334z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M439.424 816.341333a12.8 12.8 0 0 0-12.8 12.8V883.2a12.8 12.8 0 0 0 12.8 12.8h145.066667a12.8 12.8 0 0 0 12.8-12.8v-27.008-27.050667a12.8 12.8 0 0 0-12.8-12.8h-145.066667z"
        fill={getIconColor(color, 1, "currentColor")}
      />
    </svg>
  );
};

IconIconAlertCover.defaultProps = {
  size: 14,
};

export default IconIconAlertCover;

export const TimeFilterValue = {
  // All Date
  AllDates: "all_dates",
  // Last 24h
  Last24hrs: "last_24hrs",
  // Last 48h
  Last48hrs: "last_48hrs",
  // Last 7d
  Last7d: "last_7d",
  // Last 30d
  Last30d: "last_30d",
  // last 90d
  Last90d: "last_90d",
  // last 180d
  Last180d: "last_180d",
  // last 360d
  Last360d: "last_360d",
};

export const timeFilterValues = [
  {
    title: "All Date",
    sTitle: "All",
    value: TimeFilterValue.AllDates,
  },
  {
    title: "Last 24h",
    sTitle: "24h",
    value: TimeFilterValue.Last24hrs,
  },
  {
    title: "Last 48h",
    sTitle: "48h",
    value: TimeFilterValue.Last48hrs,
  },
  {
    title: "Last 7d",
    sTitle: "7d",
    value: TimeFilterValue.Last7d,
  },
  {
    title: "Last 30d",
    sTitle: "30d",
    value: TimeFilterValue.Last30d,
  },
  {
    title: "Last 3m",
    sTitle: "3m",
    value: TimeFilterValue.Last90d,
  },
  {
    title: "Last 6m",
    sTitle: "6m",
    value: TimeFilterValue.Last180d,
  },
  {
    title: "Last 12m",
    sTitle: "12m",
    value: TimeFilterValue.Last360d,
  },
];

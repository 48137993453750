/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconCard: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M576 648.533333a34.133333 34.133333 0 1 0 0 68.266667H768a34.133333 34.133333 0 0 0 0-68.266667h-192z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M51.2 213.333333A76.8 76.8 0 0 1 128 136.533333h768A76.8 76.8 0 0 1 972.8 213.333333v597.333334a76.8 76.8 0 0 1-76.8 76.8H128A76.8 76.8 0 0 1 51.2 810.666667V213.333333zM128 204.8a8.533333 8.533333 0 0 0-8.533333 8.533333v93.866667h785.066666V213.333333a8.533333 8.533333 0 0 0-8.533333-8.533333H128z m-8.533333 170.666667V810.666667c0 4.693333 3.84 8.533333 8.533333 8.533333h768a8.533333 8.533333 0 0 0 8.533333-8.533333V375.466667H119.466667z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconCard.defaultProps = {
  size: 14,
};

export default IconCard;

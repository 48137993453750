/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconDeleteUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M426.666667 386.346667a40.32 40.32 0 0 0-40.277334 40.32v277.333333a40.32 40.32 0 0 0 80.64 0V426.666667A40.32 40.32 0 0 0 426.666667 386.346667zM637.653333 426.666667a40.32 40.32 0 1 0-80.64 0v277.333333a40.32 40.32 0 0 0 80.64 0V426.666667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M411.477333 45.013333a40.32 40.32 0 0 0-35.328 20.906667L317.44 173.056H85.333333a40.32 40.32 0 1 0 0 80.64h66.346667V917.333333c0 34.048 27.605333 61.653333 61.653333 61.653334h597.333334c34.048 0 61.653333-27.605333 61.653333-61.653334V253.653333H938.666667a40.32 40.32 0 0 0 0-80.64h-231.893334L649.386667 66.261333a40.32 40.32 0 0 0-35.541334-21.248H411.477333z m203.733334 128H409.386667l25.984-47.36h154.453333l25.386667 47.36z m-382.890667 80.64h559.36v644.693334H232.32V253.653333z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconDeleteUncover.defaultProps = {
  size: 14,
};

export default IconIconDeleteUncover;

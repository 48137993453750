import { aesDecryptBase64Api } from "@metasearch-io/kaito-api-security";

import { AxiosInterceptor } from "./types";

export const decryptRespInterceptor: AxiosInterceptor = (client) => {
  client.interceptors.response.use((resp) => {
    if (resp.headers["encryption"] === "true") {
      const decryptedData = aesDecryptBase64Api(resp.data);
      resp.data = decryptedData;
    }
    return resp;
  });
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconASearchsummary: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M622.762667 256a32 32 0 0 0 0 64H789.333333l-228.565333 239.146667-176.085333-107.349334a32 32 0 0 0-41.344 6.954667l-208 251.477333a32 32 0 1 0 49.322666 40.789334l190.336-230.144 174.506667 106.325333a32 32 0 0 0 39.765333-5.248l242.944-254.165333v161.664a32 32 0 0 0 64 0V288a32 32 0 0 0-32-32h-241.450666z"
        fill={getIconColor(color, 0, "currentColor")}
      />
    </svg>
  );
};

IconASearchsummary.defaultProps = {
  size: 14,
};

export default IconASearchsummary;

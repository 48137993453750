/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconLink: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M370.773333 853.333333a200.106667 200.106667 0 0 1-141.525333-341.632l84.949333-84.906666 56.618667 56.576-84.906667 84.906666a120.106667 120.106667 0 0 0 169.813334 169.813334l84.906666-84.906667 56.576 56.661333-84.906666 84.906667A198.912 198.912 0 0 1 370.858667 853.333333z m28.330667-171.818666l-56.618667-56.618667 283.050667-283.008 56.576 56.618667-282.965333 282.965333h-0.042667z m311.338667-84.906667l-56.618667-56.576 84.906667-84.906667a120.064 120.064 0 1 0-169.813334-169.813333l-84.906666 84.906667-56.618667-56.618667 84.906667-84.906667a200.106667 200.106667 0 0 1 283.050666 282.965334l-84.906666 84.906666v0.042667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconLink.defaultProps = {
  size: 14,
};

export default IconIconLink;

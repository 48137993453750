/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconDashboard: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M602.112 358.698667h189.184V232.746667h-189.184v125.952zM571.093333 170.666667h251.221334a31.018667 31.018667 0 0 1 31.018666 31.018666v188.074667a31.018667 31.018667 0 0 1-31.018666 31.018667h-251.221334a31.018667 31.018667 0 0 1-31.018666-31.018667V201.685333A31.061333 31.061333 0 0 1 571.093333 170.666667z m220.16 620.629333v-250.88h-189.141333v250.88h189.184z m-251.221333 31.018667v-312.917334a31.061333 31.061333 0 0 1 31.061333-31.018666h251.221334a31.018667 31.018667 0 0 1 31.018666 31.018666v312.917334a31.018667 31.018667 0 0 1-31.018666 31.018666h-251.221334a30.976 30.976 0 0 1-31.018666-31.018666z m-118.144-31.018667v-125.994667H232.746667v125.994667h189.141333zM170.666667 822.314667v-188.032a31.018667 31.018667 0 0 1 31.018666-31.061334h251.221334a31.061333 31.061333 0 0 1 31.018666 31.061334v188.032a31.061333 31.061333 0 0 1-31.018666 31.018666H201.685333A31.018667 31.018667 0 0 1 170.666667 822.314667z m251.221333-338.773334V232.789333H232.746667v250.837334h189.141333zM170.666667 514.645333V201.685333A31.018667 31.018667 0 0 1 201.685333 170.666667h251.221334a31.018667 31.018667 0 0 1 31.018666 31.018666v312.917334a31.061333 31.061333 0 0 1-31.018666 31.018666H201.685333A31.061333 31.061333 0 0 1 170.666667 514.602667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconDashboard.defaultProps = {
  size: 14,
};

export default IconIconDashboard;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAMictalk: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M53.333333 149.333333A32 32 0 0 1 85.333333 117.333333h853.333334a32 32 0 0 1 32 32v266.666667h-64v-234.666667H117.333333v576H234.666667a32 32 0 0 1 32 32v54.912l167.04-83.541333a32 32 0 0 1 14.293333-3.370667h128v64h-120.448l-206.592 103.253334A32 32 0 0 1 202.666667 896v-74.666667H85.333333a32 32 0 0 1-32-32v-640z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M224 288h192v64h-192v-64zM224 416v64h320v-64h-320z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
      <path
        d="M687.061333 496.938667a102.272 102.272 0 1 1 204.544 0v130.517333a102.272 102.272 0 0 1-204.544 0v-130.517333z m102.272-38.272a38.272 38.272 0 0 0-38.272 38.272v130.517333a38.272 38.272 0 1 0 76.544 0v-130.517333a38.272 38.272 0 0 0-38.272-38.272z"
        fill={getIconColor(color, 2, 'currentColor')}
      />
      <path
        d="M669.482667 608V640a118.613333 118.613333 0 0 0 101.290666 117.333333h34.602667A118.613333 118.613333 0 0 0 906.666667 640v-32h64V640a182.613333 182.613333 0 0 1-149.333334 179.584v61.994667h-64v-61.568A182.613333 182.613333 0 0 1 605.44 640v-32h64z"
        fill={getIconColor(color, 3, 'currentColor')}
      />
    </svg>
  );
};

IconAMictalk.defaultProps = {
  size: 14,
};

export default IconAMictalk;

export type SourceFilterValueType =
  | [number, number]
  | { value: string; [key: string]: any }[]
  | string
  | boolean;

export enum SourceFilterType {
  Text,
  Range,
  MultiSelect,
  RadioSelect,
  SingleSelect,
  Customization,
}

export enum SourceFilterScope {
  Twitter = "twitter",
  Discord = "discord",
  Podcast = "podcast",
  News = "news",
  Research = "research",
  Medium = "medium",
  TwitterSpace = "Twitter Space",
  Mirror = "mirror",
  Governance = "governance",
  Conference = "conference",
  Newsletter = "newsletter",
  Warpcast = "farcaster",
  Telegram = "telegram",
  Vote = "vote",
}
export type FilterBlock = {
  fields: CombineField[];
  title?: string;
  showSuggest?: boolean;
  field?: string;
  /**
   * @default true
   */
  hasDivider?: boolean;
};

export interface BaseField {
  field: string;
  label?: string;
  scope: string;
  type: SourceFilterType;
  renderFilterField?: (onChange: (value: any) => void, value: any, filters?) => React.ReactNode;
  toTitle?: (value: any) => string;
  // whether or not to count as a change for this source filter field, used in function **getChangedFieldsCountForSpecificSource**
  notCount?: boolean;
  hasDivider?: boolean;
  showSuggest?: boolean;
  openExclude?: boolean;
}

export interface RangeField extends BaseField {
  range: number[];
  type: SourceFilterType.Range;
}
export interface TextField extends BaseField {
  type: SourceFilterType.Text;
  placeholder: string;
}

export interface RadioSelectField extends BaseField {
  type: SourceFilterType.RadioSelect;
}

interface MultiSelectField extends BaseField {
  type: SourceFilterType.MultiSelect;
}
interface SingleSelectField extends BaseField {
  type: SourceFilterType.SingleSelect;
}

interface CustomizationField extends BaseField {
  type: SourceFilterType.Customization;
}

export type MulitSelectWithOption = MultiSelectField;

export type CombineField =
  | RangeField
  | TextField
  | MulitSelectWithOption
  | RadioSelectField
  | SingleSelectField
  | CustomizationField;

export interface RangeFiltersProps {
  filters: any;
  filterBlocks: FilterBlock[];
  setFilter: any;
  renderFilterButton: (onOpen: () => void, isOpen: boolean) => React.ReactNode;
  officialAccounts?: any;
  name?: string;
  className?: string;
}

export enum TweetLength {
  Short = "short",
  Long = "long",
  Thread = "thread",
  Article = "article",
}

export enum TweetSentimentFilter {
  Bullish = "bullish",
  Bearish = "bearish",
  Neutral = "neutral",
}

export enum TweetType {
  Tweet = "tweet",
  Quote = "quote",
  Reply = "reply",
}

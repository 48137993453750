export enum PlanLevel {
  Free = "FREE",
  Standard = "STANDARD",
  FreeTrial = "FreeTrial",
  Pro = "PRO",
  BusinessTrial = "BusinessTrial",
  Business = "BUSINESS", // Individual(Elite)
}

export interface PricingPlan {
  active: boolean;
  name: string;
  price: number;
  isFreeTrail: boolean;
  priceId?: string;
  productId?: string;
  planLevel: PlanLevel;
  interval?: PlanInterval;
  list: string[];
}

export type PlanInterval = "day" | "month" | "year" | "2year";

export enum PayType {
  Stripe = "stripe",
  Crypto = "crypto",
}

export enum WebhookApiType {
  SUBSCRIPTION_CANCELLATION_SURVEY = "SUBSCRIPTION_CANCELLATION_SURVEY",
}

import { chakra } from "@chakra-ui/react";
import { atom, useAtom } from "jotai";
import { useAtomValue } from "jotai/index";
import { useRouter } from "next/router";
import { twMerge } from "tailwind-merge";

import { TwitterXIcon, TwitterXPrefixIcon } from "@/components/Icon";
import IconFont from "@/components/Icon/iconfont";
import ConnectSuccessPng from "@/public/TwitterConnectSuccess.png";
import { userInfoAtom } from "@/store/userInfo";

export const twitterConnectSuccessSmartFollowingBannerAtom = atom(false);

export const ConnectTwitterSuccess = () => {
  const userInfo = useAtomValue(userInfoAtom);
  const [, setShowConnectSuccessBanner] = useAtom(twitterConnectSuccessSmartFollowingBannerAtom);
  const router = useRouter();

  const onClickToView = () => {
    setShowConnectSuccessBanner(false);
    router.push("/smart_following/setting");
  };

  const onGoAccount = () => {
    setShowConnectSuccessBanner(false);
    router.push("/account");
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center flex-shrink-0 bg-black/70 z-20">
      <div className="flex flex-col items-center w-[600px] h-[798px] pb-[36px] bg-G2-600 border border-solid border-G2-300 rounded">
        <chakra.img src={ConnectSuccessPng.src} className="w-[600px] h-[182px]" />
        <div className="mt-6 text-2xl font-medium text-white">
          @{userInfo?.twitterInfo?.twitter_user_name} connected successfully!
        </div>
        <div
          className="w-[510px] h-[398px] flex flex-col items-center gap-6 mt-6 relative"
          style={{
            borderRadius: "6px",
            border: "1px solid rgba(97, 237, 230, 0.60)",
            background:
              "linear-gradient(91deg, rgba(29, 153, 147, 0.10) 0.72%, rgba(23, 47, 107, 0.30) 49.64%, rgba(118, 81, 200, 0.20) 98.56%)",
          }}
        >
          <div className="font-medium text-base mt-5">Your Twitter Circle has been created now</div>
          <video
            className="z-20"
            src="https://kaito-public-assets.s3.us-west-2.amazonaws.com/videos/smart_following_twitter_circle_guidance.mov"
            width={426}
            height={250}
            loop
            controls={false}
            autoPlay
            muted
          />
          <div
            className="px-2 h-[36px] flex items-center text-sm break-all text-black font-medium rounded bg-brand-200 cursor-pointer"
            onClick={onClickToView}
          >
            Click to view
          </div>
          <IconFont
            name="a-icon-Fourpointedstar-cover"
            size={42}
            className="absolute bottom-4 right-4 text-white/10"
          />
          <IconFont
            name="a-icon-Fourpointedstar-cover"
            size={34}
            className="absolute top-[193px] right-3 text-white/10"
          />
          <IconFont
            name="a-icon-Fourpointedstar-cover"
            size={34}
            className="absolute top-[46px] left-[36px] text-white/10"
          />
        </div>
        <div className="text-sm font-medium text-white/80 self-start ml-[45px] mt-4">
          You can always manage your Twitter Account in{" "}
          <span className="text-brand-200 cursor-pointer" onClick={onGoAccount}>
            My Account
          </span>
          .
        </div>
        <div
          className="text-brand-200 text-sm font-medium self-start	ml-[45px] mt-10 cursor-pointer"
          onClick={() => setShowConnectSuccessBanner(false)}
        >
          Not Now
        </div>
      </div>
    </div>
  );
};

export const TwitterWithPrefix = ({ className }: { className?: string }) => {
  return (
    <div className={twMerge(["inline-block relative", className])}>
      <TwitterXIcon width="24px" height="24px" />
      <TwitterXPrefixIcon className="absolute -right-[1px] -bottom-[1px]" />
    </div>
  );
};

import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { noop } from "lodash";

import { PlanLevel } from "@/types/pricing";

const initialValue = {
  stopAutoScroll: noop,
  handleOpenPreview: noop,
  isGenerating: false,
};

const uiBaseAtom = atomWithReset(initialValue);

export const uiControlAtom = atom(
  (get) => get(uiBaseAtom),
  (get, set, value: Partial<typeof initialValue>) => {
    set(uiBaseAtom, {
      ...get(uiBaseAtom),
      ...value,
    });
  }
);

export const isGeneratingAtom = atom((get) => get(uiBaseAtom).isGenerating);

const defaultPricingModal = {
  enableHide: false,
  visible: false,
  // home top tips
  showPricingTips: false,
  bannerMode: "" as "FreeTrialAutoDowngrade",
  // show balloon
  showBallon: false,
  isShowFreePayConfirm: false,
  isShowUpgradeConfirm: false,
  isShowdowngradeConfirm: false,
  isShowPayTypeConfirm: false,
};

const pricingModalBaseAtom = atomWithReset(defaultPricingModal);

export const pricingModalAtom = atom(
  (get) => get(pricingModalBaseAtom),
  (
    get,
    set,
    value:
      | Partial<typeof defaultPricingModal>
      | ((value: Partial<typeof defaultPricingModal>) => Partial<typeof defaultPricingModal>)
  ) => {
    if (typeof value === "function") {
      const fn = value;
      const nextValue = fn(get(pricingModalBaseAtom));
      set(pricingModalBaseAtom, {
        ...get(pricingModalBaseAtom),
        ...nextValue,
      });
      return;
    }

    set(pricingModalBaseAtom, {
      ...get(pricingModalBaseAtom),
      ...value,
    });
  }
);

export const showPricingTipsAtom = atom((get) => get(pricingModalBaseAtom).showPricingTips);
export const showBallonAtom = atom((get) => get(pricingModalBaseAtom).showBallon);

const defaultPlanDowngradeModal = {
  visible: false,
  currentPlanLevel: PlanLevel.Free,
  downgradePlanLevel: PlanLevel.Free,
  confirmCb: async () => {},
};

export type PlanDowngradeModalProps = typeof defaultPlanDowngradeModal;
const planDowngradeModalBaseAtom = atomWithReset(defaultPlanDowngradeModal);

export const planDowngradeModalAtom = atom(
  (get) => get(planDowngradeModalBaseAtom),
  (get, set, value: Partial<PlanDowngradeModalProps>) => {
    set(planDowngradeModalBaseAtom, {
      ...get(planDowngradeModalBaseAtom),
      ...value,
    });
  }
);

const defaultAuthFeatureModal = {
  visible: false,
  closeWhenViewPlan: false,
  mode: "" as "Alert" | "AlertBusiness" | "TldrMode" | "ArticleTldr" | "Dashboard",
  onClose: noop,
};

export type AuthFeatureModalProps = typeof defaultAuthFeatureModal;

const authFeatureModalBaseAtom = atomWithReset(defaultAuthFeatureModal);

export const authFeatureModalAtom = atom(
  (get) => get(authFeatureModalBaseAtom),
  (get, set, value: Partial<AuthFeatureModalProps>) => {
    set(authFeatureModalBaseAtom, {
      ...get(authFeatureModalBaseAtom),
      ...value,
    });
  }
);

export const CelebrateModalAtom = atomWithReset({
  show: false,
});

const defaultSearchOverlay: {
  visible: boolean;
  type: "NormalSearch" | "AddWatchList" | "UpdateWatchList";
  initSearchUrl: string;
} = {
  visible: false,
  type: "NormalSearch",
  initSearchUrl: "",
};

const searchOverlayBaseAtom = atom(defaultSearchOverlay);

export const searchOverlayAtom = atom(
  (get) => get(searchOverlayBaseAtom),
  (
    get,
    set,
    value:
      | Partial<typeof defaultSearchOverlay>
      | ((value: Partial<typeof defaultSearchOverlay>) => Partial<typeof defaultSearchOverlay>)
  ) => {
    if (typeof value === "function") {
      const fn = value;
      const nextValue = fn(get(searchOverlayBaseAtom));
      set(searchOverlayBaseAtom, {
        ...get(searchOverlayBaseAtom),
        ...nextValue,
      });
      return;
    }

    set(searchOverlayBaseAtom, {
      ...get(searchOverlayBaseAtom),
      ...value,
    });
  }
);

const initialStandardTip = {
  handleOpenStandardTip: noop,
  openStandardTip: false,
};

const standardTipAtom = atomWithReset(initialStandardTip);

export const standardTipUseAtom = atom(
  (get) => get(standardTipAtom),
  (get, set, value: Partial<typeof initialStandardTip>) => {
    set(standardTipAtom, {
      ...get(standardTipAtom),
      ...value,
    });
  }
);

const initialSentimentTime = {
  time: "all_dates",
};

const sentimentTimeAtom = atomWithReset(initialSentimentTime);

export const sentimentTimeUseAtom = atom(
  (get) => get(sentimentTimeAtom),
  (get, set, value: Partial<typeof initialSentimentTime>) => {
    set(sentimentTimeAtom, {
      ...get(sentimentTimeAtom),
      ...value,
    });
  }
);

const initialResultsPickUp = {
  resultsIsPickUp: false,
  feedsIsHide: true,
};

const resultsIsPickUpAtom = atomWithReset(initialResultsPickUp);

export const resultsIsPickUpUseAtom = atom(
  (get) => get(resultsIsPickUpAtom),
  (get, set, value: Partial<typeof initialResultsPickUp>) => {
    set(resultsIsPickUpAtom, {
      ...get(resultsIsPickUpAtom),
      ...value,
    });
  }
);

const initialComopanySort = {
  sortBy: "company",
  direction: "asc",
  selectCompany: ["all"],
  search: "",
};

const comopanySortAtom = atomWithReset(initialComopanySort);

export const comopanySortUseAtom = atom(
  (get) => get(comopanySortAtom),
  (get, set, value: Partial<typeof initialComopanySort>) => {
    set(comopanySortAtom, {
      ...get(comopanySortAtom),
      ...value,
    });
  }
);

const initialpayShow = {
  isShowRetryPay: false,
  isShowUpdatePay: false,
  payPrice: 0,
};

const resultsIsShowPay = atomWithReset(initialpayShow);

export const paymentRetryUseAtom = atom(
  (get) => get(resultsIsShowPay),
  (get, set, value: Partial<typeof initialpayShow>) => {
    set(resultsIsShowPay, {
      ...get(resultsIsShowPay),
      ...value,
    });
  }
);

const initialComopanyFilter = {
  sortBy: "company",
  direction: "asc",
  selectCompany: ["all"],
  search: "",
};

const comopanyFilterAtom = atomWithReset(initialComopanyFilter);

export const comopanyFilterUseAtom = atom(
  (get) => get(comopanyFilterAtom),
  (get, set, value: Partial<typeof initialComopanyFilter>) => {
    set(comopanyFilterAtom, {
      ...get(comopanyFilterAtom),
      ...value,
    });
  }
);

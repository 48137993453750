/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconSearchlistTheblock: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M852.821333 239.018667l-343.338666-133.845334a36.010667 36.010667 0 0 0-13.866667-2.602666c-5.248-0.853333-9.6 0.853333-14.805333 2.602666L138.368 239.018667a25.429333 25.429333 0 0 0-16.554667 23.466666V721.493333c0 10.410667 6.101333 19.114667 15.658667 23.466667l343.338667 139.946667a40.533333 40.533333 0 0 0 14.805333 2.56c5.205333 0 9.557333-0.853333 15.616-3.413334l341.589333-139.093333a25.429333 25.429333 0 0 0 16.512-23.466667V263.381333a29.184 29.184 0 0 0-16.512-24.32zM785.92 724.053333l-290.346667 116.48-290.304-116.48 290.346667-105.173333 290.304 105.173333zM205.226667 260.778667l290.346666-111.274667 290.304 111.274667-290.346666 106.026666-290.304-106.026666z m398.976 315.52a13.056 13.056 0 0 0 8.704-12.202667V420.693333a13.056 13.056 0 0 0-8.704-12.16l-41.728-16.512 258.133333-95.616v392.874667l-258.133333-95.573333 41.728-17.408z m-39.125334-131.242667v95.573333l-69.546666 27.818667-69.546667-27.818667v-95.573333l69.546667-27.818667 69.546666 27.818667z m-186.88-22.613333v142.506666c0 5.248 3.498667 10.453333 8.704 12.202667l41.728 16.512-258.133333 95.616V296.405333l258.133333 95.573334-40.874666 16.554666c-6.058667 1.706667-9.557333 7.808-9.557334 13.909334z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconSearchlistTheblock.defaultProps = {
  size: 14,
};

export default IconAIconSearchlistTheblock;

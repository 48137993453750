import type { SystemStyleFunction, SystemStyleObject } from "@chakra-ui/theme-tools";
import { mode } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
  _disabled: {
    opacity: "initial",
  },
  borderRadius: "4px",
  fontWeight: 500,
};
const variantGhost: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  if (c === "grayBlue") {
    return {
      color: mode("", `brand.200`)(props),
      bg: "transparent",
      minW: "48px",

      _hover: {
        bg: mode("", `${c}.200`)(props),
        _disabled: {
          bg: `${c}.500`,
          color: "brand.500",
        },
      },
      _active: {
        bg: mode("", `${c}.400`)(props),
      },
      _disabled: {
        bg: `${c}.500`,
        color: "brand.500",
      },
    };
  }
  return {};
};

const variantOutline: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  if (c === "brand") {
    const lightBrand = "";
    return {
      bg: "transparent",
      color: `brand.200`,
      borderColor: `brand.200`,
      borderRadius: "4px",
      _hover: {
        backgroundColor: `whiteAlpha.5`,
        borderColor: mode(lightBrand, `brand.100`)(props),
        color: `brand.100`,
        _disabled: {
          borderColor: mode(lightBrand, `brand.500`)(props),
          color: `brand.500`,
        },
      },
      _active: {
        borderColor: mode(lightBrand, `brand.300`)(props),
        color: `brand.300`,
      },
      _disabled: {
        borderColor: mode(lightBrand, `brand.500`)(props),
        color: `brand.500`,
      },
    };
  }
  return {};
};

const variantSolid: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  if (c === "brand") {
    //@TODO not support light mode

    const lightBrand = "";
    const bg = mode(lightBrand, `brand.200`)(props);

    return {
      bg,
      color: "black",
      _hover: {
        bg: mode(lightBrand, `brand.100`)(props),
        _disabled: {
          bg: "brand.500",
          color: "blackAlpha.300",
        },
      },
      _disabled: {
        bg: "brand.500",
        color: "blackAlpha.300",
      },
      _active: { bg: mode(lightBrand, `brand.300`)(props) },
    };
  }

  if (c === "grayBlue") {
    const lightGrayBlue = "";
    const bg = mode(lightGrayBlue, `grayBlue.300`)(props);
    return {
      bg,
      color: "white",
      _hover: {
        bg: mode(lightGrayBlue, `grayBlue.200`)(props),
        _disabled: {
          bg: "grayBlue.500",
          color: "whiteAlpha.300",
        },
      },
      _disabled: {
        bg: "grayBlue.500",
        color: "whiteAlpha.300",
      },
      _active: { bg: mode(lightGrayBlue, `grayBlue.400`)(props) },
    };
  }
  if (c === "red") {
    const lightGrayBlue = "";
    const bg = mode(lightGrayBlue, `R.200`)(props);
    return {
      bg,
      color: "white",
      _hover: {
        bg: mode(lightGrayBlue, `R.100`)(props),
        _disabled: {
          bg: "R.500",
          color: "whiteAlpha.300",
        },
      },
      _disabled: {
        bg: "R.500",
        color: "whiteAlpha.300",
      },
      _active: { bg: mode(lightGrayBlue, `R.300`)(props) },
    };
  }
  return {};
};
const variantLink: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  return {
    padding: 0,
    height: "auto",
    lineHeight: "normal",
    verticalAlign: "baseline",
    color: mode(``, `${c}.200`)(props),
    _hover: {
      textDecoration: "underline",
      color: mode(``, `${c}.100`)(props),
      _disabled: {
        textDecoration: "none",
      },
    },
    _active: {
      color: mode(``, `${c}.300`)(props),
    },
  };
};

const variants = {
  solid: variantSolid,
  ghost: variantGhost,
  link: variantLink,
  outline: variantOutline,
};

const sizes: Record<string, SystemStyleObject> = {
  lg: {
    h: 10,
    minW: "80px",
    fontSize: "16px",
    px: 4,
  },
  md: {
    h: 9,
    minW: "80px",
    fontSize: "14px",
    px: 4,
  },
  sm: {
    h: 6,
    minW: "48px",
    fontSize: "12px",
    px: 2,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sizes,
  variants,
  baseStyle,
};

import { useAtomValue } from "jotai";
import Image from "next/image";
import React from "react";

import { PaymentFailModal } from "@/components/Account/paymentFailModal";
import { AUTH_LIMIT_PAGE_SHOW, DASHBOARD_IMG, FreeUserallowShowPage, S3BaseUrl } from "@/constants";
import { subscriptionAtom, userInfoAtom } from "@/store/userInfo";
import { isFreeUser, isPaymentErr } from "@/utils/user";
interface Props {
  children: React.ReactElement;
}

/**
 * If the current user does not pay or fails to pay,
 * pages other than "/account" and" /buy" are not displayed
 */

const AuthPage: React.FC<Props> = ({ children }) => {
  const userInfo = useAtomValue(userInfoAtom);
  const subscription = useAtomValue(subscriptionAtom);

  const getpageContentImg = (pathname) => {
    if (!pathname) {
      return "";
    }
    const [_, firstPath] = pathname.split("/");
    if (!firstPath) {
      return DASHBOARD_IMG;
    }
    return AUTH_LIMIT_PAGE_SHOW[firstPath] ?? "";
  };

  const pageContent = () => {
    let backImg = getpageContentImg(location.pathname);
    if (backImg) {
      return (
        <Image
          src={`${S3BaseUrl}${backImg}`}
          alt="gating_dashboard"
          width={1920}
          height={1080}
          className="!w-full !h-full"
        />
      );
    }
    return null;
  };

  if (
    (isFreeUser(userInfo) || isPaymentErr(userInfo, subscription)) &&
    !FreeUserallowShowPage.includes(location.pathname)
  ) {
    return (
      <div className="relative overflow-hidden">
        {pageContent()}
        <PaymentFailModal />
      </div>
    );
  }

  return <>{children}</>;
};

export default AuthPage;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconLightningCover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M706.986667 197.376a49.749333 49.749333 0 0 0-78.506667-57.088L230.4 488.661333a49.792 49.792 0 0 0 32.810667 87.253334h173.397333l-119.594667 279.04a49.749333 49.749333 0 0 0 78.506667 57.045333l398.165333-348.373333c15.573333-13.653333 21.162667-35.626667 13.866667-54.912a49.92 49.92 0 0 0-46.677333-32.170667h-173.397334l119.594667-279.168z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconLightningCover.defaultProps = {
  size: 14,
};

export default IconIconLightningCover;

/// <reference types="@welldone-software/why-did-you-render" />
import React from "react";

if (process.env.NODE_ENV === "development" && process.env.ENABLE_WhyDidYouRender === "true") {
  if (typeof window !== "undefined") {
    console.log("why-did-you-render enabled");
    // eslint-disable-next-line import/no-extraneous-dependencies, global-require
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, {
      trackAllPureComponents: true,
      trackHooks: true,
      logOwnerReasons: true,
      collapseGroups: true,
      include: [/^DataTableWithCircle/],
      logOnDifferentValues: true,
    });
  }
}

import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";

interface DialogProps {
  title: string;
  content: React.ReactNode;
  okText?: string;
  okButtonColorScheme?: "brand" | "red";
  cancelText?: string;
  showCloseIcon?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  isOpen: boolean;
  onClose: () => void;
  closeOnOverlayClick?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({
  title,
  content,
  okText,
  cancelText,
  showCloseIcon,
  onOk,
  onCancel,
  isOpen,
  onClose,
  closeOnOverlayClick = false,
  okButtonColorScheme = "brand",
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent
        p="16px 24px 24px 24px"
        bg="grayBlue.700"
        border="1px solid"
        borderColor="whiteAlpha.10"
        borderRadius="4px"
      >
        <ModalHeader fontSize="16px" fontWeight="500" p="0" lineHeight="24px">
          {title}
        </ModalHeader>
        {showCloseIcon ? (
          <CloseIcon
            position="absolute"
            right="24px"
            top="22px"
            width="10px"
            height="10px"
            color="whiteAlpha.200"
            onClick={onClose}
            cursor="pointer"
          />
        ) : null}
        <Text fontSize="14px" color="whiteAlpha.300" mt="16px">
          {content}
        </Text>
        {onCancel || onOk ? (
          <Flex mt="16px" gap="8px" justifyContent="flex-end">
            {onCancel ? (
              <Button colorScheme="grayBlue" onClick={onCancel}>
                {cancelText}
              </Button>
            ) : null}
            {onOk ? (
              <Button colorScheme={okButtonColorScheme} onClick={onOk}>
                {okText}
              </Button>
            ) : null}
          </Flex>
        ) : null}
      </ModalContent>
    </Modal>
  );
};

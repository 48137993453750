/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconInfoCover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.658667 853.333333a341.333333 341.333333 0 1 1 0.682666-682.666666 341.333333 341.333333 0 0 1-0.682666 682.666666z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M546.133333 341.333333h-68.266666v68.266667h68.266666V341.333333zM546.133333 448h-102.4v68.181333h34.133334V614.4H426.666667V682.666667h170.666666v-68.266667h-51.2v-166.4z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconInfoCover.defaultProps = {
  size: 14,
};

export default IconIconInfoCover;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAFrame1312317937: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M224 128A96 96 0 0 0 128 224v576A96 96 0 0 0 224 896h576A96 96 0 0 0 896 800v-82.773333a38.4 38.4 0 1 0-76.8 0v82.773333a19.2 19.2 0 0 1-19.2 19.2H224a19.2 19.2 0 0 1-19.2-19.2V224a19.2 19.2 0 0 1 19.2-19.2h576a19.2 19.2 0 0 1 19.2 19.2v93.610667a38.4 38.4 0 1 0 76.8 0V224A96 96 0 0 0 800 128H224z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M654.336 423.936a38.4 38.4 0 1 0-54.272-54.272L457.685333 512l142.378667 142.336a38.4 38.4 0 1 0 54.272-54.272L604.714667 550.4H857.6a38.4 38.4 0 1 0 0-76.8h-252.885333l49.621333-49.664z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconAFrame1312317937.defaultProps = {
  size: 14,
};

export default IconAFrame1312317937;

import { newSearchQueryString } from "./index";
import { isObject } from "./isObject";

type ParseObject = {
  filters: {
    field: string;
    type: string;
    values?: any[];
  }[];
  [k: string]: any;
};

const _replaceFilterFieldValue = (parseObj: ParseObject, key: string, pickProperties: string[]) => {
  try {
    const targetFieldIndex = parseObj.filters.findIndex(({ field }) => field === key);
    if (targetFieldIndex === -1) return parseObj;
    const targetField = parseObj.filters[targetFieldIndex];
    if (!targetField.values) return parseObj;
    const pickValues = Array.isArray(targetField.values?.[0])
      ? [
          targetField.values[0].map((item) => {
            const res = {};
            pickProperties.forEach((propert) => {
              if (item[propert]) {
                res[propert] = item[propert];
              }
            });
            return res;
          }),
        ]
      : targetField.values;
    parseObj.filters[targetFieldIndex] = {
      ...targetField,
      values: pickValues,
    };
    return parseObj;
  } catch (e) {
    console.error(e);
    return parseObj;
  }
};

const filterFields: [string, string[]][] = [["twitter_user_id", ["value", "type", "_type"]]];

export const replaceFilterFieldValue = (pattern: string) => {
  try {
    const parseObj = newSearchQueryString.parse(pattern) as ParseObject;
    if (!isObject(parseObj) || !parseObj.filters) {
      throw new Error("please check pattern");
    }

    filterFields.forEach(([key, pickProperties]) => {
      _replaceFilterFieldValue(parseObj, key, pickProperties);
    });
    return newSearchQueryString.stringify(parseObj);
  } catch (e) {
    console.error(e);
    return pattern;
  }
};

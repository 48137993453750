import { useCallback, useRef } from "react";

type noop = (this: any, ...args: any[]) => any;

export const useCallbackFn = <T extends noop>(callback: T) => {
  const _ref = useRef(callback);
  _ref.current = callback;

  const fn = useCallback((...args) => {
    return _ref.current(...args);
  }, []);

  return fn as T;
};

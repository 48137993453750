import { client } from "@/client/base";

interface Event {
  type: string;
  /**
   * {
   *  name: 'xxx',
   *    metadata: {
   *      user_id: xxx,
   *      xxx
   *    }
   * }
   */
  params: any;
}

class LoggerClient {
  logEvent(event: Event) {
    return client.get("/log_event", {
      params: { data: { ...event, timeStamp: new Date().toISOString() } },
    });
  }
}

export default new LoggerClient();

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconChatUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M317.056 805.802667a30.293333 30.293333 0 0 1-30.293333-30.848l1.024-59.861334C201.898667 665.002667 149.333333 578.858667 149.333333 486.144 149.333333 335.786667 285.312 213.333333 452.48 213.333333c167.168 0 303.146667 122.410667 303.146667 272.853334 0 150.4-135.978667 272.810667-303.146667 272.810666a322.133333 322.133333 0 0 1-41.472-2.773333l-78.762667 45.525333a30.336 30.336 0 0 1-15.189333 4.053334zM452.48 273.92c-133.717333 0-242.517333 95.189333-242.517333 212.224 0 75.562667 46.762667 146.090667 122.026666 183.936a30.293333 30.293333 0 0 1 16.725334 27.605333l-0.426667 24.746667 41.472-23.978667a30.421333 30.421333 0 0 1 19.84-3.669333c15.274667 2.346667 29.269333 3.541333 42.88 3.541333 133.717333 0 242.517333-95.189333 242.517333-212.181333 0-117.034667-108.8-212.224-242.517333-212.224z m297.173333 531.84a30.293333 30.293333 0 0 1-18.346666-6.186667l-63.061334-47.872a30.293333 30.293333 0 0 1 36.693334-48.298666l15.786666 11.989333 0.170667-7.552a30.293333 30.293333 0 0 1 17.28-26.709333c62.72-29.781333 75.861333-88.789333 75.861333-133.077334 0-29.994667-10.112-59.050667-29.269333-84.010666a30.293333 30.293333 0 1 1 48.128-36.906667c27.306667 35.626667 41.770667 77.44 41.770667 120.917333 0 80.128-33.066667 142.933333-93.568 178.858667l-1.152 49.237333a30.378667 30.378667 0 0 1-30.293334 29.610667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconChatUncover.defaultProps = {
  size: 14,
};

export default IconIconChatUncover;

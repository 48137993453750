import axios from "axios";
import { uniqBy } from "lodash";

import {
  VoteMultiSelectName,
  VoteMultiSelectSource,
} from "@/components/Filter/SourceFilter/multiselectConstants";

import {
  generateRenderFilterField,
  SelectOption,
} from "../podcast_by_name/generateMultiSelectFilterField";

export const SNAPSHOT_ID_NAME = "vote_snapshot_space_id";
export const TALLY_ID_NAME = "vote_tally_dao_id";

const getRemoteOptions = async (prefix: string) => {
  const {
    data: { data },
  } = await axios.get("/api/gateway/auto_complete", {
    params: {
      prefix,
      source: "vote",
    },
  });

  return uniqBy(
    data?.map((v) => ({
      title: v.vote_ticker_fullname,
      icon_url: v.vote_ticker_icon_url,
      value: v.value,
      data: {
        [SNAPSHOT_ID_NAME]: v?.vote_snapshot_space_id,
        [TALLY_ID_NAME]: v?.vote_tally_dao_id,
      },
      key: v.value,
    })) as SelectOption[],
    "value"
  );
};

const renderFilterField = generateRenderFilterField({
  placeholder: "Project",
  noResultTip: "No vote found",
  getRemoteOptions,
  name: VoteMultiSelectName,
  source: VoteMultiSelectSource,
});
export default renderFilterField;

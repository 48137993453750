import { Flex } from "@chakra-ui/react";

export const ToastContent: React.FC<{
  children: any;
  className?: string;
}> = ({ children, className }) => (
  <Flex
    px="20px"
    py="16px"
    bg="grayBlue.300"
    fontSize="14px"
    fontWeight="500"
    borderRadius="4px"
    boxShadow="0px 6px 16px rgba(0, 0, 0, 0.3)"
    alignItems="center"
    className={className}
  >
    {children}
  </Flex>
);

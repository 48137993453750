import { Icon, IconProps } from "./Icon";

export const DiscordVeryfiedIcon = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 20 20" fill="none">
      <path
        d="M4.83333 15H3.5C3.5 12.7909 5.29086 11 7.5 11C9.70914 11 11.5 12.7909 11.5 15H10.1667C10.1667 13.5272 8.97276 12.3333 7.5 12.3333C6.02724 12.3333 4.83333 13.5272 4.83333 15ZM12.8293 12.6067L11.0253 10.8067L11.9667 9.86133L12.828 10.7227L15.6947 7.86133L16.636 8.80533L12.828 12.6053L12.8293 12.6067ZM7.5 10.3333C6.02724 10.3333 4.83333 9.13943 4.83333 7.66667C4.83333 6.19391 6.02724 5 7.5 5C8.97276 5 10.1667 6.19391 10.1667 7.66667C10.1648 9.13866 8.972 10.3315 7.5 10.3333ZM7.5 6.33333C6.7716 6.33407 6.17859 6.91923 6.16815 7.64756C6.15772 8.37589 6.73371 8.9778 7.46179 8.99941C8.18988 9.02102 8.80055 8.45433 8.83333 7.72667V7.99333V7.66667C8.83333 6.93029 8.23638 6.33333 7.5 6.33333Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const DiscordMovement = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 20 20" fill="none">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.68182 2.5C3.30526 2.5 3 2.807 3 3.18571V8.67143C3 9.05014 3.30526 9.35714 3.68182 9.35714H17.3182C17.6947 9.35714 18 9.05014 18 8.67143V3.18571C18 2.807 17.6947 2.5 17.3182 2.5H3.68182ZM4.36364 7.98571V3.87143H16.6364V7.98571H4.36364Z"
          fill="white"
          fill-opacity="0.9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.68182 10.6429C3.30526 10.6429 3 10.9499 3 11.3286V16.8143C3 17.193 3.30526 17.5 3.68182 17.5H17.3182C17.6947 17.5 18 17.193 18 16.8143V11.3286C18 10.9499 17.6947 10.6429 17.3182 10.6429H3.68182ZM4.36364 16.1286V12.0143H16.6364V16.1286H4.36364Z"
          fill="white"
          fill-opacity="0.9"
        />
        <path
          d="M6.23853 5.07139H7.09081C7.32616 5.07139 7.51694 5.26326 7.51694 5.49996V6.3571C7.51694 6.5938 7.32616 6.78567 7.09081 6.78567H6.23853C6.00318 6.78567 5.8124 6.5938 5.8124 6.3571V5.49996C5.8124 5.26326 6.00318 5.07139 6.23853 5.07139Z"
          fill="white"
          fill-opacity="0.9"
        />
        <path
          d="M6.23853 13.2142H7.09081C7.32616 13.2142 7.51694 13.4061 7.51694 13.6428V14.5C7.51694 14.7367 7.32616 14.9285 7.09081 14.9285H6.23853C6.00318 14.9285 5.8124 14.7367 5.8124 14.5V13.6428C5.8124 13.4061 6.00318 13.2142 6.23853 13.2142Z"
          fill="white"
          fill-opacity="0.9"
        />
        <path
          d="M10.5 5.07139H9.64773C9.41237 5.07139 9.22159 5.26326 9.22159 5.49996V6.3571C9.22159 6.5938 9.41237 6.78567 9.64773 6.78567H10.5C10.7354 6.78567 10.9261 6.5938 10.9261 6.3571V5.49996C10.9261 5.26326 10.7354 5.07139 10.5 5.07139Z"
          fill="white"
          fill-opacity="0.9"
        />
        <path
          d="M9.64773 13.2142H10.5C10.7354 13.2142 10.9261 13.4061 10.9261 13.6428V14.5C10.9261 14.7367 10.7354 14.9285 10.5 14.9285H9.64773C9.41237 14.9285 9.22159 14.7367 9.22159 14.5V13.6428C9.22159 13.4061 9.41237 13.2142 9.64773 13.2142Z"
          fill="white"
          fill-opacity="0.9"
        />
      </svg>
    </Icon>
  );
};

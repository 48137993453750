import { client } from "@/client/base";

const getRemoteOptions = async (keyword: string) => {
  const { listData = [] } = (await client.get("/ai/search/farcaster_user", {
    params: {
      keyword,
    },
  })) as any;

  if (!listData) return [];

  return listData.map((v) => ({
    username: v.farcaster_user_username,
    name: v.farcaster_user_display_name,
    id: v.farcaster_user_fid,
    avatarUrl: v.farcaster_user_profile_avatar_url,
    value: v.farcaster_user_fid,
  }));
};

export default getRemoteOptions;

import { Tooltip } from "@chakra-ui/react";
import { isNil, isString } from "lodash";
import { i18n } from "next-i18next";
import { ReactNode } from "react";

import type {
  DuneDashboardObject,
  HighLight,
  OpenSearchA16zResearchObject,
  OpenSearchDiscordObject,
  OpenSearchMediumObject,
  OpenSearchMirrorObject,
  OpenSearchObject,
  OpenSearchPodcastObject,
  OpenSearchSnapshotObject,
  OpenSearchTallyObject,
  OpenSearchTelegramObject,
  OpenSearchTwitterObject,
  OpenSearchTwitterSpaceObject,
  OpenSearchUniswapObject,
  OpenSearchWhitePaperObject,
  OpenSearchYoutubeObject,
  SourceName,
} from "@/types";
import { formatNumber, formatTwitterPubType } from "@/utils";
import { dataSource2SourceName } from "@/utils/convertDataSource";
import { getHightLightNumber } from "@/utils/highlight";

import IconFont from "../Icon/iconfont";

type Props = {
  item: OpenSearchObject;
  highlight?: HighLight;
};

const renderTag = (title: React.ReactNode) => (
  <span className="p-1 rounded search-render-tag bg-white/10 text-white/60">{title}</span>
);

const renderNewTag = (node: React.ReactNode) => {
  return (
    <span className="inline-flex items-center gap-1 px-1 rounded search-render-tag hover:bg-white/10 text-white/60 hover:text-white/80">
      {node}
    </span>
  );
};

const renderHighlightTextNumber = (highlight: HighLight): ReactNode => {
  const highlightNum = getHightLightNumber(highlight);

  return renderIconTagWithTooltip({
    icon: <IconFont name="icon-Keywords_uncover" />,
    highlight: highlightNum,
  });
};

const renderIconTag = (icon: React.ReactNode, node: React.ReactNode) => {
  return (
    <span className="inline-flex items-center gap-[2px] text-white/40 hover:text-white/60">
      {icon}
      {node}
    </span>
  );
};

const renderTwitterTags = (item: OpenSearchTwitterObject) => {
  return (
    <>
      {formatTwitterPubType(item)}{" "}
      {renderIconTagWithTooltip({
        icon: <IconFont name="icon-hear_uncovert" />,
        engagement: item.engagement_count ?? 0,
      })}
      {renderIconTagWithTooltip({
        icon: <IconFont name="bulb" />,
        smart_engagement: item.twitter_kkol_engagement_count_ranking ?? 0,
      })}
      {renderIconTagWithTooltip({
        icon: <IconFont name="show_uncover" />,
        impression: item.twitter_tweet_view_count ?? 0,
      })}
      {renderIconTagWithTooltip({
        icon: <IconFont name="icon-Bookmarks_uncover" />,
        bookmarks: item.twitter_tweet_bookmark_count ?? 0,
      })}
    </>
  );
};

type SelfCalcEngagementProps = {
  likes?: number;
  reactions?: number;
  replies?: number;
  collected?: number;
  impression?: number;
  bookmarks?: number;
  smart_engagement?: number;
  engagement?: number;
  highlight?: number;
  votes?: number;

  icon?: React.ReactNode;
  rightNode?: React.ReactNode;
};

const SelfCalcTooltipLabel = (props: Omit<SelfCalcEngagementProps, "icon">) => {
  const { rightNode, ...restProps } = props;
  const content = Object.keys(restProps).map((key: keyof SelfCalcEngagementProps) => {
    const v = props[key];
    const value = isString(v) ? v : formatNumber(Math.floor(props[key]));
    let content: React.ReactNode = null;
    switch (key) {
      case "likes":
        content = (
          <>
            <span>Likes</span>
            <span>{value}</span>
          </>
        );
        break;
      case "reactions":
        content = (
          <>
            <span>Reactions</span>
            <span>{value}</span>
          </>
        );
        break;
      case "replies":
        content = (
          <>
            <span>Replies</span>
            <span>{value}</span>
          </>
        );
        break;
      case "collected":
        content = (
          <>
            <span>Collected</span>
            <span>{value}</span>
          </>
        );
        break;
      case "impression":
        content = (
          <>
            <span>Impressions</span>
            <span>{value}</span>
          </>
        );
        break;
      case "bookmarks":
        content = (
          <>
            <span>Bookmarks</span>
            <span>{value}</span>
          </>
        );
        break;
      case "smart_engagement":
        content = (
          <>
            <span>Smart Engagements</span>
            <span>{value}</span>
          </>
        );
        break;
      case "engagement":
        content = (
          <>
            <span>Engagements</span>
            <span>{value}</span>
          </>
        );
        break;
      case "highlight":
        content = (
          <>
            <span>Total Keyword Hits</span>
            <span>{value}</span>
          </>
        );
        break;
      case "votes":
        content = (
          <>
            <span>Total votes</span>
            <span>
              {value}
              &nbsp;{rightNode}
            </span>
          </>
        );
    }

    if (!content) return null;

    return (
      <div
        key={key}
        className="flex items-center justify-between text-sm text-white/80 min-w-[60px] gap-4"
      >
        {content}
      </div>
    );
  });

  return <div className="flex flex-col gap-1">{content}</div>;
};

const renderIconTagWithTooltip = (props: SelfCalcEngagementProps) => {
  const { icon, rightNode, ...restProps } = props;
  const sum = Object.values(restProps).reduce((prev, curr = 0) => prev + curr, 0);

  return (
    <Tooltip
      hasArrow
      bg="#2A3242"
      border="1px solid #474F5C"
      sx={{
        "--popper-arrow-shadow-color": "#474F5C",
      }}
      label={<SelfCalcTooltipLabel {...restProps} rightNode={rightNode} />}
    >
      {renderIconTag(
        icon,
        <>
          {formatNumber(Math.floor(sum))}
          {rightNode}
        </>
      )}
    </Tooltip>
  );
};

const DiscordTip: React.FC<{
  item: OpenSearchDiscordObject;
}> = ({ item }) => {
  return (
    <>
      {renderNewTag(
        <>
          <IconFont name="icon-Serve_uncover" size={12} />
          {item.discord_server_name}
        </>
      )}
      {renderIconTagWithTooltip({
        reactions: item.discord_message_reaction_count,
        icon: <IconFont name="icon-hear_uncovert" />,
      })}
    </>
  );
};

const PodcastTip: React.FC<{
  item: OpenSearchPodcastObject;
  highlight?: HighLight;
}> = ({ item, highlight }) => {
  return <>{renderHighlightTextNumber(highlight)}</>;
};

const TwitterTip: React.FC<{
  item: OpenSearchTwitterObject;
}> = ({ item }) => {
  return <>{renderTwitterTags(item)}</>;
};

const YoutubeTip: React.FC<{
  item: OpenSearchYoutubeObject;
  highlight?: HighLight;
}> = ({ item, highlight }) => {
  return (
    <>
      {`${item.youtube_hardcode_playlist_host_name || ""}`} {renderHighlightTextNumber(highlight)}
    </>
  );
};

const MirrorTip: React.FC<{
  item: OpenSearchMirrorObject;
  highlight?: HighLight;
}> = ({ item, highlight }) => {
  return (
    <>
      {renderIconTagWithTooltip({
        icon: <IconFont name="icon-hear_uncovert" />,
        collected: item.mirror_collected_count || 0,
      })}
      {renderHighlightTextNumber(highlight)}
    </>
  );
};

const NewsTip: React.FC<{
  item: OpenSearchA16zResearchObject;
  highlight?: HighLight;
}> = ({ item, highlight }) => {
  return (
    <>
      {renderNewTag(
        <>
          <IconFont name="iocn-Author_uncover" size={12} />
          {item.web_authors}
        </>
      )}
      {renderHighlightTextNumber(highlight)}
    </>
  );
};

const GovernanceTip: React.FC<{
  item: OpenSearchUniswapObject;
  highlight?: HighLight;
}> = ({ item }) => {
  const { gov_forum_post_author_username, gov_forum_post_like_count, gov_forum_post_reply_count } =
    item;
  return (
    <>
      {renderNewTag(
        <>
          <IconFont name="iocn-Author_uncover" size={12} />
          {gov_forum_post_author_username}
        </>
      )}{" "}
      {renderIconTagWithTooltip({
        likes: gov_forum_post_like_count,
        replies: gov_forum_post_reply_count,
        icon: <IconFont name="icon-hear_uncovert" />,
      })}
    </>
  );
};

const DashboardTip: React.FC<{
  item: DuneDashboardObject;
  highlight?: HighLight;
}> = ({ item }) => {
  const { dune_dashboard_user, dune_dashboard_favorite_count_all } = item;
  return (
    <>
      {renderTag(`Creator: ${dune_dashboard_user}`)}{" "}
      {i18n.t("common:result_star", {
        count: dune_dashboard_favorite_count_all.favorite_count,
      })}
    </>
  );
};

const TwitterSpaceTip: React.FC<{
  item: OpenSearchTwitterSpaceObject;
  highlight?: HighLight;
}> = ({ highlight }) => {
  return <>{renderHighlightTextNumber(highlight)}</>;
};

const MediumTip: React.FC<{
  item: OpenSearchMediumObject;
  highlight?: HighLight;
}> = ({ item, highlight }) => {
  return (
    <>
      {renderNewTag(
        <>
          <IconFont name="iocn-Author_uncover" size={12} />
          {item.medium_author_name}
        </>
      )}
      {renderHighlightTextNumber(highlight)}
    </>
  );
};

const WhitepaperTip: React.FC<{
  item: OpenSearchWhitePaperObject;
  highlight?: HighLight;
}> = () => {
  return null;
};

const SnapshotTip: React.FC<{
  item: OpenSearchSnapshotObject;
  highlight?: HighLight;
}> = ({ item }) => {
  if (!item.snapshot_proposal_symbol) return;
  if (isNil(item.proposal_scores_total)) return;

  const total_votes = item.proposal_scores_total;

  return (
    <>
      {renderIconTagWithTooltip({
        icon: <IconFont name="a-icon-chart-uncover" size={12} />,
        votes: total_votes,
        rightNode: <span className="ml-[2px]">{item.snapshot_proposal_symbol}</span>,
      })}
    </>
  );
};

// newly Tally
const TallyTip: React.FC<{
  item: OpenSearchTallyObject;
  highlight?: HighLight;
}> = ({ item }) => {
  if (isNil(item.engagement_count)) return;

  const total_votes = item.engagement_count;

  return (
    <>
      {renderIconTagWithTooltip({
        icon: <IconFont name="a-icon-chart-uncover" size={12} />,
        votes: total_votes,
      })}
    </>
  );
};

const TelegramTip: React.FC<{
  item: OpenSearchTelegramObject;
  highlight?: HighLight;
}> = ({ item }) => {
  const name = item.telegram_sender_info.first_name
    ? `${item.telegram_sender_info.first_name} ${item.telegram_sender_info.last_name || ""}`
    : item.telegram_sender_info.username;

  return (
    <>
      {renderNewTag(
        <>
          <IconFont name="iocn-Author_uncover" size={12} />
          {name}
        </>
      )}
      {renderIconTagWithTooltip({
        reactions: item.telegram_reactions_count,
        icon: <IconFont name="icon-hear_uncovert" />,
      })}
    </>
  );
};

const WarpcastTip: React.FC<{
  item: OpenSearchWhitePaperObject;
  highlight?: HighLight;
}> = ({ item }) => {
  return (
    <>
      {renderIconTagWithTooltip({
        icon: <IconFont name="icon-hear_uncovert" />,
        engagement: item?.engagement_count ?? 0,
      })}
    </>
  );
};

const resultTipRenderMap: Partial<Record<SourceName, React.FC<Props>>> = {
  Twitter: TwitterTip,
  Discord: DiscordTip,
  Podcast: PodcastTip,
  Youtube: YoutubeTip,
  Mirror: MirrorTip,
  News: NewsTip,
  Research: NewsTip,
  Forum: GovernanceTip,
  DuneDashboard: DashboardTip,
  Twitter_Space: TwitterSpaceTip,
  Medium: MediumTip,
  Whitepaper: WhitepaperTip,
  SnapShot: SnapshotTip,
  Tally: TallyTip,
  Warpcast: WarpcastTip,
  Telegram: TelegramTip,
};

/**
 * this logic migrate from /src/common/connector/buildSearchResponse.tsx
 */
export const SearchResultTip: React.FC<Props> = ({ item, highlight }) => {
  const sourceName = dataSource2SourceName(item.data_source);

  const Tip = resultTipRenderMap[sourceName];

  if (!Tip) return null;

  return (
    <span className="inline-flex items-center gap-1 translate-y-[0.5px]">
      <Tip item={item} highlight={highlight} />
    </span>
  );
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconAIconNewchat: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M338.517333 894.634667a37.12 37.12 0 0 1-19.285333-5.461334 39.509333 39.509333 0 0 1-18.773333-34.474666l1.28-77.44C193.962667 712.448 128 601.002667 128 481.024 128 286.378667 298.666667 128 508.501333 128c209.749333 0 380.458667 158.378667 380.458667 353.024 0 25.813333-2.986667 50.944-8.704 75.178667a215.637333 215.637333 0 0 1-329.386667 275.712 392.106667 392.106667 0 0 1-94.464-1.365334L357.546667 889.344a37.248 37.248 0 0 1-19.029334 5.248z m162.816-139.093334a215.68 215.68 0 0 1 311.210667-262.314666c0.213333-4.053333 0.298667-8.106667 0.298667-12.202667 0-151.381333-136.533333-274.56-304.341334-274.56-167.850667 0-304.426667 123.178667-304.426666 274.56 0 97.834667 58.709333 189.098667 153.216 238.08 6.4 3.328 11.733333 8.448 15.445333 14.762667a40.149333 40.149333 0 0 1 5.461333 20.949333l-0.512 32 52.053334-31.018667a37.248 37.248 0 0 1 24.96-4.778666c16.469333 2.645333 31.786667 4.138667 46.634666 4.522666z m180.181334 20.608c0 5.973333 4.821333 10.794667 10.794666 10.794667h21.546667c5.973333 0 10.794667-4.864 10.794667-10.794667v-64.725333c0-5.973333 4.821333-10.794667 10.794666-10.794667h64.725334c5.973333 0 10.752-4.821333 10.752-10.752v-21.589333a10.794667 10.794667 0 0 0-10.752-10.794667h-64.725334a10.794667 10.794667 0 0 1-10.794666-10.752V581.973333a10.794667 10.794667 0 0 0-10.794667-10.794666h-21.546667a10.794667 10.794667 0 0 0-10.794666 10.794666v64.725334c0 5.973333-4.821333 10.752-10.794667 10.752h-64.682667a10.794667 10.794667 0 0 0-10.794666 10.794666v21.589334c0 5.973333 4.821333 10.752 10.794666 10.752h64.682667c5.973333 0 10.794667 4.864 10.794667 10.794666v64.725334z"
        fill={getIconColor(color, 0, "currentColor")}
      />
    </svg>
  );
};

IconAIconNewchat.defaultProps = {
  size: 14,
};

export default IconAIconNewchat;

import React, { useContext } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { RelevantUserModal } from "@/components/RelevantUserModal";
import { SourceFilterContext } from "../context";

const NoResultRender = () => {
  const { setShouldCloseOnBlur } = useContext(SourceFilterContext);

  return (
    <Flex color="whiteAlpha.200" flexFlow="flex-start" height="24px" p="6.5px" alignItems="center">
      <InfoOutlineIcon mr="6px" boxSize="11px" />
      <Text fontSize="12px" textAlign="center">
        No{" "}
        <RelevantUserModal closeHook={() => setShouldCloseOnBlur(true)}>
          {({ onOpen }) => (
            <Text
              as="span"
              display="inline-flex"
              textDecoration="underline dashed"
              _hover={{ color: "#1DA1F2" }}
              cursor="pointer"
              onClick={() => {
                setShouldCloseOnBlur(false);
                onOpen();
              }}
            >
              relevant users
            </Text>
          )}
        </RelevantUserModal>{" "}
        found
      </Text>
    </Flex>
  );
};

export default NoResultRender;

type ReplacementFunction = (a: string, p1: string, p2?: string) => string;
function parseNormalMarkdown(content: string) {
  const holder: [RegExp, ReplacementFunction][] = [
    [/__(.*?)__/g, (_, p1: string) => `<span style="text-decoration: underline">${p1}</span>`],
    [/\*\*(.*?)\*\*/g, (_, p1: string) => `<strong>${p1}</strong>`],
    [/\*(.*?)\*/, (_, p1: string) => `<em>${p1}</em>`],
    [/~~(.*?)~~/g, (_, p1: string) => `<span style="text-decoration: line-through">${p1}</span>`],
    [
      /\|\|(.*?)\|\|/g,
      (_, p1: string) =>
        `<span class="spoiler spoiler--hidden"> <span class="spoiler-text">${p1}</span></span>`,
    ],
    // [
    //   /\[(.+?)]\((.+?)\)/g,
    //   (_, p1: string, p2: string) => `<a href="${p2}">${p1}</a>`,
    // ],
  ];
  return holder.reduce((acc, [regex, replaeFn]) => {
    return acc.replace(regex, replaeFn);
  }, content);
}

function parseQuote(content: string) {
  return content.replace(/^>\s(.+)/, (_, p1) => `<div class="quote">${p1}</div>`);
}

export function parseCustomEmoji(content: string) {
  const holder: [RegExp, ReplacementFunction][] = [
    [
      /&lt;:.*?:(\d*)&gt/,
      (_: string, p1: string) =>
        `<img class="emoji emoji--small" src="https://cdn.discordapp.com/emojis/${p1}.png">`,
    ],
    [
      /&lt;a:.*?:(\d*)&gt/,
      (_: string, p1: string) =>
        `<img class="emoji emoji--small" src="https://cdn.discordapp.com/emojis/${p1}.gif">`,
    ],
    [
      /<:.*?:(\d*)>/g,
      (_: string, p1: string) =>
        `<img class="emoji emoji--small" src="https://cdn.discordapp.com/emojis/${p1}.png">`,
    ],
    [
      /<a:.*?:(\d*)>/,
      (_: string, p1: string) =>
        `<img class="emoji emoji--small" src="https://cdn.discordapp.com/emojis/${p1}.gif">`,
    ],
  ];
  return holder.reduce((acc, [regex, replaeFn]) => {
    if (acc.match(regex)) {
      return acc.replace(regex, replaeFn);
    }
    return acc;
  }, content);
}

export function parseNormalLink(content: string) {
  const regex =
    /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
  return content?.replace(
    regex,
    (match) => `<a href="${match}" target="__blank" onClick="event.stopPropagation()">${match}</a>`
  );
}

function returnToMarkdown(content: string) {
  const holders: [RegExp, ReplacementFunction][] = [
    [/<strong>(.*?)<\/strong>/g, (_, p1: string) => `**${p1}**`],
    [/<em>([^<>]+)<\/em>/g, (_, p1) => `*${p1}*`],
    [/<span style="text-decoration: underline">([^<>]+)<\/span>/g, (_, p1: string) => `__${p1}__`],
    [
      /<span style="text-decoration: line-through">([^<>]+)<\/span>/g,
      (_, p1: string) => `~~${p1}~~`,
    ],
    [/<div class="quote">(.*?)<\/div>/g, (_, p1) => `> ${p1}`],
    [
      /<span class="spoiler spoiler--hidden" onclick="showSpoiler(event, this)"> <span class="spoiler-text">(.*?)<\/span><\/span>/g,
      (_, p1: string) => `||${p1}||`,
    ],
  ];
  return holders
    .reduce((acc, [regex, replaeFn]) => {
      return acc.replace(regex, replaeFn);
    }, content)
    .replace(/<a href="(.*?)">(.*?)<\/a>/g, (_, p1) => p1);
}

function parseCode(content: string = "", isInline: boolean) {
  const markdown_languages = [
    "asciidoc",
    "autohotkey",
    "bash",
    "coffeescript",
    "cpp",
    "cs",
    "css",
    "diff",
    "fix",
    "glsl",
    "ini",
    "json",
    "md",
    "ml",
    "prolog",
    "py",
    "tex",
    "xl",
    "xml",
    "js",
    "html",
    "javascript",
    "typescript",
  ];
  let base = content;
  base = base.replaceAll("\n", "<br>");

  // ```code```
  base = base.replace(/```(.*?)```/g, (_: string, p1: string) => {
    let languageClass = "nohighlight";
    let affectedText = p1;
    markdown_languages.forEach((v) => {
      if (p1.toLowerCase().startsWith(v)) {
        languageClass = `language-${v}`;
        affectedText = p1.slice(v.length);
      }
    });
    if (isInline) {
      return `<span class="pre pre-inline">${returnToMarkdown(affectedText)}</span>`;
    } else {
      return `<div class="pre pre--multiline ${languageClass}">${returnToMarkdown(
        affectedText
      )}</div>`;
    }
  });
  // ``code``
  base = base.replace(/``(.*?)``/g, (_, p1) => {
    return `<span class="pre pre-inline">${returnToMarkdown(p1)}</span>`;
  });

  // `code`
  base = base.replace(/`(.*?)`/g, (_, p1) => {
    return `<span class="pre pre-inline">${returnToMarkdown(p1)}</span>`;
  });
  base = base.replaceAll("<br>", "\n");

  return base;
}

export function buildMarkdown(content: string = "", isInline: boolean) {
  return parseCode(
    parseQuote(parseNormalMarkdown(parseCustomEmoji(parseNormalLink(content)))),
    isInline
  );
}

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconAIconChartUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M328.533333 128a34.133333 34.133333 0 0 1 34.133334-34.133333H640a34.133333 34.133333 0 0 1 34.133333 34.133333v392.533333h243.2a34.133333 34.133333 0 0 1 34.133334 34.133334v341.333333a34.133333 34.133333 0 0 1-34.133334 34.133333H85.333333a34.133333 34.133333 0 0 1-34.133333-34.133333V384a34.133333 34.133333 0 0 1 34.133333-34.133333h243.2V128z m68.266667 733.866667h209.066667V162.133333H396.8v699.733334z m277.333333 0h209.066667v-273.066667h-209.066667v273.066667z m-345.6 0V418.133333H119.466667v443.733334h209.066666z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconAIconChartUncover.defaultProps = {
  size: 14,
};

export default IconAIconChartUncover;

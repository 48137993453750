/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconAuthenticationUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M899.498667 196.352l-38.357334-0.597333a573.013333 573.013333 0 0 1-175.786666-36.309334c-91.050667-35.413333-149.76-76.16-150.314667-76.544l-23.125333-16.042666-23.04 16.085333c-0.554667 0.341333-59.306667 41.045333-150.4 76.501333-88.746667 34.56-174.805333 36.266667-175.573334 36.266667l-38.570666 0.64v374.186667c0 187.946667 252.074667 386.602667 387.626666 386.602666 135.594667 0 387.712-198.656 387.712-386.56l-0.170666-374.229333zM512 901.546667c-112.597333 0-332.074667-178.688-332.074667-331.008V250.538667c31.744-2.133333 103.68-10.112 178.773334-39.253334A893.141333 893.141333 0 0 0 512 134.144c22.101333 14.378667 76.373333 47.104 153.216 77.056 75.306667 29.269333 147.413333 37.12 178.773333 39.296l0.085334 320c0 152.32-219.562667 331.050667-332.16 331.050667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M743.253333 548.394667l-148.650666 148.48-62.293334-70.656a27.861333 27.861333 0 0 0-39.253333-2.517334 27.733333 27.733333 0 0 0-2.389333 39.253334l81.792 92.842666a27.733333 27.733333 0 0 0 20.864 9.386667 27.733333 27.733333 0 0 0 19.626666-8.106667l169.557334-169.301333a27.904 27.904 0 0 0 0.085333-39.338667 27.946667 27.946667 0 0 0-39.338667-0.042666z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
      <path
        d="M591.232 598.784c12.586667-12.970667 28.032-26.538667 40.96-37.248a202.069333 202.069333 0 0 0-66.474667-46.72 127.616 127.616 0 0 0 50.346667-101.162667c0-70.826667-57.642667-128.426667-128.426667-128.426666-70.826667 0-128.469333 57.6-128.469333 128.426666 0 41.130667 19.797333 77.44 50.048 100.906667a199.253333 199.253333 0 0 0-120.576 182.826667 27.733333 27.733333 0 1 0 55.552 0 143.573333 143.573333 0 0 1 143.402667-143.402667c40.832 0 77.482667 17.28 103.68 44.8z m-103.637333-258.005333a72.96 72.96 0 0 1 72.874666 72.874666 72.96 72.96 0 0 1-72.874666 72.832A72.96 72.96 0 0 1 414.72 413.653333a72.96 72.96 0 0 1 72.874667-72.874666z"
        fill={getIconColor(color, 2, 'currentColor')}
      />
    </svg>
  );
};

IconIconAuthenticationUncover.defaultProps = {
  size: 14,
};

export default IconIconAuthenticationUncover;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconTldrNeutral: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M128 512a384 384 0 0 0 384 384 384.426667 384.426667 0 0 0 384-384 384 384 0 1 0-768 0z m76.8 0A307.2 307.2 0 0 1 512 204.8a307.541333 307.541333 0 0 1 307.2 307.2 307.2 307.2 0 1 1-614.4 0z m115.2 36.266667v85.333333h384v-85.333333h-384z m0-64v-85.333334h384v85.333334h-384z"
        fill={getIconColor(color, 0, '#2187FF')}
      />
    </svg>
  );
};

IconTldrNeutral.defaultProps = {
  size: 14,
};

export default IconTldrNeutral;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconCalendar: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M398.08 160a32 32 0 0 0-64 0v24.192H197.418667c-38.4 0-69.461333 31.104-69.461334 69.461333v580.693334c0 38.357333 31.104 69.461333 69.461334 69.461333h636.885333c38.357333 0 69.461333-31.104 69.461333-69.461333V253.653333c0-38.357333-31.104-69.461333-69.461333-69.461333h-136.576V160a32 32 0 0 0-64 0v24.192H398.037333V160zM192 253.653333c0-2.986667 2.432-5.461333 5.461333-5.461333H334.08v61.653333a32 32 0 1 0 64 0V248.192h235.733333v61.653333a32 32 0 1 0 64 0V248.192h136.533334c3.072 0 5.504 2.432 5.504 5.461333v155.306667H192v-155.306667z m5.461333 586.154667a5.461333 5.461333 0 0 1-5.461333-5.461333v-361.386667h647.808v361.386667c0 2.986667-2.432 5.461333-5.461333 5.461333H197.461333z m131.114667-299.690667a32 32 0 0 0 0 64h112.384a32 32 0 0 0 0-64H328.576z m262.272 0a32 32 0 1 0 0 64h112.384a32 32 0 0 0 0-64h-112.384zM328.533333 689.92a32 32 0 0 0 0 64h112.384a32 32 0 0 0 0-64H328.576z m262.272 0a32 32 0 1 0 0 64h112.384a32 32 0 0 0 0-64h-112.384z"
        fill={getIconColor(color, 0, "currentColor")}
      />
    </svg>
  );
};

IconCalendar.defaultProps = {
  size: 14,
};

export default IconCalendar;

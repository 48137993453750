// import { Img } from "@chakra-ui/image";
// import Navbar from "./Navbar";
import { Img } from "@chakra-ui/image";
import * as Sentry from "@sentry/browser";
import { useAtom, useSetAtom } from "jotai";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import loadingGif from "public/loading.gif";
import { useEffect, useRef, useState } from "react";
import { useMount } from "react-use";
import useSWR from "swr";

import { getPayInvoice } from "@/client/payment";
import { PaymentConfire } from "@/components/Account/paymentConfire";
import AuthPage from "@/components/Auth/AuthPage";
import FreeTrialPaymentFailedTip from "@/components/FreeTrialPaymentFailedTip";
import SimultaneousLoginTip from "@/components/SimultaneousLoginTip";
import { useTwitterFollowingCount } from "@/components/SmartFollowing/hooks";
import { useToast } from "@/hooks/useToast";
import { guideAtom } from "@/store/guide";
import { ownerInfoAtom, scheduleAtom, subscriptionAtom, userInfoAtom } from "@/store/userInfo";
import { PlanLevel } from "@/types/pricing";
import { authHeadersLocalStorageUtil, levelLocalStorageUtil } from "@/utils";
import { initInterCom } from "@/utils/interCom";
import { isPaymentErr } from "@/utils/user";

import { RateLimitReached } from "./ErrorNotify";
import Navbar from "./Navbar";
import { usePricingModalController } from "./Pricing/hooks";

const SUCCESS_HINT = "Payment retry successful.";
const FAILED_HINT = "Payment retry unsuccessful.";

interface LayoutProps {
  children?: React.ReactNode;
}

const PricingModalWithAtomControl = dynamic(() => import("./Pricing/PricingModalWithAtomControl"), {
  ssr: false,
});
const CelebrateEffect = dynamic(() => import("./CelebrateEffect"), { ssr: false });
const FloatBallon = dynamic(() => import("./Pricing/FloatBallon"), { ssr: false });

const WatchlistManageModal = dynamic(() => import("./WatchlistManageModal"), { ssr: false });
const SaveSearch = dynamic(() => import("./SaveSearch"), { ssr: false });
const GlobalDataFetcher: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  usePricingModalController();
  const session = useSession();
  const { replace } = useRouter();
  const firstRedirectRef = useRef(null);
  const [userInfo, updateUserInfo] = useAtom(userInfoAtom);
  const [subscription, updateSubscription] = useAtom(subscriptionAtom);
  const updateSchedule = useSetAtom(scheduleAtom);
  const updateOwnerInfo = useSetAtom(ownerInfoAtom);

  const [payLoading, setPayLoading] = useState(false);

  // Compare the values in local storage with those in userInfo,
  // and if they're different, update the token,
  // so the level won't use the cached data.
  try {
    const currLevel = levelLocalStorageUtil.getValue();
    if (currLevel && userInfo?.membershipLevel) {
      if (userInfo.membershipLevel !== currLevel) {
        session.update();
      }
    }
  } catch (e) {
    // console.error("levelLocalStorageUtil error", e);
  }

  useTwitterFollowingCount();
  const toast = useToast();

  const { isLoading: isUserLoading, data } = useSWR(
    `/updateUserInfo/${session.data?.user?.user_id}`,
    updateUserInfo
  );
  useSWR(`/updateSubscription/${userInfo.stripeSubscriptionId}`, updateSubscription);
  useSWR(`${userInfo.stripeCustomerId}`, updateSchedule);
  useSWR(`/updateOwnerInfo`, updateOwnerInfo);

  const payInvoice = async () => {
    setPayLoading(true);
    try {
      const result = await getPayInvoice();
      if (result.status === 200) {
        updateUserInfo();
        updateSubscription();
        toast.success(SUCCESS_HINT, 2000);
      } else {
        toast.error(FAILED_HINT, 2000);
      }
      setPayLoading(false);
    } catch {
      toast.error(FAILED_HINT, 2000);
      setPayLoading(false);
    }
  };

  /*
    free users / users who failed to renew / users in a 7-day transition period: only the account page is displayed
  */
  useEffect(() => {
    if (
      userInfo.membershipLevel &&
      (userInfo.membershipLevel === PlanLevel.Free || isPaymentErr(userInfo, subscription)) &&
      !firstRedirectRef.current &&
      location.pathname !== "/buy"
    ) {
      firstRedirectRef.current = "isPaymentErr";
      replace("/account");
    }
  }, [userInfo]);

  if (isUserLoading || payLoading) {
    return (
      <div className="flex bg-G2-700 h-[var(--chakra-vh)] justify-center items-center mx-4">
        <Img src={loadingGif.src} w="80px" h="80px" />
      </div>
    );
  }

  // active
  return (
    <AuthPage>
      <>
        <PaymentConfire payInvoice={payInvoice} />
        {children}
      </>
    </AuthPage>
  );
};

const Initialize = () => {
  const session = useSession();
  const updateGuide = useSetAtom(guideAtom);

  useMount(() => {
    updateGuide();
  });

  useEffect(() => {
    initInterCom(session);

    authHeadersLocalStorageUtil.setValue(
      JSON.stringify({
        Authorization: `Bearer ${session.data?.idToken}`,
        user_id: session.data?.user?.user_id,
        session_id: session.data?.user?.session_id,
      })
    );

    Sentry.setUser({
      id: session.data?.user?.user_id,
      email: session.data?.user?.email,
      username: session.data?.user?.name,
      ip_address: "{{auto}}",
    });

    // Initialize clarity
    if (window.clarity && session.data?.user) {
      window.clarity("identify", session.data?.user?.email, session.data?.user?.session_id);
    }
  }, [session]);
  return null;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div
      className="grid grid-cols-[48px_1fr] h-[var(--chakra-vh)]"
      style={{
        gridTemplateAreas: `"nav main"`,
      }}
    >
      <Initialize />
      <Navbar />
      <GlobalDataFetcher>{children}</GlobalDataFetcher>
      <PricingModalWithAtomControl />
      <FloatBallon />
      <CelebrateEffect />
      <WatchlistManageModal />
      <SaveSearch />
      <SimultaneousLoginTip />
      <FreeTrialPaymentFailedTip />
      <RateLimitReached />
    </div>
  );
};
export default Layout;

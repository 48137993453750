/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconHistory: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M519.765333 128a390.528 390.528 0 0 0-274.688 112.426667v-22.186667a42.666667 42.666667 0 1 0-85.333333 0v126.976a42.666667 42.666667 0 0 0 42.666667 42.666667h126.933333a42.666667 42.666667 0 0 0 0-85.333334h-25.728A306.432 306.432 0 1 1 213.333333 519.68a42.666667 42.666667 0 1 0-85.333333 0A391.765333 391.765333 0 1 0 519.765333 128z m42.752 182.314667a42.666667 42.666667 0 0 0-85.333333 0v209.578666a42.666667 42.666667 0 0 0 12.458667 30.165334l148.053333 148.053333a42.666667 42.666667 0 0 0 60.288-60.373333l-135.509333-135.509334V310.314667z"
        fill={getIconColor(color, 0, "currentColor")}
      />
    </svg>
  );
};

IconHistory.defaultProps = {
  size: 14,
};

export default IconHistory;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconSlideBack: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M548.266667 224.085333V149.333333h-72.533334v74.709334a290.261333 290.261333 0 0 0-251.690666 251.733333H149.333333v72.533333h74.752a290.261333 290.261333 0 0 0 251.648 251.52V874.666667h72.533334v-74.837334a290.261333 290.261333 0 0 0 251.562666-251.562666H874.666667v-72.533334h-74.837334a290.261333 290.261333 0 0 0-251.562666-251.648z m-72.533334 73.258667V366.933333h72.533334V297.386667a217.770667 217.770667 0 0 1 178.261333 178.389333H657.066667v72.533333h69.461333a217.770667 217.770667 0 0 1-178.261333 178.261334V657.066667h-72.533334v69.461333a217.770667 217.770667 0 0 1-178.346666-178.261333H366.933333v-72.533334H297.386667a217.770667 217.770667 0 0 1 178.346666-178.346666z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconSlideBack.defaultProps = {
  size: 14,
};

export default IconIconSlideBack;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconFlashUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M152.874667 526.037333L638.848 49.92c12.117333-11.861333 31.104 3.584 21.930667 17.834667l-218.154667 339.370666a14.208 14.208 0 0 0 11.946667 21.930667h401.92c13.312 0 19.285333 16.64 9.002666 25.173333L233.728 976.170667c-13.184 10.88-30.848-6.485333-20.181333-19.84l305.621333-382.848a14.208 14.208 0 0 0-11.093333-23.082667H162.816a14.208 14.208 0 0 1-9.984-24.405333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconFlashUncover.defaultProps = {
  size: 14,
};

export default IconIconFlashUncover;

import { SmartFollowingDetailListItem, SmartFollowingResult } from "@/client/smart_follow";
import { smartFollowingCircleKeys } from "@/components/SmartFollowing/types";

// check user select a custom circle to search and if the circle has a member of not
export const isNoMemberInCustomCircle = ({
  special,
  id_list,
}: {
  special?: string;
  id_list?: string;
}) => {
  // no special that indicate this is not a circle search
  if (!special) return false;

  // selected a custom circle but no id list there
  return !smartFollowingCircleKeys.includes(special as any) && !id_list;
};

export const getSmartFollowingDetailPageType = (
  user_tag_individual_or_organization: SmartFollowingResult["user_tag_individual_or_organization"]
) => {
  const detailType = [
    user_tag_individual_or_organization === "Organization" && "project",
    user_tag_individual_or_organization === "Individual" && "follower",
    user_tag_individual_or_organization === "EIndividual" && "emerging",
  ].filter(Boolean)[0];

  return detailType;
};

export const generateSmartFollowingUserType = (
  tag: SmartFollowingResult["user_tag_individual_or_organization"],
  type: SmartFollowingResult["user_type"]
): SmartFollowingResult["user_tag_individual_or_organization"] => {
  return tag === "Individual" && type === "non_kkol" ? "EIndividual" : tag;
};

export const generateShareLink = ({ id, userId }: { id: string; userId: string }) => {
  return `${location.origin}/share/smart_following/${userId}/${encodeURIComponent(id)}`;
};

export const wrapSmartFollowingDetailTableData = (data: Array<SmartFollowingDetailListItem>) => {
  return data.map((item) => {
    return {
      project: {
        name: item.name,
        icon: item.icon,
        username: item.username,
      },
      user_tag_individual_or_organization: generateSmartFollowingUserType(
        item.tag_individual_or_organization,
        item.user_type
      ),
      followers: item.smart_followers,
      ...item,
    };
  });
};

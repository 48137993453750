import dayjs from "dayjs";
import { get } from "lodash";
import { useMemo } from "react";

import { FilterPipeTransform } from "@/types";

import { timeFilterValues } from "./constant";

export const createdatFilterPipe: FilterPipeTransform = (filters, params) => {
  filters.forEach((filter) => {
    if (filter.field === "created_at") {
      const filterValue = get(filter, ["values", "0"], "") as string;
      const [startDate, endDate] = formatCreatedAtValue(filterValue);
      if (filterValue === "last_24hrs") {
        params[`min_${filter.field}`] = startDate;
      } else if (filterValue === "last_48hrs") {
        params[`min_${filter.field}`] = startDate;
      } else if (filterValue === "last_7d") {
        params[`min_${filter.field}`] = startDate;
      } else if (filterValue === "last_30d") {
        params[`min_${filter.field}`] = startDate;
      } else if (filterValue === "last_90d") {
        params[`min_${filter.field}`] = startDate;
      } else if (filterValue === "last_180d") {
        params[`min_${filter.field}`] = startDate;
      } else if (filterValue === "last_360d") {
        params[`min_${filter.field}`] = startDate;
      } else if (filterValue.indexOf("custom_date") > -1) {
        if (startDate) {
          params[`min_${filter.field}`] = startDate;
        }
        if (endDate) {
          params[`max_${filter.field}`] = endDate;
        }
      }
    }
  });

  return [filters, params];
};

export const formatCreatedAtValue = (createdAtValue: string): string[] => {
  if (createdAtValue.indexOf("custom_date") > -1) {
    const rangeState = createdAtValue.replace("custom_date--", "").split("_");
    return rangeState.map((v, idx) => {
      if (!v) return;

      if (v.indexOf(":") > -1) {
        return v;
      }

      return dayjs(dayjs(v).add(idx, "d").format("YYYY-MM-DD")).utc().format();
    });
  }
  const filterValue = timeFilterValues.find((v) => v.value === createdAtValue)?.value;

  if (!filterValue) return [];

  // Performance: ceil to 60 seconds for backend cache
  // TODO: Optimize this logic to according to filerValue
  const day = dayjs().second(60);

  if (filterValue === "last_24hrs") {
    return [day.subtract(1, "d").utc().format()];
  } else if (filterValue === "last_48hrs") {
    return [day.subtract(2, "d").utc().format()];
  } else if (filterValue === "last_7d") {
    return [day.subtract(7, "d").utc().format()];
  } else if (filterValue === "last_30d") {
    return [day.subtract(30, "d").utc().format()];
  } else if (filterValue === "last_90d") {
    return [day.subtract(90, "d").utc().format()];
  } else if (filterValue === "last_180d") {
    return [day.subtract(180, "d").utc().format()];
  } else if (filterValue === "last_360d") {
    return [day.subtract(360, "d").utc().format()];
  }
  return [];
};

export const useTimeResult = (createdAtValue: string, shortMode = false) => {
  const result = useMemo(() => {
    if (createdAtValue.indexOf("custom_date") > -1) {
      const rangeState = createdAtValue.replace("custom_date--", "").split("_");
      let result = rangeState
        .map((v) => {
          if (v.indexOf(":") > -1) {
            return dayjs(v).format("MM/DD/YYYY HH:mm");
          }
          return v;
        })
        .join("-");
      if (!rangeState[0]) {
        result = `Before ${dayjs(rangeState[1]).format("MM/DD/YYYY")}`;
      }
      if (!rangeState[1]) {
        result = `After ${dayjs(rangeState[0]).format("MM/DD/YYYY")}`;
      }
      return result;
    } else if (createdAtValue) {
      const target = timeFilterValues.find((v) => v.value === createdAtValue);
      if (shortMode) {
        return target?.sTitle;
      }
      return target?.title;
    }
  }, [createdAtValue, shortMode]);
  return result;
};

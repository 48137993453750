export const ENV = {
  DEPLOYMENT_PLATFORM: process.env.DEPLOYMENT_PLATFORM,
  EDGE_LAMBDA_USER_ACCESS_KEY_ID: process.env.EDGE_LAMBDA_USER_ACCESS_KEY_ID,
  EDGE_LAMBDA_USER_SECRET_ACCESS_KEY: process.env.EDGE_LAMBDA_USER_SECRET_ACCESS_KEY,
  stage: process.env.NEXT_PUBLIC_STAGE,
  localhostPort: process.env.NODE_ENV === "development" ? process.env.LOCALHOST_PORT || 3000 : 8080,
  cognitoClientId: process.env.COGNITO_CLIENT_ID,
  cognitoUserPoolId: process.env.COGNITO_USER_POOL_ID,
  homepageUrl: process.env.NEXT_PUBLIC_HOMEPAGE_URL,
  ALERT_API_URL: process.env.ALERT_API_ENDPOINT,
  PORTAL_API_URL: process.env.PORTAL_API_ENDPOINT,
  AI_SERVER_ENDPOINT: process.env.AI_SERVER_ENDPOINT,
  // OpenAI and Azure OpenAI:
  OPENAI_API_KEY: "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",
  OPENAI_ORGANIZATION_KEY: "org-ZAcErZWhbpFvDQxMn88WfhSO",
  AZURE_OPENAI_AI_INSTANCE: "kaito",
  AZURE_OPENAI_DEPLOYMENT: "kaito-chat",
  AZURE_OPENAI_CURIE_DEPLOYMENT: "kaito-curie",
  AZURE_OPENAI_VERSION: "2023-05-15",
  AZURE_OPENAI_API_KEY: "6e799d1394ea443fbe0d57ea16782305",
  SCALE_SERP_API_KEY: "53CB6767078A43CBBB63D6EFEAC90F56",
  // Caching is enabled unless we explicitly set it to be false
  CACHING_ENABLED: process.env.CACHING_ENABLED === "false" ? "false" : "true",
  DEX_GURU_API_KEY: "VAGbajuQUGkPMWGF6f95DeiOoCyhemjCPTgpyWpaGDM",
  COINGECKO_API_KEY: "CG-VW8KN1KvNXUqNYRsPc2sHf4Z",
  GCP_ANALYTICS_API_KEY: "AIzaSyADKZ7OTqhiMeQeti9s7sGFQlIdsP5_XFE",
  TOKENINSIGHT_API_KEY: "4135149f82bf4c8a9b6b2d773ef022a0",
  STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
  PG_HOST: process.env.PG_HPST ?? "35.236.59.99",
  PG_PORT: process.env.PG_PORT ?? 4567,
  PG_DATABASE: process.env.PG_DATABASE ?? "dev",
  PG_USERNAME: process.env.PG_USERNAME ?? "readaccess",
  PG_PASSWORD: process.env.PG_PASSWORD ?? "rw_readaccess_23121",
  APPCONFIG_APPLICATION_NAME: process.env.APPCONFIG_APPLICATION_NAME ?? "MetaSearchApplication",
  APPCONFIG_ENVIRONMENT_NAME: process.env.APPCONFIG_ENVIRONMENT_NAME ?? "beta-uswest2-Environment",
  APPCONFIG_CONFIG_PROFILE_NAME:
    process.env.APPCONFIG_CONFIG_PROFILE_NAME ??
    "beta-uswest2-RisingwaveConfig-HostedConfigurationProfile",
  AMPLIFY_APP_BACKEND_ROLE_ARN: process.env.AMPLIFY_APP_BACKEND_ROLE_ARN,
  // enable api encryption or not
  ENCRYPTION_ENABLED: process.env.NEXT_PUBLIC_ENCRYPTION_ENABLED === "false" ? "false" : "true",
  PAYMENT_API_URL: process.env.PAYMENT_API_ENDPOINT,
};

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconNft: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M537.6 52.053333a74.666667 74.666667 0 0 0-72.533333 0l-341.333334 189.653334a74.666667 74.666667 0 0 0-38.4 65.28v376.448c0 27.093333 14.72 52.053333 38.4 65.28l341.333334 189.610666a74.666667 74.666667 0 0 0 72.533333 0l341.333333-189.653333c23.68-13.141333 38.4-38.144 38.4-65.28V306.986667a74.666667 74.666667 0 0 0-38.4-65.28l-341.333333-189.610667z m-41.429333 55.936a10.666667 10.666667 0 0 1 10.325333 0l341.333333 189.653334a10.666667 10.666667 0 0 1 5.504 9.301333v376.490667a10.666667 10.666667 0 0 1-5.504 9.301333l-341.333333 189.653333a10.666667 10.666667 0 0 1-10.325333 0l-341.333334-189.653333a10.666667 10.666667 0 0 1-5.504-9.301333V306.944c0-3.84 2.133333-7.424 5.504-9.301333l341.333334-189.653334z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M430.421333 586.666667H381.269333L301.653333 448.426667h-1.024a8508.458667 8508.458667 0 0 1 1.024 26.112c0.341333 4.266667 0.597333 8.618667 0.768 13.056l0.512 13.056v86.016h-34.56V403.882667h48.896l79.36 136.96h0.768a7547.562667 7547.562667 0 0 0-1.024-25.088c0-4.266667-0.085333-8.448-0.256-12.544l-0.512-12.8V403.882667h34.816v182.784zM514.773333 586.666667h-38.144V403.882667h104.704v31.744h-66.56v47.104h61.952v31.744h-61.952v72.192zM687.530667 586.666667h-38.656v-150.528h-49.664v-32.256h137.984v32.256h-49.664v150.528z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconNft.defaultProps = {
  size: 14,
};

export default IconNft;

const path = require("path");
const HttpBackend = require("i18next-http-backend/cjs");
const ChainedBackend = require("i18next-chained-backend").default;
const LocalStorageBackend = require("i18next-localstorage-backend").default;

const isBrowser = typeof window !== "undefined";

// https://github.com/i18next/i18next-http-backend/tree/master/example/next
module.exports = {
  i18n: {
    defaultLocale: "en",
    locales: ["en"],
  },
  backend: {
    backendOptions: [
      // 7 days
      { expirationTime: 7 * 24 * 60 * 60 * 1000 },
      {
        /* loadPath: 'https:// somewhere else' */
      },
    ],
    backends: typeof window !== "undefined" ? [LocalStorageBackend, HttpBackend] : [],
  },
  fallbackLng: {
    default: ["en"],
  },
  debug: process.env.NODE_ENV === "development",
  serializeConfig: false,
  // reloadOnPrerender: process.env.NODE_ENV === "development",
  localePath: path.resolve("./public/locales"),
  use: typeof window !== "undefined" ? [ChainedBackend] : [],
  partialBundledLanguages: isBrowser && true,
};

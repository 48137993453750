"use client";

import { ImageLoader } from "next/image";

// https://github.com/aws-samples/image-optimization/tree/main
const toCdnURL: ImageLoader = ({ src, width, quality }) => {
  if (src.startsWith("http")) {
    return `https://img.kaito.ai/v1/${encodeURIComponent(encodeURIComponent(src))}/w=${width}&q=${quality || 90}`;
  }

  if (src.startsWith("/")) {
    return src;
  }

  return toNextJsImageOptimizationURL({ src, width, quality });
};

// https://nextjs.org/docs/app/api-reference/components/image#loader
const toNextJsImageOptimizationURL: ImageLoader = ({ src, width, quality }) =>
  `/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality || 75}`;

const myImageLoader: ImageLoader = ({ src, width, quality }) => {
  // Dev: use cdn url, avoid load image to local
  if (process.env.NODE_ENV === "development") {
    return toCdnURL({ src, width, quality });
  }

  return toCdnURL({ src, width, quality });
};

export default myImageLoader;

import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { flatten, isEmpty, isNil, pick } from "lodash";
import objHash from "object-hash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useInterval, useSetState } from "react-use";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";

import {
  getSmartFollowing,
  getSmartFollowingDetail,
  SmartFollowingDetailListItem,
  SmartFollowingDetailParams,
  SmartFollowingDetailType,
  SmartFollowingParams,
} from "@/client/smart_follow";
import { twitterSyncingBannerAtom } from "@/components/SmartFollowing/TwitterCircle";
import { useLogEvent } from "@/hooks/useLogEvent";
import { useToast } from "@/hooks/useToast";
import { useCallbackFn } from "@/hooks/utils";
import {
  refreshTwitterUserInfoAtom,
  userInfoAtom,
  userInfoWithTwitterAsyncAtom,
} from "@/store/userInfo";

import { TimeFilterValue } from "../Filter/TimeFilter/constant";
import { SmartFollowingFilterContext } from "./Context";
import { DataTableColumn } from "./DataTable/types";
import { smartFollowingCircleKeys, SmartFollowingFilter } from "./types";
import { generateSmartFollowingUserType, isNoMemberInCustomCircle } from "./utils";

const PAGE_SIZE = 100;

export type FetchSmartFollowingHook = ReturnType<typeof useFetchSmartFollowing>;

export const useFetchSmartFollowing = (params: { filter: SmartFollowingFilter }) => {
  const paramsRef = useRef<SmartFollowingParams>();
  const userInfo = useAtomValue(userInfoAtom);
  const { logEvent } = useLogEvent("SmartFollowing");

  const {
    data: infiniteData,
    isLoading,
    setSize,
    mutate,
  } = useSWRInfinite(
    (pageNumber) => {
      const { filter } = params;
      const {
        dateRange = TimeFilterValue.Last30d,
        filterSmartFollowers,
        idList,
        special,
        ...restFilters
      } = filter;

      paramsRef.current = {
        from: pageNumber * PAGE_SIZE,
        filter_smart_followers: isEmpty(filterSmartFollowers) ? undefined : filterSmartFollowers,
        id_list: idList,
        special,
        self_twitter_id:
          special === "self_circle" ? userInfo.twitterInfo?.twitter_user_id : undefined,
        ...restFilters,
      };
      return objHash({
        filter,
        pageNumber,
      });
    },
    () => {
      // log smart following fetch event
      logEvent("OpenSmartFollowing", {
        duration_filter: params?.filter?.dateRange ?? TimeFilterValue.Last30d,
        // if:
        //   1. user specifies a circle, if it's a predefined circle, use the value, otherwise, use customized as value
        //   2. otherwise, it's all
        circle_type: params?.filter?.special
          ? smartFollowingCircleKeys.includes(params.filter.special as any)
            ? params.filter.special
            : "customized"
          : "all",
      });

      // if user selects a custom circle but no any member in it, directly return empty data
      if (
        isNoMemberInCustomCircle({
          special: paramsRef.current.special,
          id_list: paramsRef.current.id_list,
        })
      ) {
        return [];
      }
      return getSmartFollowing({
        ...paramsRef.current,
      }).then((result) => {
        return result.map((item) => {
          return {
            ...item,
            project: pick(item, ["name", "username", "icon"]),
            id: item.id,
            // special logic
            user_tag_individual_or_organization: generateSmartFollowingUserType(
              item.user_tag_individual_or_organization,
              item.user_type
            ),
          } as DataTableColumn;
        });
      });
    },
    {
      revalidateOnFocus: false, // 切换标签页时，不重新请求数据
      errorRetryCount: 2,
    }
  );

  const isDataEmpty = infiniteData?.[0]?.length === 0;
  // const isReachingEnd =
  //   isDataEmpty || (infiniteData && infiniteData[infiniteData.length - 1]?.length < PAGE_SIZE);
  // kk(in slack): block user for more than 100
  const isReachingEnd = true;

  const loadMore = useCallbackFn(() => {
    if (isLoading) return;
    if (isReachingEnd) return;
    setSize((prev) => prev + 1);
  });

  const refresh = useCallbackFn(() => mutate());

  const data = useMemo(() => flatten(infiniteData), [infiniteData]);

  return {
    data,
    isReachingEnd,
    isLoading,
    loadMore,
    refresh,
  };
};

/**
 * get state from Context
 */
export const useSmartFollowingFilterContext = () => {
  return useContext(SmartFollowingFilterContext);
};

export const useSmartFollowingFilters = (defaultState: Partial<SmartFollowingFilter> = {}) => {
  const [filter, setFilter] = useSetState(defaultState as SmartFollowingFilter);

  return { filter, setFilter };
};

export const useSmartFollowingDetailData = ({
  id,
  type,
  duration,
  refreshId,
}: Pick<SmartFollowingDetailParams, "id" | "type" | "duration"> & { refreshId?: number }) => {
  const { data, isLoading } = useSWR(
    `${id}-${type}-${refreshId}`,
    () =>
      getSmartFollowingDetail({
        id,
        type,
        duration,
      }),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    }
  );
  return { data: data || { timeline: [], listData: [] }, isLoading };
};

export const useSmartFollowingFollowerByDate = ({
  id,
  type,
  selected_date,
}: {
  id: string;
  type: SmartFollowingDetailType;
  selected_date: string;
}) => {
  const { data, isLoading } = useSWR(
    `${id}-${type}-${selected_date}`,
    () => selected_date && getSmartFollowingDetail({ id, type, selected_date })
  );

  return {
    data,
    isLoading,
  };
};

export const useFetchSFDetail = (params: {
  filter: SmartFollowingFilter;
  id: string;
  type: SmartFollowingDetailType;
}) => {
  const paramsRef = useRef<SmartFollowingDetailParams>();
  const { filter, id, type } = params;
  const { user_type, user_tag_individual_or_organization, filterSmartFollowers } = filter;
  const duration = "all_dates";
  const {
    data: infiniteData,
    isLoading,
    setSize,
    size,
    isValidating,
    mutate,
  } = useSWRInfinite(
    (pageNumber) => {
      const { filter } = params;
      const sort = filter?.sort
        ? filter.sort.field === "smart_followers"
          ? { field: "followers", direction: filter.sort.direction }
          : { field: "min_start_time", direction: filter.sort.direction }
        : undefined;
      paramsRef.current = {
        id,
        type,
        from: pageNumber * PAGE_SIZE,
        filterSmartFollowers,
        user_tag_individual_or_organization,
        user_type,
        duration,
        sort,
      };

      return objHash({
        filter,
        pageNumber,
        id,
        type,
      });
    },
    () => getSmartFollowingDetail(paramsRef.current),
    {
      revalidateOnFocus: true,
      errorRetryCount: 2,
    }
  );

  const data = useMemo(() => {
    let res = [];
    infiniteData?.forEach((item) => {
      res.push(...item.listData);
    });
    return res;
  }, [infiniteData]);

  const isDataEmpty = data.length === 0 || isEmpty(data);
  // const isReachingEnd = isDataEmpty || (data && data?.length < size * PAGE_SIZE);
  // kk(in slack): block user for more than 100
  const isReachingEnd = true;

  const loadMore = useCallbackFn(() => {
    if (isLoading) return;
    if (isReachingEnd) return;
    setSize((prev) => prev + 1);
  });

  const refresh = useCallbackFn(() => mutate());

  return {
    data,
    isReachingEnd,
    isLoading: isValidating,
    loadMore,
    refresh,
  };
};

export const twitterConnectSmartFollowingBannerAtom = atomWithStorage(
  "SMART_FOLLOWING_CONNECT_TWITTER_BANNER",
  { banner: true, circle: false }
);

export const useTwitterFollowingData = (params: any) => {
  const paramsRef = useRef<SmartFollowingDetailParams>();
  const {
    data: responseData = [],
    setSize,
    size,
    isValidating,
    mutate,
  } = useSWRInfinite(
    (pageNumber) => {
      paramsRef.current = { ...params, id: params.id, from: pageNumber * PAGE_SIZE };
      return objHash({
        params,
        pageNumber,
      });
    },
    () => paramsRef.current?.id && getSmartFollowingDetail(paramsRef.current),
    {
      revalidateOnFocus: true,
      errorRetryCount: 2,
    }
  );

  const data = useMemo(() => {
    let res: SmartFollowingDetailListItem[] = [];
    responseData?.forEach((item) => {
      if (!isEmpty(item?.listData)) {
        res.push(...item?.listData);
      }
    });
    return res;
  }, [responseData]);

  const isNoRes = data?.length === 0;

  const isReachingEnd = isNoRes || (data && data.length < size * PAGE_SIZE);

  const loadMore = () => {
    if (!isReachingEnd && !isValidating) {
      setSize((prev) => prev + 1);
    }
  };

  const refresh = () => mutate();

  return {
    data,
    isLoading: isValidating,
    isNoRes,
    isReachingEnd,
    loadMore,
    refresh,
    setSize,
  };
};

export const twitterFollowingCountAtom = atom<{ following_count?: number; sync_count?: number }>(
  {}
);
export const isTwitterSyncedAtom = atomWithStorage("IS_TWITTER_SYNCED", false);
export const isShowSyncedSuccessAtom = atomWithStorage("IS_SHOW_SYNCED_SUCCESS", false);
export const isShowTwitterCircleImportBannerAtom = atomWithStorage(
  "IS_SHOW_TWITTER_CIRCLE_IMPORT_BANNER",
  true
);

export const useTwitterFollowingCount = () => {
  const userInfo = useAtomValue(userInfoAtom);
  const [countAtom, setCountAtom] = useAtom(twitterFollowingCountAtom);
  const [stop, setStop] = useState<boolean>(false);
  const [isSynced, setIsSynced] = useAtom(isTwitterSyncedAtom);
  const [showBanner, setShowBanner] = useAtom(isShowSyncedSuccessAtom);
  const [_, setShowSyncingBanner] = useAtom(twitterSyncingBannerAtom);
  const twitterUserInfoState = useAtomValue(userInfoWithTwitterAsyncAtom);
  const refreshUserInfoWithTwitter = useSetAtom(refreshTwitterUserInfoAtom);
  const { twitter_user_id: userId, twitter_user_name: userName } = userInfo?.twitterInfo || {};
  const toast = useToast();
  // const userId = "950486928784228352"; //huyu
  // const userId = "1408375085329649668"; //by

  useEffect(() => {
    if (
      !countAtom?.following_count &&
      twitterUserInfoState.state === "hasData" &&
      twitterUserInfoState.data
    ) {
      setCountAtom({ ...countAtom, following_count: twitterUserInfoState.data.following_count });
    }
  }, [countAtom, setCountAtom, twitterUserInfoState, twitterUserInfoState.state, userId]);

  useInterval(
    () =>
      getSmartFollowingDetail({ id: userId, type: "following_stats" }).then((res) => {
        const total = Number(res?.stats?.total_count ?? 0);
        setCountAtom({ ...countAtom, sync_count: total });
        if (countAtom.sync_count !== total && !isNil(countAtom.sync_count)) {
          refreshUserInfoWithTwitter((prev) => prev + 1);
        }
        if (isSynced) setStop(true);
      }),
    userId && !stop ? 10000 : null
  );

  useEffect(() => {
    const { following_count = 0, sync_count = 0 } = countAtom;
    if (following_count && sync_count && !isSynced) {
      // 爬到数据达到70%认为同步完成
      if (following_count * 0.7 < sync_count) {
        setIsSynced(true);
        setShowBanner(true);
        setShowSyncingBanner(false);
      }
    }
  }, [countAtom, isSynced]);

  useEffect(() => {
    if (showBanner) {
      toast.success(
        <span onClick={() => setShowBanner(false)}>
          Your Twitter Circle has been synced successfully!
          <span className="ml-8 text-brand-200 text-sm cursor-pointer">Got it</span>
        </span>,
        60 * 60 * 1000
      );
      // toast
    }
  }, [showBanner]);
};

import { Filter } from "@metasearch-io/search-ui";
import { Dictionary, every, flatten, get, isArray, isEqual, keyBy, set } from "lodash";

import { DEFAULT_DISCORD_RANGE_FILTER, DEFAULT_TWITTER_RANGE_FILTER } from "@/constants";

import { tickerFilterPipe } from "../SearchBox/TickerFilterPipe";
import { languageFilterPipe } from "./LanguageFilter";
import { AllSourceFilterFields } from "./SourceFilter/constants";
import { getRealBooleanValue } from "./SourceFilter/OfficialFilter/utils";
import { indexFilterPipe, rangeFilterPipe } from "./SourceFilter/rangeFilterPipe";
import { CombineField, FilterBlock, SourceFilterType } from "./SourceFilter/types";
import { createdatFilterPipe } from "./TimeFilter/helper";

export const getFieldProperty = (field: string): CombineField | null => {
  const field_result = AllSourceFilterFields.find((item) => item.field == field);
  if (!field_result) {
    return null;
  }
  return field_result;
};

export const getSourceFilterValue = (filters: Filter[], field: string): any => {
  const property = getFieldProperty(field);
  return getFilterValue(filters, property.field, getEmptyValue(property));
};

export const getFilterValue = (filters: Filter[], field: string, defaultValue: any): any => {
  return get(
    filters.find((v) => v.field === field),
    ["values", "0"],
    defaultValue
  );
};

export const setFilterValue = (filters: Filter[], field: string, value: any): any => {
  return set(
    filters.find((v) => v.field === field),
    ["values", "0"],
    value
  );
};

const getEmptyValue = (field: CombineField) => {
  const { type } = field;
  switch (type) {
    case SourceFilterType.Range:
      return [0, 10] as [number, number];
    case SourceFilterType.Text:
      return "" as string;
    case SourceFilterType.MultiSelect:
      return [] as any[];
    case SourceFilterType.RadioSelect:
      return "false";
    default:
      break;
  }
};
const normalizedRangeDefaultFilter = keyBy(
  AllSourceFilterFields.map((filterField) => {
    const value = getSourceFilterValue(
      [...DEFAULT_TWITTER_RANGE_FILTER, ...DEFAULT_DISCORD_RANGE_FILTER],
      filterField.field
    );

    return [filterField.field, value] as [string, any];
  }),
  (v) => v[0]
);

export const getNormalizedRangeFilter = (filters) => {
  return keyBy(
    AllSourceFilterFields.map((filterField) => {
      const value = getSourceFilterValue(filters, filterField.field);

      return [filterField.field, value] as [string, any];
    }),
    (v) => v[0]
  );
};

export const isFiltersEqualDefault = (
  filters: Dictionary<[string, [number, number]]>,
  /** if strict is false, filter is equal to default filter or [0, 10]
   *  if strict is true, filter must be equal to default filter
   */
  strict: boolean = false
) => {
  return every(filters, (value, key) => {
    if (isEqual(value, normalizedRangeDefaultFilter[key])) {
      return true;
    }
    if (!strict && isEqual(value, getEmptyValue(getFieldProperty(key)))) {
      return true;
    }
    return false;
  });
};

export const getFirstNotEqualDefaultFilter = (
  filters: Dictionary<[string, any]>,
  fields: string[],
  strict: boolean = false
) => {
  let filter = null;
  fields.forEach((field) => {
    if (isEqual(filters[field], normalizedRangeDefaultFilter[field])) {
      return true;
    }
    if (!strict) {
      if (isEqual(filters[field], getEmptyValue(getFieldProperty(field)))) {
        return true;
      }
    }
    if (!filter) {
      filter = filters[field];
    }
  });
  return filter;
};

/**
 *
 * @param normalizedRangeFilter filters to compare with default value
 * @param filterBlocks fields in one data source
 * @returns how many fields changed in the rangeFilterFields
 */
export const getChangedFieldsCountForSpecificSource = (
  normalizedRangeFilter: Record<string, any>,
  filterBlocks: FilterBlock[]
): number => {
  let count = 0;
  for (const key in normalizedRangeFilter) {
    const value = normalizedRangeFilter[key][1];
    const filterFields = flatten(filterBlocks.map((v) => v.fields));
    if (
      !isEqual(
        getRealBooleanValue(value),
        getRealBooleanValue(normalizedRangeDefaultFilter[key][1])
      ) &&
      filterFields.findIndex((item) => item.field === key && !item.notCount) > -1
    ) {
      count += 1;
    }
  }
  return count;
};

export const getTickersValue = (filters): string[] => {
  return getFilterValue(filters, "crypto_ticker", []).map(
    (ticker: string) => ticker.split("__")[1]
  );
};

export const topidFilterPipe: any = (filters, params) => {
  filters.forEach((filter) => {
    if (filter.field === "topic_id") {
      const topicId = get(filter, ["values", "0"], []) as string[];
      if (isArray(topicId) && !!topicId.length) {
        params["topic_id"] = topicId[0];
      } else {
        params["topic_id"] = topicId;
      }
    }
  });
  return [filters, params];
};

const twitterFields = ["tweet_length", "tweet_type", "sentiment_filter"];
export const twitterAttributeFilterPipe = (filters, params) => {
  filters.forEach((filter) => {
    if (twitterFields.includes(filter.field)) {
      const value = get(filter, ["values", "0"], "");
      if (value) {
        params[filter.field] = value;
      }
    }
  });
  return [filters, params];
};

function compose(...funcs: Function[]) {
  return funcs.reduce(
    (a, b) =>
      (...args: any) =>
        a(...b(...args))
  );
}

export const filterToParams = compose(
  tickerFilterPipe,
  languageFilterPipe,
  createdatFilterPipe,
  indexFilterPipe,
  rangeFilterPipe,
  topidFilterPipe,
  twitterAttributeFilterPipe
);

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconSetting: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 328.533333a183.466667 183.466667 0 1 0 0 366.933334 183.466667 183.466667 0 0 0 0-366.933334zM396.8 512a115.2 115.2 0 1 1 230.4 0 115.2 115.2 0 0 1-230.4 0z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M428.330667 85.248a34.133333 34.133333 0 0 0-39.509334-17.365333 460.117333 460.117333 0 0 0-190.506666 106.581333 34.133333 34.133333 0 0 0-7.381334 40.106667A93.866667 93.866667 0 0 1 106.666667 349.866667h-1.28a34.133333 34.133333 0 0 0-33.066667 23.893333 462.72 462.72 0 0 0-11.733333 231.04 34.133333 34.133333 0 0 0 36.736 27.136 93.866667 93.866667 0 0 1 81.109333 153.898667 34.133333 34.133333 0 0 0 1.536 45.653333 460.672 460.672 0 0 0 200.362667 122.197333 34.133333 34.133333 0 0 0 42.24-22.314666 93.909333 93.909333 0 0 1 178.858666 0 34.133333 34.133333 0 0 0 42.24 22.314666c77.269333-22.997333 146.005333-65.706667 200.362667-122.197333a34.133333 34.133333 0 0 0 1.493333-45.653333 93.866667 93.866667 0 0 1 81.109334-153.898667 34.133333 34.133333 0 0 0 36.821333-27.136 462.805333 462.805333 0 0 0-11.776-231.04 34.133333 34.133333 0 0 0-33.024-23.893333H917.333333a93.866667 93.866667 0 0 1-84.266666-135.296 34.133333 34.133333 0 0 0-7.338667-40.106667 460.117333 460.117333 0 0 0-190.549333-106.581333 34.133333 34.133333 0 0 0-39.509334 17.365333 93.866667 93.866667 0 0 1-167.338666 0zM268.8 256a162.133333 162.133333 0 0 0-6.826667-46.634667 391.850667 391.850667 0 0 1 121.173334-68.266666A161.877333 161.877333 0 0 0 512 204.8a161.877333 161.877333 0 0 0 128.853333-63.701333 391.850667 391.850667 0 0 1 121.173334 68.266666 162.176 162.176 0 0 0 130.773333 206.933334 393.6 393.6 0 0 1 8.32 147.712 162.133333 162.133333 0 0 0-125.994667 239.274666 392.533333 392.533333 0 0 1-122.837333 75.434667A162.048 162.048 0 0 0 512 797.866667a162.048 162.048 0 0 0-140.330667 80.853333 392.533333 392.533333 0 0 1-122.794666-75.434667 162.133333 162.133333 0 0 0-125.994667-239.274666 396.544 396.544 0 0 1 8.32-147.712A162.133333 162.133333 0 0 0 268.8 256z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconSetting.defaultProps = {
  size: 14,
};

export default IconSetting;

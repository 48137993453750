/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconSad: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M399.36 641.706667c-6.528 13.226667-11.093333 27.392-13.482667 41.941333l63.146667 10.496c1.365333-8.277333 3.925333-16.341333 7.594667-23.893333 3.968-8.32 10.026667-15.445333 17.621333-20.650667 11.392-6.826667 24.490667-10.197333 37.76-9.6 13.226667-0.682667 26.368 2.56 37.76 9.258667 7.594667 5.248 13.653333 12.373333 17.621333 20.650666 3.669333 7.552 6.229333 15.616 7.552 23.893334 2.901333 0.256 43.306667-6.741333 63.146667-10.282667a147.84 147.84 0 0 0-13.44-41.941333 115.669333 115.669333 0 0 0-39.381333-45.226667A129.877333 129.877333 0 0 0 512 576a131.754667 131.754667 0 0 0-70.741333 19.285333l-0.341334 0.213334H440.106667l-0.426667 0.256-0.554667 0.426666-0.426666 0.256a115.2 115.2 0 0 0-39.381334 45.226667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
      <path
        d="M512 874.666667a362.666667 362.666667 0 1 1 362.666667-362.666667 363.093333 363.093333 0 0 1-362.666667 362.666667z m0-652.8a290.133333 290.133333 0 1 0 290.133333 290.133333A290.474667 290.474667 0 0 0 512 221.866667zM385.066667 512a54.4 54.4 0 1 1 0-108.8 54.4 54.4 0 0 1 0 108.8z m253.610666-0.512a54.186667 54.186667 0 1 1 0-108.288 54.186667 54.186667 0 0 1 0 108.288z"
        fill={getIconColor(color, 1, 'currentColor')}
      />
    </svg>
  );
};

IconIconSad.defaultProps = {
  size: 14,
};

export default IconIconSad;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconSnapshotOfficialCover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M573.44 127.744a85.333333 85.333333 0 0 0-122.88 0L408.021333 171.946667a85.333333 85.333333 0 0 1-63.018666 26.069333l-61.226667-1.109333A85.333333 85.333333 0 0 0 196.864 283.733333l1.109333 61.269334a85.333333 85.333333 0 0 1-26.069333 63.018666l-44.117333 42.538667a85.333333 85.333333 0 0 0 0 122.88l44.117333 42.538667a85.333333 85.333333 0 0 1 26.069333 63.018666l-1.109333 61.226667a85.333333 85.333333 0 0 0 86.912 86.912l61.226667-1.109333a85.333333 85.333333 0 0 1 63.018666 26.069333l42.538667 44.16a85.333333 85.333333 0 0 0 122.88 0l42.538667-44.16a85.333333 85.333333 0 0 1 63.018666-26.069333l61.226667 1.109333a85.333333 85.333333 0 0 0 86.912-86.912l-1.109333-61.226667a85.333333 85.333333 0 0 1 26.069333-63.018666l44.16-42.538667a85.333333 85.333333 0 0 0 0-122.88l-44.16-42.538667a85.333333 85.333333 0 0 1-26.069333-63.018666l1.109333-61.269334a85.333333 85.333333 0 0 0-86.912-86.869333l-61.226667 1.109333a85.333333 85.333333 0 0 1-63.018666-26.069333l-42.538667-44.117333zM362.666667 533.333333l32.512-32.512 81.322666 81.237334 194.986667-194.986667 32.512 32.469333-227.541333 227.584L362.666667 533.333333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconSnapshotOfficialCover.defaultProps = {
  size: 14,
};

export default IconIconSnapshotOfficialCover;

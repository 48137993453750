import { get } from "lodash";

import { TagSelect } from "@/components/Filter/SourceFilter/TagSelect";
import {
  TweetLength,
  TweetSentimentFilter,
  TweetType,
} from "@/components/Filter/SourceFilter/types";

type OnChange = (v: any) => void;

export const renderTweetTypeField = (onChange: OnChange, value: any, filters: any) => {
  const isDisabled = filters?.allValue?.[6] === TweetLength.Article;

  if (isDisabled && value) {
    onChange(undefined);
  }

  return (
    <TagSelect
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
      options={[
        { label: "Tweet", value: TweetType.Tweet },
        { label: "Quote Tweet", value: TweetType.Quote },
        { label: "Reply", value: TweetType.Reply },
      ]}
    />
  );
};

export const renderTweetSentimentField = (onChange: OnChange, value: any, filters: any) => {
  const ticker = get(
    filters?.filters?.find((v) => v.field === "crypto_ticker"),
    ["values", "0"],
    []
  );
  const isDisabled = filters?.allValue?.[6] === TweetLength.Article || !ticker?.length;
  if (isDisabled && value) {
    onChange(undefined);
  }

  return (
    <TagSelect
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
      options={[
        { label: "Bullish", value: TweetSentimentFilter.Bullish },
        { label: "Neutral", value: TweetSentimentFilter.Neutral },
        { label: "Bearish", value: TweetSentimentFilter.Bearish },
      ]}
    />
  );
};

import type { Filter, SortOption } from "@metasearch-io/search-ui";
import {
  OpenSearchObject,
  OpenSearchSnapshotObject,
  OpenSearchTallyObject,
  SourceName,
  TallyStatusType,
} from "@metasearch-io/shared/types/open-search";

export * from "@metasearch-io/shared/types/open-search";

interface RangeFacet {
  type: "range";
  ranges: { from?: number; to?: number; name: string }[];
}

export interface FacetData {
  like_count?: RangeFacet;
  created_at?: RangeFacet;
  reply_count?: RangeFacet;
  retweet_count?: RangeFacet;
}

export interface LocalFilter extends Filter {
  field: "created_at" | "like_count" | "retweet_count" | "reply_count" | "crypto_ticker" | "index";
}

export interface LocalSort extends SortOption {
  field: "created_at";
}

export type HighLight = {
  "text.english_analyzed": string[];
};

type MediaSearchExtendResult<
  Type extends SourceName = SourceName,
  ExtendResult extends Record<string, any> = Record<string, any>,
> = Type extends "SnapShot"
  ? Partial<OpenSearchSnapshotObject>
  : Type extends "Tally"
    ? Partial<OpenSearchTallyObject>
    : ExtendResult;

export type MediaSearchResult<
  Type extends SourceName = SourceName,
  ExtendResult extends Record<string, any> = Record<string, any>,
> = {
  id: string;
  avatarUrl?: string;
  name: string;
  isVerified?: boolean;
  status?: TallyStatusType;
  subName?: string;
  contentTitle?: string;
  content: string;
  ticker: string[];
  displayTicker?: string[];
  displayTopic?: string[];
  footerTip: React.ReactNode;
  pubDate: string;
  type: Type;
  prefix?: string;
  data_source?: string;
  cardType: "normal" | "withoutAvatar";
  custom: any;
  sourceIcon: React.ReactNode;
  subrequest?: string;
  rawOpenSearchResult?: OpenSearchObject;
  rawHighLight?: HighLight;
  sentiment?: any;
  /**
   * some source has uniq fields, put in this field
   */
  extendResult?: MediaSearchExtendResult<Type, ExtendResult>;
};

export type FilterPipeTransform = (
  filters: Filter[],
  params: Record<string, any>
) => [Filter[], Record<string, any>];

export type FilterFieldName = "created_at" | "source" | "" | "sort";

/**
 * OmitStringType<"A" | "B" | "C", "C"> => "A" | "C"
 */
export type OmitStringType<T extends string, K extends string> = keyof {
  [P in T as P extends K ? never : P]: unknown;
};

export class MockApiResonseErrorClass extends Error {
  response: { data: { message: string } };
  constructor(message: string) {
    super(message);
    this.response.data.message = message;
  }
}

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconThumbdownCover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M330.922667 601.472l107.605333 213.034667c13.354667 28.842667 42.24 47.36 74.069333 47.36a129.024 129.024 0 0 0 126.890667-152.405334l-14.976-71.808h120.149333a131.669333 131.669333 0 0 0 129.28-156.629333l-39.210666-202.837333a131.669333 131.669333 0 0 0-129.28-106.666667H228.864c-45.738667 0-82.858667 37.12-82.858667 82.858667v264.192c0 45.781333 37.12 82.901333 82.901334 82.901333h102.016z m8.405333-361.216a21.333333 21.333333 0 0 0-21.333333 21.333333v250.069334a21.333333 21.333333 0 0 0 21.333333 21.333333h25.088a21.333333 21.333333 0 0 0 21.333333-21.333333V261.589333a21.333333 21.333333 0 0 0-21.333333-21.333333h-25.088z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconThumbdownCover.defaultProps = {
  size: 14,
};

export default IconIconThumbdownCover;

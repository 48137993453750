/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from "react";
import { getIconColor } from "./helper";

interface Props extends Omit<SVGAttributes<SVGElement>, "color"> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: "block",
};

const IconIconTelescopeUncover: FunctionComponent<Props> = ({
  size,
  color,
  style: _style,
  ...rest
}) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + "px"} height={size + "px"} style={style} {...rest}>
      <path
        d="M849.664 168.448a146.773333 146.773333 0 0 0-103.168-42.752c-40.192 0-76.8 16.64-103.168 42.752-6.4 6.656-12.544 14.336-17.664 21.76l-308.48 195.84a259.84 259.84 0 0 0-117.248 67.072 258.858667 258.858667 0 0 0-75.776 182.528c0 70.144 28.416 134.4 74.24 180.736l1.536 1.792a256.298667 256.298667 0 0 0 182.528 75.776 253.781333 253.781333 0 0 0 182.272-75.776 258.133333 258.133333 0 0 0 67.072-116.736l195.584-308.48c8.192-5.12 15.36-11.008 22.016-17.92a145.706667 145.706667 0 0 0 42.752-103.168c0-40.192-16.128-77.056-42.496-103.424z m-326.144 608a200.277333 200.277333 0 0 1-140.8 58.368 199.808 199.808 0 0 1-140.8-58.368l-1.28-1.536a196.565333 196.565333 0 0 1-56.832-139.264 198.741333 198.741333 0 0 1 198.912-198.912c54.784 0 104.704 22.272 140.8 58.112v0.256a199.850667 199.850667 0 0 1 0 281.344z m111.957333-191.104a259.157333 259.157333 0 0 0-70.485333-132.224v0.512c-42.069333-42.069333-69.717333-64.256-129.706667-69.077333 2.986667 0.170667-2.773333-0.213333 0 0l165.290667-112.938667c0 38.912 15.616 75.52 41.216 101.376l1.536 1.792a146.176 146.176 0 0 0 103.168 43.264c-8.192 1.28 8.704 0 0 0l-111.018667 167.296z m172.458667-252.032c-15.616 15.616-37.12 25.088-61.44 25.088a86.912 86.912 0 0 1-61.184-25.088l-1.28-1.28a85.973333 85.973333 0 0 1-24.32-60.416c0-23.808 9.728-45.824 25.6-61.184 15.616-15.872 37.376-25.6 61.184-25.6 24.32 0 45.824 9.728 61.44 25.6 15.616 15.616 25.344 37.376 25.344 61.184 0 23.808-9.472 45.568-25.344 61.696z"
        fill={getIconColor(color, 0, "currentColor")}
      />
      <path
        d="M459.605333 564.821333l0.853334 1.109334a106.410667 106.410667 0 0 1-0.853334 149.504h-0.298666a107.050667 107.050667 0 0 1-75.178667 31.232 106.666667 106.666667 0 0 1-75.434667-31.232l-1.408-1.365334A108.117333 108.117333 0 0 1 277.333333 640c0-29.312 11.946667-55.808 31.36-74.88v-0.298667a106.752 106.752 0 0 1 75.434667-31.488c29.696 0 56.32 12.16 75.477333 31.488z m-30.250666 30.08a63.445333 63.445333 0 0 0-45.269334-18.901333c-17.664 0-33.621333 7.296-45.269333 18.901333v0.170667A62.72 62.72 0 0 0 320 640c0 17.066667 6.997333 32.853333 17.962667 44.416l0.853333 0.853333A64 64 0 0 0 384 704c17.664 0 33.493333-7.296 45.141333-18.773333h0.170667a63.872 63.872 0 0 0 0.512-89.685334l-0.512-0.64z"
        fill={getIconColor(color, 1, "currentColor")}
      />
    </svg>
  );
};

IconIconTelescopeUncover.defaultProps = {
  size: 14,
};

export default IconIconTelescopeUncover;

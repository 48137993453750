/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconHome: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M486.4 59.434667a37.632 37.632 0 0 1 46.933333 0l352.810667 282.24c8.96 7.125333 14.122667 17.92 14.122667 29.354666v564.48a37.632 37.632 0 0 1-37.632 37.589334H157.098667a37.632 37.632 0 0 1-37.632-37.632V371.029333c0-11.434667 5.205333-22.229333 14.122666-29.354666l352.768-282.24z m-291.669333 838.4h159.914666V629.76c0-20.778667 16.853333-37.632 37.632-37.632h235.178667c20.778667 0 37.632 16.853333 37.632 37.632v268.074667h159.914667V389.12L509.866667 137.002667l-315.136 252.16v508.672z m395.093333 0v-230.442667h-159.914667v230.442667h159.914667z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconHome.defaultProps = {
  size: 14,
};

export default IconHome;

import axios from "axios";
import { uniqBy } from "lodash";
import { generateRenderFilterField, SelectOption } from "./generateMultiSelectFilterField";
import {
  PodcastMultiSelectSource,
  PodcastNameMultiSelectName,
} from "@/components/Filter/SourceFilter/multiselectConstants";

const getRemoteOptions = async (prefix: string) => {
  const {
    data: { data },
  } = await axios.get("/api/gateway/auto_complete", {
    params: {
      prefix,
      source: "podcast",
    },
  });

  return uniqBy(
    data?.map((v) => ({
      title: v.podcast_name,
      icon_url: v.podcast_playlist_image_url,
      value: v.value,
      key: v.value,
    })) as SelectOption[],
    "value"
  );
};

const renderFilterField = generateRenderFilterField({
  placeholder: "Podcast Source",
  noResultTip: "No podcast source found",
  getRemoteOptions,
  name: PodcastNameMultiSelectName,
  source: PodcastMultiSelectSource,
});
export default renderFilterField;

export const TwitterSpaceUserMultiSelectName = "twitterSpaceUser";
export const TwitterSpaceUserMultiSelectSource = "TwitterSpace";
export const ResearchSourceMultiSelectName = "research";
export const ResearchSourceMultiSelectSource = "Research";
export const GovernanceMultiSelectName = "governance";
export const GovernanceMultiSelectSource = "Governance";
export const MediumMultiSelectSource = "Medium";
export const DiscordMultiSelectSource = "Discord";
export const TwitterMultiSelectSource = "Twitter";
export const TwitterUserMultiSelectName = "twitterByUser";
export const DiscordServerMultiSelectName = "discordByServer";
export const MediumAuthorMultiSelectName = "mediumByAuthor";
export const MediumCollectionMultiSelectName = "mediumByCollection";
export const NewsMultiSelectSource = "News";
export const NewsMultiSelectName = "news";
export const PodcastMultiSelectSource = "Podcast";
export const PodcastNameMultiSelectName = "podcastByName";
export const ConferenceMultiSelectSource = "Conference";
export const ConferenceMultiSelectName = "conference";
export const NewsletterMultiSelectSource = "Newsletter";
export const NewsletterMultiSelectName = "newsletter";
export const WarpCastMultiSelectSource = "WarpCast";
export const WarpCastMultiSelectName = "warpCast";
export const TelegramMultiSelectSource = "Telegram";
export const TelegramMultiSelectName = "telegram";
export const VoteMultiSelectSource = "Vote";
export const VoteMultiSelectName = "vote";

export const MultiSelectExcludeFieldsMap = {
  twitter_space_creator_user_id: `${TwitterSpaceUserMultiSelectName}_${TwitterSpaceUserMultiSelectSource}`,
  research_data_source: `${ResearchSourceMultiSelectName}_${ResearchSourceMultiSelectSource}`,
  forum_data_source: `${GovernanceMultiSelectName}_${GovernanceMultiSelectSource}`,
  medium_author_id: `${MediumAuthorMultiSelectName}_${MediumMultiSelectSource}`,
  medium_collection_id: `${MediumCollectionMultiSelectName}_${MediumMultiSelectSource}`,
  discord_server_id: `${DiscordServerMultiSelectName}_${DiscordMultiSelectSource}`,
  twitter_user_id: `${TwitterUserMultiSelectName}_${TwitterMultiSelectSource}`,
  news_data_source: `${NewsMultiSelectName}_${NewsMultiSelectSource}`,
  podcast_name: `${PodcastNameMultiSelectName}_${PodcastMultiSelectSource}`,
  conference_data_source: `${ConferenceMultiSelectName}_${ConferenceMultiSelectSource}`,
  newsletter_data_source: `${NewsletterMultiSelectName}_${NewsletterMultiSelectSource}`,
  vote_project_name: `${VoteMultiSelectName}_${VoteMultiSelectSource}`,
};

import { useRef, useEffect, useState } from "react";
import { Flex, chakra, Text, Tooltip } from "@chakra-ui/react";

interface DropmenuRenderProps {
  discord_server_name: string;
  discord_server_icon_url: string;
  value: string;
}

const DropmenuRender = ({ discord_server_name, discord_server_icon_url }: DropmenuRenderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpoen] = useState(false);

  useEffect(() => {
    const hasToolTip = nameRef?.current?.offsetWidth < nameRef?.current?.scrollWidth;
    const handler = () => {
      if (hasToolTip) {
        if (isOpen) {
          setIsOpoen(false);
        } else {
          setIsOpoen(true);
        }
      }
    };
    const curr = containerRef?.current;
    curr?.addEventListener("mouseenter", handler);
    curr?.addEventListener("mouseleave", handler);
    return () => {
      curr.removeEventListener("mouseenter", handler);
      curr.removeEventListener("mouseleave", handler);
    };
  }, [isOpen]);

  const elementToShow = (
    <Flex gap="4px">
      {discord_server_icon_url ? (
        <chakra.img src={discord_server_icon_url} w="20px" h="20px" borderRadius="24px" />
      ) : null}
      <Text
        fontSize="14px"
        fontWeight="400"
        lineHeight="20px"
        color="#FFFFFF"
        wordBreak="break-all"
      >
        {discord_server_name}
      </Text>
    </Flex>
  );

  return (
    <Tooltip placement="top" label={elementToShow} isOpen={isOpen} p="8px" minW="356px">
      <Flex alignItems="center" gap="4px" p="4px" maxW="100%" ref={containerRef}>
        {discord_server_icon_url ? (
          <chakra.img src={discord_server_icon_url} w="20px" h="20px" borderRadius="24px" />
        ) : null}
        <Text
          fontSize="14px"
          fontWeight="400"
          lineHeight="20px"
          color="#FFFFFF"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          ref={nameRef}
        >
          {discord_server_name}
        </Text>
      </Flex>
    </Tooltip>
  );
};

export default DropmenuRender;

/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, SVGAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends Omit<SVGAttributes<SVGElement>, 'color'> {
  size?: number;
  color?: string | string[];
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconIconEditUncover: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M676.138667 93.866667a34.133333 34.133333 0 0 1 24.149333 9.984l155.861333 155.904a34.133333 34.133333 0 0 1 0 48.298666L414.890667 749.482667a34.133333 34.133333 0 0 1-24.106667 9.984h-156.16A34.133333 34.133333 0 0 1 200.533333 725.333333v-155.306666a34.133333 34.133333 0 0 1 9.984-24.106667l441.514667-442.026667a34.133333 34.133333 0 0 1 24.106667-10.026666z m0 82.432L268.8 584.106667V691.2h107.818667l407.125333-407.296-107.562667-107.605333zM115.2 896a34.133333 34.133333 0 0 1 34.133333-34.133333h768a34.133333 34.133333 0 0 1 0 68.266666h-768a34.133333 34.133333 0 0 1-34.133333-34.133333z"
        fill={getIconColor(color, 0, 'currentColor')}
      />
    </svg>
  );
};

IconIconEditUncover.defaultProps = {
  size: 14,
};

export default IconIconEditUncover;
